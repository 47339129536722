import { useLazyQuery } from '@apollo/client';
import React from 'react';
import googleBtn from '../../assets/images/social/btn_google_signin_light@2x.png';
import { SIGN_UP_METHODS } from '../../shared/constants';
import { signInWithGoogle } from '../../shared/firebase';
import { CREATE_USER_FROM_FIREBASE } from '../../shared/gqlQueries/createUserFromFirebase';
import { setAuthToken } from '../../shared/helpers/localStorageHelper';

const GoogleSignUp = ({
  nextStep,
  setEmail,
  setFirstName,
  setLastName,
  setErrors,
  setSignUpMethod,
}) => {
  const [createUser, { data }] = useLazyQuery(CREATE_USER_FROM_FIREBASE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      console.log('[GoogleSignUp] createUser', data);
      nextStep();
      setSignUpMethod(SIGN_UP_METHODS.GOOGLE);
    },
    onError: (error) => {
      // console.log('[GoogleSignUp] error createUser gql', error.graphQLErrors);
      error.graphQLErrors?.forEach((err) =>
        setErrors((prevArr) => [...prevArr, err.message || 'Error creating user']),
      );
    },
  });

  function handleSignUp() {
    return signInWithGoogle()
      .then(async (data) => {
        // User signed in successfully.
        try {
          console.log('[GoogleLogin] user', data.user);
          const token = await data.user.getIdToken();
          setAuthToken(token);
          setEmail(data.user.email);
          setFirstName(data.user.displayName?.split(' ')[0] || '');
          setLastName(data.user.displayName?.split(' ')[1] || '');
          createUser();
        } catch (e) {
          console.error(e);
        }
      })
      .catch((e) => setErrors((prevArr) => [...prevArr, e.message || 'Google sign up error']));
  }
  return (
    <button className="mt-6 focus:outline-none">
      <img
        src={googleBtn}
        alt="Google Sign Up"
        className="w-64"
        onClick={async () => await handleSignUp()}
      />
    </button>
  );
};

export default GoogleSignUp;
