import gql from 'graphql-tag';

export const RIDE_REQUESTS_QUERY = gql`
  query rideRequests {
    rideRequests {
      id
      passenger {
        id
        firstName
        lastName
        email
        phone
        userImg
      }
      ride {
        id
        driver {
          id
          firstName
          lastName
          gender
          userImg
          userVehicles {
            make {
              name
            }
            model
            spec {
              engineSize
              fuelConsumption
            }
          }
        }
        seats
        startDate
        pricePerSeat
        startPoint {
          name
          location {
            lat
            lng
          }
          eta
        }
        endPoint {
          name
          location {
            lat
            lng
          }
          eta
        }
        waypoints {
          id
          name
        }
        waypointsEta {
          waypointId
          eta
        }
      }
      status
      requestedSeatsCount
      message
    }
  }
`;
