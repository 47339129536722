import React from 'react';
import { DirectionsRenderer, Polyline } from '@react-google-maps/api';

export const DirectionsRendererComponent = ({
  response,
  map,
  selectRouteIndex,
  setSelectRouteIndex,
  findRide,
  getNearestPoints,
}) => {
  return (
    <>
      {response !== null && (
        <DirectionsRenderer
          options={{
            directions: response,
            map: map,
            polylineOptions: {
              strokeColor: '#00CCBF',
              strokeWeight: 3,
              strokeOpacity: 0,
              // suppressPolylines: true,
              // clickable: true
              // editable: true
            },
            // draggable: true,
            markerOptions: {
              // icon: LocationImg,
              opacity: 0,
            },
            icon: { scale: 3 },
          }}
          onLoad={(directionsRenderer) => {
            // console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer);
          }}
          onUnmount={(directionsRenderer) => {
            // console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer);
          }}
          zIndex={0}
        />
      )}

      {response !== null &&
        response.routes.map((route, index) => (
          <Polyline
            key={'Polyline' + index}
            options={{
              strokeColor: selectRouteIndex === index ? '#00CCBF' : '#5750FF',
              strokeOpacity: selectRouteIndex === index ? '1' : '0.4',
              strokeWeight: 3,
              map: map,
              geodesic: true,
              // suppressPolylines: true,
              clickable: !findRide,
              // editable: true
              // draggable: true,
            }}
            onLoad={(event) => {
              // console.log('Polyline onLoad Polyline: ', event);
            }}
            path={route.overview_path}
            onClick={() => {
              // changeResponseRoutesStatus(response, index.js);
              setSelectRouteIndex(index);
              getNearestPoints(response, index);
            }}
            zIndex={route.selectStatus ? 10 : 0}
          />
        ))}
    </>
  );
};
