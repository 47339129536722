import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

// import { YearPickerComponent } from '../YearPicker';
import YearPickerComponentWithStyles from '../YearDatePicker';

import { UPDATE_USER_PREFERENCES } from '../../shared/gqlQueries/updateUserPreferences';
import { UPDATE_USER } from '../../shared/gqlQueries/updateUser';
import { UPDATE_USER_EDIT } from '../../shared/gqlQueries/updateUserEdit';
import { ME_QUERY } from '../../shared/gqlQueries/me';
import { CARMAKERS_QUERY } from '../../shared/gqlQueries/carmakers';
import { VEHICLES_QUERY } from '../../shared/gqlQueries/vehicles';
import { UPLOAD_PROFILE_FILE } from '../../shared/gqlQueries/uploadFiles';
import { ROLES } from '../../shared/constants';
import { useAuth } from '../../components/contexts/AuthContext';
// import NualeSelect from '../inputs/NualeSelect';
import { BecomeDriver } from '../';
import { UploadFiles } from '../UploadFiles/index';

import { PreferencesList } from './preferences';
import { InfoInputs } from './setInfoInput';

import profile_x3 from '../../assets/images/menu/profile@3x.png';
import mytLogo from '../../assets/images/myt-money-logo.png';

export const ProfileRider = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const [apply, setApply] = useState(false);
  const [showUploadFilesWindow, setShowUploadFilesWindow] = useState(false);
  const [userAvatarImage, setUserAvatarImage] = useState([]);
  const [info, setInfo] = useState(false);
  const [firstNameChange, setFirstNameChange] = useState('');
  const [lastNameChange, setLastNameChange] = useState('');
  // const [emailChange, setEmailChange] = useState('');
  // const [phoneChange, setPhoneChange] = useState('');
  const [genderChange, setGenderChange] = useState('');
  const [yearOfRegistrationChange, setYearOfRegistrationChange] = useState(new Date());
  const [bankNameChange, setBankNameChange] = useState('');
  const [bankAccountNumberChange, setBankAccountNumberChange] = useState('');
  const [beneficiaryNameChange, setBeneficiaryNameChange] = useState('');
  const [driverLicenceNumberChange, setDriverLicenceNumberChange] = useState('');
  const [idCardChange, setIdCardChange] = useState('');
  const [brandChange, setBrandChange] = useState('');
  const [modelChange, setModelChange] = useState('');
  const [engineSizeChange, setEngineSizeChange] = useState('');
  const [fuelTypeChange, setFuelTypeChange] = useState('');
  const [fuelConsumptionChange, setFuelConsumptionChange] = useState('');
  const [categoryChange, setCategoryChange] = useState('');
  const [carMakers, setCarMakers] = useState([]);
  const [carMakers2, setCarMakers2] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vehicles2, setVehicles2] = useState([]);
  const [showLinkMytMoney, setShowLinkMytMoney] = useState(true);

  const [ladyDrivers, setLadyDrivers] = useState({
    name: 'Lady drivers',
    status: false,
    id: 'ladyDrivers',
  });
  const [ecoFriendlyRides, setEcoFriendlyRides] = useState({
    name: 'Eco friendly rides',
    status: true,
    id: 'ecoFriendlyRides',
  });
  const [luxuryCars, setLuxuryCars] = useState({
    name: 'Luxury Cars',
    status: false,
    id: 'luxuryCars',
  });
  const [standardCars, setStandardCars] = useState({
    name: 'Standard Cars',
    status: false,
    id: 'standardCars',
  });
  const [moreThanTwoSeats, setMoreThanTwoSeats] = useState({
    name: 'Vehicles with more than 2 seats',
    status: false,
    id: 'moreThanTwoSeats',
  });

  const { setCurrentUser, currentUser } = useAuth();

  const {
    phone,
    email,
    lastName,
    firstName,
    userPreferences,
    driverInfo,
    userImg,
    userVehicles,
    gender,
  } = currentUser;

  const [updateUserPreferences, updateUserPreferencesStatus] = useMutation(
    UPDATE_USER_PREFERENCES,
    {
      errorPolicy: 'all',
      onCompleted: (res) => {
        setCurrentUser(res.updateUser);
        setApply(false);
        setShowPreferences(false);
        console.log('[ProfileInfo] updateUser', res.updateUser);
      },
    },
  );

  const [
    updateUserEdit,
    // updateUserEditStatus
  ] = useMutation(UPDATE_USER_EDIT, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      setCurrentUser(res.updateUser);
      // setInfo(false);
      console.log('[ProfileInfo] updateUser', res.updateUser);
    },
    onError: (error) => {
      console.log('[ProfileInfo] error updateUser', error);
      setInfo(true);
    },
  });

  const [
    updateUser,
    // updateUserStatus
  ] = useMutation(UPDATE_USER, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      setCurrentUser(res.updateUser);
      // setInfo(false);
      console.log('[ProfileInfo] updateUser', res);
    },
    onError: (error) => {
      console.log('[ProfileInfo] error updateUser', error);
      setInfo(true);
    },
  });

  const [getMyInfo, { data }] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      console.log('[DriverInfo] getMyInfo', data);
      setCurrentUser(data.me);
      setShowUploadFilesWindow(false);
      setUserAvatarImage([]);
    },
    onError: (error) => {
      console.log('[DriverInfo] error getMyInfo', error);
    },
  });

  const [uploadUserAvatar, uploadAvatarStatus] = useMutation(UPLOAD_PROFILE_FILE, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('res.uploadUserAvatar', res);
      getMyInfo();
    },
    onError: (error) => {
      console.log('[DriverInfo] error uploadUserAvatar', error);
    },
  });

  function addlabelAndValueInPerElementOfArrayForSelectComponent(arr, typeName, vehicles) {
    let newArr;
    let newObj = {};
    newArr = arr.map((el) => {
      let subNewObj = {};
      newObj = { ...el };
      newObj.label = el[typeName];
      newObj.value = el[typeName].toUpperCase();
      if (vehicles) {
        newObj.fuelTypes = newObj.fuelTypes.map((el2) => {
          subNewObj = { ...el2 };
          subNewObj.label = el2.type;
          subNewObj.value = el2.type.toUpperCase();
          return subNewObj;
        });
        newObj.spec = newObj.spec.map((el2) => {
          subNewObj = { ...el2 };
          subNewObj.label = el2.engineSize;
          subNewObj.value = el2.engineSize;
          return subNewObj;
        });
      }
      return newObj;
    });
    return newArr;
  }

  const [getCarsInfo, carsData] = useLazyQuery(CARMAKERS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[DriverInfo] getCarsInfo', carsData.data.carMakers);
      if (carsData.data && carsData.data.carMakers && carsData.data.carMakers.length) {
        let newArr = addlabelAndValueInPerElementOfArrayForSelectComponent(
          carsData.data.carMakers,
          'name',
        );
        setCarMakers(() => (newArr.length ? newArr.filter((el) => el.name === brandChange) : []));
        setCarMakers2(newArr);
      }
    },
    onError: (error) => {
      console.log('[DriverInfo] error getCarsInfo', error);
    },
  });

  const [getVehicles, vehiclesData] = useLazyQuery(VEHICLES_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[DriverInfo] getVehiclesInfo', vehiclesData.data);
      if (vehiclesData.data && vehiclesData.data.vehicles && vehiclesData.data.vehicles.length) {
        let newArr = addlabelAndValueInPerElementOfArrayForSelectComponent(
          vehiclesData.data.vehicles,
          'model',
          true,
        );
        setVehicles(newArr);
        setVehicles2(newArr);
      }
    },
    onError: (error) => {
      console.log('[DriverInfo] error getVehiclesInfo', error);
    },
  });

  function uploadUserAvatarFunc() {
    console.log('userAvatarImage[0]', userAvatarImage[0]);
    uploadUserAvatar({
      variables: {
        file: userAvatarImage[0],
      },
    });
  }

  function applyUpdateUser() {
    if (currentUser.role === ROLES.driver) {
      let input = {
        firstName: firstNameChange,
        lastName: lastNameChange,
        // email: emailChange,
        // phone: phoneChange,
        gender: genderChange,
        userVehicles: vehicles.length > 1 ? userVehicles[0].id : vehicles[0].id,
        driverInfo: {
          bankName: bankNameChange,
          bankAccountNumber: bankAccountNumberChange,
          beneficiaryName: beneficiaryNameChange,
          yearOfRegistration: new Date(yearOfRegistrationChange).getFullYear(),
          driverLicenceNumber: driverLicenceNumberChange,
          vehicleCategory: categoryChange,
          fuelType: fuelTypeChange,
          engineSize: +engineSizeChange,
          fuelConsumption: fuelConsumptionChange,
          carImages: driverInfo.carImages.map((el) => ({
            key: el.key,
            imgPath: el.imgPath,
          })),
          driverLicenceImages: driverInfo.driverLicenceImages.map((el) => ({
            key: el.key,
            imgPath: el.imgPath,
          })),
          idCard: idCardChange,
        },
      };

      updateUserEdit({
        variables: {
          input,
        },
      });
    } else if (currentUser.role === ROLES.rider) {
      updateUser({
        variables: {
          input: {
            firstName: firstNameChange,
            lastName: lastNameChange,
            // email: emailChange,
            // phone: phoneChange,
            gender: genderChange,
          },
        },
      });
    }
  }

  function applyUpdateUserPreferences() {
    updateUserPreferences({
      variables: {
        input: {
          userPreferences: {
            ladyDrivers: ladyDrivers.status,
            ecoFriendlyRides: ecoFriendlyRides.status,
            luxuryCars: luxuryCars.status,
            standardCars: standardCars.status,
            moreThanTwoSeats: moreThanTwoSeats.status,
          },
        },
      },
    });
  }

  const isPreferences =
    ladyDrivers.status ||
    ecoFriendlyRides.status ||
    luxuryCars.status ||
    standardCars.status ||
    moreThanTwoSeats.status;

  useEffect(() => {
    getCarsInfo();
    getVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLadyDrivers((current) => ({ ...current, status: userPreferences.ladyDrivers }));
    setEcoFriendlyRides((current) => ({ ...current, status: userPreferences.ecoFriendlyRides }));
    setLuxuryCars((current) => ({ ...current, status: userPreferences.luxuryCars }));
    setStandardCars((current) => ({ ...current, status: userPreferences.standardCars }));
    setMoreThanTwoSeats((current) => ({ ...current, status: userPreferences.moreThanTwoSeats }));
  }, [
    userPreferences.ladyDrivers,
    userPreferences.ecoFriendlyRides,
    userPreferences.luxuryCars,
    userPreferences.standardCars,
    userPreferences.moreThanTwoSeats,
  ]);

  useEffect(() => {
    setFirstNameChange(firstName);
    setLastNameChange(lastName);
    // setPhoneChange(phone);
    // setEmailChange(email);
    setGenderChange(gender);
    driverInfo && setBrandChange(userVehicles[0].make.name);
    driverInfo && setModelChange(userVehicles[0].model);
    driverInfo && setBankNameChange(driverInfo.bankName);
    driverInfo && setBankAccountNumberChange(driverInfo.bankAccountNumber);
    driverInfo && setBeneficiaryNameChange(driverInfo.beneficiaryName);
    driverInfo &&
      setYearOfRegistrationChange(new Date(driverInfo.yearOfRegistration, 0, 1, 0, 0, 0));
    driverInfo && setDriverLicenceNumberChange(driverInfo.driverLicenceNumber);
    driverInfo && setIdCardChange(driverInfo.idCard);
    driverInfo && setEngineSizeChange(driverInfo.engineSize);
    driverInfo && setFuelConsumptionChange(driverInfo.fuelConsumption);
    driverInfo && setFuelTypeChange(driverInfo.fuelType);
    driverInfo && setCategoryChange(driverInfo.vehicleCategory);
  }, [
    // phone,
    // email,
    lastName,
    firstName,
    gender,
    userVehicles,
    driverInfo,
    // driverInfo && driverInfo.bankName,
    // driverInfo && driverInfo.bankAccountNumber,
    // driverInfo && driverInfo.beneficiaryName,
    // driverInfo && driverInfo.yearOfRegistration,
    // driverInfo && driverInfo.driverLicenceNumber,
    // driverInfo && driverInfo.idCard,
    // driverInfo && driverInfo.engineSize,
    // driverInfo && driverInfo.fuelConsumption,
    // driverInfo && driverInfo.fuelType
  ]);

  const vehicleCategories = [
    { value: 'STANDARD', label: 'Standard' },
    { value: 'ECOFRIENDLY', label: 'Ecofriendly' },
    { value: 'LUXURY', label: 'Luxury' },
  ];

  const genders = [
    { value: 'MALE', label: 'Male' },
    { value: 'FEMALE', label: 'Female' },
  ];

  const prefValues = [
    [ladyDrivers, setLadyDrivers],
    [ecoFriendlyRides, setEcoFriendlyRides],
    [luxuryCars, setLuxuryCars],
    [standardCars, setStandardCars],
    [moreThanTwoSeats, setMoreThanTwoSeats],
  ];

  const values = {
    userAvatarImage,
  };

  const avatarStyle = {
    backgroundImage: `url(${userImg ? userImg : profile_x3})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };

  return (
    <div
      className="bg-gray-100"
      onClick={() => {
        setShowPreferences(false);
      }}
    >
      <div className="pt-20 px-7 pb-4 bg-white">
        <h1 className="text-3xl font-bold mb-4">Your profile</h1>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div
              className="bg-white w-12 h-12 mr-4 rounded-full border"
              style={avatarStyle}
              onClick={() => setShowUploadFilesWindow(true)}
            >
              {' '}
            </div>
            <div>
              <span className="text-base font-medium">{`${firstName} ${lastName}`}</span>
            </div>
          </div>
          <div>
            {info ? (
              <span
                className="text-sm text-nuale_neon_blue cursor-pointer"
                onClick={() => {
                  applyUpdateUser();
                  setInfo(false);
                }}
              >
                OK
              </span>
            ) : (
              <span
                className="text-sm text-nuale_neon_blue cursor-pointer"
                onClick={() => {
                  setInfo(true);
                }}
              >
                EDIT
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="pt-4 px-4 pb-7">
        <div>
          <h2 className="p-4 bg-white text-base font-medium text-nuale_turquoise">
            Personal Details
          </h2>
          <ul>
            <li className="px-4 py-3 border-b flex justify-between items-center">
              <span className="text-sm font-medium">First Name</span>
              <span className="text-sm">
                {info ? (
                  <InfoInputs
                    value={firstNameChange}
                    onChange={(event) => setFirstNameChange(event.target.value)}
                  />
                ) : (
                  firstName
                )}
              </span>
            </li>
            <li className="px-4 py-3 border-b flex justify-between items-center">
              <span className="text-sm font-medium">Last Name</span>
              <span className="text-sm">
                {info ? (
                  <InfoInputs
                    value={lastNameChange}
                    onChange={(event) => setLastNameChange(event.target.value)}
                  />
                ) : (
                  lastName
                )}
              </span>
            </li>
            <li className="px-4 py-3 border-b flex justify-between items-center">
              <span className="text-sm font-medium">Email</span>
              <span className="text-sm">
                {email}
                {/*{*/}
                {/*info*/}
                {/*? <InfoInputs*/}
                {/*value={emailChange}*/}
                {/*onChange={(event)=> setEmailChange(event.target.value)}*/}
                {/*/>*/}
                {/*: email*/}
                {/*}*/}
              </span>
            </li>
            <li className="px-4 py-3 border-b flex justify-between items-center">
              <span className="text-sm font-medium">Mobile</span>
              <span className="text-sm">
                {phone}
                {/*{*/}
                {/*info*/}
                {/*? <InfoInputs*/}
                {/*value={phoneChange}*/}
                {/*onChange={(event)=> setPhoneChange(event.target.value)}*/}
                {/*/>*/}
                {/*: phone*/}
                {/*}*/}
              </span>
            </li>
            {gender !== 'UNKNOWN' && (
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Gender</span>
                <div className="text-sm">
                  {info ? (
                    <select
                      type="select"
                      value={genderChange}
                      onChange={(event) => setGenderChange(event.target.value)}
                      className="w-36 bg-white"
                    >
                      {genders.map((el, index) => (
                        <option key={`genderEdit${index}`} value={el.value}>
                          {el.value}
                        </option>
                      ))}
                    </select>
                  ) : (
                    gender
                  )}
                </div>
              </li>
            )}
            {driverInfo && (
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">NIC</span>
                <span className="text-sm">
                  {info ? (
                    <InfoInputs
                      value={idCardChange}
                      onChange={(event) => setIdCardChange(event.target.value)}
                    />
                  ) : (
                    driverInfo.idCard
                  )}
                </span>
              </li>
            )}
            <li
              className="px-4 py-3 border-b flex justify-between items-center relative"
              onClick={(event) => event.stopPropagation()}
            >
              <span className="text-sm font-medium">Preferences</span>
              <span
                className="text-sm text-blue-500 cursor-pointer"
                onClick={() => setShowPreferences(true)}
              >
                {isPreferences ? 'EDIT YOUR PREFERENCES' : 'ADD PREFERENCES'}
              </span>
              {showPreferences && (
                <PreferencesList
                  prefValues={prefValues}
                  applyUpdateUserPreferences={applyUpdateUserPreferences}
                  updateUserPreferencesStatus={updateUserPreferencesStatus}
                  setApply={setApply}
                  apply={apply}
                />
              )}
            </li>
            {driverInfo && (
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Bank name</span>
                <span className="text-sm">
                  {info ? (
                    <InfoInputs
                      value={bankNameChange}
                      onChange={(event) => setBankNameChange(event.target.value)}
                    />
                  ) : (
                    driverInfo.bankName
                  )}
                </span>
              </li>
            )}
            {driverInfo && (
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Bank account number</span>
                <span className="text-sm">
                  {info ? (
                    <InfoInputs
                      value={bankAccountNumberChange}
                      onChange={(event) => setBankAccountNumberChange(event.target.value)}
                    />
                  ) : (
                    driverInfo.bankAccountNumber
                  )}
                </span>
              </li>
            )}
            <li
              className={
                driverInfo ? 'px-4 py-3 border-b flex justify-between items-center' : 'hidden'
              }
            >
              <span className="text-sm font-medium">MYT MONEY</span>
              <span className="text-sm">
                {/*{info ? (*/}
                {/*<InfoInputs*/}
                {/*value={bankAccountNumberChange}*/}
                {/*onChange={(event) => setBankAccountNumberChange(event.target.value)}*/}
                {/*/>*/}
                {/*) : (*/}
                {/*driverInfo.bankAccountNumber*/}
                {/*)}*/}
              </span>
            </li>
          </ul>
        </div>
        <button
          onClick={() => {
            setShowLinkMytMoney(true);
          }}
          className={
            'inline-block mt-6 w-full font-bold text-base px-4 py-2 text-white bg-nuale_turquoise rounded-full ml-auto uppercase'
          }
        >
          Link myT Money
        </button>
        {driverInfo ? (
          <div className="mt-8 mb-24">
            <h2 className="p-4 bg-white text-base font-medium text-nuale_turquoise">
              Driver information
            </h2>
            <ul>
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Car Brand</span>
                <span className="text-sm">
                  {info ? (
                    <select
                      name="select"
                      value={brandChange.toUpperCase()}
                      onChange={(event) => {
                        setBrandChange(event.target.value);
                        setCarMakers((current) =>
                          carMakers2.filter((el) => el.value === event.target.value),
                        );
                        modelChange && setModelChange('');
                        engineSizeChange && setEngineSizeChange('');
                        fuelConsumptionChange && setFuelConsumptionChange('');
                        fuelTypeChange && setFuelTypeChange('');
                      }}
                      placeholder="Vehicle Brand"
                      className="w-36 bg-white"
                    >
                      {carMakers2.map((el, index) => (
                        <option
                          key={`brandEdit${index}`}
                          value={el.value}
                          // selected={true}
                        >
                          {el.value}
                        </option>
                      ))}
                    </select>
                  ) : (
                    userVehicles[0].make.name
                  )}
                </span>
                {/*{`${userVehicles[0].make.name} ${userVehicles[0].model}`}*/}
              </li>
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Car model</span>
                <span className="text-sm">
                  {info ? (
                    <select
                      type="select"
                      value={
                        // vehicles2.length &&
                        // carMakers.length &&
                        // vehicles2.filter(el=> el.value === modelChange).length &&
                        // vehicles2.filter(el=> el.value === modelChange)[0].value
                        modelChange.toUpperCase()
                      }
                      onChange={(event) => {
                        setModelChange(event.target.value);
                        // setCarMakers((current) => carMakers2.filter((el) => el.value === event.target.value));
                        engineSizeChange && setEngineSizeChange('');
                        fuelConsumptionChange && setFuelConsumptionChange('');
                        fuelTypeChange && setFuelTypeChange('');
                      }}
                      placeholder="Vehicle Model"
                      className="w-36 bg-white"
                    >
                      <option value=""> </option>
                      {vehicles2.length &&
                        carMakers.length &&
                        vehicles2.filter((b) => b.make.id === carMakers[0].id).length &&
                        vehicles2
                          .filter((b) => b.make.id === carMakers[0].id)
                          .map((el, index) => (
                            <option key={`modelEdit${index}`} value={el.value}>
                              {el.value}
                            </option>
                          ))}
                    </select>
                  ) : (
                    userVehicles[0].model
                  )}
                </span>
                {/*{`${userVehicles[0].make.name} ${userVehicles[0].model}`}*/}
              </li>
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Engine size</span>
                <span className="text-sm">
                  {info ? (
                    <select
                      type="select"
                      value={engineSizeChange}
                      onChange={(event) => {
                        setEngineSizeChange(event.target.value);
                        setFuelConsumptionChange(
                          () =>
                            vehicles2
                              .filter(
                                (b) =>
                                  b.make.id === carMakers[0].id &&
                                  b.model.toLowerCase() === modelChange.toLowerCase(),
                              )[0]
                              .spec.find((el) => +event.target.value === +el.value).fuelConsumption,
                        );
                      }}
                      className="w-36 bg-white"
                    >
                      <option value=""> </option>
                      {vehicles2.length &&
                        carMakers.length &&
                        vehicles2.filter(
                          (b) =>
                            b.make.id === carMakers[0].id &&
                            b.model.toLowerCase() === modelChange.toLowerCase(),
                        ).length &&
                        vehicles2
                          .filter(
                            (b) =>
                              b.make.id === carMakers[0].id &&
                              b.model.toLowerCase() === modelChange.toLowerCase(),
                          )[0]
                          .spec.map((el, index) => (
                            <option key={`enginSizeEdit${index}`} value={el.value}>
                              {el.value}
                            </option>
                          ))}
                    </select>
                  ) : (
                    driverInfo.engineSize
                  )}
                </span>
              </li>
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Powered by</span>
                <span className="text-sm">
                  {info ? (
                    <select
                      type="select"
                      value={fuelTypeChange}
                      onChange={(event) => {
                        setFuelTypeChange(event.target.value);
                        setVehicles(
                          () =>
                            vehicles2.length &&
                            vehicles2.filter(
                              (b) =>
                                b.make.id === carMakers[0].id &&
                                b.model.toLowerCase() === modelChange.toLowerCase(),
                            ),
                        );
                      }}
                      className="w-36 bg-white"
                    >
                      <option value=""> </option>
                      {vehicles2.length &&
                        carMakers.length &&
                        vehicles2.filter(
                          (b) =>
                            b.make.id === carMakers[0].id &&
                            b.model.toLowerCase() === modelChange.toLowerCase(),
                        ).length &&
                        vehicles2
                          .filter(
                            (b) =>
                              b.make.id === carMakers[0].id &&
                              b.model.toLowerCase() === modelChange.toLowerCase(),
                          )[0]
                          .fuelTypes.map((el, index) => (
                            <option key={`fuelTypeEdit${index}`} value={el.value}>
                              {el.value}
                            </option>
                          ))}
                    </select>
                  ) : (
                    driverInfo.fuelType
                  )}
                </span>
              </li>
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Category</span>
                <span className="text-sm">
                  {info ? (
                    <select
                      type="select"
                      value={categoryChange}
                      onChange={(event) => setCategoryChange(event.target.value)}
                      className="w-36 bg-white"
                    >
                      {vehicleCategories.map((el, index) => (
                        <option key={`categoryEdit${index}`} value={el.value}>
                          {el.value}
                        </option>
                      ))}
                    </select>
                  ) : (
                    driverInfo.vehicleCategory
                  )}
                </span>
              </li>
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Car registration</span>
                <span className="text-sm" id="yearPickerEdit">
                  {info ? (
                    <YearPickerComponentWithStyles
                      placeholder="Year of registration" // placeholder not work here, look at useEffect
                      value={yearOfRegistrationChange}
                      onChange={(date) => setYearOfRegistrationChange(date)}
                      status={'yearPickerEdit'}
                    />
                  ) : (
                    // <YearPickerComponent
                    //   placeholder="Year of registration" // placeholder not work here, look at useEffect
                    //   value={yearOfRegistrationChange}
                    //   onChange={(date) => setYearOfRegistrationChange(date)}
                    //   status={'yearPickerEdit'}
                    // />
                    driverInfo.yearOfRegistration
                  )}
                  {/*{*/}
                  {/*info*/}
                  {/*? <InfoInputs*/}
                  {/*value={yearOfRegistrationChange}*/}
                  {/*onChange={(event)=> setYearOfRegistrationChange(event.target.value)}*/}
                  {/*/>*/}
                  {/*: driverInfo.yearOfRegistration*/}
                  {/*}*/}
                </span>
              </li>
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">License number</span>
                <span className="text-sm">
                  {info ? (
                    <InfoInputs
                      value={driverLicenceNumberChange}
                      onChange={(event) => setDriverLicenceNumberChange(event.target.value)}
                    />
                  ) : (
                    driverInfo.driverLicenceNumber
                  )}
                </span>
              </li>
              <li className="px-4 py-3 border-b flex justify-between items-center">
                <span className="text-sm font-medium">Beneficiary name</span>
                <span className="text-sm">
                  {info ? (
                    <InfoInputs
                      value={beneficiaryNameChange}
                      onChange={(event) => setBeneficiaryNameChange(event.target.value)}
                    />
                  ) : (
                    driverInfo.beneficiaryName
                  )}
                </span>
              </li>
            </ul>
          </div>
        ) : (
          <BecomeDriver />
        )}
      </div>

      <UploadFiles
        values={values}
        showUploadFilesWindow={showUploadFilesWindow}
        setShowUploadFilesWindow={setShowUploadFilesWindow}
        setCarImages={[]}
        setDriverLicenceImages={[]}
        setUserAvatarImage={setUserAvatarImage}
        imagesState={'profile'}
        delItemImage={() => setUserAvatarImage([])}
        uploadUserAvatarFunc={uploadUserAvatarFunc}
        uploadAvatarStatus={uploadAvatarStatus}
      />

      {/* start link myt money */}
      {showLinkMytMoney && (
        <div className="bg-black bg-opacity-25 z-20 text-center absolute bottom-0 left-0 right-0 w-full h-full">
          <div className="fixed bottom-0 left-0 p-4 bg-white w-full">
            <div className="flex justify-items-start">
              <button
                onClick={() => {
                  setShowLinkMytMoney(false);
                }}
                className="w-6 h-6 text-3xl opacity-50 leading-none bg-transparent"
              >
                &times;
              </button>
            </div>
            <img src={mytLogo} alt="myT money logo" className="w-32 m-auto" />
            <h3 className="fontFamily-WorkSans text-3xl mt-6">Reward Drivers</h3>
            <p className="text-sm fontFamily-WorkSans-regular mt-3 px-16">
              Contribute by rewading drivers with myT Money
            </p>
            <a
              href="https://www.myt.mu/money/"
              target="_blank"
              rel="noreferrer"
              className="px-3 py-3 block bg-black leading-none text-white text-base m-auto rounded-full w-11/12 mt-6"
            >
              Create account
            </a>

            <div className="text-center my-6">
              <a
                href="https://www.myt.mu/money/"
                target="_blank"
                rel="noreferrer"
                className="text-nuale_neon_blue mx-auto"
              >
                ALREADY HAVE AN ACCOUNT
              </a>
            </div>
          </div>
        </div>
      )}
      {/* end link myt money */}
    </div>
  );
};
