import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import iconLeft from '../../assets/images/sign-up/icons_left.png';
import { UPDATE_USER } from '../../shared/gqlQueries/updateUser';
import { ROLES, SIGN_UP_METHODS } from '../../shared/constants';
import { useAuth } from '../contexts/AuthContext';
import { setUpRecaptcha } from '../../shared/helpers/setUpRecapcha';

export function ProfileInfo({ values, setters, nextStep, prevStep }) {
  const { setFirstName, setLastName, setEmail, setPhone } = setters;
  const { firstName, lastName, email, phone, signUpMethod } = values;
  const { setCurrentUser, firebaseUser } = useAuth();
  const [validationError, setValidationError] = useState([]);
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      setCurrentUser(res.updateUser);
      console.log('[ProfileInfo] updateUser', res.updateUser);
      nextStep();
    },
    onError: (error) => {
      console.log('[ProfileInfo] error updateUser gql', error.graphQLErrors);
    },
  });

  async function updateUserHandler() {
    if (validateForm()) {
      if (signUpMethod === SIGN_UP_METHODS.GOOGLE) {
        await setUpRecaptcha();
        const appVerifier = window.recaptchaVerifier;
        await firebaseUser.linkWithPhoneNumber(phone, appVerifier).then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          nextStep();
        });
      }

      if (signUpMethod === SIGN_UP_METHODS.PHONE) {
        // update email for firebase user
        firebaseUser
          .updateEmail(email)
          .then(() => {
            // update user on our backend
            updateUser({
              variables: {
                input: {
                  firstName,
                  lastName,
                  email,
                  role: ROLES.rider,
                  // phone,
                  // selectedVehicle: '60882aa45a261861fe148972',
                },
              },
            });
          })
          .catch((e) => {
            console.log('[ProfileInfo] Error updating firebase user email', e);
            setValidationError((prevArr) => [
              ...prevArr,
              e.message || 'Error updating firebase user email',
            ]);
          });
      }
    }
  }

  function validateForm() {
    setValidationError([]);
    let isValid = true;
    if (!firstName) {
      setValidationError((prevArr) => [...prevArr, 'First Name is required']);
      isValid = false;
    }
    if (!lastName) {
      setValidationError((prevArr) => [...prevArr, 'Last Name is required']);
      isValid = false;
    }
    if (!email) {
      setValidationError((prevArr) => [...prevArr, 'Email is required']);
      isValid = false;
    }

    let tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!!email && !tester.test(email)) {
      setValidationError((prevArr) => [...prevArr, 'Email is not valid']);
      isValid = false;
    }

    if (!phone) {
      setValidationError((prevArr) => [...prevArr, 'Phone number is required']);
      isValid = false;
    }

    return isValid;
  }

  return (
    <div>
      <div id="recaptcha-container"></div>
      <div className="py-2 flex flex-row items-center">
        <button className="focus:outline-none p-1.5" onClick={() => prevStep()}>
          <img src={iconLeft} alt="Back" className="w-4" />
        </button>
        <div className="font-bold ml-3">Back</div>
      </div>

      <div>
        <h1 className="font-bold" style={{ fontSize: 32 }}>
          Sign Up
        </h1>
      </div>

      <div className="mt-6">To create your Nuale profile information</div>

      <div className="w-full flex flex-col mt-10">
        <input
          type="text"
          name="firstName"
          id="firstName"
          required
          placeholder="First Name*"
          className="bg-gray-100 text-black rounded-sm px-4 py-2 w-full"
          value={firstName}
          // onBlur={validateForm}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          name="lastName"
          id="lastName"
          required
          placeholder="Last Name*"
          className="bg-gray-100 text-black rounded-sm px-4 py-2 w-full mt-4"
          value={lastName}
          // onBlur={validateForm}
          onChange={(e) => setLastName(e.target.value)}
        />
        <input
          type="email"
          name="email"
          id="email"
          required
          placeholder="Email*"
          className="bg-gray-100 text-black rounded-sm px-4 py-2 w-full mt-4"
          value={email}
          // onBlur={validateForm}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="tel"
          name="phone"
          id="phone"
          required
          placeholder="+230 x xxx xxxx"
          className="bg-gray-100 text-black rounded-sm px-4 py-2 w-full mt-4"
          value={phone}
          // onBlur={validateForm}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>

      <button
        disabled={loading}
        className="bg-black text-white text-base font-bold px-4 py-2 rounded-full block mt-6 focus:outline-none w-full disabled:opacity-50"
        onClick={() => updateUserHandler()}
      >
        Next
      </button>

      {validationError &&
        validationError.map((err, i) => (
          <div className="bg-red-300 px-5 py-2 mt-4 rounded-sm" key={i}>
            {err}
          </div>
        ))}

      <div className="text-sm mt-6 font-medium">
        By continuing, I confirm that I have read and agree to the{' '}
        <a href="/" className="text-neon_blue">
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a href="/" className="text-neon_blue">
          Privacy Policy
        </a>
      </div>
    </div>
  );
}
