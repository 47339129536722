import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Map,
  MapFindForm,
  AddPickupPoint,
  Menu,
  Header,
  Popup,
  ScheduleRideDetailes,
} from '../../components';
import { useAuth } from '../../components/contexts/AuthContext';
import { useLoadScript } from '@react-google-maps/api';
import { useMutation, useLazyQuery } from '@apollo/client';
import Geocode from 'react-geocode';
import OneSignal from 'react-onesignal';
import axios from 'axios';

import { CREATE_RIDE } from '../../shared/gqlQueries/createRide';
import { RIDE_COST_PER_SEAT_QUERY } from '../../shared/gqlQueries/rideCostPerSeat';
import { CREATE_NOTIFICATION } from '../../shared/gqlQueries/createNotification';
import { RIDES_BY_DRIVER_ID_QUERY } from '../../shared/gqlQueries/findRidesByDriverId';
import { RIDE_REQUESTS_QUERY } from '../../shared/gqlQueries/rideRequests';
import { ROUTES, LINK_SET_GEOLOCATION, NUALE_NOTIFICATION_TYPE, RIDE_REQUEST_STATUS } from '../../shared/constants';

const libraries = ['places'];

export function AddRide() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
    region: 'US',
  });

  let history = useHistory();
  const { isUserAuthenticated, currentUser } = useAuth();
  const [centerCoords, setCenterCoords] = useState({ lat: -20.3, lng: 57.5833333 });
  const [mapHeight, setMapHeight] = useState('70%');
  const [response, setResponse] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [markerStart, setMarkerStart] = useState();
  const [markerEnd, setMarkerEnd] = useState();
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [isAddPickupPoint, setAddPickupPoint] = useState(false);
  const [isScheduleRide, setScheduleRide] = useState(false);
  const [isActiveRide, setActiveRide] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [wayPoints3, setWayPoints3] = useState([]);
  const [wayPoints4, setWayPoints4] = useState([]);
  const [pickUpPoint, setPickUpPoint] = useState(null);
  const [dropOffPoint, setDropOffPoint] = useState(null);
  const [startRequests, setStartRequests] = useState(true);
  const [seatsNumber, setSeatsNumber] = useState(1);
  const [startPointDate, setStartPointDate] = useState(Date.now());
  const [totalDuration, setTotalDuration] = useState(Date.now());
  const [pickUpPointTime, setPickUpPointTime] = useState(Date.now());
  const [dropOffPointTime, setDropOffPointTime] = useState(Date.now());
  const [distanceMarker, setDistanceMarker] = useState([]);
  const [rideCostPerSeat, setRideCostPerSeat] = useState('');
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [geoLocationMessage, setGeoLocationMessage] = useState('');
  const [geoLocationMarkerLoading, setGeoLocationMarkerLoading] = useState(false);
  const [popupContent, setPopupContent] = useState('Your ride has been successfully added.');
  // const [popupTitle, setTitle] = useState("");
  const [geoLocationMarker, setGeoLocationMarker] = useState('');
  const [selectRouteIndex, setSelectRouteIndex] = useState(0);
  const [fuelConsumptionMarker, setFuelConsumptionMarker] = useState([]);
  const [co2Marker, setCo2Marker] = useState([]);
  const [durationMarker, setDurationMarker] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [showScheduledRideDetails, setShowScheduledRideDetails] = useState(true);
  const [notificationsArr, setNotificationsArr] = useState([]);
  const [selectRide, setSelectRide] = useState();
  const [chooseScheduleRide, setChooseScheduleRide] = useState(false);
  // const [scheduledRide, setScheduledRide] = useState();
  const [rideRequestsArr, setRideRequestsArr] = useState([]);
  const [watchGeoPosition, setWatchGeoPosition] = useState();

  const values = {
    response,
    markers,
    markerStart,
    markerEnd,
    origin,
    destination,
    isAddPickupPoint,
    showMenu,
    wayPoints3,
    wayPoints4,
    pickUpPoint,
    dropOffPoint,
    startRequests,
    seatsNumber,
    startPointDate,
    totalDuration,
    pickUpPointTime,
    dropOffPointTime,
    distanceMarker,
    rideCostPerSeat,
    isShowPopup,
    geoLocationMessage,
    selectRouteIndex,
    fuelConsumptionMarker,
    co2Marker,
    durationMarker,
    showForm,
    notificationsArr,
    geoLocationMarker,
    geoLocationMarkerLoading,
    mapHeight,
  };

  const setters = {
    setResponse,
    setMarkers,
    setMarkerStart,
    setMarkerEnd,
    setOrigin,
    setDestination,
    setAddPickupPoint,
    setShowMenu,
    setWayPoints3,
    setWayPoints4,
    setPickUpPoint,
    setDropOffPoint,
    setStartRequests,
    setSeatsNumber,
    setStartPointDate,
    setTotalDuration,
    setPickUpPointTime,
    setDropOffPointTime,
    setDistanceMarker,
    setRideCostPerSeat,
    setIsShowPopup,
    setGeoLocationMessage,
    setSelectRouteIndex,
    setFuelConsumptionMarker,
    setCo2Marker,
    setDurationMarker,
    setShowForm,
    setNotificationsArr,
    setGeoLocationMarker,
    setMapHeight,
  };

  useEffect(() => {
    if (pickUpPoint && dropOffPoint) {
      let obj = {
        // vehicleId: currentUser.userVehicles[0].id,
        fuelTypeName: currentUser.driverInfo.fuelType,
        distanceKm: distanceMarker.length && +(distanceMarker[0].dis / 1000).toFixed(),
        vehicleCategory: currentUser.driverInfo.vehicleCategory,
        fuelConsumption: currentUser.userVehicles[0].spec[0].fuelConsumption,
      };

      getRideCostPerSeat({
        variables: {
          input: obj,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpPoint, dropOffPoint, isUserAuthenticated]);

  useEffect(() => {
    getRideByDriverId({ variables: { id: currentUser.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveRide]);

  useEffect(() => {
    // if (testRideGlobal || testRideByRiderGlobal) {
    if (selectRide) {
      setRideParamsInState(selectRide);
      getWaypointsLocations(selectRide);
      // testRideGlobal && getRideRequestsFunc();
    }
    // return ()=> setTestRideGlobal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveRide]);

  const [createRide, createRideStatus] = useMutation(CREATE_RIDE, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      // console.log('[createRide] res.createRide', res);
      if (res) {
        setPopupContent('Your ride has been successfully added.');
        setIsShowPopup(true);
        createAddRideNotificationFunc(res.createRide.id);
      }
    },
    onError: (error) => {
      console.log('[createRide] error createRide', error);
    },
  });

  const [getRideRequestsFunc, rideRequestsData] = useLazyQuery(RIDE_REQUESTS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
      if (
        rideRequestsData.data &&
        rideRequestsData.data.rideRequests &&
        rideRequestsData.data.rideRequests.length
      ) {
        // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
        setRideRequestsArr(ridesFilterByRideAndPaidStatus(rideRequestsData.data.rideRequests));
      }
    },
    onError: (error) => {
      console.log('[getRideRequestsFunc] error getRideRequestsFunc', error);
    },
  });

  const [getRideCostPerSeat, getRideCostPerSeatData] = useLazyQuery(RIDE_COST_PER_SEAT_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[createRide] rideCostPerSeat', getRideCostPerSeatData.data.getRideCostPerSeat);
      if (getRideCostPerSeatData.data.getRideCostPerSeat) {
        setRideCostPerSeat(getRideCostPerSeatData.data.getRideCostPerSeat);
      }
    },
    onError: (error) => {
      console.log('[createRide] error rideCostPerSeat', error);
    },
  });

  const [getRideByDriverId, getRideByDriverIdData] = useLazyQuery(RIDES_BY_DRIVER_ID_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[getRideByDriverId] getRideByDriverIdData', getRideByDriverIdData.data.findRidesByDriverId);
      if (
        getRideByDriverIdData.data.findRidesByDriverId &&
        getRideByDriverIdData.data.findRidesByDriverId.length
      ) {
        // setRideCostPerSeat(getRideByDriverId.data.findRidesByDriverId);
        let nearestRides = getRideByDriverIdData.data.findRidesByDriverId.filter(
          (ride) => ride.startDate <= +Date.now() + 7200000 && ride.startDate > +Date.now(),
        );
        if (nearestRides.length) {
          setSelectRide([nearestRides.sort((a, b) => a.startDate - b.startDate)[0]]);
          getRideRequestsFunc()
        }
      }
    },
    onError: (error) => {
      console.log('[getRideByDriverId] error getRideByDriverId', error);
    },
  });

  const [
    createAddRideNotification,
    // createAddRideNotificationStatus
  ] = useMutation(CREATE_NOTIFICATION, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('[createAddRideNotification] createAddRideNotification', res);
      if (res) {
      }
    },
    onError: (error) => {
      console.log('[createAddRideNotification] error createAddRideNotification', error);
      // setTitle("Error");
      setPopupContent('Something wrong');
    },
  });

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function createAddRideNotificationFunc(id) {
    createAddRideNotification({
      variables: {
        input: {
          type: NUALE_NOTIFICATION_TYPE.DRIVER_RIDE_ADDED,
          ride: id,
          notificationReceiver: currentUser.id,
          // message: "Ride added"
        },
      },
    });
    OneSignal.sendTag(`${id}`, id, (sentId) => {
      // console.log('sendTag rideId:', sentId);

      let data;
      if (startPointDate - Date.now() >= 7200000) {
        data = {
          filters: [{ field: 'tag', key: `${id}`, relation: '=', value: `${id}` }],
          app_id: process.env.REACT_APP_ONE_SIGNAL_ID,
          contents: {
            en: `Today you have scheduled a trip at ${formatAMPM(new Date(+startPointDate))}`,
          },
          headings: { en: 'Ride scheduled' },
          data: { custom_data: `${id}` },
          send_after: new Date(startPointDate - 7200000),
        };
      } else {
        data = {
          filters: [{ field: 'tag', key: `${id}`, relation: '=', value: `${id}` }],
          app_id: process.env.REACT_APP_ONE_SIGNAL_ID,
          contents: {
            en: `Today you have scheduled a trip at ${formatAMPM(new Date(+startPointDate))}`,
          },
          headings: {
            en: 'Ride scheduled',
          },
          data: { custom_data: `${id}` },
          // "send_after": "Sept 20 2021 19:34:00 GMT+0300"
          // "send_after": "Sept 20 2021 19:20:00 GMT-0700"
          // "web_url": "https://onesignal.com",
          // "app_url": "app://localhost:3000"
        };
      }

      setTimeout(() => {
        axios({
          method: 'post',
          url: 'https://onesignal.com/api/v1/notifications',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Basic ${process.env.REACT_APP_ONE_SIGNAL_REST_API_KEY}`,
            // 'Authorization': 'YTQ1YzNiZjktYjZiNC00NDJhLTljY2YtOTYyYTg5OWYwN2Fi'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          data,
        })
          .then((result) => console.log(result))
          .catch((err) => console.log(err));
      }, 10000);
    });
  }

  function dateWithResetSeconds(date) {
    return +(+date - +new Date(date).getSeconds() * 1000 - +new Date(date).getMilliseconds());
  }

  function showScheduleRide() {
    let obj = {
      startDate: +startPointDate,
      seats: +seatsNumber,
      driver: currentUser.id,
      startPoint: {
        name: origin.name,
        location: markerStart.location,
        // location: markers[0].location,
        eta: dateWithResetSeconds(startPointDate),
      },
      endPoint: {
        name: destination.name,
        location: markerEnd.location,
        // location: markers[1].location,
        eta: dateWithResetSeconds(
          +(pickUpPointTime - pickUpPoint.duration * 1000) + totalDuration * 1000,
        ),
      },
      waypoints: wayPoints4,
      waypointsEta: [
        {
          waypointId: wayPoints4[0].id,
          eta: dateWithResetSeconds(+pickUpPointTime),
        },
        {
          waypointId: wayPoints4[1].id,
          eta: dateWithResetSeconds(
            +pickUpPointTime + (dropOffPoint.duration - pickUpPoint.duration) * 1000,
          ),
        },
      ],
      distance: +(distanceMarker[0].dis / 1000),
      pricePerSeat: rideCostPerSeat,
    };

    setSelectRide([obj]);
    setAddPickupPoint(false);
    setScheduleRide(true);
  }

  function cancelScheduleRide() {
    setSelectRide();
    // setAddPickupPoint(true);
    setScheduleRide(false);
    setActiveRide(false);
    setShowForm(true);
    navigator.geolocation.clearWatch(watchGeoPosition);
    // setGeoLocationMarker('');
    setOrigin('');
    setDestination('');
    setMarkerStart();
    setMarkerEnd();
    setWayPoints4([]);
    setWayPoints3([]);
  }

  function createRideFunc() {
    let obj = {
      startDate: +startPointDate,
      seats: +seatsNumber,
      driver: currentUser.id,
      startPoint: {
        name: origin.name,
        location: markerStart.location,
        // location: markers[0].location,
        eta: dateWithResetSeconds(startPointDate),
      },
      endPoint: {
        name: destination.name,
        location: markerEnd.location,
        // location: markers[1].location,
        eta: dateWithResetSeconds(
          +(pickUpPointTime - pickUpPoint.duration * 1000) + totalDuration * 1000,
        ),
      },
      waypoints: wayPoints4.map((el, index) => el.id),
      waypointsEta: [
        {
          waypointId: wayPoints4[0].id,
          eta: dateWithResetSeconds(+pickUpPointTime),
        },
        {
          waypointId: wayPoints4[1].id,
          eta: dateWithResetSeconds(
            +pickUpPointTime + (dropOffPoint.duration - pickUpPoint.duration) * 1000,
          ),
        },
      ],
      distance: +(distanceMarker[0].dis / 1000),
      // status: RIDE_STATUS.SCHEDULED
    };

    setSelectRide(obj);

    createRide({
      variables: {
        input: obj,
      },
    });
  }

  function ridesFilterByRideAndPaidStatus(arr) {
    if(selectRide) {
      return arr.filter(
        (el) =>
          el.ride.id === selectRide[0].id &&
          +el.ride.waypointsEta[0].eta > +Date.now() &&
          el.status.toLowerCase() === RIDE_REQUEST_STATUS.PAID.toLowerCase(),
      );
      // return arr.filter(el => (el.driver.id === currentUser.id && +el.waypointsEta[0].eta > +Date.now()))
    } else {
      return []
    }
  }

  function popupReset() {
    setIsShowPopup(false);
    !geoLocationMessage && history.push(ROUTES.FIND_RIDE);
    geoLocationMessage && history.push(ROUTES.ADD_RIDE);
    setGeoLocationMessage('');
  }

  function getYourLocation(number = 0) {
    setGeoLocationMarkerLoading(true);
    // Try geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          let address = await geocode(position.coords.latitude, position.coords.longitude);

          setCenterCoords({
            lat: position.coords.latitude + number,
            lng: position.coords.longitude,
          });
          setGeoLocationMarker({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setOrigin({
            name: address,
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
          let newCoords = {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          };
          // setMarkers((current) => {
          //   if (!current.length) {
          //     return [{ ...newCoords }, ...current];
          //   }
          //   return current[1] ? [{ ...newCoords }, current[1]] : [{ ...newCoords }];
          // });
          setMarkerStart(newCoords);

          setGeoLocationMarkerLoading(false);
        },
        (err) => {
          handleLocationError(true);
          console.log(err.code);
          setGeoLocationMessage(`Error: ${err.message}`);
          setGeoLocationMarkerLoading(false);
        },
      );
    } else {
      handleLocationError(false);
      setGeoLocationMarkerLoading(false);
    }
  }

  function watchYourPosition(number = 0) {
    setGeoLocationMarkerLoading(true);
    // Try geolocation.
    if (navigator.geolocation) {
      // console.log('navigator.geolocation', navigator.geolocation);
      let id = navigator.geolocation.watchPosition(
        async (position) => {
          setCenterCoords({
            lat: position.coords.latitude + number,
            lng: position.coords.longitude,
          });
          setGeoLocationMarker({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          setGeoLocationMarkerLoading(false);
        },
        (err) => {
          handleLocationError(true);
          console.log(err.code);
          setGeoLocationMessage(`Error: ${err.message}`);
          setGeoLocationMarkerLoading(false);
        },
      );
      setWatchGeoPosition(id);
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false);
      setGeoLocationMarkerLoading(false);
    }
  }

  async function geocode(lat, lng) {
    console.log('Geocode', Geocode);
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    try {
      let response = await Geocode.fromLatLng(lat, lng);
      return response.results[0].formatted_address;
    } catch (error) {
      console.error(error);
      setGeoLocationMessage(`Error: ${error.message}`);
      setGeoLocationMarkerLoading(false);
      setPopupContent(`Error: ${error.message}`);
      setIsShowPopup(true);
    }
  }

  async function geocodeFromAddress(address) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    try {
      let response = await Geocode.fromAddress(address);
      return response.results[0].geometry.location;
    } catch (error) {
      console.error(error);
      setGeoLocationMessage(`Error: ${error.message}`);
      setGeoLocationMarkerLoading(false);
      setPopupContent(`Error: ${error.message}`);
      setIsShowPopup(true);
    }
  }

  function handleLocationError(browserHasGeolocation) {
    setPopupContent(
      browserHasGeolocation ? (
        // ? 'Error: The Geolocation service failed.'
        <span>
          Nuale does not have permission to use your location.{' '}
          <span>{makeLinkForSetGeolocation('Learn more')}</span>
        </span>
      ) : (
        "Error: Your browser doesn't support geolocation."
      ),
    );
    setIsShowPopup(true);
  }

  function makeLinkForSetGeolocation(content) {
    return (
      <a href={LINK_SET_GEOLOCATION} target="blank" className="text-nuale_neon_blue">
        {content}
      </a>
    );
  }

  async function setRideParamsInState(ride) {
    await setOrigin({ location: { ...ride[0].startPoint.location } });
    await setDestination({ location: { ...ride[0].endPoint.location } });
    await setMarkerStart({ location: { ...ride[0].startPoint.location } });
    await setMarkerEnd({ location: { ...ride[0].endPoint.location } });
    // await setMarkers([
    //   { location: { ...ride[0].startPoint.location } },
    //   { location: { ...ride[0].endPoint.location } },
    // ]);
  }

  async function getWaypointsLocations(ride) {
    let pickUpLocation = await geocodeFromAddress(ride[0].waypoints[0].name);
    let dropOffLocation = await geocodeFromAddress(ride[0].waypoints[1].name);
    await setWayPoints4([
      { name: ride[0].waypoints[0].name, location: pickUpLocation },
      { name: ride[0].waypoints[1].name, location: dropOffLocation },
    ]);
    setStartRequests(true);
  }

  return (
    <div className="container mx-auto w-full box-border relative overflow-hidden">
      {!isAddPickupPoint && (
        <Header setShowMenu={setShowMenu} notificationsCount={notificationsArr.length} />
      )}

      <Map
        values={values}
        setters={setters}
        centerCoords={centerCoords}
        response={response}
        markers={markers}
        markerStart={markerStart}
        markerEnd={markerEnd}
        origin={origin}
        destination={destination}
        loadError={loadError}
        isLoaded={isLoaded}
        fuelConsumption={
          !!(currentUser && currentUser.userVehicles.length)
            ? currentUser.userVehicles[0].spec[0].fuelConsumption
            : null
        }
        wayPoints3={wayPoints3}
        wayPoints4={wayPoints4}
        setWayPoints3={setWayPoints3}
        setWayPoints4={setWayPoints4}
        setPickUpPoint={setPickUpPoint}
        setDropOffPoint={setDropOffPoint}
        startRequests={startRequests}
        setResponse={setResponse}
        setStartRequests={setStartRequests}
        setTotalDuration={setTotalDuration}
        isMarkersClickable={isAddPickupPoint}
        distanceMarker={distanceMarker}
        setDistanceMarker={setDistanceMarker}
        setPickUpPointTime={setPickUpPointTime}
        selectRouteIndex={selectRouteIndex}
        setSelectRouteIndex={setSelectRouteIndex}
        selectedRide={true}
        setFuelConsumptionMarker={setFuelConsumptionMarker}
        fuelConsumptionMarker={fuelConsumptionMarker}
        setCo2Marker={setCo2Marker}
        co2Marker={co2Marker}
        durationMarker={durationMarker}
        setDurationMarker={setDurationMarker}
        geoLocationMarker={geoLocationMarker}
        mapHeight={mapHeight}
        isScheduleRide={isScheduleRide}
      />
      {/*{!isAddPickupPoint && isScheduleRide && selectRide && (*/}
      {!isAddPickupPoint && selectRide && (
        <ScheduleRideDetailes
          setShowForm={setShowForm}
          setShowScheduledRideDetails={setShowScheduledRideDetails}
          showForm={showForm}
          showScheduledRideDetails={showScheduledRideDetails}
          ride={selectRide}
          setMapHeight={setMapHeight}
          watchYourPosition={watchYourPosition}
          cancelScheduleRide={cancelScheduleRide}
          isActiveRide={isActiveRide}
          setActiveRide={setActiveRide}
          setChooseScheduleRide={setChooseScheduleRide}
          chooseScheduleRide={chooseScheduleRide}
          rideRequestsArr={rideRequestsArr}
          // setMapHeightByShowForm={setMapHeightByShowForm}
        />
      )}
      {/*{!isAddPickupPoint && scheduledRide && (*/}
      {/*<ScheduleRideDetailes*/}
      {/*setShowForm={setShowScheduledRideDetails}*/}
      {/*showForm={showScheduledRideDetails}*/}
      {/*ride={scheduledRide}*/}
      {/*setMapHeight={setMapHeight}*/}
      {/*watchYourPosition={watchYourPosition}*/}
      {/*cancelScheduleRide={cancelScheduleRide}*/}
      {/*isActiveRide={isActiveRide}*/}
      {/*setActiveRide={setActiveRide}*/}
      {/*// setMapHeightByShowForm={setMapHeightByShowForm}*/}
      {/*/>*/}
      {/*)}*/}
      {!isAddPickupPoint && !isScheduleRide && !isActiveRide && (
        <MapFindForm
          values={values}
          setters={setters}
          setCenterCoords={setCenterCoords}
          // setTargetCoords={setTargetCoords}
          setResponse={setResponse}
          setMarkers={setMarkers}
          setMarkerStart={setMarkerStart}
          setWayPoints3={setWayPoints3}
          setMarkerEnd={setMarkerEnd}
          setDestination={setDestination}
          setDistanceMarker={setDistanceMarker}
          setDurationMarker={setDurationMarker}
          setFuelConsumptionMarker={setFuelConsumptionMarker}
          setCo2Marker={setCo2Marker}
          setOrigin={setOrigin}
          setAddPickupPoint={setAddPickupPoint}
          origin={origin}
          destination={destination}
          role={currentUser.role}
          addRide={true}
          setStartRequests={setStartRequests}
          seatsNumber={seatsNumber}
          setSeatsNumber={setSeatsNumber}
          startPointDate={startPointDate}
          setStartPointDate={setStartPointDate}
          setPickUpPointTime={setPickUpPointTime}
          getYourLocation={getYourLocation}
          setShowScheduledRideDetails={setShowScheduledRideDetails}
          setShowForm={setShowForm}
          showForm={showForm}
          setMapHeight={setMapHeight}
          geoLocationMarkerLoading={geoLocationMarkerLoading}
          setChooseScheduleRide={setChooseScheduleRide}
          chooseScheduleRide={chooseScheduleRide}
        />
      )}
      {isAddPickupPoint && !isScheduleRide && (
        <AddPickupPoint
          values={values}
          setters={setters}
          getYourLocation={getYourLocation}
          origin={origin}
          destination={destination}
          setCenterCoords={setCenterCoords}
          setAddPickupPoint={setAddPickupPoint}
          showScheduleRide={showScheduleRide}
          wayPoints3={wayPoints3}
          wayPoints4={wayPoints4}
          setWayPoints3={setWayPoints3}
          setWayPoints4={setWayPoints4}
          setPickUpPoint={setPickUpPoint}
          pickUpPoint={pickUpPoint}
          setDropOffPoint={setDropOffPoint}
          dropOffPoint={dropOffPoint}
          setStartRequests={setStartRequests}
          createRideFunc={createRideFunc}
          setPickUpPointTime={setPickUpPointTime}
          pickUpPointTime={pickUpPointTime}
          dropOffPointTime={dropOffPointTime}
          setDropOffPointTime={setDropOffPointTime}
          createRideStatus={createRideStatus}
          rideCostPerSeat={rideCostPerSeat}
          rideCostPerSeatStatus={getRideCostPerSeatData.loading}
          setSelectRouteIndex={setSelectRouteIndex}
          fuelConsumptionMarker={fuelConsumptionMarker}
          co2Marker={co2Marker}
          setMapHeight={setMapHeight}
          geoLocationMarkerLoading={geoLocationMarkerLoading}
        />
      )}
      <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
      <Popup isShowPopup={isShowPopup} popupReset={popupReset} content={popupContent} />
    </div>
  );
}
