import React from 'react';
import {
  // Menu,
  TalkToUsForm,
} from '../../components';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../../shared/constants';

import iconLeft from '../../assets/images/arrow-left-black.svg';

export function TalkToUs() {
  // const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  return (
    <div className="container mx-auto box-border relative overflow-x-hidden">
      <div className="fixed z-20 top-0 left-0 w-full flex justify-start items-center bg-white p-4">
        <button onClick={() => history.push(ROUTES.FIND_RIDE)}>
          <img src={iconLeft} alt="icon back" className="w-4" />
        </button>
        <span className="text-lg font-sans font-medium ml-4">Back</span>
      </div>
      <TalkToUsForm />
      {/*<Menu showMenu={showMenu} setShowMenu={setShowMenu} />*/}
    </div>
  );
}
