import React from 'react';

export const AntiDiscriminationPolicy = () => {
  return (
    <div className="anti-discrimination-policy-block mt-12">
      <h2 className="pb-2 mb-7 font-bold border-b-2 border-gray-400">
        Anti-Discrimination Policies
      </h2>
      <p className="text-sm my-4">
        Discrimination against riders or drivers is strictly prohibited on the Nuale platform.
        Reports of discrimination based on any of the following can result in account deactivation:
      </p>
      <ul className="list-disc text-sm">
        <li>race</li>
        <li>color</li>
        <li>national origin</li>
        <li>religion</li>
        <li>gender, gender identity or expression</li>
        <li>
          physical or mental disability (including the refusal of service animals or mobility
          devices)
        </li>
        <li>medical condition</li>
        <li>marital status</li>
        <li>age</li>
        <li>sexual orientation</li>
      </ul>
      <p className="text-sm my-4">
        Nuale commits to maintaining an inclusive and welcoming community. Our mission is ensuring
        people who need rides most are able to get them.
      </p>
      <p className="text-sm my-4">
        Refusing riders based on any of the protected characteristics listed above is a violation of
        our <span className="underline">Terms of Service.</span>
      </p>
    </div>
  );
};
