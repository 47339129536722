import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// console.log('firebaseConfig', firebaseConfig);

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();

export const firebaseSignOut = () => {
  return auth.signOut();
};

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const signInWithGoogle = () => {
  return auth.signInWithPopup(googleProvider);
  // .catch((error) => {
  //   console.error('[signInWithGoogle] error:', error);
  // });
};
export const signInWithFacebook = () => {
  return auth.signInWithPopup(facebookProvider);
  // .catch((error) => {
  //   console.error('[signInWithFacebook] error:', error);
  // });
};

export default app;
