import React from 'react';

import { MinSpinner } from '../MinSpinner/index';

export const PreferencesList = ({
  prefValues,
  applyUpdateUserPreferences,
  updateUserPreferencesStatus,
  setApply,
  apply,
}) => {
  return (
    <div
      className="absolute top-0 left-0 bg-gray-100 pt-5 pb-8 px-4 w-full"
      style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
    >
      <div className="flex justify-between mb-5">
        <h2 className="text-base font-medium">Your preferences</h2>
        {apply && (
          <span
            className="inline-block text-center px-4 w-20 bg-nuale_turquoise text-white rounded-full"
            onClick={() => {
              applyUpdateUserPreferences();
            }}
          >
            {!updateUserPreferencesStatus.loading ? 'APPLY' : <MinSpinner />}
          </span>
        )}
      </div>
      <ul>
        {prefValues.map((el) => {
          return (
            <li className="flex justify-between items-center mb-4" key={el[0].id}>
              <span className="text-base">{el[0].name}</span>
              <input
                type="checkbox"
                onChange={() => {
                  el[1]((current) => ({ ...current, status: !current.status }));
                  setApply(true);
                }}
                checked={el[0].status}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
