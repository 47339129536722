import React, { useEffect, useState } from 'react';
import { POLICIES } from '../../shared/policy';
import arrowDown from '../../assets/images/chevron-down-solid.svg';

export const PrivacyPolicy = () => {
  const [privacyPolicyArr, setPrivacyPolicyArr] = useState([]);

  useEffect(() => {
    setPrivacyPolicyArr(POLICIES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPrivacyPolicyItem = (policyItem) => {
    let updatedPrivacyPolicyArr = privacyPolicyArr.map((el) =>
      el.id === policyItem.id ? { ...el, status: !el.status } : el,
    );
    setPrivacyPolicyArr([...updatedPrivacyPolicyArr]);
  };

  return (
    <>
      <div className="privacy-policy-block mt-12">
        <h2 className="pb-2 mb-7 font-bold border-b-2 border-gray-400">Nuale Privacy Policy</h2>
        <p className="text-sm underline">Last updated: July 08, 2021</p>
        <p className="text-sm my-4">
          At Nuale our mission is to improve people’s lives by redesigning transportation around
          people and not cars, providing a platform to help you get from point A to point B. To do
          that, we need to collect, use, and share some of your personal information. This Privacy
          Policy is meant to help you understand how Nuale does that and how to exercise the choices
          and rights you have in your information.
        </p>
        <p className="text-sm my-4">
          Nuale’s privacy homepage provides additional information about our commitment to
          respecting your personal information, including ways for you to access and delete that
          information.
        </p>
        <ul className="mb-4 font-medium">
          {privacyPolicyArr?.length &&
            privacyPolicyArr.map((policyItem, index) => (
              <li className="text-sm" key={'termItem' + index}>
                <div
                  className="border-b-2 border-gray-200 py-4 flex items-center justify-between cursor-pointer"
                  onClick={() => showPrivacyPolicyItem(policyItem)}
                >
                  <span>{policyItem.title}</span>
                  <img
                    className={
                      policyItem.status ? 'w-3 transform rotate-180 duration-75' : 'w-3 duration-75'
                    }
                    src={arrowDown}
                    alt="chevron img"
                  />
                </div>
                <p
                  className={
                    policyItem.status
                      ? 'py-4 h-full duration-75'
                      : 'overflow-hidden h-0 duration-75'
                  }
                  dangerouslySetInnerHTML={{ __html: policyItem.content }}
                />
              </li>
            ))}
        </ul>
      </div>
      <div className="privacy-policy-block mt-12">
        <h2 className="pb-2 mb-7 font-bold border-b-2 border-gray-400">
          Anti-Discrimination Policies
        </h2>
        <p className="text-sm my-4">
          Discrimination against riders or drivers is strictly prohibited on the Nuale platform.
          Reports of discrimination based on any of the following can result in account
          deactivation:
        </p>
        <ul className="mb-4 text-sm font-medium">
          <li className="text-sm">race</li>
          <li className="text-sm">color</li>
          <li className="text-sm">national origin</li>
          <li className="text-sm">religion</li>
          <li className="text-sm">gender, gender identity or expression</li>
          <li className="text-sm">
            physical or mental disability (including the refusal of service animals or mobility
            devices)
          </li>
          <li className="text-sm">medical condition</li>
          <li className="text-sm">marital status</li>
          <li className="text-sm">age</li>
          <li className="text-sm">sexual orientation</li>
        </ul>
        <p className="text-sm my-4">
          Nuale commits to maintaining an inclusive and welcoming community. Our mission is ensuring
          people who need rides most are able to get them. Refusing riders based on any of the
          protected characteristics listed above is a violation of our{' '}
          <span className="underline">Terms of Service.</span>
        </p>
      </div>
      <div className="privacy-policy-block mt-12">
        <h2 className="pb-2 mb-7 font-bold border-b-2 border-gray-400">Wheelchair Policy</h2>
        <p className="text-sm my-4">
          Drivers are required by law and Nuale’s policy to transport riders who use foldable
          mobility devices. Drivers are required to assist riders in the storage of their mobility
          devices, unless physically unable to do so.
        </p>
        <ul className="mb-4 text-sm font-medium">
          <li className="text-sm">Mobility devices include the following:</li>
          <li className="text-sm">Foldable wheelchair</li>
          <li className="text-sm">Foldable walker</li>
          <li className="text-sm">Foldable scooter</li>
          <li className="text-sm">Canes, crutches and other smaller assistive devices</li>
        </ul>
        <p className="text-sm my-4">
          Wheelchairs and scooters that can safely and securely fit in the vehicle’s trunk or
          backseat, without obstructing the driver’s view, must be accommodated by drivers on the
          Nuale platform.
        </p>
        <p className="text-sm my-4">
          Drivers may not deny service to or otherwise discriminate against individuals with
          disabilities. Drivers who are found to have refused service due to a mobility device are
          at risk for removal from the platform for violating Nuale’s Terms of Service.
        </p>
      </div>
    </>
  );
};
