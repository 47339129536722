import React, { useState, useEffect } from 'react';

import TimePickerComponentWithStyles from '../../TimePicker/index';
import { MinSpinner } from '../../MinSpinner/index';
import { useAuth } from '../../contexts/AuthContext';

import pointFrom_x1 from '../../../assets/images/mapFindForm/edit-location-black-18-dp.png';
import pointTo_x1 from '../../../assets/images/mapFindForm/location-on-black-18-dp.png';
import iconLeft from '../../../assets/images/arrow-left-solid.svg';
import setTime from '../../../assets/images/setTime.svg';

export const AddPickupPointSecondStep = ({
  // setCenterCoords,
  // setOrigin,
  // setMarkers,
  setAddPickupPoint,
  showScheduleRide,
  origin,
  destination,
  pickUpPoint,
  dropOffPoint,
  // setPickUpPoint,
  setDropOffPoint,
  wayPoints3,
  setWayPoints3,
  setWayPoints4,
  setStartRequests,
  // setSecondStep,
  createRideFunc,
  setPickUpPointTime,
  pickUpPointTime,
  // dropOffPointTime,
  // setDropOffPointTime,
  createRideStatus,
  rideCostPerSeat,
  rideCostPerSeatStatus,
  fuelConsumptionMarker,
  co2Marker,
  setMapHeight,
  // startPointTime,
}) => {
  const [wayPointsFilter, setWayPointsFilter] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [addDropOffPointTime, setAddDropOffPointTime] = useState(true);
  const addDropOffPointTimeStyle = {
    backgroundImage: `url(${setTime})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  const { currentUser } = useAuth();

  useEffect(() => {
    let newArr = [...wayPoints3].filter(
      (el) => el.name.toLowerCase() !== pickUpPoint.name.toLowerCase(),
    );
    let arr = [];
    newArr.forEach((el) => {
      let newObj = {
        ...el,
        distanceFromPickUpPoint: el.distance - pickUpPoint.distance,
        durationFromPickUpPoint: el.duration - pickUpPoint.duration,
      };
      arr = [...arr, newObj];
    });

    setWayPointsFilter(arr);
    // setWayPoints3(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wayPoints3]);

  useEffect(() => {
    setMapHeight('170%');
    return () => setMapHeight('70%');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function filterWayPointsFunc(event) {
    let someName = event.target.value;
    setInputValue(someName);
    setWayPointsFilter(
      wayPoints3.filter((el) => el.name.toLowerCase().includes(someName.toLowerCase())),
    );
  }

  return (
    <div
      className="absolute w-full right-0 top-0 bg-white pb-6"
      style={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' }}
    >
      <div className="flex justify-start items-center bg-black mb-5 p-4">
        <button onClick={() => setAddPickupPoint(false)}>
          {/*<span className="text-white text-lg">{"<-"}</span>*/}
          <img src={iconLeft} alt="icon back" className="w-4" />
        </button>

        <span className="text-lg font-sans text-white font-medium ml-4">Add Pickup Point</span>
      </div>
      <div className="flex justify-between flex-wrap px-4 mb-4">
        <div className="flex items-center mb-6 relative">
          <div className="w-4 h-4 bg-green-400 rounded-full mr-3 ml-1"> </div>
          <div className="flex flex-col">
            <span className="text-xs text-gray-600">Start</span>
            <span className="text-base text-gray-600 font-medium">{origin.name}</span>
          </div>
          <div className="h-10 border-r border-gray-400 absolute left-3 top-8"> </div>
        </div>
        <div className="w-full flex items-center relative  mb-14">
          <picture className="w-8">
            {/*<picture className='absolute left-auto bottom-2/4 transform translate-y-1/2'>*/}
            {/*<source srcSet={pointFrom_x3} media="(max-width: 360px)" />*/}
            <img src={pointFrom_x1} alt="Rider icon" />
          </picture>
          <div className="flex flex-col">
            <span className="text-xs text-gray-600">Pickup</span>
            <span className="w-full text-base font-medium font-sans">{pickUpPoint.name}</span>
            {/*<button className=""*/}
            {/*onClick={() => {*/}
            {/*setStartRequests(true);*/}
            {/*setPickUpPoint('');*/}
            {/*setSecondStep(false);*/}
            {/*setWayPoints4((current) => {*/}
            {/*if(current.length === 2) return [{...current[1]}];*/}
            {/*if(current.length === 1) return [{...current[0]}];*/}
            {/*})*/}
            {/*}}>*/}
            {/*x*/}
            {/*</button>*/}
          </div>
          <div
            className={
              addDropOffPointTime
                ? 'text-white h-6 w-24 rounded-full text-center ml-auto cursor-pointer'
                : 'w-28 ml-auto'
            }
            style={addDropOffPointTime ? addDropOffPointTimeStyle : null}
          >
            <TimePickerComponentWithStyles
              addDropOffPointTime={addDropOffPointTime}
              setAddDropOffPointTime={setAddDropOffPointTime}
              setPointTime={setPickUpPointTime}
              pointTime={pickUpPointTime}
            />
          </div>
        </div>
        <div className="mb-5 w-full relative">
          {!dropOffPoint ? (
            <h2 className="ml-8 text-sm font-medium mb-3">Select Prefered Drop off point</h2>
          ) : (
            <span className="px-2 rounded-full border-2 absolute -top-10 left-10 ">
              {Math.floor((dropOffPoint.duration - pickUpPoint.duration) / 60) + ' mins'}
            </span>
          )}
          <div className="w-full flex items-center relative">
            <picture className="w-8">
              {/*<source srcSet={pointTo_x3} media="(max-width: 360px)" />*/}
              <img src={pointTo_x1} alt="Rider icon" />
            </picture>
            <div className="w-full flex justify-between">
              {!dropOffPoint ? (
                <input
                  className="w-full bg-gray-100 py-3 px-4 text-base font-bold font-sans"
                  placeholder="Drop off"
                  // value={dropOffPoint.name}
                  // onChange={(event) => setDropOffPoint(event.target.value)}
                  value={inputValue}
                  onChange={filterWayPointsFunc}
                  // disabled
                  type="text"
                />
              ) : (
                <div className="flex justify-between w-full">
                  <div className="flex flex-col ">
                    <span className="text-xs text-gray-600">Drop off</span>
                    <span className="text-base font-medium font-sans">{dropOffPoint.name}</span>
                  </div>
                  <div className="flex justify-between w-36 pl-6">
                    <div className="flex flex-col ml-auto font-sans">
                      <span className="text-xs ">ETA</span>
                      <span className="text-base font-medium">
                        {formatAMPM(
                          new Date(
                            +pickUpPointTime +
                              (dropOffPoint.duration - pickUpPoint.duration) * 1000,
                          ),
                        )}
                      </span>
                      {/*<span>{pickUpPointTime && dropOffPoint ? formatAMPM((pickUpPointTime + dropOffPoint.durationFromPickUpPoint)) : ''}</span>*/}
                    </div>
                    <button
                      className="ml-auto"
                      onClick={() => {
                        setStartRequests(true);
                        setDropOffPoint('');
                        setWayPoints4((current) => [{ ...current[0] }]);
                      }}
                    >
                      x
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={
              !dropOffPoint
                ? 'h-40 border-r border-gray-400 border-dashed absolute left-3 -top-12'
                : 'h-14 border-r border-gray-400 border-dashed absolute left-3 -top-14'
            }
          >
            {' '}
          </div>
        </div>
        {!dropOffPoint && (
          <div className="px-7 pr-2 w-full mb-6 relative overflow-scroll">
            <div className="text-sm w-full h-36">
              {wayPointsFilter.length ? (
                wayPointsFilter.map((el, index) => (
                  <button
                    key={`addDropOffPointsKey${index + 1}`}
                    className="mb-4 w-full flex justify-between items-center hover:bg-gray-200"
                    // data-duration={el.duration}
                    onClick={() => {
                      // setWayPoints4();
                      // setSelectItemDuration(el.duration);
                      setStartRequests(true);
                      setDropOffPoint(el);
                      setWayPoints4((current) => [
                        ...current,
                        el,
                        // {
                        //   location: {
                        //     lat: el.location.lat,
                        //     lng: el.location.lng,
                        //   },
                        // },
                      ]);
                    }}
                  >
                    <div className="flex items-center w-1/2">
                      <span className="inline-block text-center text-xs leading-5 w-5 h-5 text-white rounded-full bg-indigo-600 mr-1">
                        {el.wayPointIndex}
                      </span>
                      <span className="font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis w-3/4 text-left">
                        {el.name}
                      </span>
                    </div>
                    <div className="w-1/2 min-w-124 flex justify-between">
                      <span className="px-2 rounded-full border-2">
                        {Math.floor((el.duration - pickUpPoint.duration) / 60) + ' mins'}
                      </span>
                      <span>
                        {((el.distance - pickUpPoint.distance) / 1000).toFixed(1) + ' km'}
                      </span>
                    </div>
                  </button>
                ))
              ) : (
                <span className="text-nuale_neon_blue">No Prefered drop off</span>
              )}
            </div>
          </div>
        )}
        <div className="flex items-center relative">
          <div className="w-4 h-4 bg-black rounded-full mr-3 ml-1"></div>
          <div className="flex flex-col">
            <span className="text-xs text-gray-600">End of trip</span>
            <span className="text-base text-gray-600 font-medium">{destination.name}</span>
          </div>
          <div
            className={
              !dropOffPoint
                ? 'h-36 border-r border-gray-400 absolute left-3 -top-36'
                : 'h-9 border-r border-gray-400 absolute left-3 -top-7'
            }
          >
            {' '}
          </div>
        </div>
        {dropOffPoint && (
          <div className="w-full flex flex-col items-end">
            <div className="flex flex-col w-full mt-5 ml-3 mb-3">
              <div className="font-bold text-base text-nuale_neon_blue fontFamily-WorkSans mb-4">
                <div>{`ETA : ${Math.floor(
                  (dropOffPoint.duration - pickUpPoint.duration) / 60,
                )}mins, `}</div>
                <div>{`Consumption : ${(fuelConsumptionMarker[0].fuel / 1000).toFixed(1)}L, ${(
                  (fuelConsumptionMarker[0].fuel * currentUser.userVehicles[0].fuelTypes[0].price) /
                  1000
                ).toFixed(1)}MUR`}</div>
                <div>{`CO2 Emission : ${(co2Marker[0].co2 / 1000).toFixed(0)}g/km`}</div>
              </div>
              <span className="font-bold text-base">Contribution per seats</span>
              <span className="font-bold text-2xl text-green-400">
                {!rideCostPerSeatStatus && rideCostPerSeat ? (
                  `MUR ${rideCostPerSeat.toFixed(2)}`
                ) : (
                  <span>loading ...</span>
                )}
              </span>
            </div>
            <div className="flex justify-end">
              <button
                className={
                  !rideCostPerSeatStatus && rideCostPerSeat
                    ? 'inline-block disabled:opacity-50 w-28 h-10 focus:outline-none font-bold text-sm px-4 py-2 text-white bg-black rounded-full mr-3'
                    : 'hidden'
                }
                onClick={() => {
                  // show schedule ride
                  showScheduleRide();
                }}
                // disabled={createRideStatus.loading}
              >
                TEST RIDE
              </button>
              <button
                className={
                  !rideCostPerSeatStatus && rideCostPerSeat
                    ? 'inline-block disabled:opacity-50 w-28 h-10 focus:outline-none font-bold text-sm px-4 py-2 text-white bg-black rounded-full ml-auto'
                    : 'hidden'
                }
                disabled={createRideStatus.loading}
                onClick={() => createRideFunc()}
              >
                {!createRideStatus.loading ? 'ADD RIDE' : <MinSpinner />}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
