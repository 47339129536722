import React, { useRef, useState, useEffect } from 'react';
// import ImageUploader from 'react-images-upload';

import { CameraComponent, TouchActions } from '../index';

import cloud from '../../assets/images/sign-up/cloud-upload-outline.svg';
import camera from '../../assets/images/sign-up/17655483781571183080.svg';
import deleteItem from '../../assets/images/sign-up/delete.png';
import { MinSpinner } from '../MinSpinner/index';

export function UploadFiles({
  showUploadFilesWindow,
  setShowUploadFilesWindow,
  setCarImages,
  setDriverLicenceImages,
  setUserAvatarImage,
  imagesState,
  values,
  delItemImage,
  uploadUserAvatarFunc,
  uploadAvatarStatus,
}) {
  const cloudIconRef = useRef(null);

  const [imageSource, setImageSource] = useState('');
  const [imagesArray, setImagesArray] = useState([]);
  const [cameraShow, setCameraShow] = useState(false);

  useEffect(() => {
    switch (imagesState) {
      case 'car':
        return setImagesArray(values.carImages);
      case 'driver license':
        return setImagesArray(values.driverLicenceImages);
      case 'profile':
        return setImagesArray(values.userAvatarImage);
      default:
        return null;
    }
  }, [values.carImages, values.driverLicenceImages, values.userAvatarImage, imagesState]);

  const handleImageChange = (event) => {
    event.preventDefault();
    let fileInput = event.target.files;
    // console.log('fileInput', fileInput[0]);
    let reader = new FileReader();
    if (fileInput && !!Object.keys(fileInput).length) {
      reader.onloadend = () => {
        fileInput[0].path = reader.result;
        switch (imagesState) {
          case 'car':
            return setCarImages((result) => [...result, fileInput[0]]);
          case 'driver license':
            return setDriverLicenceImages((result) => [...result, fileInput[0]]);
          case 'profile':
            return setUserAvatarImage((result) => [...result, fileInput[0]]);
          default:
            return null;
        }
      };
      reader.readAsDataURL(fileInput[0]);
    }
  };

  const handleImageChangeByCamera = (dataUri) => {
    let file = new File([], `photo.jpg`, { type: 'image/jpeg' });

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = dataUri;
      img.onload = () => {
        const elem = document.createElement('canvas');
        const ctx = elem.getContext('2d');

        const maxWidth = 100;
        const maxHeight = 100;

        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
        const width = (img.width * ratio + 0.5) | 0;
        const height = (img.height * ratio + 0.5) | 0;

        elem.width = width;
        elem.height = height;

        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob((blob) => {
          const newimg = new File([blob], `photo${+Date.now()}.jpg`, {
            type: 'image/jpeg',
          });

          newimg.path = dataUri;

          switch (imagesState) {
            case 'car':
              return setCarImages((result) => [...result, newimg]);
            case 'driver license':
              return setDriverLicenceImages((result) => [...result, newimg]);
            case 'profile':
              // console.log('profile', newimg);
              return setUserAvatarImage((result) => [...result, newimg]);
            default:
              return null;
          }
        });
      };
    };
  };

  const addImages = () => {
    setShowUploadFilesWindow(false);
  };

  return (
    <TouchActions setShowUploadFilesWindow={setShowUploadFilesWindow}>
      <div>
        <CameraComponent
          cameraShow={cameraShow}
          setCameraShow={setCameraShow}
          handleImageChangeByCamera={handleImageChangeByCamera}
        />
        <div
          className={
            showUploadFilesWindow
              ? 'fixed top-0 left-0 z-10 w-full h-full bg-black bg-opacity-70 visible'
              : 'fixed top-0 left-0 z-10 w-full bg-black bg-opacity-0 h-full delay-200 invisible'
          }
          style={{ transition: '0.5s' }}
          onClick={() => {
            setShowUploadFilesWindow(false);
            // console.log('showUploadFilesWindow', showUploadFilesWindow);
          }}
        >
          <div
            className={
              showUploadFilesWindow
                ? 'absolute bottom-0 left-0 w-full bg-white p-8'
                : 'absolute -bottom-full left-0 w-full bg-white p-8 '
            }
            onClick={(event) => {
              event.stopPropagation();
            }}
            style={{ transition: '0.4s' }}
          >
            <h2 className="font-bold text-lg text-nuale_neon_blue mb-8">{`Add picture of your ${imagesState}`}</h2>
            {imagesArray && imagesArray.length ? (
              <div className="w-full">
                <div className="flex justify-between items-center">
                  <div className="flex flex-wrap  h-36 overflow-y-scroll items-center font-bold text-base text-nuale_neon_blue cursor-pointer ">
                    {imagesArray.map((el, index) => (
                      <div
                        className="relative w-28 h-28 mr-4 mb-4 mt-4 rounded border"
                        key={`${imagesState}${Date.now()}${index}`}
                        style={{
                          background: `url(${el.path}) no-repeat center`,
                          backgroundSize: '100%',
                        }}
                      >
                        <img
                          data-id={el.path}
                          data-name={imagesState}
                          // data-id={`${imagesState}${imageSource}${index}`}
                          className="absolute -top-3.5 -right-3.5 w-7 opacity-70"
                          onClick={(event) => {
                            delItemImage(event);
                          }}
                          src={deleteItem}
                          alt="delete icon"
                        />
                      </div>
                    ))}
                  </div>
                  {((imagesState === 'profile' && imagesArray.length < 1) ||
                    imagesState !== 'profile') && (
                    <div
                      className="flex flex-col items-center w-36 text-center text-nuale_neon_blue font-bold"
                      onClick={() => {
                        imageSource === 'cloud' && cloudIconRef.current.click();
                        imageSource === 'camera' && setCameraShow(true);
                      }}
                    >
                      <span className="text-3xl font-medium ">+</span>
                      <span>Add another picture</span>
                      <input
                        type="file"
                        name="cloud"
                        className="w-0 h-0"
                        ref={cloudIconRef}
                        onChange={(event) => handleImageChange(event)}
                      />
                    </div>
                  )}
                </div>
                <button
                  className="py-2 px-8 mt-8 w-full bg-black text-white rounded-full text-center text-base font-bold"
                  onClick={() => {
                    uploadUserAvatarFunc && uploadUserAvatarFunc();
                    !uploadUserAvatarFunc && addImages();
                  }}
                  disabled={uploadAvatarStatus.loading}
                >
                  {!uploadAvatarStatus.loading ? `Add ${imagesState} picture` : <MinSpinner />}
                </button>
              </div>
            ) : (
              <ul className="flex justify-between w-full">
                <li className="flex flex-col items-center font-bold text-base w-28">
                  <img
                    className="w-16 mb-4 cursor-pointer"
                    src={cloud}
                    alt="cloud icon"
                    onClick={() => {
                      setImageSource('cloud');
                      cloudIconRef.current.click();
                    }}
                  />
                  <input
                    type="file"
                    name="cloud"
                    className="w-0 h-0"
                    ref={cloudIconRef}
                    onChange={(event) => handleImageChange(event)}
                  />
                  <span>From library</span>
                </li>
                <li className="flex flex-col items-center font-bold text-base w-28">
                  <img
                    className="w-16 mb-4 cursor-pointer"
                    src={camera}
                    alt="camera icon"
                    onClick={() => {
                      setImageSource('camera');
                      setCameraShow(true);
                    }}
                  />
                  <span>From camera</span>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </TouchActions>
  );
}
