import gql from 'graphql-tag';

export const NOTIFICATION_ADDED_SUBSCRIPTION = gql`
  subscription notificationAdded($receiverID: ID!) {
    notificationAdded(receiverID: $receiverID) {
      read
      id
      type
      message
      notificationReceiver {
        id
      }
      ride {
        id
        driver {
          id
          firstName
          lastName
          gender
          userImg
          userVehicles {
            make {
              name
            }
            model
            spec {
              engineSize
              fuelConsumption
            }
          }
        }
        seats
        startDate
        pricePerSeat
        startPoint {
          name
          location {
            lat
            lng
          }
          eta
        }
        endPoint {
          name
          location {
            lat
            lng
          }
          eta
        }
        waypoints {
          id
          name
        }
        waypointsEta {
          waypointId
          eta
        }
        createdAt
      }
      createdAt
    }
  }
`;
