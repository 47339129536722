import React from 'react';

// import { MinSpinner } from '../MinSpinner/index.js';

export const InfoInputs = ({ value, onChange }) => {
  return (
    <div className="bg-gray-100 w-36">
      <input className="w-full bg-white" type="text" value={value} onChange={onChange} />
    </div>
  );
};
