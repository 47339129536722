import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import firebase from 'firebase/app';
import iconLeft from '../../assets/images/sign-up/icons_left.png';
import { SIGN_UP_METHODS } from '../../shared/constants';
import { CREATE_USER_FROM_FIREBASE } from '../../shared/gqlQueries/createUserFromFirebase';
import { UPDATE_USER } from '../../shared/gqlQueries/updateUser';
import { useAuth } from '../contexts/AuthContext';
import { setUpRecaptcha } from '../../shared/helpers/setUpRecapcha';

export function OTP({ nextStep, prevStep, values }) {
  const { firstName, lastName, email, phone, signUpMethod } = values;
  const [code, setCode] = useState('');
  const { setCurrentUser } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [showResendLink, setShowResendLink] = useState(true);
  const [validationError, setValidationError] = useState('');
  const { processFirebaseUserLogin } = useAuth();
  let recaptchaWrapperRef;
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      if (res?.updateUser) {
        setCurrentUser(res.updateUser);
        // console.log('[OTP] updateUser', res.updateUser);
        nextStep();
      }
    },
    onError: (error) => {
      console.error('[OTP] error updateUser gql', error.graphQLErrors || error.message);
    },
  });

  const [createUser, { data, error }] = useLazyQuery(CREATE_USER_FROM_FIREBASE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      console.log('[OTP] createUser', data);
      nextStep();
    },
    onError: (error) => {
      console.log('[OTP] error createUser', error.graphQLErrors);
    },
  });

  async function resendOTP() {
    if (window.recaptchaVerifier && recaptchaWrapperRef) {
      setShowResendLink(false);
      window.recaptchaVerifier.clear();
      recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;

      await setUpRecaptcha();
      // const appVerifier = window.recaptchaVerifier;

      await firebase
        .auth()
        .signInWithPhoneNumber(phone, window.recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    }
  }

  async function verifyOtp() {
    if (!code) return;

    const optConfirm = window.confirmationResult;

    setLoading(true);

    await optConfirm
      .confirm(code)
      .then(async ({ user }) => {
        // User signed in successfully.
        // console.log('[optConfirm] user', user);
        try {
          await processFirebaseUserLogin(user);
        } catch (e) {
          console.error(e);
        }
      })
      .then(() => {
        if (signUpMethod === SIGN_UP_METHODS.GOOGLE) {
          updateUser({
            variables: {
              input: {
                firstName,
                lastName,
                email,
                phone,
              },
            },
          });
        }
        if (signUpMethod === SIGN_UP_METHODS.PHONE) {
          createUser();
        }
      })
      .catch((e) => {
        // User couldn't sign in (bad verification code?)
        // console.error('[verifyOtp]', e);
        switch (e.code) {
          case 'auth/invalid-verification-code':
            setValidationError('Incorrect OTP');
            break;
          case 'auth/credential-already-in-use':
            setValidationError(
              'This phone number is already associated with a different user account.',
            );
            break;

          default:
            setValidationError('OTP error');
            break;
        }
      })
      .finally(function () {
        setLoading(false);
      });
  }

  return (
    <div>
      <div ref={(ref) => (recaptchaWrapperRef = ref)}>
        <div id="recaptcha-container"></div>
      </div>
      <div className="py-2 flex flex-row items-center">
        <button className="focus:outline-none p-1.5" onClick={() => prevStep()}>
          <img src={iconLeft} alt="Back" className="w-4" />
        </button>
        <div className="font-bold ml-3">Back</div>
      </div>

      <div>
        <h1 className="font-bold" style={{ fontSize: 32 }}>
          Sign Up
        </h1>
      </div>

      <div className="mt-6">
        Validate email or mobile by using 6 digit code sent to your email and mobile
      </div>

      <div className="w-full flex flex-col mt-10">
        <input
          type="number"
          name="otp"
          id="otp"
          required
          placeholder="Insert OTP code"
          className="bg-gray-100 text-black rounded-sm px-4 py-2 w-full"
          value={code}
          onChange={(e) => {
            setValidationError('');
            setCode(e.target.value);
          }}
        />

        {showResendLink && (
          <div className="px-2">
            <p className="my-4">Having trouble with the code</p>
            <div
              onClick={() => resendOTP()}
              className="uppercase font-bold text-nuale_neon_blue mt-6"
            >
              RESEND OTP CODE
            </div>
          </div>
        )}
      </div>

      {error &&
        error.graphQLErrors.map((err, i) => (
          <div className="bg-red-300 px-5 py-2 rounded-sm" key={i}>
            {err.message}
          </div>
        ))}

      {validationError && <div className="bg-red-300 px-5 py-2 rounded-sm">{validationError}</div>}

      <button
        disabled={!code || isLoading || loading}
        style={{ cursor: !code ? 'not-allowed' : 'pointer' }}
        className="bg-black text-white text-base font-bold px-4 py-2 rounded-full block mt-6 focus:outline-none w-full disabled:opacity-50"
        onClick={() => verifyOtp()}
      >
        Validate Account
      </button>
    </div>
  );
}
