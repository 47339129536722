import React from 'react';
import ReactTouchEvents from 'react-touch-events';

export const TouchActions = ({
  children,
  setShowMenu,
  setRequestRide,
  setShowForm,
  setShowScheduledRideDetails,
  setShowUploadFilesWindow,
  setMapHeight,
  setMapHeightFromRideDetailes,
}) => {
  function handleTap() {
    // console.log('you have taped me');
  }

  function handleSwipe(event, direction) {
    // let coords = event.changedTouches[0]

    switch (direction) {
      case 'top':
        setRequestRide && setRequestRide(false);
        setShowForm && setShowForm(true);
        setShowScheduledRideDetails && setShowScheduledRideDetails(true);
        setMapHeight && setMapHeight('70%');
        setMapHeightFromRideDetailes && setMapHeightFromRideDetailes('40%');

        break;
      case 'bottom':
        setRequestRide && setRequestRide(true);
        setShowForm && setShowForm(false);
        setShowScheduledRideDetails && setShowScheduledRideDetails(false);
        setShowUploadFilesWindow && setShowUploadFilesWindow(false);
        setMapHeight && setMapHeight('100%');
        setMapHeightFromRideDetailes && setMapHeightFromRideDetailes('90%');

        break;
      case 'left':
        setShowMenu && setShowMenu(false);

        break;
      case 'right':
        break;
      default:
        console.log(`Direction on default case: ${direction}`);
    }
  }

  return (
    <ReactTouchEvents onTap={handleTap} onSwipe={handleSwipe} setShowMenu={setShowMenu}>
      {children}
    </ReactTouchEvents>
  );
};
