import React from 'react'; // useEffect
import { useHistory } from 'react-router-dom';
// import { useSubscription } from '@apollo/client';
// import { useGlobalVariables } from '../contexts/GlobalVariablesContext';
import { useWebSocket } from '../contexts/WebSocketContext';

// import { NOTIFICATIONS_QUERY } from '../../shared/gqlQueries/notifications';
// import { NOTIFICATION_ADDED_SUBSCRIPTION } from '../../shared/gqlQueries/notificationAdded';
import { ROUTES } from '../../shared/constants';
// import { useAuth } from '../../components/contexts/AuthContext';

// import bars from '../../assets/images/header/bars-solid.svg';
// import bars from '../../assets/images/header/icon-menu.png';
import bell from '../../assets/images/header/bell-regular.svg';

export function Header({ setShowMenu, notificationsCount }) {
  let history = useHistory();
  // const { currentUser } = useAuth();
  const {
    // setNotificationsNotReadedGlobal,
    notificationsNotReadedGlobal,
  } = useWebSocket();

  // const { data, loading, error } = useSubscription(NOTIFICATION_ADDED_SUBSCRIPTION, {variables:{ receiverID: currentUser.id}});
  // if(loading) {
  // console.log('header subscribe data', data);
  // console.log('header subscribe loading', loading);
  // // // }
  // // // if(error) {
  // console.log('header subscribe error', error);
  // }

  // const {
  //   // loading,
  //   // error,
  //   data,
  // } = useQuery(NOTIFICATIONS_QUERY, {
  //   pollInterval: 5000,
  // });
  //
  // function ridesFilterByReceiver(arr) {
  //   // return arr
  //   return arr.filter((el) => el.notificationReceiver.id === currentUser.id);
  //   // return arr.filter((el) => el.notificationReceiver.id === currentUser.id && el.type === 'RIDE_ADDED');
  // }

  // if (error) return `Error! ${error}`;

  // useEffect(() => {}, [notificationsCount]);
  // useEffect(() => {
  //   data && setNotificationsNotReadedGlobal((current) => [...current, data.notificationAdded]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);
  // console.log('notificationsNotReadedGlobal', notificationsNotReadedGlobal);

  return (
    <div className="absolute top-0 left-0 z-10 w-full headerBackground p-4 flex justify-between items-center">
      <div className="w-5 cursor-pointer flex flex-col" onClick={() => setShowMenu(true)}>
        {/*<img src={bars} alt="bars icon" />*/}
        <span className="inline w-5 h-0.5 bg-black mb-1"></span>
        <span className="inline w-5 h-0.5 bg-black mb-1"></span>
        <span className="inline w-5 h-0.5 bg-black"></span>
      </div>
      <div
        className="w-5 cursor-pointer relative"
        onClick={() => history.push(ROUTES.NOTIFICATIONS)}
      >
        <img src={bell} alt="bell icon" />
        <div
          className={
            notificationsNotReadedGlobal.length
              ? 'absolute w-3 top-0 -right-1 bg-nuale_turquoise text-white text-min p-0.5 rounded-full text-center'
              : 'hidden'
          }
        >
          {notificationsNotReadedGlobal.length}
          {/*{notificationsNotReadedGlobal.length < 10 ? `0${notificationsNotReadedGlobal.length}` : notificationsNotReadedGlobal.length}*/}
          {/*{data && ridesFilterByReceiver(data.notificationAdded).length}*/}
        </div>
      </div>
    </div>
  );
}
