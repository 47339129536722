import React, {
  // useState,
  useEffect,
} from 'react';
// import { useHistory } from 'react-router-dom';
// import { useLazyQuery, useMutation } from '@apollo/client';

// import { TouchActions } from '../../ReactTouchEvent/index.js';
// import { useAuth } from '../../contexts/AuthContext';
// import { ROUTES } from '../../../shared/constants';
// import { RIDES_QUERY } from '../../../shared/gqlQueries/rides';
// import { REMOVE_RIDE } from '../../../shared/gqlQueries/removeRideById';

// import pointFrom_x3 from '../../../assets/images/mapFindForm/edit-location-black-18-dp@3x.png';
// import pointFrom_x3 from '../../../assets/images/mapFindForm/edit-location-black-18-dp.svg';
// import pointTo_x3 from '../../../assets/images/mapFindForm/location-on-black-18-dp.svg';
// import pointTo_x3 from '../../../assets/images/mapFindForm/location-on-black-18-dp@3x.png';
// import profile_x3 from '../../../assets/images/menu/profile@3x.png';
import phone from '../../assets/images/phone-solid-blue.svg';
// import star from '../../../assets/images/star-black-18-dp-copy-2.svg';
// import iconLeft from '../../../assets/images/arrow-left-solid.svg';
// import check from '../../../assets/images/check-solid.svg';
// import cancel from '../../../assets/images/times-solid-black.svg';
import iconClose from '../../assets/images/icon-close-filled-black.svg';
// import securityCallButton from '../../../assets/images/icon-secuity.svg';

export const SecurityCalls = ({ setShowSecurityCalls, showSecurityCalls, rider }) => {
  // console.log('ride', ride);

  useEffect(() => {
    // getRidesFunc();
    // setTimeout(()=> getRidesFunc(), 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if(!ride.length) return null;

  return (
    <>
      <div
        // className={'w-full absolute right-0 -bottom-20 flex flex-col justify-between px-5 pt-1 pb-32 bg-white rounded-tl-3xl rounded-tr-3xl'}
        className={!rider
          ?
          showSecurityCalls
            ? 'w-full absolute right-0 bottom-0 flex flex-col justify-between p-8 pb-12 bg-white'
            : 'w-full absolute right-0 -bottom-full flex flex-col justify-between p-8 pb-12 bg-white'
          : 'w-full flex flex-col justify-between p-8 pb-12 bg-white'
        }
        style={{
          boxShadow: `${
            showSecurityCalls ? '0 -10px 20px rgba(0, 0, 0, 0.2)' : '0 0 0 rgba(0, 0, 0, 0.2)'
          }`,
          transition: '0.5s',
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={rider ? "hidden" : "w-full mb-6"}>
          <img
            src={iconClose}
            className="w-4 ml-auto"
            alt="cancel button"
            onClick={() => setShowSecurityCalls(false)}
          />
        </div>
        <h2 className="text-base font-bold mb-5">EMERGENCY CALL</h2>
        <div className="text-base font-medium mb-10">
          <a href="tel:999">
            <div className="mb-5 flex justify-between hover:bg-gray-100">
              <span>Policy</span>
              <span className="inline-block w-10 text-center border border-nuale_neon_blue rounded-full">
                999
              </span>
            </div>
          </a>
          <a href="tel:114">
            <div className="mb-5 flex justify-between hover:bg-gray-100">
              <span>SAMU</span>
              <span className="inline-block w-10 text-center border border-nuale_neon_blue rounded-full">
                114
              </span>
            </div>
          </a>
          <a href="tel:">
            <div className="flex justify-between hover:bg-gray-100">
              <span>CALL OTHER NUMBER</span>
              <span className="inline-block w-10">
                <img src={phone} className="w-5 mx-auto" alt="" />
              </span>
            </div>
          </a>
        </div>
        <button
          className="inline-block w-full font-bold text-base px-4 py-2 text-white bg-black rounded-full ml-auto"
          onClick={(event) => {
            // setRideDetailes(false);
            // removeRideFunc(event.target.dataset.id)
          }}
        >
          Share Location
        </button>
      </div>
    </>
  );
};
