import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { auth } from '../../shared/firebase';
import { ME_QUERY } from '../../shared/gqlQueries/me';
import { Spinner } from '../../components';
import { clearAuthToken, setAuthToken } from '../../shared/helpers/localStorageHelper';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState();
  const [getMyInfo, { data }] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[AuthContext] getMyInfo', data);
      setCurrentUser(data.me);
      setLoading(false);
    },
    onError: async (error) => {
      console.log('[AuthProvider] error getMyInfo', error);
      if (error.graphQLErrors[0]?.message?.includes('token has expired')) {
        clearAuthTokenFunc();
      }
      setLoading(false);

      // TODO: need process user registration case - user loging in
      // to firebase, but not created in mongodb yet - getMyInfo() fails
      // and signOut() called

      // case if user deleted from mongodb but still logged in with firebase
      // if (firebaseUser) {
      //   try {
      //     await auth.signOut();
      //   } catch (error) {
      //     console.error(error);
      //   }
      // }
    },
  });

  // const { result } = useQuery(ME_QUERY)
  // console.log('result', result);

  useEffect(() => {
    try {
      const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
        // console.log('[AuthContext] firebaseUser', firebaseUser);

        if (firebaseUser) {
          await processFirebaseUserLogin(firebaseUser);
          setFirebaseUser(firebaseUser);
        } else {
          clearAuthTokenFunc();
        }
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function processFirebaseUserLogin(fbUser) {
    const token = await fbUser.getIdToken();
    setAuthToken(token);
    getMyInfo();
  }

  function clearAuthTokenFunc() {
    setLoading(false);
    clearAuthToken();
    setCurrentUser(null);
  }

  function isUserAuthenticated() {
    return !!currentUser;
  }

  const value = {
    currentUser,
    setCurrentUser,
    firebaseUser,
    isUserAuthenticated,
    loading,
    processFirebaseUserLogin,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <div className="bg-startPageImage bg-cover bg-no-repeat bg-center">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
