/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import { useMutation } from '@apollo/client';
import Geocode from 'react-geocode';

import { Map, RideDetailes, MakePayment, Popup, GridAllRides } from '../../components';
import { useAuth } from '../../components/contexts/AuthContext';

import { SET_PAID_STATUS } from '../../shared/gqlQueries/setPaidStatus';
import { CREATE_NOTIFICATION } from '../../shared/gqlQueries/createNotification';
import { ROUTES, NUALE_NOTIFICATION_TYPE } from '../../shared/constants';

const libraries = ['places'];

export function AllRidesSchedules() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
    region: 'US',
  });

  let history = useHistory();

  const { currentUser } = useAuth();
  const [centerCoords, setCenterCoords] = useState({ lat: -20.3, lng: 57.5833333 });
  const [mapHeight, setMapHeight] = useState('70%');
  const [markers, setMarkers] = useState([]);
  const [response, setResponse] = useState(null);
  const [markerStart, setMarkerStart] = useState();
  const [markerEnd, setMarkerEnd] = useState();
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [isGetRide, setGetRide] = useState(false);
  const [isGetPickUpPoint, setIsGetPickUpPoint] = useState(false);
  const [isGetDropOffPoint, setIsGetDropOffPoint] = useState(false);
  const [wayPoints3, setWayPoints3] = useState([]);
  const [wayPoints4, setWayPoints4] = useState([]);
  const [pickUpPoint, setPickUpPoint] = useState(false);
  const [dropOffPoint, setDropOffPoint] = useState(false);
  const [startRequests, setStartRequests] = useState(false);
  const [seatsNumber, setSeatsNumber] = useState(1);
  const [distanceMarker, setDistanceMarker] = useState([]);
  const [durationMarker, setDurationMarker] = useState([]);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [geoLocationMarker, setGeoLocationMarker] = useState('');
  const [geoLocationMessage, setGeoLocationMessage] = useState('');
  const [popupContent, setPopupContent] = useState(
    'Thank you for your payment. Have a nice journey Let’s us save the planet together!',
  );
  // const [popupTitle, setTitle] = useState("Payment received");
  const [selectRouteIndex, setSelectRouteIndex] = useState(0);
  const [selectedRide, setSelectedRide] = useState(null);
  const [fuelConsumptionMarker, setFuelConsumptionMarker] = useState([]);
  const [co2Marker, setCo2Marker] = useState([]);
  const [gridAllRidesShow, setGridAllRidesShow] = useState(true);
  const [isRideDetailes, setRideDetailes] = useState(false);
  const [isMakePayment, setMakePayment] = useState(false);
  const [rideRequestId, setRideRequestId] = useState('');

  const values = {
    response,
    markers,
    markerStart,
    markerEnd,
    origin,
    destination,
    isGetRide,
    isGetPickUpPoint,
    isGetDropOffPoint,
    wayPoints3,
    wayPoints4,
    pickUpPoint,
    dropOffPoint,
    startRequests,
    seatsNumber,
    distanceMarker,
    isShowPopup,
    selectRouteIndex,
    selectedRide,
    fuelConsumptionMarker,
    co2Marker,
    geoLocationMarker,
    mapHeight,
  };

  const setters = {
    setResponse,
    setMarkers,
    setMarkerStart,
    setMarkerEnd,
    setOrigin,
    setDestination,
    setGetRide,
    setIsGetPickUpPoint,
    setIsGetDropOffPoint,
    setWayPoints3,
    setWayPoints4,
    setPickUpPoint,
    setDropOffPoint,
    setStartRequests,
    setSeatsNumber,
    setDistanceMarker,
    setIsShowPopup,
    setSelectRouteIndex,
    setSelectedRide,
    setFuelConsumptionMarker,
    setCo2Marker,
    setGeoLocationMarker,
    setMapHeight,
    setGridAllRidesShow,
  };

  const [setPaidStatus, setPaidStatusStatus] = useMutation(SET_PAID_STATUS, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('[setPaidStatus] setPaidStatus', res);
      if (res) {
        createAddRideNotificationFunc('DRIVER_RIDE_BOOKED', selectedRide.driver.id);
        setTimeout(() => createAddRideNotificationFunc('RIDER_RIDE_BOOKED', currentUser.id), 1000);
        setPopupContent(
          'Thank you for your payment. Have a nice journey Let’s us save the planet together!',
        );
        setIsShowPopup(true);
        backToHomePage();
        cancelSelectedRide();
      }
    },
    onError: (error) => {
      console.log('[createRide] error createRide', error);
      // setTitle("Error");
      setPopupContent(`Error: ${error.message}`);
    },
  });

  const [
    createAddRideNotification,
    // createAddRideNotificationStatus
  ] = useMutation(CREATE_NOTIFICATION, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('[createAddRideNotification] createAddRideNotification', res);
      if (res) {
      }
    },
    onError: (error) => {
      console.log('[createAddRideNotification] error createAddRideNotification', error);
      // setTitle("Error");
      setPopupContent(`Error: ${error.message}`);
    },
  });

  function createAddRideNotificationFunc(type, receiverId) {
    createAddRideNotification({
      variables: {
        input: {
          type: NUALE_NOTIFICATION_TYPE[type],
          ride: selectedRide.id,
          notificationReceiver: receiverId,
          // message: 'Ride schedule',
        },
      },
    });
  }

  function cancelSelectedRide() {
    setSelectedRide(null);
    setWayPoints4([]);
    setOrigin('');
    setDestination('');
    setMarkers([]);
    setMarkerStart();
    setMarkerEnd();
  }

  useEffect(() => {
    if (selectedRide) {
      selectRideFromFindAllRidesListFunc(selectedRide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRide]);

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function backToHomePage() {
    setGetRide(false);
  }

  function popupReset() {
    geoLocationMessage && history.push(ROUTES.FIND_RIDE);
    !geoLocationMessage && history.push(ROUTES.ADD_RIDE);
    setIsShowPopup(false);
    history.push(ROUTES.FIND_RIDE);
  }

  async function geocodeAddress(address) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    try {
      let response = await Geocode.fromAddress(address);
      return response.results[0].geometry.location;
    } catch (error) {
      console.error(error);
      setPopupContent(`Error: ${error.message}`);
      setIsShowPopup(true);
    }
  }

  async function getWaypointsLocations(ride) {
    let pickUpLocation = await geocodeAddress(ride.waypoints[0].name);
    let dropOffLocation = await geocodeAddress(ride.waypoints[1].name);
    await setWayPoints4([
      { name: ride.waypoints[0].name, location: pickUpLocation },
      { name: ride.waypoints[1].name, location: dropOffLocation },
    ]);
  }

  async function setRideParamsInState(ride) {
    await setOrigin({ location: { ...ride.startPoint.location } });
    await setDestination({ location: { ...ride.endPoint.location } });
    await setMarkerStart({ location: { ...ride.startPoint.location } });
    await setMarkerEnd({ location: { ...ride.endPoint.location } });
    // await setMarkers([
    //   { location: { ...ride.startPoint.location } },
    //   { location: { ...ride.endPoint.location } },
    // ]);
  }

  function selectRideFromFindAllRidesListFunc(ride) {
    if (ride) {
      setRideParamsInState(ride);
      getWaypointsLocations(ride);
      setRideDetailes(true);
      setStartRequests(true);
      setGridAllRidesShow(false);
      // setMapHeight('70%');
    } else {
      // setMapHeight('100%');
    }
  }

  function stepFromMakePaymentToRideDetails() {
    setRideDetailes(true);
    setMakePayment(false);
  }

  return (
    <div className="container w-full mx-auto box-border relative overflow-hidden">
      <Map
        values={values}
        setters={setters}
        centerCoords={centerCoords}
        response={response}
        setResponse={setResponse}
        markers={markers}
        markerStart={markerStart}
        markerEnd={markerEnd}
        origin={origin}
        setOrigin={setOrigin}
        setDestination={setDestination}
        setMarkers={setMarkers}
        setMarkerStart={setMarkerStart}
        setMarkerEnd={setMarkerEnd}
        destination={destination}
        loadError={loadError}
        isLoaded={isLoaded}
        fuelConsumption={
          !!(selectedRide && selectedRide.driver.userVehicles.length)
            ? selectedRide.driver.userVehicles[0].spec[0].fuelConsumption
            : null
        }
        wayPoints3={wayPoints3}
        wayPoints4={wayPoints4}
        setWayPoints3={setWayPoints3}
        setWayPoints4={setWayPoints4}
        setPickUpPoint={setPickUpPoint}
        setDropOffPoint={setDropOffPoint}
        startRequests={startRequests}
        setStartRequests={setStartRequests}
        isGetPickUpPoint={isGetPickUpPoint}
        isGetDropOffPoint={isGetDropOffPoint}
        findRide={true}
        isMarkersClickable={isGetPickUpPoint || isGetDropOffPoint}
        distanceMarker={distanceMarker}
        setDistanceMarker={setDistanceMarker}
        setIsGetPickUpPoint={setIsGetPickUpPoint}
        setIsGetDropOffPoint={setIsGetDropOffPoint}
        selectRouteIndex={selectRouteIndex}
        setSelectRouteIndex={setSelectRouteIndex}
        selectedRide={selectedRide}
        setFuelConsumptionMarker={setFuelConsumptionMarker}
        fuelConsumptionMarker={fuelConsumptionMarker}
        setCo2Marker={setCo2Marker}
        co2Marker={co2Marker}
        durationMarker={durationMarker}
        setDurationMarker={setDurationMarker}
        geoLocationMarker={geoLocationMarker}
        mapHeight={mapHeight}
      />
      {gridAllRidesShow && <GridAllRides setSelectedRide={setSelectedRide} />}
      {isRideDetailes && (
        <RideDetailes
          setRideDetailes={setRideDetailes}
          formatAMPM={formatAMPM}
          setMakePayment={setMakePayment}
          selectedRide={selectedRide}
          co2Marker={co2Marker}
          seatsNumber={seatsNumber}
          setRideRequestId={setRideRequestId}
          setMapHeight={setMapHeight}
          allRides={true}
          setGridAllRidesShow={setGridAllRidesShow}
          cancelSelectedRide={cancelSelectedRide}
        />
      )}
      {isMakePayment && (
        <MakePayment
          stepFromMakePaymentToRideDetails={stepFromMakePaymentToRideDetails}
          setPaidStatus={setPaidStatus}
          setPaidStatusStatus={setPaidStatusStatus}
          rideRequestId={rideRequestId}
          totalPrice={
            selectedRide && seatsNumber && (selectedRide.pricePerSeat * seatsNumber).toFixed(2)
          }
        />
      )}
      <Popup
        isShowPopup={isShowPopup}
        // resetRide={resetRide}
        popupReset={popupReset}
        content={popupContent}
        // title={popupTitle}
      />
    </div>
  );
}
