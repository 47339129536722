import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
// import { useHistory } from 'react-router-dom';
// import { useLazyQuery, useMutation } from '@apollo/client';
import OneSignal from 'react-onesignal';

import { TouchActions } from '../ReactTouchEvent/index.js';
import { PassengersGrid } from './PassengersGrid';
import { SecurityCalls } from './securityCalls';
// import { useAuth } from '../contexts/AuthContext';
// import { ROUTES } from '../../../shared/constants';
// import { RIDES_QUERY } from '../../../shared/gqlQueries/rides';
// import { REMOVE_RIDE } from '../../../shared/gqlQueries/removeRideById';
// import { RIDE_STATUS } from '../../shared/constants';
import { SET_COMPLETED_RIDE_STATUS } from '../../shared/gqlQueries/setCompletedRideStatus';
import { SET_IN_PROGRESS_RIDE_STATUS } from '../../shared/gqlQueries/setInProgressRideStatus';

// import pointFrom_x3 from '../../../assets/images/mapFindForm/edit-location-black-18-dp@3x.png';
import pointFrom_x3 from '../../assets/images/mapFindForm/edit-location-black-18-dp.svg';
import pointTo_x3 from '../../assets/images/mapFindForm/location-on-black-18-dp.svg';
// import pointTo_x3 from '../../../assets/images/mapFindForm/location-on-black-18-dp@3x.png';
import profile_x3 from '../../assets/images/menu/profile@3x.png';
import phone from '../../assets/images/phone-solid.svg';
// import star from '../../../assets/images/star-black-18-dp-copy-2.svg';
// import iconLeft from '../../../assets/images/arrow-left-solid.svg';
import check from '../../assets/images/check-solid.svg';
import cancel from '../../assets/images/icon-close-filled.svg';
import securityCallButton from '../../assets/images/icon-secuity.svg';

export const ScheduleRideDetailes = ({
  // setRideDetailes,
  // setMakePayment,
  // formatAMPM,
  ride,
  setShowForm,
  showForm,
  setShowScheduledRideDetails,
  showScheduledRideDetails,
  setMapHeight,
  watchYourPosition,
  cancelScheduleRide,
  setActiveRide,
  isActiveRide,
  rideRequestsArr,
  setChooseScheduleRide,
  chooseScheduleRide,
                                       updateRideStatusFunc,
  // rideRequestsForRiderArr,
  // nearestScheduledRide,
  // setMapHeightByShowForm
  // setAddPickupPoint
}) => {
  // console.log('ride', ride);

  // const [requestRide, setRequestRide] = useState(false);
  // const [ride, setRide] = useState([]);
  const [showSecurityCalls, setShowSecurityCalls] = useState(false);
  const [counterToFinish, setCounterToFinish] = useState(
    +new Date(+ride[0].waypointsEta[1].eta - +ride[0].waypointsEta[0].eta),
  );
  const [counterToStart, setCounterToStart] = useState(
    +new Date(+ride[0].waypointsEta[0].eta - +Date.now())
  );
  const [durationRide] = useState(
    Date.now() + +new Date(+ride[0].waypointsEta[1].eta - +ride[0].waypointsEta[0].eta)
  );
  const [durationToStartRide] = useState(
    +new Date(+ride[0].waypointsEta[0].eta)
  );
  const [intervalCounterToFinish, setIntervalCounterToFinish] = useState();
  const [intervalCounterToStart, setIntervalCounterToStart] = useState();

  // const { currentUser } = useAuth();
  // let history = useHistory();

  // const [getRidesFunc, ridesData] = useLazyQuery(RIDES_QUERY, {
  //   fetchPolicy: 'network-only', // always will fetch data, no cache
  //   onCompleted: () => {
  //     if (ridesData.data && ridesData.data.rides) {
  //       console.log('[ridesData]', ridesFilterByDriver(ridesData.data.rides));
  //       setRide(ridesFilterByDriver(ridesData.data.rides));
  //     }
  //   },
  //   onError: (error) => {
  //     console.log('[GetRidesHistory] error getRidesFunc', error);
  //   },
  // });

  // const [removeRide, removeRideData] = useMutation(REMOVE_RIDE, {
  //   errorPolicy: 'all',
  //   onCompleted: () => {
  //     console.log('[RemoveRide]', removeRideData);
  //     setRide([]);
  //   },
  //   onError: (error) => {
  //     console.log('[GetRidesHistory] error getRidesFunc', error);
  //   }
  // });

  // function ridesFilterByDriver(arr){
  //   return arr.filter(el => (el.driver.id === currentUser.id && +el.waypointsEta[0].eta > +Date.now() && +el.waypointsEta[0].eta - Date.now() <= 7200000))
  // }
  //
  // function removeRideFunc(id) {
  //   removeRide({
  //     variables: {
  //       id,
  //     }
  //   })
  // }

  const [setInProgressRideStatus] = useMutation(SET_IN_PROGRESS_RIDE_STATUS, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('[setInProgressRideStatus] res.setInProgressRideStatus', res);
      if (res) {
        // setPopupContent('Your ride has been successfully added.');
        // setIsShowPopup(true);
        // createAddRideNotificationFunc(res.updateRide.id);
      }
    },
    onError: (error) => {
      console.log('[setInProgressRideStatus] error setCompletedRideStatus', error);
    },
  });

  const [setCompletedRideStatus] = useMutation(SET_COMPLETED_RIDE_STATUS, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('[setCompletedRideStatus] res.setCompletedRideStatus', res);
      if (res) {
        // setPopupContent('Your ride has been successfully added.');
        // setIsShowPopup(true);
        // createAddRideNotificationFunc(res.updateRide.id);
      }
    },
    onError: (error) => {
      console.log('[setCompletedRideStatus] error setCompletedRideStatus', error);
    },
  });

  function setRideStatusFunc(id, setRideStatus){
    setRideStatus({
      variables: {
        id
      },
    })
  }

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function formatByMinutes(date) {
    return Math.floor(date / 1000 / 60) + 'Min';
  }

  function counterFunc(setCounterValue, counterInterval, setCounterInterval, duration) {
    if(+duration - +Date.now() > 0){
      setCounterValue(+duration - +Date.now())
    } else {
      stopCount(counterInterval, setCounterInterval);
      setCounterValue(0);
    }
  }

  function startCount(setCounterValue, counterInterval, setCounterInterval, duration) {
    setCounterInterval(setInterval(() => counterFunc(setCounterValue, counterInterval, setCounterInterval, duration), 60000));
  }

  function stopCount(counterInterval, setCounterInterval) {
    clearInterval(counterInterval);
    setCounterInterval();
  }

  function activeRideFunc(id) {
    setActiveRide((current) => !current);
    isActiveRide ? setShowScheduledRideDetails(true) : setShowScheduledRideDetails(false);
    setChooseScheduleRide(false);
    // setMapHeightByShowForm();
    isActiveRide && cancelScheduleRide();
    !isActiveRide && watchYourPosition();
    setMapHeight('100%');
    // showScheduledRideDetails ? setMapHeight('70%') : setMapHeight('100%');
    !isActiveRide && startCount(setCounterToFinish, intervalCounterToFinish, setIntervalCounterToFinish, durationRide);
    isActiveRide && stopCount(intervalCounterToFinish, setIntervalCounterToFinish);
    isActiveRide && deleteRideIdAsTagInOneSignal(id);
    !isActiveRide && setRideStatusFunc(id, setInProgressRideStatus);
    isActiveRide && setRideStatusFunc(id, setCompletedRideStatus);
  }

  function deleteRideIdAsTagInOneSignal(id) {
    OneSignal.deleteTag(id);
  }

  useEffect(() => {
    // counterFunc();
    startCount(setCounterToStart, intervalCounterToStart, setIntervalCounterToStart, durationToStartRide);

    return () => {
      setCounterToFinish(0);
      setCounterToStart(0);
      clearInterval(intervalCounterToFinish);
      clearInterval(intervalCounterToStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // getRidesFunc();
  //   // setTimeout(()=> getRidesFunc(), 1000)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ride]);

  // if(!ride.length) return null;

  console.log('rideRequestsArr', rideRequestsArr);
  if(rideRequestsArr.length) {
    console.log('rideRequestsArr[0].passenger.phone', rideRequestsArr[0].passenger.phone);
  }

  return (
    <>
      <TouchActions
        // setRequestRide={setRequestRide}
        setShowScheduledRideDetails={setShowScheduledRideDetails}
        setShowForm={setShowForm}
        // setShowForm={showScheduledRideDetails ? setShowForm : false}
        setMapHeight={setMapHeight}
      >
        <div
          // className={'w-full absolute right-0 -bottom-20 flex flex-col justify-between px-5 pt-1 pb-32 bg-white rounded-tl-3xl rounded-tr-3xl'}
          className={
            // !requestRide
            chooseScheduleRide
              ? 'w-full absolute right-0 bottom-20 flex flex-col justify-between px-5 pt-1 pb-7 bg-white rounded-tl-3xl rounded-tr-3xl'
              : !showScheduledRideDetails
              ? // ? 'w-full absolute right-0 -bottom-116 flex flex-col justify-between px-5 pt-1 pb-32 bg-white rounded-tl-3xl rounded-tr-3xl'
                `w-full absolute right-0 ${
                  isActiveRide ? '-bottom-116' : '-bottom-112'
                } flex flex-col justify-between px-5 pt-1 pb-32 bg-white rounded-tl-3xl rounded-tr-3xl`
              : `w-full absolute right-0 ${
                  isActiveRide ? '-bottom-4' : '-bottom-8'
                } flex flex-col justify-between px-5 pt-1 pb-7 bg-white rounded-tl-3xl rounded-tr-3xl`
          }
          onClick={() => {
            if(!isActiveRide) {
              !chooseScheduleRide && setChooseScheduleRide(true);
              !chooseScheduleRide && setShowForm(false);
            }
          }}
          // className={
          //     // !requestRide
          //     !showForm
          //       ? 'w-full absolute right-0 -bottom-4 flex flex-col justify-between px-5 pt-1 pb-32 bg-white rounded-tl-3xl rounded-tr-3xl'
          //       : 'w-full absolute right-0 -bottom-8 flex flex-col justify-between px-5 pt-1 pb-7 bg-white rounded-tl-3xl rounded-tr-3xl'
          // }
          style={{ boxShadow: '0 -10px 20px rgba(0, 0, 0, 0.2)', transition: '0.5s' }}
        >
          <div className="relative ">
            <div
              className={
                showScheduledRideDetails
                  ? 'absolute -top-16 right-0 bg-nuale_turquoise w-10 h-10 rounded-full flex justify-center items-center text-white text-2xl'
                  : 'absolute -top-16 right-0 bg-nuale_turquoise w-10 h-10 rounded-full flex justify-center items-center text-white text-2xl'
              }
              onClick={(event) => {
                event.stopPropagation();
                activeRideFunc(ride[0].id);
              }}
              style={{ boxShadow: '0 1px 10px rgba(0, 0, 0, 0.5)' }}
            >
              <img className="w-1/2" src={!isActiveRide ? check : cancel} alt="" />
            </div>
          </div>
          <div
            className="w-20 h-1 bg-gray-200 m-auto rounded-full"
            onClick={(event) => {
              event.stopPropagation();
              setShowScheduledRideDetails((current) => !current);
              !showScheduledRideDetails && setMapHeight('70%');
              showScheduledRideDetails && setMapHeight('100%');
              // setRequestRide((current) => !current)
            }}
          >
            {' '}
          </div>
          <div className="min-h-20 w-full flex justify-between pt-2 border-b border-gray-200 mb-4">
            {rideRequestsArr && rideRequestsArr.length && rideRequestsArr.length > 1 ? (
              <PassengersGrid passengersArr={rideRequestsArr} />
            ) : (
              <div className="flex justify-between">
                <div
                  className="bg-white min-w-12 w-12 h-12 rounded-full border mr-3"
                  style={{
                    backgroundImage: `url(${
                      rideRequestsArr && rideRequestsArr.length
                        ? ride[0].driver.userImg || profile_x3
                        : profile_x3
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                >
                  {' '}
                </div>
                <div className="flex flex-col  mr-1">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex flex-col relative">
                      <span className="text-xs text-nuale_turquoise">Rider</span>
                      <span className="text-base font-bold">
                        {rideRequestsArr && rideRequestsArr.length
                          ? `${rideRequestsArr[0].passenger.firstName} ${rideRequestsArr[0].passenger.lastName}`
                          : 'No riders'}
                      </span>
                      {/*<span className="text-sm font-bold">{`${ride[0].driver.firstName} ${ride[0].driver.lastName}`}</span>*/}
                    </div>
                  </div>

                  {/*<div className="w-full flex text-sm">*/}
                    {/*/!*<span>{ride[0].driver.userVehicles.length ? `${ride[0].driver.userVehicles[0].make.name} ${ride[0].driver.userVehicles[0].model}` : "----"}</span>*!/*/}
                    {/*<span>*/}
                    {/*{currentUser.userVehicles.length*/}
                    {/*? `${currentUser.userVehicles[0].make.name} ${currentUser.userVehicles[0].model}`*/}
                    {/*: '----'}*/}
                    {/*</span>*/}
                  {/*</div>*/}
                </div>
              </div>
            )}
            <div className="text-sm flex flex-col mr-1">
              <span className="text-xs text-nuale_turquoise">
                {isActiveRide ? 'ETA' : 'Ride Scheduled'}
              </span>
              <span className="text-nuale_neon_blue text-lg font-bold">
                {isActiveRide ? formatByMinutes(new Date(+counterToFinish)) : formatByMinutes(new Date(+counterToStart))}
                {/*{((+ride[0].startDate - Date.now()) / 60000).toFixed(0) + 'mins'}*/}
              </span>
            </div>
            <div
              className={isActiveRide ? 'w-12 h-12' : 'w-12 h-12 bg-nuale_neon_blue rounded'}
              style={{
                backgroundImage: `url(${isActiveRide ? securityCallButton : phone})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '80%',
              }}
              onClick={(event) => {
                event.stopPropagation();
                isActiveRide && setShowSecurityCalls(true);
              }}
            >
              <a
                className={!isActiveRide ? 'inline-block w-full h-full' : 'hidden'}
                href={`tel:${
                  rideRequestsArr && rideRequestsArr.length
                    ? rideRequestsArr[0].passenger.phone
                    : ''
                }`}
              >
                {' '}
              </a>
            </div>
          </div>
          <h2 className="mb-2 text-base font-bold">Trip details</h2>
          <div className="flex justify-between items-center mb-6 relative">
            <div className="flex items-center">
              <div className="w-4 h-4 bg-nuale_turquoise rounded-full mr-3 ml-1"></div>
              <div className="flex flex-col">
                <span className="text-xs text-nuale_turquoise">Start</span>
                <span className="text-base text-gray-400 font-medium">
                  {ride[0].startPoint.name}
                </span>
              </div>
            </div>
            <span className="text-base text-gray-400 font-medium">
              {formatAMPM(new Date(+ride[0].startPoint.eta))}
            </span>
            <div className="h-10 border-r border-gray-400 absolute left-3 top-8"></div>
          </div>
          <div className="w-full flex justify-between items-center relative  mb-9">
            <div className="flex items-center">
              <picture className="w-8">
                <img className="w-6" src={pointFrom_x3} alt="Rider icon" />
              </picture>
              <div className="flex flex-col">
                <span className="text-xs text-gray-600">Pickup</span>
                <span className="w-full text-base font-medium font-sans">
                  {ride[0].waypoints[0].name}
                </span>
              </div>
            </div>
            <span className="text-base font-medium">
              {formatAMPM(new Date(+ride[0].waypointsEta[0].eta))}
            </span>
          </div>
          <div className="mb-5 w-full relative">
            <div className="w-full flex items-center relative">
              <picture className="w-8">
                <img className="w-6" src={pointTo_x3} alt="Rider icon" />
              </picture>
              <div className="w-full flex justify-between">
                <div className="flex flex-col">
                  <span className="text-xs text-gray-600">Drop off</span>
                  <span className="text-base font-medium">{ride[0].waypoints[1].name}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-xs text-gray-600">Eta</span>
                  <span className="text-base font-medium">
                    {formatAMPM(new Date(+ride[0].waypointsEta[1].eta))}
                  </span>
                </div>
              </div>
            </div>
            <div className="h-12 border-r border-gray-400 border-dashed absolute left-3 -top-10"></div>
          </div>
          <div className="flex items-center relative">
            <div className="w-4 h-4 bg-black rounded-full mr-3 ml-1"></div>
            <div className="flex flex-col">
              <span className="text-xs text-gray-400">End of trip</span>
              <span className="text-base text-gray-400 font-medium">{ride[0].endPoint.name}</span>
            </div>
            <div className="h-9 border-r border-gray-400 absolute left-3 -top-7"></div>
          </div>
          <div
            className="px-4 w-full flex flex-col items-end"
            style={{
              transition: '0.5s',
              // backgroundImage: "linear-gradient(to bottom, rgba(238, 238, 238, 0) 31%, #171717)",
            }}
          >
            <div className="flex flex-col w-full mt-3 ml-3 mb-4">
              <span className="font-bold text-base">Contribution per seats</span>
              <span className="font-bold text-2xl text-nuale_turquoise">
                {ride[0] && ride[0].pricePerSeat ? `MUR ${ride[0].pricePerSeat.toFixed(2)}` : ''}
              </span>
            </div>
            <button
              className={
                isActiveRide
                  ? 'hidden'
                  : 'inline-block w-full font-bold text-base px-4 py-2 text-white bg-black rounded-full ml-auto'
              }
              data-id={ride[0].id}
              onClick={(event) => {
                // setRideDetailes(false);
                // removeRideFunc(event.target.dataset.id)
              }}
            >
              Cancel ride
            </button>
          </div>
        </div>
      </TouchActions>
      <SecurityCalls
        showSecurityCalls={showSecurityCalls}
        setShowSecurityCalls={setShowSecurityCalls}
      />
    </>
  );
};
