import React from 'react';
import { Redirect } from 'react-router';
import { ROLES } from '../../shared/constants';
import { OTP, ProfileInfo, SignUpRiderSuccess, SignUpDriverSuccess, DriverInfo } from './';

export function SignUpWithGoogleFlow({ values, setters, nextStep, prevStep }) {
  const { activeStep } = values;

  if (values.role === ROLES.rider) {
    switch (activeStep) {
      case 1:
        return (
          <ProfileInfo values={values} setters={setters} nextStep={nextStep} prevStep={prevStep} />
        );
      case 2:
        return <OTP nextStep={nextStep} prevStep={prevStep} values={values} />;
      case 3:
        return <SignUpRiderSuccess />;

      default:
        break;
    }
  }

  if (values.role === ROLES.driver) {
    switch (activeStep) {
      case 1:
        return (
          <ProfileInfo values={values} setters={setters} nextStep={nextStep} prevStep={prevStep} />
        );
      case 2:
        return <OTP nextStep={nextStep} prevStep={prevStep} values={values} />;
      case 3:
        return (
          <DriverInfo values={values} setters={setters} nextStep={nextStep} prevStep={prevStep} />
        );
      case 4: {
        return <SignUpDriverSuccess />;
      }

      default:
        return (
          <ProfileInfo values={values} setters={setters} nextStep={nextStep} prevStep={prevStep} />
        );
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
}
