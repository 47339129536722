import React from 'react';
import { useHistory } from 'react-router';
import googleBtn from '../../assets/images/social/btn_google_signin_light@2x.png';
import { signInWithGoogle } from '../../shared/firebase';
import { useAuth } from '../contexts/AuthContext';

const GoogleLoginBtn = ({ className }) => {
  const history = useHistory();
  const { processFirebaseUserLogin } = useAuth();

  function handleLogin() {
    return signInWithGoogle()
      .then(async ({ user }) => {
        // User signed in successfully.
        try {
          // console.log('[GoogleLogin] FirebaseUser', user);
          await processFirebaseUserLogin(user);
        } catch (e) {
          console.error(e);
        }
      })
      .then(() => {
        history.push('/find-ride');
      });
  }
  return (
    <button className={className}>
      <img src={googleBtn} alt="Google Sign Up" className="w-64" onClick={handleLogin} />
    </button>
  );
};

export default GoogleLoginBtn;
