import React from 'react';
import { useHistory } from 'react-router';
import { signInWithFacebook } from '../../shared/firebase';
import { useAuth } from '../contexts/AuthContext';

const FacebookLoginBtn = ({ className }) => {
  const history = useHistory();
  const { processFirebaseUserLogin } = useAuth();

  function handleLogin() {
    return signInWithFacebook()
      .then(async ({ user }) => {
        // User signed in successfully.
        try {
          if (!user) {
            console.warn('[signInWithFacebook] no user returned');
            return;
          }
          await processFirebaseUserLogin(user);
        } catch (e) {
          console.error(e);
        }
      })
      .then(() => {
        history.push('/find-ride');
      })
      .catch((err) => console.error(err));
  }
  return (
    <button
      className="shadow w-64 p-3 text-white bg-blue-700 font-medium rounded-sm focus:outline-none"
      onClick={handleLogin}
    >
      Sign in with Facebook
    </button>
  );
};

export default FacebookLoginBtn;
