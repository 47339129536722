import React, { useEffect, useState } from 'react';
import { TERMS } from '../../shared/terms';
import arrowDown from '../../assets/images/chevron-down-solid.svg';

export const Terms = () => {
  const [termsArr, setTermsArr] = useState([]);

  useEffect(() => {
    setTermsArr(TERMS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showTermsItem = (termItem) => {
    let updatedTermsArr = termsArr.map((el) =>
      el.id === termItem.id ? { ...el, status: !el.status } : el,
    );
    setTermsArr([...updatedTermsArr]);
  };

  return (
    <div className="terms-block">
      <h2 className="pb-2 mb-7 font-bold border-b-2 border-gray-400">Terms</h2>
      <p className="text-sm underline">Last Updated: July 6, 2021</p>
      <p className="text-sm my-4">
        These Terms of Service constitute a legally binding agreement (the “Agreement”) between you
        and Nuale Ltd, its parents, subsidiaries, representatives, affiliates, officers and
        directors (collectively, “Nuale,” “we,” “us” or “our”) governing your use of the Nuale
        application (the “Nuale App”), website, and technology platform (collectively, the “Nuale
        Platform”).
      </p>
      <p className="text-sm my-4">
        By entering into this Agreement, and/or by using or accessing the Nuale Platform you
        expressly acknowledge that you understand this Agreement (including the dispute resolution
        and arbitration provisions in Section 17) and accept all of its terms. IF YOU DO NOT AGREE
        TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE
        NUALE PLATFORM OR ANY OF THE SERVICES PROVIDED THROUGH THE NUALE PLATFORM.
      </p>
      <p className="text-sm my-4">
        When using the Nuale Platform, you also agree to conduct yourself in accordance with our
        Community Guidelines, which shall form part of this Agreement between you and Nuale.
      </p>
      <ul className="mb-4 font-medium">
        {termsArr.length &&
          termsArr.map((termItem, indexTerm) => (
            <li className="text-sm" key={'termItem' + indexTerm}>
              <div
                className="border-b-2 border-gray-200 py-4 flex items-center justify-between cursor-pointer"
                onClick={() => showTermsItem(termItem)}
              >
                <span>{termItem.title}</span>
                <img
                  className={
                    termItem.status ? 'w-3 transform rotate-180 duration-75' : 'w-3 duration-75'
                  }
                  src={arrowDown}
                  alt="chevron img"
                />
              </div>
              <p
                className={
                  termItem.status
                    ? 'py-4 faqItem h-full duration-75 termsItemContent'
                    : 'overflow-hidden h-0 duration-75 termsItemContent'
                }
                dangerouslySetInnerHTML={{ __html: termItem.content }}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};
