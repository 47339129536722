import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../components/contexts/AuthContext';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isUserAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              propsStep: 1,
            }}
          />
        )
      }
    />
  );
};
