import React, { useState } from 'react';
import { useHistory } from 'react-router';
import firebase from 'firebase/app';
import iconLeft from '../../assets/images/sign-up/icons_left.png';
import { setUpRecaptcha } from '../../shared/helpers/setUpRecapcha';
import { useAuth } from '../contexts/AuthContext';

export function OTPLogin({ prevStep, phone }) {
  const { processFirebaseUserLogin } = useAuth();
  const [code, setCode] = useState('');
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [showResendLink, setShowResendLink] = useState(true);
  let recaptchaWrapperRef;

  async function verifyOtp() {
    if (!code) return;

    try {
      const otpConfirm = window.confirmationResult;

      setLoading(true);

      await otpConfirm
        .confirm(code)
        .then(async ({ user }) => {
          // User signed in successfully.
          try {
            await processFirebaseUserLogin(user);
          } catch (e) {
            console.error(e);
          }
        })
        .then(() => {
          setLoading(false);
          history.push('/find-ride');
        })
        .catch((e) => {
          setLoading(false);
          // User couldn't sign in (bad verification code?)
          setValidationError('Incorrect OTP');
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function resendOTP() {
    if (window.recaptchaVerifier && recaptchaWrapperRef) {
      setShowResendLink(false);
      window.recaptchaVerifier.clear();
      recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;

      await setUpRecaptcha();
      // const appVerifier = window.recaptchaVerifier;

      await firebase
        .auth()
        .signInWithPhoneNumber(phone, window.recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
        })
        .catch((e) => {
          setLoading(false);
          console.error('err', e);
        });
    }
  }

  return (
    <div>
      <div ref={(ref) => (recaptchaWrapperRef = ref)}>
        <div id="recaptcha-container"></div>
      </div>
      <div className="py-2 flex flex-row items-center">
        <button className="focus:outline-none p-1.5" onClick={() => prevStep()}>
          <img src={iconLeft} alt="Back" className="w-4" />
        </button>
        <div className="font-bold ml-3">Back</div>
      </div>

      <div>
        <h1 className="font-bold" style={{ fontSize: 32 }}>
          Login
        </h1>
      </div>

      <div className="mt-6">Validate mobile by using 6 digit code sent to your phone</div>

      <div className="w-full flex flex-col mt-10">
        <input
          type="number"
          name="otp"
          id="otp"
          required
          placeholder="Insert OTP code"
          className="bg-gray-100 text-black rounded-sm px-4 py-2 w-full"
          value={code}
          onChange={(e) => {
            setValidationError('');
            setCode(e.target.value);
          }}
        />

        {showResendLink && (
          <div className="px-2">
            <p className="my-4">Having trouble with the code</p>
            <div
              onClick={() => resendOTP()}
              className="uppercase font-bold text-nuale_neon_blue mt-6"
            >
              RESEND OTP CODE
            </div>
          </div>
        )}
      </div>

      {/* {error &&
        error.graphQLErrors.map((err, i) => (
          <div className="bg-red-300 px-5 py-2 rounded-sm" key={i}>
            {err.message}
          </div>
        ))} */}

      {validationError && <div className="bg-red-300 px-5 py-2 rounded-sm">{validationError}</div>}

      <button
        disabled={!code || isLoading}
        style={{ cursor: !code ? 'not-allowed' : 'pointer' }}
        className="bg-black text-white text-base font-bold px-4 py-2 rounded-full block mt-6 focus:outline-none w-full disabled:opacity-50"
        onClick={async () => await verifyOtp()}
      >
        Login
      </button>
    </div>
  );
}
