import React, { useState } from 'react';
import { Redirect } from 'react-router';
import {
  SignUpMethod,
  RoleSelect,
  SignUpWithGoogleFlow,
  SignUpWithPhoneFlow,
} from '../../components/SignUp/';
import { SIGN_UP_METHODS } from '../../shared/constants';

export function SignUp() {
  const [signUpMethod, setSignUpMethod] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [gender, setGender] = useState('');
  const [idCard, setIdCard] = useState('');
  const [driverLicenceNumber, setDriverLicenceNumber] = useState('');
  const [vehicleCategory, setVehicleCategory] = useState('');
  const [vehicleBrand, setVehicleBrand] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [fuelType, setFuelType] = useState('');
  const [fuelConsumption, setFuelConsumption] = useState('');
  const [engineSize, setEngineSize] = useState('');
  const [carImages, setCarImages] = useState([]);
  const [driverLicenceImages, setDriverLicenceImages] = useState([]);
  const [userAvatarImage, setUserAvatarImage] = useState([]);
  const [bankName, setBankName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [yearOfRegistration, setYearOfRegistration] = useState(new Date());

  const setters = {
    setSignUpMethod,
    setActiveStep,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setRole,
    setGender,
    setIdCard,
    setDriverLicenceNumber,
    setVehicleCategory,
    setVehicleBrand,
    setVehicleModel,
    setFuelType,
    setFuelConsumption,
    setEngineSize,
    setCarImages,
    setDriverLicenceImages,
    setUserAvatarImage,
    setBankName,
    setBankAccountNumber,
    setBeneficiaryName,
    setYearOfRegistration,
  };

  const values = {
    signUpMethod,
    activeStep,
    firstName,
    lastName,
    email,
    phone,
    role,
    gender,
    idCard,
    driverLicenceNumber,
    vehicleCategory,
    vehicleBrand,
    vehicleModel,
    fuelType,
    fuelConsumption,
    engineSize,
    carImages,
    driverLicenceImages,
    userAvatarImage,
    bankName,
    bankAccountNumber,
    beneficiaryName,
    yearOfRegistration,
  };

  function prevStep() {
    setActiveStep(activeStep - 1);
  }
  function nextStep() {
    setActiveStep(activeStep + 1);
  }

  function _renderSignUp() {
    if (!role) {
      return <RoleSelect setRole={setRole} />;
    }

    if (role && activeStep === 0) {
      return <SignUpMethod values={values} setters={setters} nextStep={nextStep} />;
    }

    if (role && activeStep > 0) {
      switch (signUpMethod) {
        case SIGN_UP_METHODS.GOOGLE: {
          return (
            <SignUpWithGoogleFlow
              values={values}
              setters={setters}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          );
        }

        case SIGN_UP_METHODS.PHONE: {
          return (
            <SignUpWithPhoneFlow
              values={values}
              setters={setters}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          );
        }

        default: {
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }
      }
    }
  }

  return <div className="p-6">{_renderSignUp()}</div>;
}
