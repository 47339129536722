import gql from 'graphql-tag';

export const REMOVE_NOTIFICATION = gql`
  mutation removeNotification($id: ID!) {
    removeNotification(id: $id) {
      id
      type
      notificationReceiver {
        id
      }
      message
    }
  }
`;
