import React, { useState, useEffect } from 'react';

// import { Search } from '../';
import { AddPickupPointSecondStep } from './secondStep';
import { MinSpinner } from '../../';

import pointFrom_x1 from '../../../assets/images/mapFindForm/edit-location-black-18-dp.png';
import iconLeft from '../../../assets/images/arrow-left-solid.svg';
import LocationImg from '../../../assets/images/mapFindForm/my-location-24-px-1.png';

export const AddPickupPoint = ({
  // setCenterCoords,
  // setOrigin,
  // setMarkers,
  // setCenterCoords,
  setAddPickupPoint,
  origin,
  destination,
  wayPoints3,
  wayPoints4,
  setWayPoints3,
  setWayPoints4,
  setPickUpPoint,
  pickUpPoint,
  setDropOffPoint,
  dropOffPoint,
  setStartRequests,
  createRideFunc,
  setPickUpPointTime,
  pickUpPointTime,
  dropOffPointTime,
  setDropOffPointTime,
  createRideStatus,
  rideCostPerSeat,
  rideCostPerSeatStatus,
  setSelectRouteIndex,
  fuelConsumptionMarker,
  co2Marker,
  getYourLocation,
  setMapHeight,
  geoLocationMarkerLoading,
  showScheduleRide,
  // isSecondStep,
  // setSecondStep
}) => {
  const [isSecondStep, setSecondStep] = useState(false);
  const [wayPointsFilter, setWayPointsFilter] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setSecondStep(!!wayPoints4.length);
    setWayPointsFilter(wayPoints3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wayPoints3]);

  useEffect(() => {
    setMapHeight('120%');
    // setCenterCoords({
    //   lat: -20.1,
    //   lng: 57.5833333
    // });
    return () => setMapHeight('70%');
    // return () => setCenterCoords({lat: -20.4, lng: 57.5833333});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function filterWayPointsFunc(event) {
    let someName = event.target.value;
    setInputValue(someName);
    setWayPointsFilter(
      wayPoints3.filter((el) => el.name.toLowerCase().includes(someName.toLowerCase())),
    );
  }

  return (
    <div
      className="absolute w-full right-0 top-0 bg-white pb-6"
      style={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' }}
    >
      {/*<div className="m-auto w-12 h-1 bg-black" onClick={}></div>*/}
      {!isSecondStep ? (
        <>
          <div className="flex justify-start items-center bg-black mb-5 p-4">
            <button onClick={() => setAddPickupPoint(false)}>
              <img src={iconLeft} alt="icon back" className="w-4" />
            </button>
            <span className="text-lg font-sans text-white font-medium ml-4">Add Pickup Point</span>
          </div>
          <form className="flex justify-between flex-wrap px-4 mb-4">
            <div className="w-full flex items-center relative">
              <picture className="w-8">
                <img src={pointFrom_x1} alt="Rider icon" />
              </picture>
              {/*<Search*/}
              {/*setCenterCoords={setCenterCoords}*/}
              {/*setMarkers={setMarkers}*/}
              {/*setOrigin={setOrigin}*/}
              {/*>*/}
              <div className="w-full">
                <input
                  className="w-full bg-gray-100 py-3 px-4 text-base font-bold font-sans"
                  placeholder="Your location"
                  // defaultValue={origin}
                  value={inputValue}
                  onChange={filterWayPointsFunc}
                  type="text"
                  // disabled
                />
                <div
                  className="absolute top-0 right-0 h-full w-10 cursor-pointer flex items-center justify-center z-20 bg-gray-100"
                  onClick={(event) => {
                    event.stopPropagation();
                    getYourLocation();
                    // getYourLocation(0.15);
                  }}
                >
                  {!geoLocationMarkerLoading ? (
                    <img className="w-4 cursor-pointer" src={LocationImg} alt="Location img" />
                  ) : (
                    <MinSpinner color={'gray'} />
                  )}
                </div>
              </div>
              {/*</Search>*/}
            </div>
          </form>
          <div className="px-5">
            <h1 className="text-sm font-bold font-sans mb-4">Select Pickup point</h1>
            <div className="text-sm pl-6 overflow-scroll">
              <div className="max-h-36">
                {wayPointsFilter.length ? (
                  wayPointsFilter.map((el, index) => (
                    <button
                      key={`addPickUpPointsKey${index + 1}`}
                      className="mb-5 w-full flex justify-between items-center hover:bg-gray-100 focus:outline-none"
                      onClick={() => {
                        setStartRequests(true);
                        setPickUpPoint(el);
                        setPickUpPointTime((current) => +current + +el.duration * 1000);
                        setSelectRouteIndex(0);
                        setWayPoints4((current) => {
                          if (!current.length) {
                            return [
                              ...current,
                              el,
                              // {
                              //   location: {
                              //     lat: el.location.lat,
                              //     lng: el.location.lng,
                              //   },
                              // },
                            ];
                          }
                          return [
                            // {
                            //   location: {
                            //     lat: el.location.lat,
                            //     lng: el.location.lng,
                            //   },
                            // },
                            el,
                            {
                              ...current[1],
                            },
                          ];
                        });
                        setSecondStep(true);
                      }}
                    >
                      <span className="flex items-center">
                        <span className="inline-block text-center text-xs leading-5 w-5 h-5 text-white rounded-full bg-indigo-600 mr-1">
                          {index + 1}
                        </span>
                        <span className="font-semibold">{el.name}</span>
                      </span>
                      <span>{(el.distance / 1000).toFixed(1) + ' km'}</span>
                    </button>
                  ))
                ) : (
                  <span className="text-nuale_neon_blue">No Prefered pickup points</span>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <AddPickupPointSecondStep
          setAddPickupPoint={setAddPickupPoint}
          showScheduleRide={showScheduleRide}
          origin={origin}
          destination={destination}
          pickUpPoint={pickUpPoint}
          setPickUpPoint={setPickUpPoint}
          wayPoints3={wayPoints3}
          setWayPoints3={setWayPoints3}
          setWayPoints4={setWayPoints4}
          setDropOffPoint={setDropOffPoint}
          dropOffPoint={dropOffPoint}
          setStartRequests={setStartRequests}
          setSecondStep={setSecondStep}
          createRideFunc={createRideFunc}
          setPickUpPointTime={setPickUpPointTime}
          pickUpPointTime={pickUpPointTime}
          dropOffPointTime={dropOffPointTime}
          setDropOffPointTime={setDropOffPointTime}
          createRideStatus={createRideStatus}
          rideCostPerSeat={rideCostPerSeat}
          rideCostPerSeatStatus={rideCostPerSeatStatus}
          fuelConsumptionMarker={fuelConsumptionMarker}
          co2Marker={co2Marker}
          setMapHeight={setMapHeight}
        />
      )}
    </div>
  );
};
