import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import {
  withStyles as DatePickerComponentWithStyles,
  createMuiTheme,
} from '@material-ui/core/styles';

const classes = (theme) => ({
  input: {
    'font-weight': '500',
    padding: '0 1rem',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00CCBF',
    },
  },
});

function DatePickerComponent({ classes, startPointDate, setStartPointDate, setPickUpPointTime }) {
  // const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setStartPointDate(date);
    setPickUpPointTime && setPickUpPointTime(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <DatePicker
          className="w-full h-12 flex items-center justify-center bg-gray-100 py-3 px-4 text-base font-bold font-sans"
          id="date-picker-dialog"
          format="dd/MM/yyyy"
          // views={["year"]}
          // label="Year only"
          value={startPointDate}
          minDate={Date.now()}
          onChange={handleDateChange}
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default DatePickerComponentWithStyles(classes)(DatePickerComponent);
