import React from 'react';
import { NavLink } from 'react-router-dom';
import { Skip } from '../../components';
import { ROUTES } from '../../shared/constants';

import logo_x3 from '../../assets/images/startPage/asset-1@3x.png';

export const Welcome = () => {
  return (
    <div>
      <div className="px-5 pt-28 pb-5">
        <h1 className="mb-4 text-3xl font-sans font-bold">Welcome to</h1>
        <div className="mb-8 w-36 h-12"
             style={{
               backgroundImage: `url(${logo_x3})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
             }}
        >
          {/*<picture>*/}
            {/*<img className="w-36" src={logo_x3} alt="Logo nuale" />*/}
          {/*</picture>*/}
        </div>
        <p className="mb-12 text-sm font-sans">
          For a better experience using Nuale app, create an account or login to your existing one.
        </p>
        <NavLink to={ROUTES.LOGIN}>
          <div className="mb-8 text-base font-sans font-bold text-white bg-black rounded-3xl text-center py-2 cursor-pointer">
            Login
          </div>
        </NavLink>
        <NavLink to={ROUTES.SIGN_UP}>
          <div className="mb-36 text-base font-sans font-bold text-black bg-white rounded-3xl text-center py-1.5 border-2 border-solid border-black cursor-pointer">
            Sign up
          </div>
        </NavLink>
        <Skip path={ROUTES.FIND_RIDE} />
      </div>
    </div>
  );
};
