import React from 'react';
import { NavLink } from 'react-router-dom';

export const BecomeDriver = () => {
  return (
    <div className="px-4 mt-24">
      <h2 className="font-bold text-3xl mb-10">Want to become driver ?</h2>
      <div className="p-2 rounded-full bg-nuale_neon_blue text-white text-center">
        <NavLink to="/become-driver" className="font-bold text-base">
          Register as driver
        </NavLink>
      </div>
    </div>
  );
};
