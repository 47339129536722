import React from 'react';
import { NavLink } from 'react-router-dom';

export const Skip = ({ path }) => {
  return (
    <NavLink to={path}>
      <div className="w-24 mx-auto text-base font-sans font-bold text-black bg-white rounded-3xl text-center py-1.5 border-2 border-solid border-black cursor-pointer">
        Skip
      </div>
    </NavLink>
  );
};
