import gql from 'graphql-tag';

export const FAQ_QUERY = gql`
  query faq {
    faqs {
      id
      category {
        name
      }
      faqTarget
      question
      answer
    }
  }
`;
