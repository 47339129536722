import React from 'react';
import { NavLink } from 'react-router-dom';
import driverImg from '../../assets/images/sign-up/driver_big.svg';

export function SignUpDriverSuccess() {
  return (
    <div className="flex flex-col h-full absolute top-0 left-0 right-0">
      <img className="w-full max-w-md mx-auto" src={driverImg} alt="Driver" />

      <div className="flex flex-col p-6 w-full">
        <div className="">
          <h1 className="font-bold text-3xl" style={{ fontSize: 32 }}>
            Thanks for believing in us!
          </h1>
        </div>

        <div className="mt-7">
          Someone will contact you to validate your <br />
          <b>Nuale Driver account</b>
        </div>

        <div className="font-bold mt-4 text-base">
          Login and share your first ride <br /> with the community.
        </div>

        <NavLink to="/find-ride">
          <button className="block mt-12 w-full disabled:opacity-50 bg-blue-700 text-white text-base font-bold px-4 py-2 rounded-full focus:outline-none">
            Login
          </button>
        </NavLink>
      </div>
    </div>
  );
}
