import gql from 'graphql-tag';

export const REMOVE_RIDE = gql`
  mutation removeRide($id: ID!) {
    removeRide(id: $id) {
      id
      driver {
        id
        firstName
        lastName
        gender
        userImg
        userVehicles {
          make {
            name
          }
          model
          spec {
            engineSize
            fuelConsumption
          }
        }
      }
      seats
      startDate
      pricePerSeat
      startPoint {
        name
        location {
          lat
          lng
        }
        eta
      }
      endPoint {
        name
        location {
          lat
          lng
        }
        eta
      }
      waypoints {
        id
      }
      waypointsEta {
        waypointId
        eta
      }
      createdAt
      updatedAt
    }
  }
`;
