import React, { useContext, useEffect, useState } from 'react';

import { useSubscription, useLazyQuery } from '@apollo/client';
import { useAuth } from '../../components/contexts/AuthContext';
import { NOTIFICATION_ADDED_SUBSCRIPTION } from '../../shared/gqlQueries/notificationAdded';
// import { RIDE_STATUS_CHANGED_SUBSCRIPTION } from '../../shared/gqlQueries/rideStatusChanged';
import { MY_NOTIFICATIONS_QUERY } from '../../shared/gqlQueries/getMyNotifications';

const WebSocketContext = React.createContext();

export function useWebSocket() {
  return useContext(WebSocketContext);
}

export function WebSocketProvider({ children }) {
  const [notificationsNotReadedGlobal, setNotificationsNotReadedGlobal] = useState([]);
  const [notificationsArr, setNotificationsArr] = useState([]);
  const { currentUser, isUserAuthenticated } = useAuth();

  const { data } = useSubscription(NOTIFICATION_ADDED_SUBSCRIPTION, {
    variables: { receiverID: getID() },
  });

  // // function ChangeRideStatusSubscription() {
  //     const rideData = useSubscription(RIDE_STATUS_CHANGED_SUBSCRIPTION, {
  //       variables: {ridesList: ['61654ccef0935667601d6d33']},
  //       // variables: {ridesList: ridesArr.map(el => el.id)},
  //     });
  //
  //     console.log('rideData', rideData);
  // // }

  const [getMyNotificationsFunc, notificationsData] = useLazyQuery(MY_NOTIFICATIONS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      if (
        notificationsData.data &&
        notificationsData.data.getMyNotifications &&
        notificationsData.data.getMyNotifications.length
      ) {
        // console.log('[getNotificationsFunc]', (([...notificationsData.data.getMyNotifications]).sort((a,b)=> b.createdAt - a.createdAt)));
        setNotificationsNotReadedGlobal(
          getNotReadedNotificationsArr(notificationsData.data.getMyNotifications),
        );
        setNotificationsArr(
          updateNotificationsArrForShow(notificationsData.data.getMyNotifications),
        );
      }
    },
    onError: (error) => {
      console.log('[getMyNotificationsFunc] error getNotificationsFunc', error);
    },
  });

  // console.log('data', data);
  // console.log('notificationsArr', notificationsArr);

  useEffect(() => {
    isUserAuthenticated() && getMyNotificationsFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated]);

  useEffect(() => {
    data &&
      setNotificationsNotReadedGlobal((current) => [
        ...current,
        updateNotificationsArrForShow([data.notificationAdded]),
      ]);
    // data && setNotificationsArr(current => [...current, updateNotificationsArrForShow([data.notificationAdded])])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // useEffect(()=>{
  //   // setRidesArr.length && ChangeRideStatusSubscription();
  // }, [ridesArr]);

  const value = {
    notificationsNotReadedGlobal,
    setNotificationsNotReadedGlobal,
    notificationsArr,
    setNotificationsArr,
    getMyNotificationsFunc,
    notificationsData
  };

  function getID() {
    return isUserAuthenticated() ? currentUser.id : '';
  }

  function getNotReadedNotificationsArr(arr) {
    return arr.length ? arr.filter((el) => !el.read) : [];
  }

  const updateNotificationsArrForShow = (arr) => {
    let sortArrByCategory = [...arr].sort((a, b) => b.createdAt - a.createdAt);

    let addReadStatusArr = [];
    // let categoryObj = {};
    sortArrByCategory.forEach((el) => {
      addReadStatusArr = [...addReadStatusArr, { ...el, statusShow: false }];
    });
    return addReadStatusArr;
  };

  // // if(loading) {
  //   console.log('GlobalVariablesContext subscribe data', data);
  //   console.log('GlobalVariablesContext subscribe loading', loading);
  // // }
  // // if(error) {
  //   console.log('GlobalVariablesContext subscribe error', error);
  // // }

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
}
