import React from 'react';

export function TalkToUsForm() {
  // const [showMenu, setShowMenu] = useState(false);
  const isDisabled = () => {
    if (true) return true;
  };

  return (
    <div className="min-h-screen pt-20 px-7 pb-4 bg-white">
      <h1 className="text-3xl font-bold mb-4">Talk to us</h1>
      <p className="mb-4">Leave us a message and we shall revert back to you</p>
      <form>
        <input className="bg-gray-100 px-4 py-3 w-full mb-4" type="text" placeholder="First name" />
        <input className="bg-gray-100 px-4 py-3 w-full mb-4" type="text" placeholder="Last name" />
        <input className="bg-gray-100 px-4 py-3 w-full mb-4" type="text" placeholder="Phone" />
        <textarea
          className="bg-gray-100 px-4 py-3 w-full h-60 mb-8 resize-none"
          placeholder="Your message"
        />
        <button
          className={
            !isDisabled()
              ? 'w-full text-center py-2 px-4 text-white rounded-3xl bg-gray-400 text-base font-bold font-sans'
              : 'w-full text-center py-2 px-4 text-white rounded-3xl bg-black text-base font-bold font-sans'
          }
          onClick={() => {
            // setAddPickupPoint(true)
          }}
          disabled={!isDisabled() ? 'disabled' : ''}
        >
          SEND
        </button>
      </form>
    </div>
  );
}
