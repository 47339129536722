import gql from 'graphql-tag';

export const MY_NOTIFICATIONS_QUERY = gql`
  query getMyNotifications {
    getMyNotifications {
      id
      type
      read
      ride {
        id
        driver {
          id
          firstName
          lastName
          gender
          userImg
          userVehicles {
            make {
              name
            }
            model
            spec {
              engineSize
              fuelConsumption
            }
          }
        }
        seats
        startDate
        pricePerSeat
        startPoint {
          name
          location {
            lat
            lng
          }
          eta
        }
        endPoint {
          name
          location {
            lat
            lng
          }
          eta
        }
        waypoints {
          id
          name
        }
        waypointsEta {
          waypointId
          eta
        }
        createdAt
      }
      notificationReceiver {
        id
      }
      message
      createdAt
    }
  }
`;
