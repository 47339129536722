import { gql } from '@apollo/client';

export const CREATE_RIDE = gql`
  mutation createRide($input: CreateRideInput!) {
    createRide(createRideInput: $input) {
      id
      driver {
        id
        uid
        firstName
        lastName
        email
        phone
        userImg
        role
        createdAt
        updatedAt
        driverInfo {
          bankName
          bankAccountNumber
          beneficiaryName
          yearOfRegistration
          fuelConsumption
          engineSize
          driverLicenceNumber
          vehicleCategory
          driverLicenceImages {
            key
            imgPath
          }
          carImages {
            key
            imgPath
          }
          fuelType
        }
        userVehicles {
          id
          make {
            id
            name
          }
          model
          spec {
            engineSize
            fuelConsumption
          }
          fuelTypes {
            id
            type
            price
          }
        }
        userPreferences {
          ladyDrivers
          ecoFriendlyRides
          luxuryCars
          standardCars
          moreThanTwoSeats
        }
      }
      seats
      startDate
      startPoint {
        name
        location {
          lat
          lng
        }
        eta
      }
      endPoint {
        name
        location {
          lat
          lng
        }
        eta
      }
      waypoints {
        id
      }
      waypointsEta {
        waypointId
        eta
      }
      createdAt
      updatedAt
    }
  }
`;
