import React, { useState } from 'react';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

export function Search({
  setCenterCoords,
  // setMarkers,
  setMarkerStart,
  setMarkerEnd,
  setOrigin,
  setDestination,
  setStartRequests,
  children,
}) {
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
    region: 'US',
  });

  function onLoad(autocomplete) {
    // console.log('autocomplete', autocomplete);
    setAutocomplete(autocomplete);
  }

  function onPlaceChanged() {
    // console.log('autocomplete', autocomplete);
    if (autocomplete !== null) {
      const newCoords = {
        location: {
          lat: autocomplete.getPlace().geometry.location.lat(),
          lng: autocomplete.getPlace().geometry.location.lng(),
        },
      };

      setCenterCoords(newCoords.location);
      if (setOrigin) {
        setOrigin({ name: autocomplete.getPlace().name, ...newCoords });
        // setOrigin(autocomplete.getPlace().name);

        setMarkerStart(newCoords);
        // setMarkers((current) => {
        //   if (!current.length) {
        //     return [{ ...newCoords }, ...current];
        //   }
        //   return current[1] ? [{ ...newCoords }, current[1]] : [{ ...newCoords }];
        // });
      } else {
        // setDestination(newCoords);
        setDestination({ name: autocomplete.getPlace().name, ...newCoords });

        setMarkerEnd(newCoords);
        // setMarkers((current) => {
        //   if (!current.length) {
        //     return [...current, { ...newCoords }];
        //   }
        //   return current[0] ? [current[0], { ...newCoords }] : [{ ...newCoords }];
        // });
      }
      setStartRequests && setStartRequests(true);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }

  if (loadError) return 'Error';
  if (!isLoaded) return <div className="w-full relative">{children}</div>;

  return (
    <>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        restrictions={{ country: 'MU' }}
        className={'w-full relative'}
      >
        {children}
      </Autocomplete>
    </>
  );
}
