import React from 'react';

import arrowDown from '../../assets/images/chevron-down-solid.svg';

export function NotificationItem({
  notificationInfo,
  removeNotificationFunc,
  showNotificationItem,
}) {
  return (
    <div className="w-full">
      <div
        className={`py-2 w-full px-5 flex items-center justify-between ${
          notificationInfo.read ? 'bg-nuale_dark_grey' : 'bg-nuale_turquoise'
        }`}
      >
        <span className="text-white text-sm">{notificationInfo.message}</span>
        <div className="w-20 flex justify-end">
          <img
            className={`w-3 duration-75 mr-5 ${
              notificationInfo.statusShow ? 'transform rotate-180' : ''
            }`}
            src={arrowDown}
            alt="chevron img"
            id={notificationInfo.id}
            data-name={'notification'}
            onClick={(event) => showNotificationItem(event.target)}
          />
          <span
            className="inline-block h-4 leading-3 text-nuale_dark_grey px-1 rounded-full bg-white"
            data-id={notificationInfo.id}
            onClick={(event) => removeNotificationFunc(event.target.dataset.id)}
          >
            x
          </span>
        </div>
      </div>
      <div
        className={`w-full px-7 bg-nuale_light_grey duration-75 ${
          notificationInfo.statusShow ? 'py-3 h-full' : 'py-1 overflow-hidden h-0'
        }`}
      >
        <div className="w-full flex flex-col justify-between py-1">
          <div className="w-full flex justify-between mb-3 ">
            <div className="">
              <div className="text-xs text-purple-400">Driver</div>
              <div className="text-base font-bold">{`${notificationInfo.ride.driver.firstName} ${notificationInfo.ride.driver.lastName}`}</div>
              {/*<div className="w-full flex text-sm">{notificationInfo.rideRequest.ride.driver.userVehicles.length ? `${notificationInfo.rideRequest.ride.driver.userVehicles[0].make.name} ${notificationInfo.rideRequest.ride.driver.userVehicles[0].model}` : "-----"}</div>*/}
            </div>
            <div className="=">
              <div className="text-xs text-purple-400">Ride Date</div>
              <div className="text-base font-bold">{`${new Date(
                notificationInfo.ride.startDate,
              ).getDate()}/${new Date(notificationInfo.ride.startDate).getMonth() + 1}/${new Date(
                notificationInfo.ride.startDate,
              ).getFullYear()}`}</div>
            </div>
            <div className="">
              <div className="text-xs text-purple-400">Seats</div>
              <div className="text-sm font-bold">{notificationInfo.ride.seats}</div>
            </div>
          </div>
        </div>
        <div className="flex justify-between py-1">
          <div className="w-1/2 text-xs mr-1">
            <div className="">Pickup point</div>
            <div className="font-bold">{notificationInfo.ride.waypoints[0].name}</div>
          </div>
          <div className="w-1/2 text-xs">
            <div className="">Dropoff point</div>
            <div className="font-bold">{notificationInfo.ride.waypoints[1].name}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
