// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { useAuth } from '../../components/contexts/AuthContext';
import { RIDE_REQUESTS_BY_PASSENGER_ID_QUERY } from '../../shared/gqlQueries/rideRequestsByPassengerId';
import { RIDE_REQUESTS_QUERY } from '../../shared/gqlQueries/rideRequests';
import { RIDES_BY_DRIVER_ID_QUERY } from '../../shared/gqlQueries/findRidesByDriverId';
import { CANCEL_RIDE_REQUEST } from '../../shared/gqlQueries/cancelRideRequest';
import { REMOVE_RIDE } from '../../shared/gqlQueries/removeRideById';
import { CREATE_NOTIFICATION } from '../../shared/gqlQueries/createNotification';
import { NOTIFICATIONS_QUERY } from '../../shared/gqlQueries/notifications';
import { REMOVE_NOTIFICATION } from '../../shared/gqlQueries/removeNotificationById';
import {
  ROUTES,
  ROLES,
  RIDE_REQUEST_STATUS,
  NUALE_NOTIFICATION_TYPE,
} from '../../shared/constants';

import { RideHistoryItem, Popup } from '../../components';

import iconLeft from '../../assets/images/arrow-left-solid.svg';

export function RideScheduled(props) {
  const someProp = props.location.propsDriver || 'riderList';
  const history = useHistory();
  const [rideRequestsFilterByIdArr, setRideRequestsFilterByIdArr] = useState([]);
  const [rideRequestsArr, setRideRequestsArr] = useState([]);
  // const [notificationsArr, setNotificationsArr] = useState([]);
  const [ridesArr, setRidesArr] = useState([]);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isShowPopupError, setIsShowPopupError] = useState(false);
  const [activeList, setActiveList] = useState(someProp);
  const [cancelRideId, setCancelRideId] = useState('');
  const [driverId, setDriverId] = useState('');
  const [rideId, setRideId] = useState('');
  // const [selectRideRequestById, setSelectRideRequestById] = useState('');
  const [popupContent, setPopupContent] = useState(
    'Are your sure your want to cancel your ride ? Note you will not be refunded.',
  );
  const [popupContentError, setPopupContentError] = useState('');
  const [
    popupTitle,
    // setPopupTitle
  ] = useState('Ride Cancellation');
  const { currentUser } = useAuth();

  const [getRideRequestsByIdDataFunc, rideRequestsByIdData] = useLazyQuery(
    RIDE_REQUESTS_BY_PASSENGER_ID_QUERY,
    {
      fetchPolicy: 'network-only', // always will fetch data, no cache
      onCompleted: () => {
        if (rideRequestsByIdData.data && rideRequestsByIdData.data.rideRequestsByPassengerId) {
          // console.log('[rideRequestsByIdData]', ridesFilterByRiderAndPaidStatus(rideRequestsByIdData.data.rideRequestsByPassengerId));
          setRideRequestsFilterByIdArr(
            ridesFilterByRiderAndPaidStatus(rideRequestsByIdData.data.rideRequestsByPassengerId),
          );
        }
      },
      onError: (error) => {
        console.log('[GetRidesHistory] error getRideRequestsByIdDataFunc', error);
      },
    },
  );

  const [getRideRequestsFunc, rideRequestsData] = useLazyQuery(RIDE_REQUESTS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
      if (rideRequestsData.data && rideRequestsData.data.rideRequests) {
        // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
        setRideRequestsArr(
          rideRequestsFilterByDateAndPaidStatus(rideRequestsData.data.rideRequests),
        );
      }
    },
    onError: (error) => {
      console.log('[getRideRequest] error rideRequestsData', error);
    },
  });

  const [getNotificationsFunc, notificationsData] = useLazyQuery(NOTIFICATIONS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      if (notificationsData.data && notificationsData.data.notifications) {
        // console.log('[notificationsData]', ridesFilterByRiderAndPaidStatus(notificationsData.data.notifications));

        // setNotificationsArr(
        //   notificationsData.data.notifications
        // );
        //   console.log(notificationsFilterByRide(notificationsData.data.notifications));
        let noteIdArr;
        let noteFilterByRideArr = notificationsFilterByRide(notificationsData.data.notifications);
        if (noteFilterByRideArr.length) {
          noteIdArr = noteFilterByRideArr.map((el) => el.id);
          noteIdArr.forEach((id) => {
            setTimeout(
              removeNotification({
                variables: {
                  id,
                },
              }),
              500,
            );
          });
        }
        removeRide({
          variables: {
            id: cancelRideId,
          },
        });
      }
    },
    onError: (error) => {
      console.log('[notificationsData] error notificationsData', error);
    },
  });

  const [
    cancelRideNotification,
    // cancelRideNotificationStatus
  ] = useMutation(CREATE_NOTIFICATION, {
    errorPolicy: 'all',
    onCompleted: (req) => {
      console.log('[cancelRideNotification]', req);
      if (req) {
      }
    },
    onError: (error) => {
      console.log('[cancelRideNotification] error', error);
      // setTitle("Error");
      setPopupContent('Something wrong');
    },
  });

  const [getRidesFunc, ridesData] = useLazyQuery(RIDES_BY_DRIVER_ID_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      if (ridesData.data && ridesData.data.findRidesByDriverId) {
        // console.log('[rideRequestsByIdData]', ridesFilterByDriver(ridesData.data.findRidesByDriverId));
        setRidesArr(ridesFilterByDriver(ridesData.data.findRidesByDriverId));
      }
    },
    onError: (error) => {
      console.log('[GetRidesHistory] error getRideRequestsByIdDataFunc', error);
    },
  });

  const [cancelRideRequest, cancelRideRequestData] = useMutation(CANCEL_RIDE_REQUEST, {
    errorPolicy: 'all',
    onCompleted: (req) => {
      // console.log('[RemoveRideRequest]', req);
      setIsShowPopup(false);
      // getRideRequestsByIdDataFunc();
      setRideRequestsFilterByIdArr((current) => current.filter((el) => el.id !== cancelRideId));
      cancelRideNotificationFunc('DRIVER_RIDE_CANCELED_BY_RIDER', driverId);
      setTimeout(
        () => cancelRideNotificationFunc('RIDER_RIDE_CANCELED_BY_RIDER', currentUser.id),
        1000,
      );
    },
    onError: (error) => {
      setIsShowPopup(false);
      console.log('[RemoveRideRequest] error', error);
      setPopupContentError('Error: RemoveRideRequest', error.message);
      setIsShowPopupError(true);
      // setPopupTitle('Error');
    },
  });

  const [removeRide, removeRideData] = useMutation(REMOVE_RIDE, {
    errorPolicy: 'all',
    onCompleted: (req) => {
      console.log('[RemoveRide] removeRideData', req);
      if (req) {
        setIsShowPopup(false);
        // getRideRequestsByIdDataFunc();
        setRidesArr((current) => current.filter((el) => el.id !== cancelRideId));
        // getNotificationsFunc();
        // cancelRideNotificationFunc("", )
      } else {
        setIsShowPopup(false);
        setPopupContentError('It is not possible to cancel the Ride. This ride was booked.');
        setIsShowPopupError(true);
      }
    },
    onError: (error) => {
      setIsShowPopup(false);
      console.log('[RemoveRide] error RemoveRide', error);
      setPopupContentError('Error: RemoveRide', error.message);
      setIsShowPopupError(true);
      // setPopupTitle('Error');
    },
  });

  const [removeNotification] = useMutation(REMOVE_NOTIFICATION, {
    errorPolicy: 'all',
    onCompleted: (req) => {
      console.log('[removeNotification] removeNotificationData', req);
      if (req) {
      }
    },
    onError: (error) => {
      setIsShowPopup(false);
      console.log('[removeNotification] error removeNotificationData', error);
      setPopupContentError('Error: removeNotification', error);
      setIsShowPopupError(true);
      // setPopupTitle('Error');
    },
  });

  function cancelRideNotificationFunc(type, receiverId) {
    cancelRideNotification({
      variables: {
        input: {
          type: NUALE_NOTIFICATION_TYPE[type],
          ride: rideId,
          notificationReceiver: receiverId,
          // message: 'Ride schedule',
        },
      },
    });
  }

  function ridesFilterByRiderAndPaidStatus(arr) {
    return arr.filter(
      (el) =>
        el.passenger.id === currentUser.id &&
        +el.ride.waypointsEta[0].eta > +Date.now() &&
        el.status.toLowerCase() === RIDE_REQUEST_STATUS.PAID.toLowerCase(),
    );
    // return arr.filter(el => (el.driver.id === currentUser.id && +el.waypointsEta[0].eta > +Date.now()))
  }

  function rideRequestsFilterByDateAndPaidStatus(arr) {
    return arr.filter(
      (el) =>
        +el.ride.waypointsEta[0].eta > +Date.now() &&
        el.status.toLowerCase() === RIDE_REQUEST_STATUS.PAID.toLowerCase(),
    );
    // return arr.filter(el => (el.driver.id === currentUser.id && +el.waypointsEta[0].eta > +Date.now()))
  }

  function ridesFilterByDriver(arr) {
    return arr.filter(
      (el) => el.driver.id === currentUser.id && +el.waypointsEta[0].eta > +Date.now(),
    );
  }

  function notificationsFilterByRide(arr) {
    return arr.filter((el) => el.ride.id === cancelRideId);
  }

  function cancelRideRequestFunc(id) {
    cancelRideRequest({
      variables: {
        id,
      },
    });
  }

  function removeRideFunc(id) {
    getNotificationsFunc();
    // removeRide({
    //   variables: {
    //     id,
    //   },
    // });
  }

  function choisenCancelRide(id, driverId, rideId) {
    setIsShowPopup(true);
    setCancelRideId(id);
    setDriverId(driverId);
    setRideId(rideId);
    // getRideRequestByIdFunc({variables: {id}})
  }

  function popupResetOk() {
    activeList === 'riderList' && cancelRideRequestFunc(cancelRideId);
    activeList === 'driverList' && removeRideFunc(cancelRideId);
  }

  function popupResetCancel() {
    setCancelRideId('');
    setIsShowPopup(false);
  }

  useEffect(() => {
    activeList === 'riderList' &&
      getRideRequestsByIdDataFunc({ variables: { id: currentUser.id } });
    activeList === 'driverList' && getRidesFunc({ variables: { id: currentUser.id } });
    getRideRequestsFunc();
    // activeList === 'driverList' && getRideRequestsFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log('removeRideData', removeRideData);
  // console.log('cancelRideId', cancelRideId);

  return (
    <div className="container mx-auto box-border relative ">
      {/*<Header setShowMen={setShowMenu} />*/}
      <div className="w-full flex justify-start items-center bg-black p-4">
        <button onClick={() => history.push(ROUTES.FIND_RIDE)}>
          <img src={iconLeft} alt="icon back" className="w-4" />
        </button>
        <span className="text-lg font-sans font-medium ml-4 text-white">Schedules Ride</span>
      </div>
      <ul
        className={currentUser.role === ROLES.driver ? 'w-full flex justify-between' : 'hidden'}
        onClick={(event) => setActiveList(event.target.dataset.id)}
      >
        <li
          className={
            activeList === 'riderList'
              ? 'w-1/2 bg-white border-t-full p-4 font-semibold text-nuale_turquoise text-center'
              : 'w-1/2 bg-nuale_turquoise p-4 font-semibold text-center text-white'
          }
          data-id="riderList"
          onClick={() => getRideRequestsByIdDataFunc({ variables: { id: currentUser.id } })}
        >
          Rider Schedules
        </li>
        <li
          className={
            activeList === 'driverList'
              ? 'w-1/2 bg-white border-t-full p-4 font-semibold text-nuale_turquoise text-center'
              : 'w-1/2 bg-nuale_turquoise p-4 font-semibold text-center text-white'
          }
          data-id="driverList"
          onClick={() => getRidesFunc({ variables: { id: currentUser.id } })}
        >
          Driver Schedules
        </li>
      </ul>
      <div
        className={
          currentUser.role === ROLES.driver
            ? 'w-full rideListForDriverCalcHeight overflow-y-scroll'
            : 'w-full rideListForRiderCalcHeight overflow-y-scroll'
        }
      >
        <ul className={activeList === 'riderList' ? 'p-4 z-10 bg-white w-full' : 'hidden'}>
          {rideRequestsFilterByIdArr.length ? (
            rideRequestsFilterByIdArr.map((el, index) => (
              <RideHistoryItem
                key={'rideRequestItem' + index}
                rideInfo={el.ride}
                cancelObjId={el.id}
                ridesScheduled={true}
                activeList={activeList}
                cancelRideFunc={choisenCancelRide}
                cancelRideStatus={cancelRideRequestData.loading}
              />
            ))
          ) : (
            <li className="text-nuale_neon_blue min-w-full">
              {rideRequestsByIdData.loading ? 'Loading...' : 'Currently, no rides'}
            </li>
          )}
        </ul>
        <ul
          className={
            activeList === 'driverList' && currentUser.role === ROLES.driver
              ? 'p-4 bg-white w-full'
              : 'hidden'
          }
        >
          {ridesArr.length ? (
            ridesArr.map((el, index) => (
              <RideHistoryItem
                key={'rideItem' + index}
                rideInfo={el}
                cancelObjId={el.id}
                ridesScheduled={true}
                activeList={activeList}
                cancelRideFunc={choisenCancelRide}
                cancelRideStatus={removeRideData.loading}
                setIsShowPopup={setIsShowPopup}
                setPopupContent={setPopupContent}
                rideRequestsArr={rideRequestsArr}
                // rideRequestByIdData={rideRequestByIdData.loading}
                // selectRideRequestById={selectRideRequestById}
              />
            ))
          ) : (
            <li className="text-nuale_neon_blue w-full bg-white">
              {ridesData.loading ? 'Loading...' : 'Currently, no rides'}
            </li>
          )}
        </ul>
      </div>
      <Popup
        isShowPopup={isShowPopup}
        // resetRide={resetRide}
        popupReset={popupResetOk}
        popupResetCancel={popupResetCancel}
        content={popupContent}
        title={popupTitle}
        loading={removeRideData.loading}
      />
      <Popup
        isShowPopup={isShowPopupError}
        // resetRide={resetRide}
        popupReset={() => setIsShowPopupError(false)}
        // popupResetCancel={popupResetCancel}
        content={popupContentError}
      />
    </div>
  );
}
