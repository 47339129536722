import React from 'react';
import Select from 'react-select';

const NualeSelect = ({ options, value, onChange, className, placeholder = '' }) => {
  const customStyles = {
    option: (provided, state) => {
      // console.log('provided', provided);
      // console.log('state', state);
      return {
        ...provided,
        backgroundColor: state.isFocused ? '#fff' : state.isSelected ? '#f7f7f7' : '#f7f7f7',
        color: state.isSelected ? '#00CCBF' : 'rgba(0, 0, 0, 0.87)',
      };
    },
    control: (control) => {
      // console.log('control', control);
      return {
        ...control,
        padding: '0.2rem 0.5rem',
      };
    },
  };

  return (
    <Select
      className={className}
      styles={customStyles}
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      theme={(theme) => {
        // console.log('theme', theme);
        return {
          ...theme,
          spacing: {
            ...theme.spacing,
            menuGutter: 1,
          },
          colors: {
            ...theme.colors,
            primary: 'black',
            primary50: '#f7f7f7',
            neutral0: 'rgba(243, 244, 246, 1)',
            neutral20: 'transparent',
            neutral50: '#999',
          },
        };
      }}
    />
  );
};

export default NualeSelect;
