/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import { useLazyQuery, useMutation } from '@apollo/client';
import Geocode from 'react-geocode';
import OneSignal from 'react-onesignal';
// import axios from 'axios';

import {
  Map,
  MapFindForm,
  FindRideWithoutAuth,
  GetRide,
  Menu,
  Header,
  GetPickUpPoint,
  GetDropOffPoint,
  Popup,
  GridAllRides,
  ScheduleRideDetailes,
} from '../../components';
import { ScheduleRideByRiderDetailes } from '../../components/ScheduleRideDetailes/indexByRider'
import { useAuth } from '../../components/contexts/AuthContext';
import { useWebSocket } from '../../components/contexts/WebSocketContext';
import { useGlobalVariables } from '../../components/contexts/GlobalVariablesContext';

import { SET_PAID_STATUS } from '../../shared/gqlQueries/setPaidStatus';
import { FIND_RIDE_QUERY } from '../../shared/gqlQueries/findRide';
// import { USERS_QUERY } from '../../shared/gqlQueries/users';
// import { NOTIFICATION_ADDED_SUBSCRIPTION } from '../../shared/gqlQueries/notificationAdded';
import { CREATE_NOTIFICATION } from '../../shared/gqlQueries/createNotification';
import { RIDES_BY_DRIVER_ID_QUERY } from '../../shared/gqlQueries/findRidesByDriverId';
import { RIDE_REQUESTS_QUERY } from '../../shared/gqlQueries/rideRequests';
import { MY_RIDE_REQUESTS_QUERY } from '../../shared/gqlQueries/getMyRideRequests';
import {
  ROUTES,
  ROLES,
  LINK_SET_GEOLOCATION,
  NUALE_NOTIFICATION_TYPE,
  RIDE_REQUEST_STATUS,
  RIDE_STATUS
} from '../../shared/constants';


const libraries = ['places'];

export function FindRide() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
    region: 'US',
  });

  let history = useHistory();

  const { isUserAuthenticated, currentUser } = useAuth();
  const {
    originGlobal,
    destinationGlobal,
    flexiRidesGlobal,
    exactRidesGlobal,
    setOriginGlobal,
    setDestinationGlobal,
    setFlexiRidesGlobal,
    setExactRidesGlobal,
    setRidesArr,
    ridesArr
  } = useGlobalVariables();
  const [centerCoords, setCenterCoords] = useState({ lat: -20.3, lng: 57.5833333 });
  const [mapHeight, setMapHeight] = useState('70%');
  const [markers, setMarkers] = useState([]);
  const [markerStart, setMarkerStart] = useState();
  const [response, setResponse] = useState(null);
  const [markerEnd, setMarkerEnd] = useState();
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [isGetRide, setGetRide] = useState(false);
  const [isGetPickUpPoint, setIsGetPickUpPoint] = useState(false);
  const [isGetDropOffPoint, setIsGetDropOffPoint] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [wayPoints3, setWayPoints3] = useState([]);
  const [wayPoints4, setWayPoints4] = useState([]);
  const [flexiRides, setFlexiRides] = useState([]);
  const [exactRides, setExactRides] = useState([]);
  const [minMaxPriceRides, setMinMaxPriceRides] = useState([]);
  const [pickUpPoint, setPickUpPoint] = useState(false);
  const [dropOffPoint, setDropOffPoint] = useState(false);
  const [startRequests, setStartRequests] = useState(false);
  const [seatsNumber, setSeatsNumber] = useState(1);
  const [isFlexiTime, setIsFlexiTime] = useState(true);
  const [startPointDate, setStartPointDate] = useState(Date.now());
  const [distanceMarker, setDistanceMarker] = useState([]);
  const [durationMarker, setDurationMarker] = useState([]);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isShowPopup2, setIsShowPopup2] = useState(false);
  const [geoLocationMarker, setGeoLocationMarker] = useState('');
  const [geoLocationMarkerLoading, setGeoLocationMarkerLoading] = useState(false);
  const [geoLocationMessage, setGeoLocationMessage] = useState('');
  const [popupContent, setPopupContent] = useState(
    'Thank you for your payment. Have a nice journey Let’s us save the planet together!',
  );
  const [popupContent2, setPopupContent2] = useState('');
  // const [popupTitle, setTitle] = useState("Payment received");
  const [selectRouteIndex, setSelectRouteIndex] = useState(0);
  const [selectedRide, setSelectedRide] = useState(null);
  const [fuelConsumptionMarker, setFuelConsumptionMarker] = useState([]);
  const [co2Marker, setCo2Marker] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [showScheduledRideDetails, setShowScheduledRideDetails] = useState(true);
  const [notificationsArr, setNotificationsArr] = useState([]);
  const [gridAllRidesShow, setGridAllRidesShow] = useState(false);
  const [selectRide, setSelectRide] = useState(false);
  const [scheduledRide, setScheduledRide] = useState();
  const [scheduledRideRequest, setScheduledRideRequest] = useState();
  const [isRideDetailes, setRideDetailes] = useState(false);
  const [isActiveRide, setActiveRide] = useState(false);
  const [watchGeoPosition, setWatchGeoPosition] = useState();
  const [isScheduleRide, setScheduleRide] = useState(false);
  const [rideRequestsArr, setRideRequestsArr] = useState([]);
  const [chooseScheduleRide, setChooseScheduleRide] = useState(false);

  const values = {
    response,
    markers,
    markerStart,
    markerEnd,
    origin,
    destination,
    isGetRide,
    isGetPickUpPoint,
    isGetDropOffPoint,
    showMenu,
    wayPoints3,
    wayPoints4,
    flexiRides,
    exactRides,
    minMaxPriceRides,
    pickUpPoint,
    dropOffPoint,
    startRequests,
    seatsNumber,
    isFlexiTime,
    startPointDate,
    distanceMarker,
    isShowPopup,
    selectRouteIndex,
    selectedRide,
    fuelConsumptionMarker,
    co2Marker,
    durationMarker,
    showForm,
    notificationsArr,
    geoLocationMarker,
    mapHeight,
    geoLocationMarkerLoading,
    selectRide,
  };

  const setters = {
    setResponse,
    setMarkers,
    setMarkerStart,
    setMarkerEnd,
    setOrigin,
    setDestination,
    setGetRide,
    setIsGetPickUpPoint,
    setIsGetDropOffPoint,
    setShowMenu,
    setWayPoints3,
    setWayPoints4,
    setFlexiRides,
    setExactRides,
    setMinMaxPriceRides,
    setPickUpPoint,
    setDropOffPoint,
    setStartRequests,
    setSeatsNumber,
    setIsFlexiTime,
    setStartPointDate,
    setDistanceMarker,
    setIsShowPopup,
    setSelectRouteIndex,
    setSelectedRide,
    setFuelConsumptionMarker,
    setCo2Marker,
    setDurationMarker,
    setShowForm,
    setNotificationsArr,
    setGeoLocationMarker,
    setMapHeight,
    setGridAllRidesShow,
    setSelectRide,
  };

  const [getFindRidesFunc, findRidesData] = useLazyQuery(FIND_RIDE_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      if (findRidesData.data && findRidesData.data.findRide) {
        setFlexiRides(findRidesData.data.findRide.flexi);
        setExactRides(findRidesData.data.findRide.exact);
        !isUserAuthenticated() && setFlexiRidesGlobal(findRidesData.data.findRide.flexi);
        !isUserAuthenticated() && setExactRidesGlobal(findRidesData.data.findRide.exact);
        setMinMaxPriceRides(
          getMinMaxPriceRides(findRidesData.data.findRide.flexi, findRidesData.data.findRide.exact),
        );
      }
    },
    onError: (error) => {
      console.log('[FindRide] error getFindRidesFunc', error);
    },
  });

  // const [getRideRequestsForRiderFunc, rideRequestsForRiderData] = useLazyQuery(RIDE_REQUESTS_QUERY, {
  //   fetchPolicy: 'network-only', // always will fetch data, no cache
  //   onCompleted: () => {
  //     console.log('[rideRequestsData]', rideRequestsForRiderData.data.rideRequests);
  //     if (
  //       rideRequestsForRiderData.data &&
  //       rideRequestsForRiderData.data.rideRequests &&
  //       rideRequestsForRiderData.data.rideRequests.length
  //     ) {
  //       // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
  //       let nearestRides = ridesReqFilterByRiderAndDateAndPaidStatus(rideRequestsData.data.rideRequests);
  //       if(nearestRides && nearestRides.length) {
  //         setScheduledRideRequest([nearestRides.sort((a, b) => a.startDate - b.startDate)[0]]);
  //       }
  //     }
  //   },
  //   onError: (error) => {
  //     console.log('[getRideRequestsForRiderFunc] error getRideRequestsForRiderFunc', error);
  //   },
  // });
  const [getMyRideRequestsFunc, myRideRequestsData] = useLazyQuery(MY_RIDE_REQUESTS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[myRideRequestsData.data]', myRideRequestsData.data.getMyRideRequests);
      if (
        myRideRequestsData.data &&
        myRideRequestsData.data.getMyRideRequests &&
        myRideRequestsData.data.getMyRideRequests.length
      ) {
        // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
        let nearestRides = ridesReqFilterByRiderAndDateAndPaidStatus(myRideRequestsData.data.getMyRideRequests);
        if(nearestRides && nearestRides.length) {
          let scheduleRideRequest = nearestRides.sort((a, b) => a.startDate - b.startDate)[0];
          setScheduledRideRequest([scheduleRideRequest]);
          // console.log('scheduleRideRequest.ride.status', scheduleRideRequest.ride.status);

          // add ride in list subscription if it has status "scheduled"
          if(scheduleRideRequest.ride.status === RIDE_STATUS.SCHEDULED) {
            // console.log('scheduleRideRequest.ride', scheduleRideRequest.ride);
            setRidesArr([scheduleRideRequest.ride])
          }
        }
      }
    },
    onError: (error) => {
      console.log('[getRideRequestsForRiderFunc] error getRideRequestsForRiderFunc', error);
    },
  });

  const [getRideRequestsFunc, rideRequestsData] = useLazyQuery(RIDE_REQUESTS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
      if (
        rideRequestsData.data &&
        rideRequestsData.data.rideRequests &&
        rideRequestsData.data.rideRequests.length
      ) {
        // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
        setRideRequestsArr(ridesFilterByRideAndPaidStatus(rideRequestsData.data.rideRequests));
      }
    },
    onError: (error) => {
      console.log('[getRideRequestsFunc] error getRideRequestsFunc', error);
    },
  });

  const [getRideByDriverId, getRideByDriverIdData] = useLazyQuery(RIDES_BY_DRIVER_ID_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[getRideByDriverId] getRideByDriverIdData', getRideByDriverIdData.data.findRidesByDriverId);
      if (
        getRideByDriverIdData.data.findRidesByDriverId &&
        getRideByDriverIdData.data.findRidesByDriverId.length
      ) {
        // setRideCostPerSeat(getRideByDriverId.data.findRidesByDriverId);
        let nearestRides = getRideByDriverIdData.data.findRidesByDriverId.filter(
          (ride) => ride.startDate <= +Date.now() + 7200000 && ride.startDate > +Date.now(),
        );
        // console.log("nearest rides", nearestRides)
        if (nearestRides.length) {
          setScheduledRide([nearestRides.sort((a, b) => a.startDate - b.startDate)[0]]);
          getRideRequestsFunc();
        }
      }
    },
    onError: (error) => {
      console.log('[getRideByDriverId] error getRideByDriverId', error);
    },
  });

  const [setPaidStatus, setPaidStatusStatus] = useMutation(SET_PAID_STATUS, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('[setPaidStatus] setPaidStatus', res);
      if (res) {
        createAddRideNotificationFunc('DRIVER_RIDE_BOOKED', selectedRide.driver.id);
        setTimeout(() => createAddRideNotificationFunc('RIDER_RIDE_BOOKED', currentUser.id), 1000);
        // createSnoozeNotification();
        createTagForSnoozeNotification()

        setPopupContent(
          'Thank you for your payment. Have a nice journey Let’s us save the planet together!',
        );
        setIsShowPopup(true);
        backToHomePage();
        cancelSelectedRide();
      }
    },
    onError: (error) => {
      console.log('[createRide] error createRide', error);
      // setTitle("Error");
      setPopupContent(`Error: ${error.message}`);
    },
  });

  const [
    createAddRideNotification,
    // createAddRideNotificationStatus
  ] = useMutation(CREATE_NOTIFICATION, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('[createAddRideNotification] createAddRideNotification', res);
      if (res) {
      }
    },
    onError: (error) => {
      console.log('[createAddRideNotification] error createAddRideNotification', error);
      // setTitle("Error");
      setPopupContent(`Error: ${error.message}`);
    },
  });

  function createTagForSnoozeNotification(){
    OneSignal.getTags((tags) => {
      if(!tags.includes(selectedRide.id)){
        OneSignal.sendTag(`${selectedRide.id}`, selectedRide.id)
      }
    });
  }

  function ridesFilterByRideAndPaidStatus(arr) {
    // console.log('arr', arr);
    return arr.filter(
      (el) =>
        el.ride.id === scheduledRide[0].id &&
        +el.ride.waypointsEta[0].eta > +Date.now() &&
        el.status.toLowerCase() === RIDE_REQUEST_STATUS.PAID.toLowerCase(),
    );
    // return arr.filter(el => (el.driver.id === currentUser.id && +el.waypointsEta[0].eta > +Date.now()))
  }

  function ridesReqFilterByRiderAndDateAndPaidStatus(arr) {
    return arr.filter(
      (el) =>
        el.passenger.id === currentUser.id &&
        +el.ride.waypointsEta[0].eta > +Date.now() &&
        +el.ride.waypointsEta[0].eta <= +Date.now() + 7200000 &&
        el.status.toLowerCase() === RIDE_REQUEST_STATUS.PAID.toLowerCase(),
    );
    // return arr.filter(el => (el.driver.id === currentUser.id && +el.waypointsEta[0].eta > +Date.now()))
  }

  // function formatAMPM(date) {
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   let ampm = hours >= 12 ? 'PM' : 'AM';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   hours = hours < 10 ? '0' + hours : hours;
  //   minutes = minutes < 10 ? '0' + minutes : minutes;
  //   let strTime = hours + ':' + minutes + ' ' + ampm;
  //   return strTime;
  // }

  // function createSnoozeNotification(){
  //   OneSignal.sendTag(`${selectedRide.id}`, selectedRide.id, (sentId) => {
  //     // console.log('sendTag rideId:', sentId);
  //
  //     let data;
  //     if (startPointDate - Date.now() >= 7200000) {
  //       data = {
  //         filters: [{ field: 'tag', key: `${selectedRide.id}`, relation: '=', value: `${selectedRide.id}` }],
  //         app_id: process.env.REACT_APP_ONE_SIGNAL_ID,
  //         contents: {
  //           en: `Today you have scheduled a trip at ${formatAMPM(new Date(+startPointDate))}`,
  //         },
  //         headings: { en: 'Ride scheduled' },
  //         data: { custom_data: `${selectedRide.id}` },
  //         send_after: new Date(startPointDate - 7200000),
  //       };
  //     } else {
  //       data = {
  //         filters: [{ field: 'tag', key: `${selectedRide.id}`, relation: '=', value: `${selectedRide.id}` }],
  //         app_id: process.env.REACT_APP_ONE_SIGNAL_ID,
  //         contents: {
  //           en: `Today you have scheduled a trip at ${formatAMPM(new Date(+startPointDate))}`,
  //         },
  //         headings: {
  //           en: 'Ride scheduled',
  //         },
  //         data: { custom_data: `${selectedRide.id}` },
  //         // "send_after": "Sept 20 2021 19:34:00 GMT+0300"
  //         // "send_after": "Sept 20 2021 19:20:00 GMT-0700"
  //         // "web_url": "https://onesignal.com",
  //         // "app_url": "app://localhost:3000"
  //       };
  //     }
  //
  //     setTimeout(() => {
  //       axios({
  //         method: 'post',
  //         url: 'https://onesignal.com/api/v1/notifications',
  //         headers: {
  //           'Content-Type': 'application/json; charset=utf-8',
  //           Authorization: `Basic ${process.env.REACT_APP_ONE_SIGNAL_REST_API_KEY}`,
  //           // 'Authorization': 'YTQ1YzNiZjktYjZiNC00NDJhLTljY2YtOTYyYTg5OWYwN2Fi'
  //           // 'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         data,
  //       })
  //         .then((result) => console.log(result))
  //         .catch((err) => console.log(err));
  //     }, 10000);
  //   });
  // }

  function createAddRideNotificationFunc(type, receiverId) {
    createAddRideNotification({
      variables: {
        input: {
          type: NUALE_NOTIFICATION_TYPE[type],
          ride: selectedRide.id,
          notificationReceiver: receiverId,
          // message: 'Ride schedule',
        },
      },
    });
  }

  function cancelSelectedRide() {
    setSelectedRide(null);
    setWayPoints4([]);
    setOrigin('');
    setDestination('');
    setMarkers([]);
    setMarkerStart();
    setMarkerEnd();
  }

  // const [getNotificationsFunc, notificationsData] = useLazyQuery(NOTIFICATIONS_QUERY, {
  //   pollInterval: 500,
  //   fetchPolicy: 'network-only', // always will fetch data, no cache
  //   onCompleted: () => {
  //     if (notificationsData.data && notificationsData.data.notifications) {
  //       console.log('[getNotificationsFunc]', notificationsData.data.notifications);
  //       // setNotificationsArr(ridesFilterByRider(notificationsData.data.notifications));
  //     }
  //   },
  //   onError: (error) => {
  //     console.log('[getNotificationsFunc] error getNotificationsFunc', error);
  //   },
  // });

  function ridesFilterByRider(arr) {
    // return arr
    return arr.filter((el) => el.user.id === currentUser.id && el.type === 'RIDE_ADDED');
  }

  // useEffect(() => {
  //   // getYourLocation();
  //   setCenterCoords({
  //     lat: -20.4,
  //     lng: 57.5833333,
  //   });
  // }, []);

  useEffect(() => {
    if (selectedRide) {
      selectRideFromFindAllRidesListFunc(selectedRide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRide]);

  useEffect(() => {
    // isUserAuthenticated() && getNotificationsFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (
      isUserAuthenticated() &&
      !!originGlobal &&
      !!destinationGlobal &&
      !!flexiRidesGlobal &&
      !!exactRidesGlobal
    ) {
      setOrigin(originGlobal);
      setDestination(destinationGlobal);
      setFlexiRides([...flexiRidesGlobal]);
      setExactRides([...exactRidesGlobal]);
      setOriginGlobal();
      setDestinationGlobal();
      setFlexiRidesGlobal();
      setExactRidesGlobal();
      setGetRide(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated]);

  useEffect(() => {
    if(currentUser) {
      // getMyRideRequestsFunc();
      switch(currentUser.role) {
        case ROLES.driver:
          getRideByDriverId({variables: {id: currentUser.id}});
          // getMyRideRequestsFunc();
          break;
        case ROLES.rider:
          getMyRideRequestsFunc();
          break;
        default:
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveRide, isUserAuthenticated]);

  useEffect(() => {
    // if (testRideGlobal || testRideByRiderGlobal) {
    if (scheduledRide) {
      // console.log('selectRide1', selectRide);
      setRideParamsInState(scheduledRide);
      getWaypointsLocations(scheduledRide);
      // testRideGlobal && getRideRequestsFunc();
    }
    // return ()=> setTestRideGlobal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveRide]);

  function dateWithResetSeconds(date) {
    return +(+date - +new Date(date).getSeconds() * 1000 - +new Date(date).getMilliseconds());
  }

  function queryFindRides() {
    let obj = {
      seats: +seatsNumber,
      pickUpPointId: pickUpPoint.id,
      dropOffPointId: dropOffPoint.id,
      pickUpTime: dateWithResetSeconds(startPointDate),
      isFlexiTime: isFlexiTime,
    };

    getFindRidesFunc({
      variables: {
        input: obj,
      },
    });
  }

  function getMinMaxPriceRides(arr1, arr2) {
    let concatArr = arr1.concat(arr2);

    concatArr = concatArr.map((el) => el.pricePerSeat);

    return concatArr.sort((a, b) => a - b);
  }

  function backToHomePage() {
    setGetRide(false);
  }

  function popupReset() {
    geoLocationMessage && history.push(ROUTES.FIND_RIDE);
    !geoLocationMessage && history.push(ROUTES.ADD_RIDE);
    setIsShowPopup(false);
    history.push(ROUTES.FIND_RIDE);
  }

  function popupReset2() {
    setIsShowPopup2(false);
  }

  function getYourLocation(number = 0) {
    setGeoLocationMarkerLoading(true);
    // Try geolocation.
    if (navigator.geolocation) {
      // console.log('navigator.geolocation', navigator.geolocation);
      // navigator.geolocation.watchPosition(
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          console.log('position', position);
          // let address = await geocodeAddress("Mahebourg, Mauritius");
          let address = await geocode(position.coords.latitude, position.coords.longitude);

          setCenterCoords({
            lat: position.coords.latitude + number,
            lng: position.coords.longitude,
          });
          setGeoLocationMarker({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setOrigin({
            name: address,
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
          let newCoords = {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          };

          setMarkerStart(newCoords);
          // setMarkers((current) => {
          //   if (!current.length) {
          //     return [{ ...newCoords }, ...current];
          //   }
          //   return current[1] ? [{ ...newCoords }, current[1]] : [{ ...newCoords }];
          // });

          setGeoLocationMarkerLoading(false);
        },
        (err) => {
          handleLocationError(true);
          console.log(err.code);
          setGeoLocationMessage(`Error: ${err.message}`);
          setGeoLocationMarkerLoading(false);
        },
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false);
      setGeoLocationMarkerLoading(false);
    }
  }

  async function geocode(lat, lng) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    try {
      let response = await Geocode.fromLatLng(lat, lng);
      return response.results[0].formatted_address;
    } catch (error) {
      console.error(error);
      setGeoLocationMessage(`Error: ${error.message}`);
      setGeoLocationMarkerLoading(false);
      setPopupContent(`Error: ${error.message}`);
      setIsShowPopup(true);
    }
  }

  async function geocodeAddress(address) {
    // console.log('address', address);
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    try {
      let response = await Geocode.fromAddress(`${address} Mauritius`);
      // console.log('response', response);
      return response.results[0].geometry.location;
    } catch (error) {
      console.error(error);
      setPopupContent(`Error: ${error.message}`);
      setIsShowPopup(true);
    }
  }

  async function getWaypointsLocations(ride) {
    let pickUpLocation = await geocodeAddress(ride[0].waypoints[0].name);
    let dropOffLocation = await geocodeAddress(ride[0].waypoints[1].name);
    await setWayPoints4([
      { name: ride[0].waypoints[0].name, location: pickUpLocation },
      { name: ride[0].waypoints[1].name, location: dropOffLocation },
    ]);
    setStartRequests(true);
  }

  async function setRideParamsInState(ride) {
    await setOrigin({ location: { ...ride[0].startPoint.location } });
    await setDestination({ location: { ...ride[0].endPoint.location } });
    await setMarkerStart({ location: { ...ride[0].startPoint.location } });
    await setMarkerEnd({ location: { ...ride[0].endPoint.location } });
    // await setMarkers([
    //   { location: { ...ride.startPoint.location } },
    //   { location: { ...ride.endPoint.location } },
    // ]);
  }

  function selectRideFromFindAllRidesListFunc(ride) {
    if (ride) {
      setRideParamsInState([ride]);
      getWaypointsLocations([ride]);
      setGetRide(true);
      setRideDetailes(true);
      setGridAllRidesShow(false);
      // setMapHeight('70%');
    } else {
      // setMapHeight('100%');
    }
  }

  function handleLocationError(browserHasGeolocation) {
    setPopupContent(
      browserHasGeolocation ? (
        // ? 'Error: The Geolocation service failed.'
        <span>
          Nuale does not have permission to use your location.{' '}
          <span>{makeLinkForSetGeolocation('Learn more')}</span>
        </span>
      ) : (
        "Error: Your browser doesn't support geolocation."
      ),
    );
    setIsShowPopup(true);
  }

  function makeLinkForSetGeolocation(content) {
    return (
      <a href={LINK_SET_GEOLOCATION} target="blank" className="text-nuale_neon_blue">
        {content}
      </a>
    );
  }

  function watchYourPosition(number = 0) {
    setGeoLocationMarkerLoading(true);
    // Try geolocation.
    if (navigator.geolocation) {
      // console.log('navigator.geolocation', navigator.geolocation);
      let id = navigator.geolocation.watchPosition(
        async (position) => {
          if(scheduledRideRequest && +position.coords.latitude === +scheduledRideRequest[0].ride.waypoints[1].location.lat && +position.coords.longitude === +scheduledRideRequest[0].ride.waypoints[1].location.lng){
            setIsShowPopup2(true);
            setPopupContent2('You have arrived at your destination')
          }
          setCenterCoords({
            lat: position.coords.latitude + number,
            lng: position.coords.longitude,
          });
          setGeoLocationMarker({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          setGeoLocationMarkerLoading(false);
        },
        (err) => {
          handleLocationError(true);
          console.log(err.code);
          setGeoLocationMessage(`Error: ${err.message}`);
          setGeoLocationMarkerLoading(false);
        },
      );
      setWatchGeoPosition(id);
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false);
      setGeoLocationMarkerLoading(false);
    }
  }

  function cancelScheduleRide() {
    setSelectRide();
    // setAddPickupPoint(true);
    setScheduleRide(false);
    setScheduledRide();
    setActiveRide(false);
    setShowForm(true);
    navigator.geolocation.clearWatch(watchGeoPosition);
    // setGeoLocationMarker('');
    setOrigin('');
    setDestination('');
    setMarkerStart();
    setMarkerEnd();
    setWayPoints4([]);
    setWayPoints3([]);
  }

  return (
    <div className="container w-full mx-auto box-border relative overflow-hidden">
      {!isGetPickUpPoint && !isGetDropOffPoint && !isGetRide && (
        <Header setShowMenu={setShowMenu} notificationsCount={notificationsArr.length} />
      )}
      <Map
        values={values}
        setters={setters}
        centerCoords={centerCoords}
        response={response}
        markers={markers}
        markerStart={markerStart}
        markerEnd={markerEnd}
        origin={origin}
        setResponse={setResponse}
        setOrigin={setOrigin}
        setDestination={setDestination}
        setMarkers={setMarkers}
        setMarkerStart={setMarkerStart}
        setMarkerEnd={setMarkerEnd}
        destination={destination}
        loadError={loadError}
        isLoaded={isLoaded}
        fuelConsumption={
          !!(selectedRide && selectedRide.driver.userVehicles.length)
            ? selectedRide.driver.userVehicles[0].spec[0].fuelConsumption
            : null
        }
        wayPoints3={wayPoints3}
        wayPoints4={wayPoints4}
        setWayPoints3={setWayPoints3}
        setWayPoints4={setWayPoints4}
        setPickUpPoint={setPickUpPoint}
        setDropOffPoint={setDropOffPoint}
        startRequests={startRequests}
        setStartRequests={setStartRequests}
        isGetPickUpPoint={isGetPickUpPoint}
        isGetDropOffPoint={isGetDropOffPoint}
        findRide={true}
        isMarkersClickable={isGetPickUpPoint || isGetDropOffPoint}
        distanceMarker={distanceMarker}
        setDistanceMarker={setDistanceMarker}
        setIsGetPickUpPoint={setIsGetPickUpPoint}
        setIsGetDropOffPoint={setIsGetDropOffPoint}
        selectRouteIndex={selectRouteIndex}
        setSelectRouteIndex={setSelectRouteIndex}
        selectedRide={selectedRide}
        setFuelConsumptionMarker={setFuelConsumptionMarker}
        fuelConsumptionMarker={fuelConsumptionMarker}
        setCo2Marker={setCo2Marker}
        co2Marker={co2Marker}
        durationMarker={durationMarker}
        setDurationMarker={setDurationMarker}
        geoLocationMarker={geoLocationMarker}
        mapHeight={mapHeight}
      />
      {/*{!isGetPickUpPoint && !isGetDropOffPoint && !isGetRide && isScheduleRide && selectRide && (*/}
      {!isGetPickUpPoint && !isGetDropOffPoint && !isGetRide && scheduledRide && (
        <ScheduleRideDetailes
          setShowForm={setShowForm}
          setShowScheduledRideDetails={setShowScheduledRideDetails}
          showForm={showForm}
          showScheduledRideDetails={showScheduledRideDetails}
          ride={scheduledRide}
          setMapHeight={setMapHeight}
          watchYourPosition={watchYourPosition}
          cancelScheduleRide={cancelScheduleRide}
          isActiveRide={isActiveRide}
          setActiveRide={setActiveRide}
          rideRequestsArr={rideRequestsArr}
          setChooseScheduleRide={setChooseScheduleRide}
          chooseScheduleRide={chooseScheduleRide}
          // nearestScheduledRide={true}
          // setMapHeightByShowForm={setMapHeightByShowForm}
        />
      )}
      {!isGetPickUpPoint && !isGetDropOffPoint && !isGetRide && scheduledRideRequest && ridesArr.length && (
        <ScheduleRideByRiderDetailes
          setShowForm={setShowForm}
          setShowScheduledRideDetails={setShowScheduledRideDetails}
          showForm={showForm}
          showScheduledRideDetails={showScheduledRideDetails}
          ride={scheduledRideRequest[0].ride}
          setMapHeight={setMapHeight}
          watchYourPosition={watchYourPosition}
          cancelScheduleRide={cancelScheduleRide}
          isActiveRide={isActiveRide}
          setActiveRide={setActiveRide}
          setChooseScheduleRide={setChooseScheduleRide}
          chooseScheduleRide={chooseScheduleRide}
          ridesArr={ridesArr}
          // setMapHeightByShowForm={setMapHeightByShowForm}
        />
      )}
      {!isGetPickUpPoint && !isGetDropOffPoint && !isGetRide && !isActiveRide && (
        <MapFindForm
          values={values}
          setters={setters}
          setCenterCoords={setCenterCoords}
          // setTargetCoords={setTargetCoords}
          setResponse={setResponse}
          setMarkers={setMarkers}
          setMarkerStart={setMarkerStart}
          setMarkerEnd={setMarkerEnd}
          setWayPoints3={setWayPoints3}
          setDistanceMarker={setDistanceMarker}
          setDurationMarker={setDurationMarker}
          setDestination={setDestination}
          setOrigin={setOrigin}
          setAddPickupPoint={setGetRide}
          origin={origin}
          destination={destination}
          // setStartRequests={setStartRequests}
          seatsNumber={seatsNumber}
          setSeatsNumber={setSeatsNumber}
          startPointDate={startPointDate}
          setStartPointDate={setStartPointDate}
          setIsGetPickUpPoint={setIsGetPickUpPoint}
          setIsGetDropOffPoint={setIsGetDropOffPoint}
          setIsFlexiTime={setIsFlexiTime}
          isFlexiTime={isFlexiTime}
          getYourLocation={getYourLocation}
          setShowScheduledRideDetails={setShowScheduledRideDetails}
          setShowForm={setShowForm}
          showForm={showForm}
          setMapHeight={setMapHeight}
          geoLocationMarkerLoading={geoLocationMarkerLoading}
          setGridAllRidesShow={setGridAllRidesShow}
          setChooseScheduleRide={setChooseScheduleRide}
          chooseScheduleRide={chooseScheduleRide}
          // findRide={true}
        />
      )}
      {isGetPickUpPoint && (
        <GetPickUpPoint
          values={values}
          setters={setters}
          getYourLocation={getYourLocation}
          origin={origin}
          destination={destination}
          wayPoints3={wayPoints3}
          wayPoints4={wayPoints4}
          markers={markers}
          markerStart={markerStart}
          markerEnd={markerEnd}
          setDistanceMarker={setDistanceMarker}
          setDurationMarker={setDurationMarker}
          setResponse={setResponse}
          setWayPoints4={setWayPoints4}
          setWayPoints3={setWayPoints3}
          setPickUpPoint={setPickUpPoint}
          pickUpPoint={pickUpPoint}
          setStartRequests={setStartRequests}
          setIsGetPickUpPoint={setIsGetPickUpPoint}
          setCenterCoords={setCenterCoords}
          centerCoords={centerCoords}
          setMarkers={setMarkers}
          setMarkerStart={setMarkerStart}
          setOrigin={setOrigin}
          setMapHeight={setMapHeight}
          setOriginGlobal={setOriginGlobal}
          geoLocationMarkerLoading={geoLocationMarkerLoading}
        />
        )}
      {isGetDropOffPoint && (
        <GetDropOffPoint
          values={values}
          setters={setters}
          getYourLocation={getYourLocation}
          origin={origin}
          destination={destination}
          wayPoints3={wayPoints3}
          wayPoints4={wayPoints4}
          markers={markers}
          markerStart={markerStart}
          markerEnd={markerEnd}
          setDistanceMarker={setDistanceMarker}
          setDurationMarker={setDurationMarker}
          setResponse={setResponse}
          setWayPoints4={setWayPoints4}
          setWayPoints3={setWayPoints3}
          setDropOffPoint={setDropOffPoint}
          dropOffPoint={dropOffPoint}
          setStartRequests={setStartRequests}
          setIsGetDropOffPoint={setIsGetDropOffPoint}
          setCenterCoords={setCenterCoords}
          centerCoords={centerCoords}
          setMarkers={setMarkers}
          setMarkerEnd={setMarkerEnd}
          setDestination={setDestination}
          setMapHeight={setMapHeight}
          setDestinationGlobal={setDestinationGlobal}
        />
      )}

      {isGetRide && isUserAuthenticated() && (
        <GetRide
          values={values}
          setters={setters}
          origin={origin}
          setAddPickupPoint={setGetRide}
          pickUpPoint={pickUpPoint}
          startPointDate={startPointDate}
          flexiRides={flexiRides}
          exactRides={exactRides}
          queryFindRides={queryFindRides}
          queryFindRidesLoading={findRidesData.loading}
          seatsNumber={seatsNumber}
          selectedRide={selectedRide}
          setSelectedRide={setSelectedRide}
          setStartRequests={setStartRequests}
          co2Marker={co2Marker}
          setPaidStatus={setPaidStatus}
          setPaidStatusStatus={setPaidStatusStatus}
          setMapHeight={setMapHeight}
          isRideDetailes={isRideDetailes}
          setRideDetailes={setRideDetailes}
        />
        )}
      {isGetRide && !isUserAuthenticated() && (
        <FindRideWithoutAuth
          values={values}
          setters={setters}
          setGetRide={setGetRide}
          pickUpPoint={pickUpPoint}
          dropOffPoint={dropOffPoint}
          queryFindRides={queryFindRides}
          queryFindRidesLoading={findRidesData.loading}
          minMaxPriceRides={minMaxPriceRides}
        />
      )}

        <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
      {gridAllRidesShow && (
        <GridAllRides setSelectedRide={setSelectedRide} setGridAllRidesShow={setGridAllRidesShow} />
      )}
        <Popup
          isShowPopup={isShowPopup}
          // resetRide={resetRide}
          popupReset={popupReset}
          content={popupContent}
          // title={popupTitle}
        />
        <Popup
          isShowPopup={isShowPopup2}
          // resetRide={resetRide}
          popupReset={popupReset2}
          content={popupContent2}
          // title={popupTitle}
        />
      </div>
   );
}
