import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import { TouchActions } from '../../ReactTouchEvent/index';
import { useAuth } from '../../contexts/AuthContext';

import { CREATE_RIDE_REQUEST } from '../../../shared/gqlQueries/createRideRequest';
// import { RIDE_REQUESTS_BY_ID_QUERY } from '../../../shared/gqlQueries/rideRequestById';
import { RIDE_REQUESTS_BY_PASSENGER_ID_QUERY } from '../../../shared/gqlQueries/rideRequestsByPassengerId';
import { RIDE_REQUEST_STATUS } from '../../../shared/constants';

// import pointFrom_x3 from '../../../assets/images/mapFindForm/edit-location-black-18-dp@3x.png';
import pointFrom_x3 from '../../../assets/images/mapFindForm/edit-location-black-18-dp.svg';
import pointTo_x3 from '../../../assets/images/mapFindForm/location-on-black-18-dp.svg';
// import pointTo_x3 from '../../../assets/images/mapFindForm/location-on-black-18-dp@3x.png';
import profile_x3 from '../../../assets/images/menu/profile@3x.png';
import star from '../../../assets/images/star-black-18-dp-copy-2.svg';
import iconLeft from '../../../assets/images/arrow-left-solid.svg';

export const RideDetailes = ({
  setRideDetailes,
  setMakePayment,
  formatAMPM,
  selectedRide,
  co2Marker,
  seatsNumber,
  setRideRequestId,
  setMapHeight,
  setGridAllRidesShow,
  allRides,
  cancelSelectedRide,
}) => {
  const [requestRide, setRequestRide] = useState(false);
  const [isRideRequestInBase, setIsRideRequestInBase] = useState(false);
  const [isRideRequestPaid, setIsRideRequestPaid] = useState(false);
  const { currentUser } = useAuth();

  // const [getRideRequestsFunc, rideRequestsData] = useLazyQuery(RIDE_REQUESTS_BY_PASSENGER_ID_QUERY, {
  //   fetchPolicy: 'network-only', // always will fetch data, no cache
  //   onCompleted: () => {
  //     if (rideRequestsData.data && rideRequestsData.data.rideRequestsByPassengerId) {
  //       console.log('[rideRequestsData]', ridesFilterByRiderAndPaidStatus(rideRequestsData.data.rideRequestsByPassengerId));
  //       setRideRequestsArr(ridesFilterByRiderAndPaidStatus(rideRequestsData.data.rideRequestsByPassengerId));
  //     }
  //   },
  //   onError: (error) => {
  //     console.log('[GetRidesHistory] error getRideRequestsFunc', error);
  //   },
  // });

  const [createRideRequest, createRideRequestStatus] = useMutation(CREATE_RIDE_REQUEST, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('[createRideRequest] createRideRequest', res);
      if (res) {
        setRideRequestId(res.createRideRequest.id);
        showMakePaymentComponent();
      }
    },
    onError: (error) => {
      console.log('[createRide] error createRide', error);
    },
  });

  function showMakePaymentComponent() {
    setRideDetailes(false);
    setMakePayment(true);
  }

  const [getRideRequests, getRideRequestsStatus] = useLazyQuery(
    RIDE_REQUESTS_BY_PASSENGER_ID_QUERY,
    {
      fetchPolicy: 'network-only', // always will fetch data, no cache
      onCompleted: () => {
        let rideRequest = getRideRequestsStatus.data.rideRequestsByPassengerId;
        // console.log('rideRequest', getRideRequestsStatus.data);
        if (rideRequest && rideRequest.filter((el) => el.ride.id === selectedRide.id).length) {
          setIsRideRequestInBase(true);
          setRideRequestId(rideRequest.find((el) => el.ride.id === selectedRide.id).id);
        }
        if (
          rideRequest &&
          rideRequest.filter(
            (el) =>
              el.ride.id === selectedRide.id &&
              el.status.toLowerCase() === RIDE_REQUEST_STATUS.PAID.toLowerCase(),
          ).length
        ) {
          setIsRideRequestPaid(true);
        }
      },
      onError: (error) => {
        console.log('[getRideRequests] error getRideRequests', error);
      },
    },
  );

  useEffect(() => {
    getRideRequests({ variables: { id: currentUser.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMapHeight('40%');
    return () => setMapHeight('70%');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createRideRequestFunc(id) {
    if (!isRideRequestInBase) {
      createRideRequest({
        variables: {
          input: {
            passenger: currentUser.id,
            ride: id,
            requestedSeatsCount: seatsNumber,
          },
        },
      });
    }
    showMakePaymentComponent();
  }

  return (
    <>
      <div
        className="absolute w-full right-0 top-0 flex justify-start items-center bg-black mb-5 p-4"
        style={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' }}
      >
        <button
          onClick={() => {
            setRideDetailes(false);
            allRides && setGridAllRidesShow(true);
            allRides && cancelSelectedRide();
          }}
        >
          <img src={iconLeft} alt="icon back" className="w-4" />
        </button>
        <span className="text-lg font-sans text-white font-medium ml-4">Ride Available</span>
      </div>
      <TouchActions setRequestRide={setRequestRide} setMapHeightFromRideDetailes={setMapHeight}>
        <div
          className={
            !requestRide
              ? 'w-full absolute right-0 bottom-0 flex flex-col justify-between px-5 pt-1 pb-40 bg-white rounded-tl-3xl rounded-tr-3xl'
              : 'w-full absolute right-0 -bottom-80 flex flex-col justify-between px-5 pt-1 pb-7 bg-white rounded-tl-3xl rounded-tr-3xl z-40'
          }
          style={{ boxShadow: '0 -10px 20px rgba(0, 0, 0, 0.2)', transition: '0.5s' }}
        >
          <div
            className="w-20 h-1 bg-gray-200 m-auto rounded-full"
            onClick={() => {
              setRequestRide((current) => !current);
              setMapHeight((current) => (current === '40%' ? '90%' : '40%'));
            }}
          >
            {' '}
          </div>
          <div className="px-4 h-24 w-full flex items-center border-b border-gray-200 mb-4">
            <div
              className="bg-white min-w-12 w-12 h-12 rounded-full border mr-3"
              style={{
                backgroundImage: `url(${selectedRide.driver.userImg || profile_x3})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            >
              {' '}
            </div>
            <div className="flex flex-col justify-between mr-12">
              <div className="w-full flex justify-between items-center">
                <div className="relative">
                  <span className="text-sm font-bold">{`${selectedRide.driver.firstName} ${selectedRide.driver.lastName}`}</span>
                  <span className="absolute -top-3 left-0 text-xs text-purple-400">Eco</span>
                </div>
              </div>
              <div className="w-full flex text-sm">
                <span>
                  {selectedRide.driver.userVehicles.length
                    ? `${selectedRide.driver.userVehicles[0].make.name} ${selectedRide.driver.userVehicles[0].model}`
                    : '----'}
                </span>
              </div>
            </div>
            <div className="text-sm justify-self-end">
              <div className="mb-1 flex items-center">
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
              </div>
              <div className="font-bold">
                <span>45 rides</span>
              </div>
            </div>
          </div>
          <h2 className="mb-2 text-base font-bold">Trip details</h2>
          <div className="flex justify-between items-center mb-6 relative">
            <div className="flex items-center">
              <div className="w-4 h-4 bg-nuale_turquoise rounded-full mr-3 ml-1"></div>
              <div className="flex flex-col">
                <span className="text-xs text-nuale_turquoise">Start</span>
                <span className="text-base text-gray-400 font-medium">
                  {selectedRide.startPoint.name}
                </span>
              </div>
            </div>
            <span className="text-base text-gray-400 font-medium">
              {formatAMPM(new Date(+selectedRide.startPoint.eta))}
            </span>
            <div className="h-10 border-r border-gray-400 absolute left-3 top-8"></div>
          </div>
          <div className="w-full flex justify-between items-center relative  mb-9">
            <div className="flex items-center">
              <picture className="w-8">
                <img className="w-6" src={pointFrom_x3} alt="Rider icon" />
              </picture>
              <div className="flex flex-col">
                <span className="text-xs text-gray-600">Pickup</span>
                <span className="w-full text-base font-medium font-sans">
                  {selectedRide.waypoints[0].name}
                </span>
              </div>
            </div>
            <span className="text-base font-medium">
              {formatAMPM(new Date(+selectedRide.waypointsEta[0].eta))}
            </span>
          </div>
          <div className="mb-5 w-full relative">
            <div className="w-full flex items-center relative">
              <picture className="w-8">
                <img className="w-6" src={pointTo_x3} alt="Rider icon" />
              </picture>
              <div className="w-full flex justify-between">
                <div className="flex flex-col">
                  <span className="text-xs text-gray-600">Drop off</span>
                  <span className="text-base font-medium">{selectedRide.waypoints[1].name}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-xs text-gray-600">Eta</span>
                  <span className="text-base font-medium">
                    {formatAMPM(new Date(+selectedRide.waypointsEta[1].eta))}
                  </span>
                </div>
              </div>
            </div>
            <div className="h-12 border-r border-gray-400 border-dashed absolute left-3 -top-10"></div>
          </div>
          <div className="flex items-center relative">
            <div className="w-4 h-4 bg-black rounded-full mr-3 ml-1"></div>
            <div className="flex flex-col">
              <span className="text-xs text-gray-400">End of trip</span>
              <span className="text-base text-gray-400 font-medium">
                {selectedRide.endPoint.name}
              </span>
            </div>
            <div className="h-9 border-r border-gray-400 absolute left-3 -top-7"></div>
          </div>
          <div
            className={
              !requestRide
                ? 'absolute px-4 left-0 top-96 w-full flex flex-col items-end'
                : 'absolute px-4 pb-9 left-0 -top-40 w-full flex flex-col items-end bg-white bg-opacity-50 z-0'
            }
            style={{
              transition: '0.5s',
              // backgroundImage: "linear-gradient(to bottom, rgba(238, 238, 238, 0) 31%, #171717)",
            }}
          >
            <div className="flex flex-col w-full mt-3 ml-3 mb-4">
              <span className="font-bold text-base text-nuale_turquoise">
                {selectedRide &&
                  `ETA : ${
                    (+selectedRide.waypointsEta[1].eta - selectedRide.waypointsEta[0].eta) / 60000
                  }mins. CO2 Emission : ${
                    co2Marker.length ? (co2Marker[0].co2 / 1000).toFixed(0) : 0
                  }g/km`}
              </span>
              <span className="font-bold text-base">Contribution per seats</span>
              <span className="font-bold text-2xl text-nuale_turquoise">
                {selectedRide && `MUR ${selectedRide.pricePerSeat.toFixed(2)}`}
              </span>
            </div>
            {!isRideRequestPaid ? (
              <button
                className={
                  'inline-block w-full font-bold text-base px-4 py-2 text-white bg-nuale_turquoise rounded-full ml-auto'
                }
                onClick={() => createRideRequestFunc(selectedRide.id)}
                disabled={getRideRequestsStatus.loading || createRideRequestStatus.loading}
              >
                {getRideRequestsStatus.loading || createRideRequestStatus.loading
                  ? 'loading...'
                  : 'Request ride'}
              </button>
            ) : (
              <div className="w-full text-center font-bold text-nuale_neon_blue">
                Ride completely book{' '}
              </div>
            )}
          </div>
        </div>
      </TouchActions>
    </>
  );
};
