import { gql } from '@apollo/client';

export const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(updateUserData: $input) {
      id
      uid
      firstName
      lastName
      email
      phone
      userImg
      role
      gender
      createdAt
      updatedAt
      driverInfo {
        bankName
        bankAccountNumber
        beneficiaryName
        yearOfRegistration
        fuelConsumption
        engineSize
        driverLicenceNumber
        idCard
        vehicleCategory
        driverLicenceImages {
          key
          imgPath
        }
        carImages {
          key
          imgPath
        }
        fuelType
      }
      userVehicles {
        id
        make {
          id
          name
        }
        model
        spec {
          engineSize
          fuelConsumption
        }
        fuelTypes {
          id
          type
          price
        }
      }
      userPreferences {
        ladyDrivers
        ecoFriendlyRides
        luxuryCars
        standardCars
        moreThanTwoSeats
      }
    }
  }
`;
