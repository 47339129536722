import React, { useState, useEffect, useRef } from 'react';

import { Search } from '../../';
// import { AddPickupPointSecondStep } from './secondStep';

import pointFrom_x1 from '../../../assets/images/mapFindForm/edit-location-black-18-dp.png';
import iconLeft from '../../../assets/images/arrow-left-solid.svg';
import delText from '../../../assets/images/icon-close-filled-black.svg';
// import iconLeft from '../../../assets/images/sign-up/icons_left.png';
// import LocationImg from '../../../assets/images/mapFindForm/my-location-24-px-1.png';

export const GetDropOffPoint = ({
  setCenterCoords,
  // centerCoords,
  setDestination,
  setMarkers,
  setMarkerEnd,
  origin,
  destination,
  wayPoints3,
  // wayPoints4,
  markers,
  markerStart,
  markerEnd,
  // setWayPoints4,
  setDistanceMarker,
  setDurationMarker,
  setResponse,
  setWayPoints3,
  setDropOffPoint,
  // dropOffPoint,
  setStartRequests,
  setIsGetDropOffPoint,
  // getYourLocation,
  setMapHeight,
  setDestinationGlobal,
  // isSecondStep,
  // setSecondStep
}) => {
  // const [isSecondStep, setSecondStep] = useState(false);
  const [wayPointsFilter, setWayPointsFilter] = useState([]);
  const destInputRef = useRef(null);
  // const [inputValue, setInputValue] = useState('');

  // useEffect(() => {
  //   setCenterCoords({
  //     lat: -20.1,
  //     lng: 57.5833333,
  //   });
  //   return () => setCenterCoords({lat: -20.4, lng: 57.5833333});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // setSecondStep(!!wayPoints4.length);
    setWayPointsFilter(wayPoints3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wayPoints3]);

  useEffect(() => {
    // setWayPoints3([]);
    return () => setWayPoints3([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMapHeight('130%');
    return () => setMapHeight('70%');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log('[GetDrop] wayPoints3', wayPoints3);

  // function filterWayPointsFunc(event) {
  //   let someName = event.target.value;
  //   setInputValue(someName);
  //   setWayPointsFilter(
  //     wayPoints3.filter((el) => el.name.toLowerCase().includes(someName.toLowerCase())),
  //   );
  // }

  return (
    <div
      className="absolute w-full right-0 top-0 bg-white pb-6"
      style={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' }}
    >
      {/*/!*<div className="m-auto w-12 h-1 bg-black" onClick={}></div>*!/*/}
      <div>
        <div className="flex justify-start items-center bg-black mb-5 p-4">
          <button
            onClick={() => {
              origin && destination && setStartRequests(true);
              setIsGetDropOffPoint(false);
            }}
          >
            <img src={iconLeft} alt="icon back" className="w-4" />
          </button>
          <span className="text-lg font-sans text-white font-medium ml-4">Select Dropoff</span>
        </div>
        <div className="flex justify-between flex-wrap px-4 mb-4">
          <div className="w-full flex items-center relative">
            <picture className="w-8">
              <img src={pointFrom_x1} alt="Rider icon" />
            </picture>
            <Search
              setCenterCoords={setCenterCoords}
              setMarkers={setMarkers}
              setMarkerEnd={setMarkerEnd}
              setDestination={setDestination}
            >
              <div className="w-full">
                <input
                  className="w-full bg-gray-100 py-3 px-4 text-base font-bold font-sans"
                  placeholder="Select dropoff point"
                  defaultValue={destination.name}
                  // value={inputValue}
                  // onChange={filterWayPointsFunc}
                  type="text"
                  ref={destInputRef}
                  // disabled
                />
                <div
                  className={
                    destination
                      ? 'absolute top-0 right-0 h-full w-6 cursor-pointer flex items-center justify-center z-20'
                      : 'hidden'
                  }
                  onClick={() => {
                    setDestination('');
                    setMarkerEnd();
                    setWayPoints3([]);
                    destInputRef.current.value = '';
                    setResponse(null);
                    setDistanceMarker();
                    setDurationMarker();
                    // setMarkers((current) => {
                    //   if (current.length > 1) {
                    //     return [current[0]];
                    //   }
                    //   return [];
                    // });
                  }}
                >
                  <img src={delText} alt="delete img" />
                </div>
                {/*<div*/}
                {/*className="absolute top-0 right-0 h-full w-10 cursor-pointer flex items-center justify-center z-20 bg-gray-100"*/}
                {/*onClick={(event) => {*/}
                {/*event.stopPropagation();*/}
                {/*getYourLocation();*/}
                {/*// getYourLocation(0.1);*/}
                {/*}}*/}
                {/*>*/}
                {/*<img*/}
                {/*className="w-4 cursor-pointer"*/}
                {/*src={LocationImg}*/}
                {/*alt="Location img"*/}
                {/*/>*/}
                {/*</div>*/}
              </div>
            </Search>
          </div>
        </div>
        <div className="px-5">
          <h1 className="text-sm font-bold font-sans mb-4">Suggested Dropoff points</h1>
          <div className="text-sm pl-6 overflow-scroll">
            <div className="max-h-36">
              {wayPointsFilter && wayPointsFilter.length ? (
                wayPointsFilter.map((el, index) => (
                  <button
                    key={`getDropOffPointsKey${index + 1}`}
                    className="mb-5 w-full flex justify-between items-center hover:bg-gray-100 focus:outline-none"
                    onClick={() => {
                      // setStartRequests(true);
                      setDestination(el);

                      setMarkerEnd({ location: el.location });
                      // setMarkers((current) => {
                      //   if (current.length) {
                      //     return current[0]
                      //       ? [current[0], { location: el.location }]
                      //       : [{ location: el.location }];
                      //   }
                      //   return [{ location: el.location }];
                      // });
                      setDropOffPoint(el);
                      setDestinationGlobal(el);
                      setWayPoints3([]);
                      markerStart && markerEnd && setStartRequests(true);
                      // markers.length > 1 && setStartRequests(true);
                      setIsGetDropOffPoint(false);
                      // setWayPoints4((current) => {
                      //   if (!current.length) {
                      //     return [
                      //       ...current,
                      //       el,
                      //     ];
                      //   }
                      //   return [
                      //     el,
                      //     {
                      //       ...current[1],
                      //     },
                      //   ];
                      // });
                      // setSecondStep(true);
                    }}
                  >
                    <span className="flex items-center">
                      <span className="inline-block text-center text-xs leading-5 w-5 h-5 text-white rounded-full bg-indigo-600 mr-1">
                        {index + 1}
                      </span>
                      <span className="font-semibold">{el.name}</span>
                    </span>
                    <span>{(el.distance / 1000).toFixed(1) + ' km'}</span>
                  </button>
                ))
              ) : (
                <span className="text-nuale_neon_blue">No Prefered pickup points</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
