import React, {
  useContext,
  // useEffect,
  useState,
} from 'react';

const GlobalVariablesContext = React.createContext();

export function useGlobalVariables() {
  return useContext(GlobalVariablesContext);
}

export function GlobalVariablesProvider({ children }) {
  const [originGlobal, setOriginGlobal] = useState();
  const [destinationGlobal, setDestinationGlobal] = useState();
  const [startDateGlobal, setStartDateGlobal] = useState(Date.now());
  const [startTimeGlobal, setStartTimeGlobal] = useState(Date.now());
  const [seatsNumberGlobal, setSeatsNumberGlobal] = useState(Date.now());
  const [flexiRidesGlobal, setFlexiRidesGlobal] = useState();
  const [exactRidesGlobal, setExactRidesGlobal] = useState();
  const [co2MarkerGlobal, setCo2MarkerGlobal] = useState();
  const [testRideGlobal, setTestRideGlobal] = useState();
  const [testRideByRiderGlobal, setTestRideByRiderGlobal] = useState();
  const [testRideRouteGlobal, setTestRideRouteGlobal] = useState(null);
  const [ridesArr, setRidesArr] = useState([]);

  const value = {
    originGlobal,
    destinationGlobal,
    startDateGlobal,
    startTimeGlobal,
    seatsNumberGlobal,
    flexiRidesGlobal,
    exactRidesGlobal,
    co2MarkerGlobal,
    testRideGlobal,
    testRideByRiderGlobal,
    testRideRouteGlobal,
    setOriginGlobal,
    setDestinationGlobal,
    setStartDateGlobal,
    setStartTimeGlobal,
    setSeatsNumberGlobal,
    setFlexiRidesGlobal,
    setExactRidesGlobal,
    setCo2MarkerGlobal,
    setTestRideGlobal,
    setTestRideByRiderGlobal,
    setTestRideRouteGlobal,
    ridesArr,
    setRidesArr
  };

  return (
    <GlobalVariablesContext.Provider value={value}>{children}</GlobalVariablesContext.Provider>
  );
}
