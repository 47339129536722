import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';

import { RideDetail } from './RideDetail';

// import { RIDES_QUERY } from '../../../shared/gqlQueries/rides';
import { ROUTES } from '../../../shared/constants';

import back from '../../../assets/images/arrow-left-black.svg';
import { FUTURE_RIDES_QUERY } from '../../../shared/gqlQueries/futueRides';
// import cancel from '../../../assets/images/icon-close-filled-black.svg';

export function GridAllRides({ setSelectedRide }) {
  const history = useHistory();
  const [ridesArr, setRidesArr] = useState([]);

  const [getRidesFunc, ridesData] = useLazyQuery(FUTURE_RIDES_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('ridesData.data.rides', ridesData.data.rides);
      if (ridesData.data && ridesData.data.futureRides && ridesData.data.futureRides.length) {
        setRidesArr(ridesData.data.futureRides);
      }
    },
    onError: (error) => {
      console.log('[GetRides] error getRidesFunc', error);
    },
  });

  // function filterRidesByDate(ridesArr) {
  //   return ridesArr.filter((ride) => +ride.startDate >= +Date.now());
  // }

  useEffect(() => {
    getRidesFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full h-screen bg-white z-30 p-4 overflow-y-scroll">
      <div className="relative mb-12">
        <div className="flex fixed top-0 right-0 p-4 z-40 w-full mb-4 bg-white">
          <img
            className="mr-4 w-4"
            src={back}
            alt="cancel img"
            onClick={() => history.push(ROUTES.FIND_RIDE)}
            // onClick={()=> setGridAllRidesShow(false)}
          />
          <div className="w-full">
            <span className="text-lg font-sans font-medium">Back</span>
          </div>
        </div>
      </div>
      <h1 className="text-3xl font-bold  mb-9">All rides scheduled</h1>
      {!ridesData.loading ? (
        <ul className="">
          {ridesArr.length ? (
            ridesArr.map((ride, index) => (
              <RideDetail
                key={'rideInfo' + index}
                rideInfo={ride}
                setSelectedRide={setSelectedRide}
              />
            ))
          ) : (
            <span className="text-nuale_neon_blue">Currently no rides</span>
          )}
        </ul>
      ) : (
        <div className="text-nuale_neon_blue">Loading...</div>
      )}
    </div>
  );
}
