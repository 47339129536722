import React from 'react';
import { Redirect } from 'react-router';
import { ROLES } from '../../shared/constants';
import { OTP, ProfileInfo, SignUpRiderSuccess, DriverInfo, SignUpDriverSuccess } from './index';

export function SignUpWithPhoneFlow({ values, setters, nextStep, prevStep }) {
  const { activeStep } = values;

  if (values.role === ROLES.rider) {
    switch (activeStep) {
      case 1:
        return <OTP nextStep={nextStep} prevStep={prevStep} values={values} />;
      case 2:
        return (
          <ProfileInfo values={values} setters={setters} nextStep={nextStep} prevStep={prevStep} />
        );
      case 3:
        return <SignUpRiderSuccess />;

      default: {
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        );
      }
    }
  }

  if (values.role === ROLES.driver) {
    switch (activeStep) {
      case 1:
        return <OTP nextStep={nextStep} prevStep={prevStep} values={values} />;
      case 2:
        return (
          <ProfileInfo values={values} setters={setters} nextStep={nextStep} prevStep={prevStep} />
        );
      case 3:
        return (
          <DriverInfo values={values} setters={setters} nextStep={nextStep} prevStep={prevStep} />
        );
      case 4: {
        return <SignUpDriverSuccess />;
      }

      default: {
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        );
      }
    }
  }

  return (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
}
