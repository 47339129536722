import React, { useState, useEffect } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import del from '../../assets/images/sign-up/delete.png';

export const CameraComponent = ({ setCameraShow, cameraShow, handleImageChangeByCamera }) => {
  const [screenWidth, setScreenWidth] = useState('');
  const [screenHeight, setScreenHeight] = useState('');

  useEffect(() => {
    setScreenWidth(window.screen.width);
    setScreenHeight(window.screen.height);
  }, []);

  function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo...
    handleImageChangeByCamera(dataUri);
    setCameraShow(false);
  }

  function handleCameraError(error) {
    console.log('handleCameraError', error);
  }

  // function handleCameraStart(stream) {
  //   console.log('handleCameraStart', stream);
  // }

  // function handleCameraStop() {
  //   console.log('handleCameraStop');
  // }

  if (!cameraShow) return null;

  return (
    <div className="fixed z-20 top-0 left-0 w-full h-full bg-black flex items-center justify-center">
      <div className="fixed z-30 top-9 right-10" onClick={() => setCameraShow(false)}>
        <img className="rounded-full bg-white w-12" src={del} alt="delete icon" />
      </div>
      <Camera
        // onTakePhoto={(dataUri) => {
        //   handleTakePhoto(dataUri);
        // }}
        onTakePhotoAnimationDone={(dataUri) => {
          handleTakePhotoAnimationDone(dataUri);
        }}
        onCameraError={(error) => {
          handleCameraError(error);
        }}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        idealResolution={{ width: screenHeight, height: screenWidth }}
        // idealResolution={{ width: 440, height: 440 }}
        // idealResolution={{ width: 440, height: 1366 }}
        imageType={IMAGE_TYPES.JPG}
        imageCompression={0.97}
        // isMaxResolution = {true}
        isImageMirror={false}
        isSilentMode={false}
        isDisplayStartCameraError={true}
        isFullscreen={false}
        sizeFactor={1}
        // onCameraStart={(stream) => {
        //   handleCameraStart(stream);
        // }}
        // onCameraStop={() => {
        //   handleCameraStop();
        // }}
      />
    </div>
  );
};
