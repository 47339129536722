export const POLICIES = [
  {
    status: false,
    id: 'policy1',
    title: '1. The Scope of this Policy',
    content: `<p>This policy applies to all Nuale users, including Riders and Drivers (including Driver applicants),
      and to all Nuale platforms and services, including our apps, websites, features, and other services (collectively,
      the “Nuale Platform”). Please remember that your use of the Nuale Platform is also subject to our
      <span class='underline'>Terms of Service</span>.</p>`,
  },
  {
    status: false,
    id: 'policy2',
    title: '2. The Information We Collect',
    content: `<p>When you use the Nuale Platform, we collect the information you provide, usage information, and information
      about your device. We also collect information about you from other sources like third-party services,
      and optional programs in which you participate, which we may combine with other information we have about you.
      Here are the types of information we collect about you:</p>
      <h6 class='my-3'>A. Information You Provide to Us</h6>
      <p class='my-2'><span class='font-bold'>Account Registration.</span> When you create an account with Nuale, we collect the information you provide us,
      such as your name, email address, phone number, and birth date. You may choose to share additional info with us
      for your Rider profile, like your photo or saved addresses (e.g., home or work), and set up other preferences (such as
      your preferred pronouns). ***</p>
      <p class='my-2'><span class='font-bold'>Driver Information.</span> If you apply to be a Driver, we will collect the information you
      provide in your application, including your name, email address, phone number, birth date, profile photo, physical address,
      identity card number, driver’s license information, vehicle information, and car insurance information.
      We collect the payment information you provide us, including your bank account numbers so we can provide you with your contribution for your Ride Sharing services. We may need additional information
      from you at some point after you become a Driver, including information to confirm your identity (like a photo).</p>
      <p class='my-2'><span class='font-bold'>Ratings and Feedback.</span> When you rate and provide feedback about Riders or Drivers, we
      collect all of the information you provide in your feedback.</p>
      <p class='my-2'><span class='font-bold'>Communications.</span> When you contact us or we contact you, we collect any information that
      you provide, including the contents of the messages or attachments you send us.</p>
      <h6 class='my-3'>B. Information We Collect When You Use the Nuale Platform</h6>
      <p class='my-2'><span class='font-bold'>Location Information.</span> Great rides start with an easy and accurate pickup. The Nuale Platform collects
      location information (including GPS and WIFI data) differently depending on your Nuale app settings and device permissions
      as well as whether you are using the platform as a Rider or Driver:</p>
      <p class='mt-4 mb-2'><span class='font-bold'>Riders: </span>We collect your device’s precise location when you open and use
      the Nuale app, including while the app is running in the background from the time you request a ride until it ends.</p>
      <p class='my-2'><span class='font-bold'>Drivers: </span>We collect your device’s precise location when you open and use the app,
      including while the app is running in the background when it is in driver mode. We also collect precise location for a limited
      time after you exit driver mode in order to detect ride incidents, and continue collecting it until a reported or detected
      incident is no longer active.</p>
      <p class='my-2'><span class='font-bold'>Usage Information. </span>We collect information about your use of the Nuale
      Platform, including ride information like the date, time, destination, distance, route, payment, and whether you used
      a promotional or referral code. We also collect information about your interactions with the Nuale Platform like our apps
      and websites, including the pages and content you view and the dates and times of your use.</p>
      <p class='my-2'><span class='font-bold'>Device Information. </span>We collect information about the devices you use to access
      the Nuale Platform, including device model, IP address, type of browser, version of operating system, identity of carrier
      and manufacturer, radio type (such as 4G), preferences and settings (such as preferred language), application installations,
      device identifiers, advertising identifiers, and push notification tokens.</p>
      <p class='my-2'><span class='font-bold'>Cookies, Analytics, and Third-Party Technologies. </span>We collect information through
      the use of “cookies”, tracking pixels, data analytics tools like Google Analytics, SDKs, and other third-party technologies
      to understand how you navigate through the Nuale Platform and interact with Nuale advertisements, to make your Nuale experience
      safer, to learn what content is popular, to improve your site experience, to serve you better ads on other sites, and to save
      your preferences. Cookies are small text files that web servers place on your device; they are designed to store basic
      information and to help websites and apps recognize your browser. We may use both session cookies and persistent cookies.
      A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be
      accessed every time you use the Nuale Platform. You should consult your web browser(s) to modify your cookie settings. Please
      note that if you delete or choose not to accept cookies from us, you may miss out on certain features of the Nuale Platform.</p>
      <h6 class='my-3'>C. Information We Collect from Third Parties</h6>
      <p class='my-2'><span class='font-bold'>Third-Party Services. </span>Third-party services provide us with information needed
      for core aspects of the Nuale Platform, as well as for additional services, programs, loyalty benefits, and promotions that
      can enhance your Nuale experience. These third-party services include background check providers, insurance partners, financial
      service providers, marketing providers, and other businesses. We obtain the following information about you from these
      third-party services:</p>
      <p class='my-2'>Information to make the Nuale Platform safer, like background check information for drivers;</p>
      <p class='my-2'>Information about your participation in third-party programs that provide things like insurance coverage and
      financial instruments, such as insurance, payment, transaction, and fraud detection information;</p>
      <p class='my-2'>Information to operationalize loyalty and promotional programs or applications, services, or features you
      choose to connect or link to your Nuale account, such as information about your use of such programs, applications, services,
      or features; and</p>
      <p class='my-2'><span class='font-bold'>Referral Programs. </span>Friends help friends use the Nuale Platform. If someone
      refers you to Nuale, we will collect information about you from that referral including your name and contact information.</p>
      <p class='my-2'><span class='font-bold'>Other Users and Sources. </span>Other users or public or third-party sources such as
      law enforcement, insurers, media, or pedestrians may provide us information about you, for example as part of an investigation
      into an incident or to provide you support.</p>
      `,
  },
  {
    status: false,
    id: 'policy3',
    title: '3. How we use your Information',
    content: `<p>We use your personal information to:</p><ul class='list-disc'>
    <li>Provide the Nuale Platform;</li>
    <li>Maintain the security and safety of the Nuale Platform and its users;</li>
    <li>Build and maintain the Nuale community;</li>
    <li>Provide customer support;</li>
    <li>Improve the Nuale Platform; and</li>
    <li>Respond to legal proceedings and obligations.</li>
    </ul>

    <!--<p class='my-2'><span class='font-bold'></span>DELETE</p>-->

    <p class='my-2'><span class='font-bold'>Providing the Nuale Platform. </span>We use your personal information to provide
    an intuitive, useful, efficient, and worthwhile experience on our platform. To do this, we use your personal information to:</p>
    <ul class='list-disc'>
    <li>Verify your identity and maintain your account, settings, and preferences;</li>
    <li>Connect you to your rides and track their progress;</li>
    <li>Calculate prices and process payments;</li>
    <li>Allow Riders and Drivers to connect regarding their ride and to choose to share their location with others;</li>
    <li>Communicate with you about your rides and experience;</li>
    <li>Collect feedback regarding your experience;</li>
    <li>Facilitate additional services and programs with third parties; and</li>
    <li>Operate contests, sweepstakes, and other promotions.</li>
    </ul>
    <p class='my-2'><span class='font-bold'>Maintaining the Security and Safety of the Nuale Platform and its Users. </span>Providing you
    a secure and safe experience drives our platform, both on the road and on our apps. To do this, we use your personal information to:</p>
    <ul class='list-disc'>
    <li>Authenticate users;</li>
    <li>Verify that Drivers and their vehicles meet safety requirements;</li>
    <li>Investigate and resolve incidents, accidents, and insurance claims;</li>
    <li>Encourage safe driving behavior and avoid unsafe activities;</li>
    <li>Find and prevent fraud; and</li>
    <li>Block and remove unsafe or fraudulent users from the Nuale Platform.</li>
    </ul>
    <p class='my-2'><span class='font-bold'>Building and Maintaining the Nuale Community. </span>Nuale works to be a positive part of the
    community. We use your personal information to:</p>
    <ul class='list-disc'>
    <li>Communicate with you about events, promotions, elections, and campaigns;</li>
    <li>Personalize and provide content, experiences, communications, and advertising to promote and grow the Nuale Platform; and</li>
    <li>Help facilitate donations you choose to make through the Nuale Platform.</li>
    </ul>
    <p class='my-2'><span class='font-bold'>Providing Customer Support. </span>We work hard to provide the best experience possible,
    including supporting you when you need it. To do this, we use your personal information to:</p>
    <ul class='list-disc'>
    <li>Investigate and assist you in resolving questions or issues you have regarding the Nuale Platform; and</li>
    <li>Provide you support or respond to you.</li>
    </ul>
    <p class='my-2'><span class='font-bold'>Improving the Nuale Platform. </span>We are always working to improve your experience and
    provide you with new and helpful features. To do this, we use your personal information to:</p>
    <ul class='list-disc'>
    <li>Perform research, testing, and analysis;</li>
    <li>Develop new products, features, partnerships, and services;</li>
    <li>Prevent, find, and resolve software or hardware bugs and issues; and</li>
    <li>Monitor and improve our operations and processes, including security practices, algorithms, and other modeling.</li>
    </ul>
    <p class='my-2'><span class='font-bold'>Responding to Legal Proceedings and Requirements. </span>Sometimes the law, government
    entities, or other regulatory bodies impose demands and obligations on us with respect to the services we seek to provide. In such
    a circumstance, we may use your personal information to respond to those demands or obligations.</p>
    `,
  },
  {
    status: false,
    id: 'policy4',
    title: '4. How we share your Information',
    content: `
    <p>We do not sell your personal information. To make the Nuale Platform work, we may need to share your personal information with
    other users, third parties, and service providers. This section explains when and why we share your information.</p>
    <h6 class='my-3'>A. Sharing Between Lyft Users</h6>
    <p>Riders and Drivers.</p>
    <p>Rider information shared with Driver: Upon receiving a ride request, we share with the Driver the Rider’s pickup location, name,
    profile photo, rating, Rider statistics (like approximate number of rides and years as a Rider), and information the Rider includes
    in their Rider profile (like preferred pronouns). Upon pickup and during the ride, we share with the Driver the Rider’s destination
    and any additional stops the Rider inputs into the Nuale app. Once the ride is finished, we also eventually share the Rider’s
    rating and feedback with the Driver. (We remove the Rider’s identity associated with ratings and feedback when we share it
    with Drivers, but a Driver may be able to identify the Rider that provided the rating or feedback.)</p>
    <p>Driver information shared with Rider: Upon a Driver accepting a requested ride, we will share with the Rider the Driver’s name,
    profile photo, preferred pronouns, rating, real-time location, and the vehicle make, model, color, and license plate, as well as
    other information in the Driver’s Nuale profile and Driver statistics (like approximate number of rides and years as a Driver).</p>
    <p>Although we help Riders and Drivers communicate with one another to arrange a pickup, we do not share your actual phone number
    or other contact information with other users. If you report a lost or found item to us, we will seek to connect you with the
    relevant Rider or Driver, including sharing actual contact information with your consent.</p>
    <p>Rides Requested or Paid For by Others. Some rides you take may be requested or paid for by others. If you take one of those
    rides using your Nuale Business Profile account, a code or coupon, a subsidized program, we may share some or all of your ride
    details with that other party, including the date, time, charge, rating given, region of trip, and pick up and drop off location
    of your ride.</p>
    <p>Referral Programs. If you refer someone to the Nuale Platform, we will let them know that you generated the referral. If another
    user referred you, we may share information about your use of the Nuale Platform with that user. For example, a referral source may
    receive a bonus when you join the Nuale Platform or complete a certain number of rides and would receive such information.</p>
    <h6 class='my-3'>B. Sharing With Third-Party Service Providers for Business Purposes</h6>
    <p>Depending on whether you’re a Rider or a Driver, Nuale may share the following categories of your personal information for
    a business purpose (as we have done for the preceding 12 months) to provide you with a variety of the Nuale Platform’s features
    and services:</p>
    <ul class='list-disc'>
    <li>Personal identifiers, such as your name, address, email address, phone number, date of birth, national identification, driver’s license information, vehicle information, and car insurance information;</li>
    <li>Financial information, such as bank routing numbers, tax information, and any other payment information you provide us;</li>
    <li>Commercial information, such as ride information, Driver/Rider statistics and feedback, and Driver/Rider transaction history;</li>
    <li>Internet or other electronic network activity information, such as your IP address, type of browser, version of operating system,
    carrier and/or manufacturer, device identifiers, and mobile advertising identifiers; and</li>
    <li>Location data.</li>
    </ul>
    <p>We disclose those categories of personal information to service providers to fulfill the following business purposes:</p>
    <ul class='list-disc'>
    <li>Maintaining and servicing your Nuale account;</li>
    <li>Processing or fulfilling rides;</li>
    <li>Providing you customer service;</li>
    <li>Processing Rider transactions;</li>
    <li>Processing Driver applications and payments;</li>
    <li>Verifying the identity of users;</li>
    <li>Detecting and preventing fraud;</li>
    <li>Processing insurance claims;</li>
    <li>Providing Driver loyalty and promotional programs;</li>
    <li>Providing marketing and advertising services to Nuale;</li>
    <li>Providing financing;</li>
    <li>Providing requested emergency services;</li>
    <li>Providing analytics services to Nuale; and</li>
    <li>Undertaking internal research to develop the Nuale Platform.</li>
    </ul>
    <h6 class='my-3'>C. For Legal Reasons and to Protect the Nuale Platform</h6>
    <p>We may share your personal information in response to a legal obligation, or if we have determined that sharing your personal
    information is reasonably necessary or appropriate to:</p>
    <ul class='list-disc'>
    <li>Comply with any applicable local law or regulation, civil, criminal or regulatory inquiry, investigation or legal process,
    or enforceable governmental request;</li>
    <li>Respond to legal process (such as a search warrant, subpoena, summons, or court order);</li>
    <li>Enforce our Terms of Service;</li>
    <li>Cooperate with law enforcement agencies concerning conduct or activity that we reasonably and in good faith believe may violate
    local law; or</li>
    <li>Exercise or defend legal claims, protect against harm to our rights, property, interests, or safety or the rights, property,
    interests, or safety of you, third parties, or the public as required or permitted by law.</li>
    </ul>
    <h6 class='my-3'>D. In Connection with Sale or Merger</h6>
    <p>We may share your personal information while negotiating or in relation to a change of corporate control such as a restructuring,
    merger, or sale of our assets.</p>
    <h6 class='my-3'>E. Upon Your Further Direction</h6>
    <p>With your permission or upon your direction, we may disclose your personal information to interact with a third party or for
    other purposes.</p>
    `,
  },
  {
    status: false,
    id: 'policy5',
    title: '5. How we store and protect your Information',
    content: `
    <p>We retain your information for as long as necessary to provide you and our other users the Nuale Platform. This means we keep
    your profile information for as long as you maintain an account. We retain transactional information such as rides and payments
    for at least seven years to ensure we can perform legitimate business functions, such as accounting for tax obligations. If you
    request account deletion, we will delete your information as set forth in the “Deleting Your Account” section below.</p>
    <p>We take reasonable and appropriate measures designed to protect your personal information. But no security measures can be
    100% effective, and we cannot guarantee the security of your information, including against unauthorized intrusions or acts by
    third parties.</p>
    `,
  },
  {
    status: false,
    id: 'policy6',
    title: '6. Your rights and choices regarding your data',
    content: `
    <p>As explained more below and on our privacy homepage, Nuale provides ways for you to access and delete your personal information
    as well as exercise other data rights that give you certain control over your personal information.</p>
    <h6 class='my-3'>A. All Users</h6>
    <p class='my-2'><span class='font-bold'>Email Subscriptions. </span>You can always unsubscribe from our commercial or promotional
    emails by clicking unsubscribe in those messages. We will still send you transactional and relational emails about your use of the
    Nuale Platform.</p>
    <p class='my-2'><span class='font-bold'>Push Notifications. </span>You can opt out of receiving push notifications through your
    device settings. Please note that opting out of receiving push notifications may impact your use of the Nuale Platform (such as
    receiving a notification that your ride has arrived).</p>
    <p class='my-2'><span class='font-bold'>Profile Information. </span>You can review and edit certain account information you have
    chosen to add to your profile by logging in to your account settings and profile.</p>
    <p class='my-2'><span class='font-bold'>Location Information. </span>You can prevent your device from sharing location information
    through your device’s system settings. But if you do, this may impact Nuale’s ability to provide you our full range of features and
    services.</p>
    <p class='my-2'><span class='font-bold'>Cookie Tracking. </span>You can modify your cookie settings on your browser, but if you
    delete or choose not to accept our cookies, you may be missing out on certain features of the Nuale Platform.</p>
    <p class='my-2'><span class='font-bold'>Do Not Track. </span>Your browser may offer you a “Do Not Track” option, which allows you
    to signal to operators of websites and web applications and services that you do not want them to track your online activities.
    The Nuale Platform does not currently support Do Not Track requests at this time.</p>
    <p class='my-2'><span class='font-bold'>Deleting Your Account. </span>If you would like to delete your Nuale account, please visit
    our <span class='underline'>privacy homepage</span>. In some cases, we will be unable to delete your account, such as if there is an issue with your account
    related to trust, safety, or fraud. When we delete your account, we may retain certain information for legitimate business purposes
    or to comply with legal or regulatory obligations. For example, we may retain your information to resolve open insurance claims,
    or we may be obligated to retain your information as part of an open legal claim. When we retain such data, we do so in ways
    designed to prevent its use for other purposes.</p>
    `,
  },
  {
    status: false,
    id: 'policy7',
    title: '7. Links to third-party',
    content: `<p>The Nuale Platform may contain links to third-party websites. Those websites may have privacy policies that differ
    from ours. We are not responsible for those websites, and we recommend that you review their policies. Please contact those
    websites directly if you have any questions about their privacy policies.</p>`,
  },
  {
    status: false,
    id: 'policy8',
    title: '8. Changes to this Privacy Policy',
    content: `<p>We may update this policy from time to time as the Nuale Platform changes and privacy law evolves. If we update it,
    we will do so online, and if we make material changes, we will let you know through the Nuale Platform or by some other method
    of communication like email. When you use Nuale, you are agreeing to the most recent terms of this policy.</p>`,
  },
  {
    status: false,
    id: 'policy9',
    title: '9. Contact Us',
    content: `<p>If you have any questions or concerns about your privacy or anything in this policy, including if you need to access
    this policy in an alternative format, we encourage you to <span class='underline'>contact us</span>.</p>`,
  },
];
