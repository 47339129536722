import { gql } from '@apollo/client';

export const CREATE_USER_FROM_FIREBASE = gql`
  query CreateUserFromFirebase {
    createUserFromFirebase {
      id
      uid
      firstName
      lastName
      phone
      role
      createdAt
      updatedAt
    }
  }
`;
