import { gql } from '@apollo/client';

export const UPLOAD_CAR_FILES = gql`
  mutation uploadCarImages($files: [Upload!]!) {
    uploadCarImages(files: $files)
  }
`;

export const UPLOAD_LICENSE_FILES = gql`
  mutation uploadDriverLicenceImages($files: [Upload!]!) {
    uploadDriverLicenceImages(files: $files)
  }
`;

export const UPLOAD_PROFILE_FILE = gql`
  mutation uploadUserAvatar($file: Upload!) {
    uploadUserAvatar(file: $file)
  }
`;
