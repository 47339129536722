import gql from 'graphql-tag';

export const CARMAKERS_QUERY = gql`
  query carMakers {
    carMakers {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
