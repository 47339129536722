import React from 'react';
import { MinSpinner } from '../MinSpinner/index';

export const Popup = ({ isShowPopup, popupReset, popupResetCancel, title, content, loading }) => {
  if (!isShowPopup) return null;

  return (
    <div className="fixed top-0 left-0 z-20 w-full h-full bg-black bg-opacity-70 visible delay-200">
      <div className="w-3/4 bg-white m-auto mt-60 p-5 pt-5 flex flex-col items-center justify-between">
        <h1 className={title ? 'font-bold text-lg mb-1' : 'hidden'}>{title}</h1>
        <span className="text-sm mb-4">{content}</span>
        <span
          className={popupResetCancel ? 'font-medium text-sm text-nuale_neon_blue mb-4' : 'hidden'}
          onClick={popupResetCancel}
        >
          Don’t Cancel
        </span>
        <span
          className="inline w-44 bg-black text-white rounded-full py-2 text-center font-bold cursor-pointer mr-2"
          onClick={popupReset}
        >
          {loading ? <MinSpinner /> : 'OK'}
        </span>
      </div>
    </div>
  );
};
