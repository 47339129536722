import gql from 'graphql-tag';

export const FUTURE_RIDES_QUERY = gql`
  query futureRides {
    futureRides {
      id
      driver {
        id
        firstName
        lastName
        gender
        userImg
        userVehicles {
          make {
            name
          }
          model
          spec {
            engineSize
            fuelConsumption
          }
        }
      }
      seats
      startDate
      pricePerSeat
      startPoint {
        name
        location {
          lat
          lng
        }
        eta
      }
      endPoint {
        name
        location {
          lat
          lng
        }
        eta
      }
      waypoints {
        id
        name
      }
      waypointsEta {
        waypointId
        eta
      }
      createdAt
      updatedAt
    }
  }
`;
