import React from 'react';

import profile_x3 from '../../../assets/images/menu/profile@3x.png';

export const RideDetail = ({ rideInfo, setSelectedRide }) => {
  return (
    <div
      className="w-full flex mb-10 hover:bg-gray-100"
      onClick={() => {
        setSelectedRide(rideInfo);
      }}
    >
      <div
        className="bg-white min-w-12 w-12 h-12 rounded-full border mr-3"
        style={{
          backgroundImage: `url(${rideInfo.driver.userImg || profile_x3})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {' '}
      </div>
      <div className="w-4/5">
        <div className="w-full flex flex-col justify-between border-b border-gray-200 py-1">
          <div className="w-full flex justify-between mb-4 ">
            <div className="w-1/2">
              <div className="text-xs text-purple-400">Eco</div>
              <div className="text-base font-bold">{`${rideInfo.driver.firstName} ${rideInfo.driver.lastName}`}</div>
              <div className="w-full flex text-sm">
                {rideInfo.driver.userVehicles.length
                  ? `${rideInfo.driver.userVehicles[0].make.name} ${rideInfo.driver.userVehicles[0].model}`
                  : '-----'}
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-xs text-purple-400">Ride Date</div>
              <div className="text-base font-bold">{`${new Date(rideInfo.startDate).getDate()}/${
                new Date(rideInfo.startDate).getMonth() + 1
              }/${new Date(rideInfo.startDate).getFullYear()}`}</div>
            </div>
          </div>
          <div className="flex justify-between ">
            <div className="w-1/2">
              <div className="text-xs text-purple-400">Ride Time</div>
              <div className="text-sm font-bold text-nuale_turquoise">{`${
                (+rideInfo.waypointsEta[1].eta - +rideInfo.waypointsEta[0].eta) / 60000
              } mins`}</div>
            </div>
            <div className="w-1/2">
              <div className="text-xs text-purple-400">Seats</div>
              <div className="text-sm font-bold">{rideInfo.seats}</div>
            </div>
          </div>
        </div>
        <div className="flex justify-between py-1 mb-5">
          <div className="w-1/2 text-xs mr-1">
            <div className="">Pickup point</div>
            <div className="font-bold">{rideInfo.waypoints[0].name}</div>
          </div>
          <div className="w-1/2 text-xs">
            <div className="">Dropoff point</div>
            <div className="font-bold">{rideInfo.waypoints[1].name}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
