import gql from 'graphql-tag';

export const VEHICLES_QUERY = gql`
  query vehicles {
    vehicles {
      id
      fuelTypes {
        id
        type
        price
      }
      make {
        id
        name
      }
      model
      spec {
        engineSize
        fuelConsumption
      }
      createdAt
      updatedAt
    }
  }
`;
