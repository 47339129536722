import React from 'react';

import iconLeft from '../../../assets/images/arrow-left-solid.svg';
import mytMoneyLogo from '../../../assets/images/myt-money-logo.png';

export function MakePayment({
  // setRideDetailes,
  // setMakePayment,
  stepFromMakePaymentToRideDetails,
  totalPrice,
  rideRequestId,
  setPaidStatus,
  setPaidStatusStatus,
}) {
  // const [isGetRide, setGetRide] = useState(false);
  // const isDisabled = () => {};

  return (
    <div className="container mx-auto w-full h-full box-border bg-white absolute top-0 left-0">
      <div className="flex justify-start items-center bg-black mb-5 p-4">
        <button onClick={stepFromMakePaymentToRideDetails}>
          <img src={iconLeft} alt="icon back" className="w-4 h-4" />
        </button>
        <span className="text-lg font-sans text-white font-medium ml-8">Ride Details</span>
      </div>
      <div className="px-7 fontFamily-WorkSans-regular">
        <h1 className="text-3xl mb-3 fontFamily-WorkSans">Make Contribution</h1>
        <p className="text-sm">Use your myT money Wallet to make your contribution </p>

        <img src={mytMoneyLogo} alt="my.t money logo" className="m-auto w-24 mt-7" />

        <div className="text-4xl text-center text-nuale_turquoise fontFamily-WorkSans mt-16">
          MUR {totalPrice}
        </div>
        <button
          className="px-3 py-3 bg-black text-white text-sm m-auto rounded-full w-full uppercase mt-24"
          disabled={setPaidStatusStatus.loading}
          onClick={() => {
            setPaidStatus({ variables: { id: rideRequestId } });
          }}
        >
          Complete contribution
        </button>

        <div className="text-center mt-12">
          <a
            href="https://www.myt.mu/money/"
            target="_blank"
            rel="noreferrer"
            className="text-nuale_neon_blue mx-auto"
          >
            CREATE myT MONEY ACCOUNT
          </a>
        </div>
        {/* <h1 className="font-bold text-3xl px-4 mb-14">Make payment</h1>
        <form className="flex justify-between flex-wrap">
          <label className="flex flex-col text-sm font-bold mb-4 w-60">
            <span className="mb-4">CARD NUMBER</span>
            <input
              className="bg-gray-100 px-4 py-3"
              type="text"
              placeholder="7419  9412  5910  9218"
            />
          </label>
          <label className="flex flex-col text-sm font-bold mb-4 w-16">
            <span className="mb-4">CVC</span>
            <input className="bg-gray-100 px-4 py-3" type="text" placeholder="253" />
          </label>
          <label className="flex flex-col text-sm font-bold mb-4 w-full">
            <span className="mb-4">CARD HOLDER NAME</span>
            <input className="bg-gray-100 px-4 py-3" type="text" placeholder="Maria Malina" />
          </label>
          <label className="flex flex-col text-sm font-bold w-full mb-8">
            <span className="mb-4">EXPERITION DATE</span>
            <div className="flex justify-between">
              <select className="bg-gray-100 px-4 py-3 w-20" name="" id="">
                <option value="">20</option>
              </select>
              <select className="bg-gray-100 px-4 py-3 w-32" name="" id="">
                <option value="">February</option>
              </select>
              <select className="bg-gray-100 px-4 py-3 w-28" name="" id="">
                <option value="">2018</option>
              </select>
            </div>
          </label>
          <button
            className="px-3 py-3 bg-black text-white m-auto rounded-full"
            disabled={setPaidStatusStatus.loading}
            onClick={() => {
              // console.log('rideRequestId', rideRequestId);
              setPaidStatus({ variables: { id: rideRequestId } });
            }}
          >
            {setPaidStatusStatus.loading
              ? 'loading...'
              : `COMPLETE ORDER (TOTAL MUR ${totalPrice})`}
          </button>
        </form> */}
      </div>
    </div>
  );
}
