import React from 'react';
// import { useHistory } from 'react-router-dom';

// import { useGlobalVariables } from '../../components/contexts/GlobalVariablesContext';

// import { ROUTES } from '../../shared/constants';

import profile_x3 from '../../assets/images/menu/profile@3x.png';

export function RideHistoryItem({
  rideInfo,
  cancelObjId,
  ridesScheduled,
  cancelRideFunc,
  cancelRideStatus,
  activeList,
  rideRequestsArr,
  // setIsShowPopup,
  // setPopupContent,
  // rideRequestByIdData,
  // selectRideRequestById
}) {
  // const history = useHistory();
  // const { setTestRideGlobal, setTestRideByRiderGlobal } = useGlobalVariables();

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  // console.log('activeList', activeList);
  console.log('rideRequestsArr', rideRequestsArr);

  return (
    <div className="w-full flex mb-10">
      {activeList !== 'driverList' && (
        <div
          className="bg-white min-w-12 w-12 h-12 rounded-full border mr-3"
          style={{
            backgroundImage: `url(${rideInfo.driver.userImg || profile_x3})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          {' '}
        </div>
      )}

      <div className={activeList === 'riderList' ? 'w-4/5' : 'w-full'}>
        <div className={activeList === 'riderList' ? 'mb-4' : 'border-b mb-4'}>
          <div
            className={
              activeList === 'riderList'
                ? 'w-full flex flex-col justify-between border-b border-gray-200 py-1'
                : 'w-full flex flex-col justify-between py-1'
            }
          >
            <div className="w-full flex justify-between mb-4 ">
              {activeList !== 'driverList' && (
                <div className="w-1/2">
                  <div className="text-xs text-purple-400">Eco</div>
                  <div className="text-sm font-bold">{`${rideInfo.driver.firstName} ${rideInfo.driver.lastName}`}</div>
                  <div className="w-full flex text-sm">
                    {rideInfo.driver.userVehicles.length
                      ? `${rideInfo.driver.userVehicles[0].make.name} ${rideInfo.driver.userVehicles[0].model}`
                      : '-----'}
                  </div>
                </div>
              )}
              <div className="w-1/2">
                <div className="text-xs text-purple-400">Ride Date</div>
                <div className="text-sm font-bold">{`${new Date(rideInfo.startDate).getDate()}/${
                  new Date(rideInfo.startDate).getMonth() + 1
                }/${new Date(rideInfo.startDate).getFullYear()}`}</div>
              </div>
              {activeList === 'driverList' && (
                <div className="w-1/2">
                  <div className="text-xs text-purple-400">Start Time</div>
                  <div className="text-sm font-bold">
                    {formatAMPM(new Date(rideInfo.startDate))}
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-between ">
              <div className="w-1/2">
                <div className="text-xs text-purple-400">Ride Time</div>
                <div className="text-sm font-bold text-nuale_turquoise">{`${
                  (+rideInfo.waypointsEta[1].eta - +rideInfo.waypointsEta[0].eta) / 60000
                } mins`}</div>
              </div>
              <div className="w-1/2">
                <div className="text-xs text-purple-400">Seats</div>
                <div className="text-sm font-bold">{rideInfo.seats}</div>
              </div>
            </div>
          </div>
          <div className="flex justify-between py-1 mb-5">
            <div className="w-1/2 text-xs mr-1">
              <div className="">Pickup point</div>
              <div className="font-bold">{rideInfo.waypoints[0].name}</div>
            </div>
            <div className="w-1/2 text-xs">
              <div className="">Dropoff points</div>
              <div className="font-bold">{rideInfo.waypoints[1].name}</div>
            </div>
          </div>
          {!ridesScheduled && (
            <div className="py-1">
              <div className="text-xs font-bold">Contribution</div>
              <div className="text-lg font-bold text-nuale_turquoise">{`MUR ${rideInfo.pricePerSeat}`}</div>
            </div>
          )}
          {
            ridesScheduled &&
              rideRequestsArr &&
             ((rideRequestsArr.length &&
              rideRequestsArr.filter((el) => el.ride.id === rideInfo.id).length &&
              rideRequestsArr
                .filter((el) => el.ride.id === rideInfo.id)
                .map((rideReq, index) => (
                  <div key={'rideRequestItem' + index} className="flex justify-between pl-6">
                    <div
                      className="bg-white min-w-12 w-12 h-12 rounded-full border mr-3"
                      style={{
                        backgroundImage: `url(${rideReq.passenger.userImg || profile_x3})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    ></div>
                    <div className="w-4/5">
                      <div className="flex justify-between border-b border-t py-1">
                        <div className="w-1/2">
                          <div className="text-xs text-nuale_turquoise">Rider</div>
                          <div className="text-base font-bold text-black">{`${rideReq.passenger.firstName} ${rideReq.passenger.lastName}`}</div>
                        </div>
                        <div className="w-1/2">
                          <div className="text-xs text-purple-400">Seats</div>
                          <div className="text-sm font-bold">{rideReq.requestedSeatsCount}</div>
                        </div>
                      </div>
                      <div className="py-1">
                        <div className="text-xs font-bold">Contribution</div>
                        <div className="text-lg font-bold text-nuale_turquoise">{`MUR ${rideReq.ride.pricePerSeat}`}</div>
                      </div>
                    </div>
                  </div>
                ))) || (<div className="text-nuale_neon_blue text-xs mb-2">No Riders has booked till now</div>)
            )
          }
        </div>

        {ridesScheduled && (
          <div className="flex justify-between">
            {/*<div*/}
              {/*className="w-full p-1.5 border border-gray-400 rounded-full text-center text-lg mr-1"*/}
              {/*onClick={() => {*/}
                {/*if (activeList === 'driverList') {*/}
                  {/*setTestRideGlobal([rideInfo]);*/}
                {/*}*/}
                {/*if (activeList === 'riderList') {*/}
                  {/*setTestRideByRiderGlobal([rideInfo]);*/}
                {/*}*/}
                {/*history.push(ROUTES.TEST_DRIVE);*/}
              {/*}}*/}
            {/*>*/}
              {/*TEST*/}
            {/*</div>*/}
            <div
              className="w-full p-1.5 border border-gray-400 rounded-full text-center text-lg"
              onClick={(event) => {
                // if(
                //   selectRideRequestById.status.PAID || selectRideRequestById.status.REQUESTED
                // ) {
                //   setIsShowPopup(true);
                //   setPopupContent("It is not possible to cancel the Ride. This ride was booked.")
                // } else {
                cancelRideFunc(
                  event.target.dataset.id,
                  event.target.dataset.driverid,
                  event.target.dataset.rideid,
                );
                // }
              }}
              data-id={cancelObjId}
              data-driverid={rideInfo.driver.id}
              data-rideid={rideInfo.id}
            >
              {!cancelRideStatus ? 'Cancel Ride' : 'loading...'}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
