import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import pointFrom_x1 from '../../../assets/images/mapFindForm/edit-location-black-18-dp.png';
import pointFrom_x2 from '../../../assets/images/mapFindForm/edit-location-black-18-dp@2x.png';
import pointFrom_x3 from '../../../assets/images/mapFindForm/edit-location-black-18-dp@3x.png';

import pointTo_x1 from '../../../assets/images/mapFindForm/location-on-black-18-dp.png';
import pointTo_x2 from '../../../assets/images/mapFindForm/location-on-black-18-dp@2x.png';
import pointTo_x3 from '../../../assets/images/mapFindForm/location-on-black-18-dp@3x.png';

export function FindRideWithoutAuth({
  setGetRide,
  dropOffPoint,
  pickUpPoint,
  minMaxPriceRides,
  queryFindRidesLoading,
  queryFindRides,
}) {
  useEffect(() => {
    queryFindRides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function priseRangeDisplay(arr) {
    if (arr.length > 1) return `${arr[0]}-${arr[1]}`;
    if (arr.length === 1) return `${arr[0] - 5}-${arr[0] + 5}`;
  }

  return (
    <div className="container mx-auto w-full h-screen box-border absolute top-0 left-0 bg-white">
      <div className="flex justify-between items-center bg-black mb-4 p-4">
        <h1 className="text-lg font-sans text-white font-medium">Find a ride</h1>
        {/*<NavLink to="/map">*/}
        <span className="text-white text-lg" onClick={() => setGetRide(false)}>
          x
        </span>
        {/*</NavLink>*/}
      </div>
      <div className=" px-4 pt-5 pb-28">
        <div
          className="p-4 pb-5 rounded-lg mb-14"
          style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
        >
          <h2 className="text-base font-sans font-bold mb-9">Ride details</h2>
          <div className="w-full flex items-center mb-10 relative">
            <picture className="w-8">
              {/*<picture className='absolute left-auto bottom-2/4 transform translate-y-1/2'>*/}
              <source srcSet={pointFrom_x3} media="(max-width: 360px)" />
              <source srcSet={pointFrom_x2} media="(max-width: 360px)" />
              <img src={pointFrom_x1} alt="Rider icon" />
            </picture>
            <p className="relative">
              <span className="absolute -top-4 text-xs">Pick up</span>
              <span className="text-base font-medium">
                {pickUpPoint ? pickUpPoint.name : 'Some pickup point address'}
              </span>
            </p>
            <div className="absolute top-8 left-2.5 h-6 flex flex-col justify-between">
              <span className="w-0.5 h-0.5 bg-black rounded-full"></span>
              <span className="w-0.5 h-0.5 bg-black rounded-full"></span>
              <span className="w-0.5 h-0.5 bg-black rounded-full"></span>
            </div>
          </div>
          <div className="w-full flex items-center mb-10">
            <picture className="w-8">
              <source srcSet={pointTo_x3} media="(max-width: 360px)" />
              <source srcSet={pointTo_x2} media="(max-width: 360px)" />
              <img src={pointTo_x1} alt="Rider icon" />
            </picture>
            <p className="relative">
              <span className="absolute -top-4 text-xs">Drop-off</span>
              <span className="text-base font-medium">
                {dropOffPoint ? dropOffPoint.name : 'Some dropoff point address'}
              </span>
            </p>
          </div>
          <h2 className="text-base font-sans font-bold mb-3">Estimated contribution per seats</h2>
          {minMaxPriceRides.length ? (
            <div className="text-lg mb-5">
              <span className="text-xl mr-2 font-bold text-green-400">MUR</span>
              <span className="text-3xl font-bold text-green-400">
                {queryFindRidesLoading ? 'loading...' : priseRangeDisplay(minMaxPriceRides)}
              </span>
            </div>
          ) : (
            <div className="text-lg mb-5 font-bold text-green-400">
              Currently, this route has no offers
            </div>
          )}
          <p className="text-xs text-gray-400">Price may vary upon vehicle type and route.</p>
        </div>
        <div>
          <p className="mb-8 text-sm font-sans px-4">
            Log in or sign up to get the final price and request your ride.
          </p>
          <NavLink
            to="/login"
            className="inline-block w-full mb-8 text-base font-sans font-bold text-white bg-black rounded-3xl text-center py-2 cursor-pointer"
          >
            Login
          </NavLink>
          <NavLink
            to="/sign-up"
            className="inline-block w-full text-base font-sans font-bold text-black bg-white rounded-3xl text-center py-1.5 border-2 border-solid border-black cursor-pointer"
          >
            Sign up
          </NavLink>
        </div>
      </div>
    </div>
  );
}
