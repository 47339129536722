import React, { useState } from 'react';
import firebase from 'firebase/app';
import iconLeft from '../../assets/images/sign-up/icons_left.png';
import GoogleSignUp from './GoogleSignUp';
import { SIGN_UP_METHODS } from '../../shared/constants';
import FacebookSignUp from './FacebookSignUp';

export function SignUpMethod({ values, setters, nextStep }) {
  const { setPhone, setEmail, setFirstName, setLastName, setRole, setSignUpMethod } = setters;
  const { phone } = values;
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  async function setUpRecaptcha() {
    window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // console.log('response', response);
      },
    });
  }

  async function sendOtp() {
    try {
      setLoading(true);

      const cleanedPhoneNumber = phone.replace(/\s/g, '');
      await setUpRecaptcha();
      const appVerifier = window.recaptchaVerifier;

      await firebase
        .auth()
        .signInWithPhoneNumber(cleanedPhoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setSignUpMethod(SIGN_UP_METHODS.PHONE);
          nextStep();
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="sign-up-phone-wrapper">
      <div id="recaptcha-container"></div>
      <div className="py-2 flex flex-row items-center">
        <button className="focus:outline-none p-1.5" onClick={() => setRole('')}>
          <img src={iconLeft} alt="Back" className="w-4" />
        </button>
        <div className="font-bold ml-3">Back</div>
      </div>

      <div>
        <h1 className="font-bold" style={{ fontSize: 32 }}>
          Sign Up
        </h1>
      </div>

      <div className="mt-6">Create your Nuale account</div>

      <div className="w-full flex flex-col items-center mt-12">
        <input
          type="tel"
          name="phone"
          id="phone"
          required
          placeholder="+230 x xxx xxxx"
          className="bg-gray-100 text-black rounded-sm px-4 py-2 w-full"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <button
          disabled={isLoading}
          className="bg-black text-white text-base font-bold px-4 py-2 rounded-full block mt-6 focus:outline-none w-full disabled:opacity-50"
          onClick={() => sendOtp()}
        >
          Sign Up
        </button>
      </div>

      <div className="text-sm mt-6 font-medium">
        By continuing, I confirm that I have read and agree to the{' '}
        <a href="/" className="text-neon_blue">
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a href="/" className="text-neon_blue">
          Privacy Policy
        </a>
      </div>

      {errors &&
        errors.map((err, i) => (
          <div className="bg-red-300 px-5 py-2 mt-4 rounded-sm" key={i}>
            {err}
          </div>
        ))}

      <div className="mt-28 flex flex-col items-center">
        <h2 className="text-gray-500 text-base">Or connect with social</h2>

        <div className="mt-6 w-full flex flex-col items-center">
          {/* <button className="shadow w-64 p-3 text-white bg-blue-700 font-medium rounded-sm focus:outline-none">
            Sign in with Facebook
          </button> */}
          <FacebookSignUp
            nextStep={nextStep}
            setEmail={setEmail}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setErrors={setErrors}
            setSignUpMethod={setSignUpMethod}
          />
          <GoogleSignUp
            nextStep={nextStep}
            setEmail={setEmail}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setErrors={setErrors}
            setSignUpMethod={setSignUpMethod}
          />
        </div>
      </div>
    </div>
  );
}
