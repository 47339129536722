import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  Map,
  // MapFindForm,
  // AddPickupPoint,
  // Menu,
  // Header,
  Popup,
  ScheduleRideDetailes,
} from '../../components';
import { ScheduleRideByRiderDetailes } from '../../components/ScheduleRideDetailes/indexByRider';
import { useAuth } from '../../components/contexts/AuthContext';
import { useGlobalVariables } from '../../components/contexts/GlobalVariablesContext';
import { useLoadScript } from '@react-google-maps/api';
import // useMutation,
// useLazyQuery,
// useSubscription
'@apollo/client';
import Geocode from 'react-geocode';

import { RIDE_REQUESTS_QUERY } from '../../shared/gqlQueries/rideRequests';
import { ROUTES, LINK_SET_GEOLOCATION, RIDE_REQUEST_STATUS } from '../../shared/constants';

import iconLeft from '../../assets/images/arrow-left-black.svg';

const libraries = ['places'];

export function TestDrive() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
    region: 'US',
  });

  const history = useHistory();
  const {
    testRideGlobal,
    setTestRideGlobal,
    testRideByRiderGlobal,
    setTestRideByRiderGlobal,
    setTestRideRouteGlobal,
    testRideRouteGlobal,
  } = useGlobalVariables();
  const { currentUser } = useAuth();
  const [centerCoords, setCenterCoords] = useState({ lat: -20.3, lng: 57.5833333 });
  const [mapHeight, setMapHeight] = useState('70%');
  const [response, setResponse] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [markerStart, setMarkerStart] = useState();
  const [markerEnd, setMarkerEnd] = useState();
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [isAddPickupPoint, setAddPickupPoint] = useState(false);
  const [
    isScheduleRide,
    // setScheduleRide
  ] = useState(true);
  const [isActiveRide, setActiveRide] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [wayPoints3, setWayPoints3] = useState([]);
  const [wayPoints4, setWayPoints4] = useState([]);
  const [pickUpPoint, setPickUpPoint] = useState(null);
  const [dropOffPoint, setDropOffPoint] = useState(null);
  const [startRequests, setStartRequests] = useState(true);
  const [seatsNumber, setSeatsNumber] = useState(2);
  const [startPointDate, setStartPointDate] = useState(Date.now());
  const [totalDuration, setTotalDuration] = useState(Date.now());
  const [pickUpPointTime, setPickUpPointTime] = useState(Date.now());
  const [dropOffPointTime, setDropOffPointTime] = useState(Date.now());
  const [distanceMarker, setDistanceMarker] = useState([]);
  const [rideCostPerSeat, setRideCostPerSeat] = useState('');
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [geoLocationMessage, setGeoLocationMessage] = useState('');
  const [geoLocationMarkerLoading, setGeoLocationMarkerLoading] = useState(false);
  const [popupContent, setPopupContent] = useState('Your ride has been successfully added.');
  // const [popupTitle, setTitle] = useState("");
  const [geoLocationMarker, setGeoLocationMarker] = useState('');
  const [selectRouteIndex, setSelectRouteIndex] = useState(0);
  const [fuelConsumptionMarker, setFuelConsumptionMarker] = useState([]);
  const [co2Marker, setCo2Marker] = useState([]);
  const [durationMarker, setDurationMarker] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [notificationsArr, setNotificationsArr] = useState([]);
  // const [selectRide, setSelectRide] = useState();
  const [watchGeoPosition, setWatchGeoPosition] = useState();
  const [rideRequestsArr, setRideRequestsArr] = useState([]);

  const values = {
    response,
    markers,
    markerStart,
    markerEnd,
    origin,
    destination,
    isAddPickupPoint,
    showMenu,
    wayPoints3,
    wayPoints4,
    pickUpPoint,
    dropOffPoint,
    startRequests,
    seatsNumber,
    startPointDate,
    totalDuration,
    pickUpPointTime,
    dropOffPointTime,
    distanceMarker,
    rideCostPerSeat,
    isShowPopup,
    geoLocationMessage,
    selectRouteIndex,
    fuelConsumptionMarker,
    co2Marker,
    durationMarker,
    showForm,
    notificationsArr,
    geoLocationMarker,
    geoLocationMarkerLoading,
    mapHeight,
  };

  const setters = {
    setResponse,
    setMarkers,
    setMarkerStart,
    setMarkerEnd,
    setOrigin,
    setDestination,
    setAddPickupPoint,
    setShowMenu,
    setWayPoints3,
    setWayPoints4,
    setPickUpPoint,
    setDropOffPoint,
    setStartRequests,
    setSeatsNumber,
    setStartPointDate,
    setTotalDuration,
    setPickUpPointTime,
    setDropOffPointTime,
    setDistanceMarker,
    setRideCostPerSeat,
    setIsShowPopup,
    setGeoLocationMessage,
    setSelectRouteIndex,
    setFuelConsumptionMarker,
    setCo2Marker,
    setDurationMarker,
    setShowForm,
    setNotificationsArr,
    setGeoLocationMarker,
    setMapHeight,
  };

  const [getRideRequestsFunc, rideRequestsData] = useLazyQuery(RIDE_REQUESTS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
      if (rideRequestsData.data && rideRequestsData.data.rideRequests) {
        // console.log('[rideRequestsData]', rideRequestsData.data.rideRequests);
        setRideRequestsArr(ridesFilterByRideAndPaidStatus(rideRequestsData.data.rideRequests));
      }
    },
    onError: (error) => {
      console.log('[GetRidesHistory] error getRideRequestsFunc', error);
    },
  });

  function ridesFilterByRideAndPaidStatus(arr) {
    // console.log('arr', arr);
    return arr.filter(
      (el) =>
        el.ride.id === testRideGlobal[0].id &&
        +el.ride.waypointsEta[0].eta > +Date.now() &&
        el.status.toLowerCase() === RIDE_REQUEST_STATUS.PAID.toLowerCase(),
    );
    // return arr.filter(el => (el.driver.id === currentUser.id && +el.waypointsEta[0].eta > +Date.now()))
  }

  useEffect(() => {
    if (testRideGlobal || testRideByRiderGlobal) {
      setRideParamsInState(testRideGlobal || testRideByRiderGlobal);
      getWaypointsLocations(testRideGlobal || testRideByRiderGlobal);
      testRideGlobal && getRideRequestsFunc();
      setMapHeight('70%');
    } else {
      setMapHeight('100%');
    }
    // return ()=> setTestRideGlobal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testRideGlobal]);

  async function getWaypointsLocations(ride) {
    let pickUpLocation = await geocode(ride[0].waypoints[0].name);
    let dropOffLocation = await geocode(ride[0].waypoints[1].name);
    await setWayPoints4([
      { name: ride[0].waypoints[0].name, location: pickUpLocation },
      { name: ride[0].waypoints[1].name, location: dropOffLocation },
    ]);
    setStartRequests(true);
  }

  async function setRideParamsInState(ride) {
    await setOrigin({ location: { ...ride[0].startPoint.location } });
    await setDestination({ location: { ...ride[0].endPoint.location } });
    await setMarkerStart({ location: { ...ride[0].startPoint.location } });
    await setMarkerEnd({ location: { ...ride[0].endPoint.location } });
    // await setMarkers([
    //   { location: { ...ride[0].startPoint.location } },
    //   { location: { ...ride[0].endPoint.location } },
    // ]);
  }

  function cancelScheduleRide() {
    // setSelectRide();
    navigator.geolocation.clearWatch(watchGeoPosition);
    setGeoLocationMarker('');
    testRideGlobal && setTestRideGlobal();
    testRideByRiderGlobal && setTestRideByRiderGlobal();
    testRideGlobal && history.push({ pathname: ROUTES.RIDE_SCHEDULED, propsDriver: 'driverList' });
    history.push({ pathname: ROUTES.RIDE_SCHEDULED });
  }

  function popupReset() {
    setIsShowPopup(false);
    !geoLocationMessage && history.push(ROUTES.FIND_RIDE);
    geoLocationMessage && history.push(ROUTES.ADD_RIDE);
    setGeoLocationMessage('');
  }

  async function geocode(address) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    try {
      let response = await Geocode.fromAddress(address);
      return response.results[0].geometry.location;
    } catch (error) {
      console.error(error);
      setGeoLocationMessage(`Error: ${error.message}`);
      setGeoLocationMarkerLoading(false);
      setPopupContent(`Error: ${error.message}`);
      setIsShowPopup(true);
    }
    //   .then((response) => {
    //      const address = response.results[0].formatted_address;
    //     // console.log(address);
    //     return address
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
  }

  function watchYourPosition(number = 0) {
    setGeoLocationMarkerLoading(true);
    // Try geolocation.
    if (navigator.geolocation) {
      // console.log('navigator.geolocation', navigator.geolocation);
      let id = navigator.geolocation.watchPosition(
        async (position) => {
          setCenterCoords({
            lat: position.coords.latitude + number,
            lng: position.coords.longitude,
          });
          setGeoLocationMarker({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setGeoLocationMarkerLoading(false);
        },
        (err) => {
          handleLocationError(true);
          console.log(err.code);
          setGeoLocationMessage(`Error: ${err.message}`);
          setGeoLocationMarkerLoading(false);
        },
      );
      setWatchGeoPosition(id);
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false);
      setGeoLocationMarkerLoading(false);
    }
  }

  function handleLocationError(browserHasGeolocation) {
    setPopupContent(
      browserHasGeolocation ? (
        // ? 'Error: The Geolocation service failed.'
        <span>
          Nuale does not have permission to use your location.{' '}
          <span>{makeLinkForSetGeolocation('Learn more')}</span>
        </span>
      ) : (
        "Error: Your browser doesn't support geolocation."
      ),
    );
    setIsShowPopup(true);
  }

  function makeLinkForSetGeolocation(content) {
    return (
      <a href={LINK_SET_GEOLOCATION} target="blank" className="text-nuale_neon_blue">
        {content}
      </a>
    );
  }

  // console.log('selectRide', selectRide);

  return (
    <div className="container mx-auto w-full box-border relative overflow-hidden">
      <div className="flex justify-start items-center p-4 fixed top-0 left-0 z-20">
        <button
          onClick={() => {
            cancelScheduleRide();
          }}
        >
          <img src={iconLeft} alt="icon back" className="w-4" />
        </button>
        <span className="text-lg font-sans font-medium ml-4">Back</span>
      </div>

      <Map
        values={values}
        setters={setters}
        centerCoords={centerCoords}
        response={response}
        markers={markers}
        markerStart={markerStart}
        markerEnd={markerEnd}
        origin={origin}
        destination={destination}
        loadError={loadError}
        isLoaded={isLoaded}
        fuelConsumption={
          !!(currentUser && currentUser.userVehicles.length)
            ? currentUser.userVehicles[0].spec[0].fuelConsumption
            : null
        }
        wayPoints3={wayPoints3}
        wayPoints4={wayPoints4}
        setWayPoints3={setWayPoints3}
        setWayPoints4={setWayPoints4}
        setPickUpPoint={setPickUpPoint}
        setDropOffPoint={setDropOffPoint}
        setResponse={setResponse}
        startRequests={startRequests}
        setStartRequests={setStartRequests}
        setTotalDuration={setTotalDuration}
        isMarkersClickable={isAddPickupPoint}
        distanceMarker={distanceMarker}
        setDistanceMarker={setDistanceMarker}
        setPickUpPointTime={setPickUpPointTime}
        selectRouteIndex={selectRouteIndex}
        setSelectRouteIndex={setSelectRouteIndex}
        selectedRide={true}
        setFuelConsumptionMarker={setFuelConsumptionMarker}
        fuelConsumptionMarker={fuelConsumptionMarker}
        setCo2Marker={setCo2Marker}
        co2Marker={co2Marker}
        durationMarker={durationMarker}
        setDurationMarker={setDurationMarker}
        geoLocationMarker={geoLocationMarker}
        mapHeight={mapHeight}
        isScheduleRide={isScheduleRide}
        setTestRideRouteGlobal={setTestRideRouteGlobal}
        testRideRouteGlobal={testRideRouteGlobal}
      />
      {testRideGlobal && (
        <ScheduleRideDetailes
          setShowForm={setShowForm}
          showForm={showForm}
          ride={testRideGlobal}
          setMapHeight={setMapHeight}
          watchYourPosition={watchYourPosition}
          cancelScheduleRide={cancelScheduleRide}
          isActiveRide={isActiveRide}
          setActiveRide={setActiveRide}
          rideRequestsArr={rideRequestsArr}
          // setMapHeightByShowForm={setMapHeightByShowForm}
        />
      )}
      {testRideByRiderGlobal && (
        <ScheduleRideByRiderDetailes
          setShowForm={setShowForm}
          showForm={showForm}
          ride={testRideByRiderGlobal}
          setMapHeight={setMapHeight}
          watchYourPosition={watchYourPosition}
          cancelScheduleRide={cancelScheduleRide}
          isActiveRide={isActiveRide}
          setActiveRide={setActiveRide}
          rideRequestsArr={rideRequestsArr}
          // setMapHeightByShowForm={setMapHeightByShowForm}
        />
      )}
      {/*<Menu showMenu={showMenu} setShowMenu={setShowMenu} />*/}
      <Popup
        isShowPopup={isShowPopup}
        // resetRide={resetRide}
        popupReset={popupReset}
        content={popupContent}
        // content={geoLocationMessage ? geoLocationMessage : 'Your ride has been successfully added.'}
        // title={popupTitle}
      />
    </div>
  );
}
