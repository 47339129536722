import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import {useTransition, animated} from 'react-spring'
import {
  StartPage,
  FindRide,
  AddRide,
  SignUp,
  Profile,
  TalkToUs,
  Login,
  BecomeDriverPage,
  Faq,
  Term,
  RideHistory,
  RideScheduled,
  Notifications,
  TestDrive,
  AboutUs,
  AllRidesSchedules,
  Safety,
} from '../../pages';
import { PrivateRoute, PublicRoute } from '../../components';
import { ROUTES } from '../../shared/constants';

export const UseRoutes = () => {
  // let location = useLocation()
  // console.log('location', location)
  // const transitions = useTransition(location, location => location.pathname, {
  //   from: {opacity: 0, transform: 'translate3d(100%,0,0)'},
  //   enter: {opacity: 1, transform: 'translate3d(0%,0,0)'},
  //   leave: {opacity: 0, transform: 'translate3d(-50%,0,0)'},
  // })

  // return transitions.map(({item: location, props, key}) => (
  //   <animated.div key={key} style={props}>
  //     <Switch location={location}>
  //       <PublicRoute path="/" exact component={StartPage}/>
  //       <PrivateRoute path="/add-ride" exact component={AddRide}/>
  //       <PrivateRoute path="/profile" exact component={Profile}/>
  //       <PrivateRoute path="/message" exact component={TalkToUs}/>
  //       <Route path="/login" exact component={Login}/>
  //       <Route path="/sign-up" exact component={SignUp}/>
  //       <Route path="/find-ride" exact component={FindRide}/>
  //       <Redirect to="/find-ride"/>
  //     </Switch>
  //   </animated.div>
  // ));

  return (
    <Switch>
      <PublicRoute path="/" exact component={StartPage} />
      <PrivateRoute path={ROUTES.ADD_RIDE} exact component={AddRide} />
      <PrivateRoute path={ROUTES.PROFILE} exact component={Profile} />
      <PrivateRoute path={ROUTES.MESSAGE} exact component={TalkToUs} />
      <PrivateRoute path={ROUTES.BECOME_DRIVER} exact component={BecomeDriverPage} />
      <PrivateRoute path={ROUTES.RIDE_HISTORY} exact component={RideHistory} />
      <PrivateRoute path={ROUTES.RIDE_SCHEDULED} exact component={RideScheduled} />
      <PrivateRoute path={ROUTES.NOTIFICATIONS} exact component={Notifications} />
      <PrivateRoute path={ROUTES.TEST_DRIVE} exact component={TestDrive} />
      <PrivateRoute path={ROUTES.ALL_RIDES_SCHEDULES} exact component={AllRidesSchedules} />
      <Route path={ROUTES.LOGIN} exact component={Login} />
      <Route path={ROUTES.SIGN_UP} exact component={SignUp} />
      <Route path={ROUTES.FIND_RIDE} exact component={FindRide} />
      <Route path={ROUTES.FAQ} exact component={Faq} />
      <Route path={ROUTES.TERM} exact component={Term} />
      <Route path={ROUTES.ABOUT_US} exact component={AboutUs} />
      <Route path={ROUTES.SAFETY} exact component={Safety} />
      <Redirect to={ROUTES.FIND_RIDE} />
    </Switch>
  );
};
