import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import riderImg from '../../assets/images/sign-up/rider_big.png';
import { NavLink } from 'react-router-dom';

export function SignUpRiderSuccess() {
  const { currentUser } = useAuth();

  // TODO: add fullName to backend
  const getFullName = () => {
    return `${currentUser?.firstName} ${currentUser?.lastName}`;
  };

  return (
    <div className="flex flex-col h-full absolute top-0 left-0 right-0">
      <img className="w-full max-w-md mx-auto" src={riderImg} alt="Rider" />

      <div className="flex flex-col p-6 w-full">
        <div className="">
          <h1 className="font-bold text-3xl" style={{ fontSize: 32 }}>
            Sign Up <br /> Successful!
          </h1>
        </div>

        <div className="mt-7">
          Great welcome {currentUser ? getFullName() : ''}! to the community
        </div>

        <div className="font-bold mt-4 text-base">
          Login your NUALE app <br /> and share your first ride <br /> to save the planet!
        </div>
        <NavLink to="/find-ride">
          <button className="block mt-12 w-full disabled:opacity-50 bg-black text-white text-base font-bold px-4 py-2 rounded-full focus:outline-none">
            Login
          </button>
        </NavLink>
      </div>
    </div>
  );
}
