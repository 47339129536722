import React, { useState, useEffect } from 'react';
import {
  ExactDriverItem,
  FlexiDriverItem,
  RideDetailes,
  MakePayment,
  // MinSpinner
} from '../../index';

// import pointFrom_x3 from '../../../assets/images/mapFindForm/edit-location-black-18-dp@3x.png';
import pointFrom_x3 from '../../../assets/images/mapFindForm/edit-location-black-18-dp.svg';
import iconLeft from '../../../assets/images/arrow-left-solid.svg';
// import iconLeft from '../../../assets/images/sign-up/icons_left.png';

export function GetRide({
  setAddPickupPoint,
  pickUpPoint,
  startPointDate,
  flexiRides,
  exactRides,
  queryFindRides,
  queryFindRidesLoading,
  seatsNumber,
  selectedRide,
  setSelectedRide,
  setStartRequests,
  co2Marker,
  setPaidStatus,
  setPaidStatusStatus,
  setMapHeight,
  isRideDetailes,
  setRideDetailes,
}) {
  // const [isRideDetailes, setRideDetailes] = useState(false);
  const [isMakePayment, setMakePayment] = useState(false);
  const [isLadyDrivers, setIsLadyDrivers] = useState(false);
  const [filteredTime, setFilteredTime] = useState(startPointDate);
  const [filterFlexiRides, setFilterFlexiRides] = useState([]);
  const [filterExactRides, setFilterExactRides] = useState([]);
  const [rideRequestId, setRideRequestId] = useState('');

  useEffect(() => {
    queryFindRides();
    setFilterFlexiRides(flexiRides);
    setFilterExactRides(exactRides);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flexiRides]);

  const changeRange = (event) => {
    setFilteredTime(+event.target.value);
    switch (+event.target.value) {
      case +startPointDate - 3600000:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              +el.waypointsEta[0].eta < +startPointDate - 2699999 &&
              +el.waypointsEta[0].eta > +startPointDate - 3600001 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      case +startPointDate - 2700000:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              +el.waypointsEta[0].eta < +startPointDate - 1799999 &&
              +el.waypointsEta[0].eta > +startPointDate - 2700001 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      case +startPointDate - 1800000:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              +el.waypointsEta[0].eta < +startPointDate - 899999 &&
              +el.waypointsEta[0].eta > +startPointDate - 1800001 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      case +startPointDate - 900000:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              +el.waypointsEta[0].eta < +startPointDate + 1 &&
              +el.waypointsEta[0].eta > +startPointDate - 900001 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      case +startPointDate:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              el.waypointsEta[0].eta < +startPointDate + 3600000 &&
              +el.waypointsEta[0].eta > +startPointDate - 3600000 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      case +startPointDate + 900000:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              +el.waypointsEta[0].eta < +startPointDate + 900001 &&
              +el.waypointsEta[0].eta > +startPointDate - 1 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      case +startPointDate + 1800000:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              +el.waypointsEta[0].eta < +startPointDate + 1800001 &&
              +el.waypointsEta[0].eta > +startPointDate + 899999 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      case +startPointDate + 2700000:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              +el.waypointsEta[0].eta < +startPointDate + 2700001 &&
              +el.waypointsEta[0].eta > +startPointDate + 1799999 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      case +startPointDate + 3600000:
        setFilteredTime(+event.target.value);
        setFilterFlexiRides((current) =>
          flexiRides.filter(
            (el) =>
              +el.waypointsEta[0].eta < +startPointDate + 3600001 &&
              +el.waypointsEta[0].eta > +startPointDate + 2699999 &&
              (isLadyDrivers ? el.driver.gender.toLowerCase() === 'female' : true),
          ),
        );
        break;
      default:
        break;
    }
  };

  function stepFromMakePaymentToRideDetails() {
    setRideDetailes(true);
    setMakePayment(false);
  }

  function filterExactRidesByLadyDrivers(isLadyDrivers) {
    setIsLadyDrivers(isLadyDrivers);
    if (isLadyDrivers) {
      setFilterExactRides((current) =>
        exactRides.filter((el) => el.driver.gender.toLowerCase() === 'female'),
      );
      setFilterFlexiRides((current) =>
        flexiRides.filter((el) => el.driver.gender.toLowerCase() === 'female'),
      );
    } else {
      setFilterExactRides(exactRides);
      setFilterFlexiRides(flexiRides);
    }
  }

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  return (
    <>
      {!isRideDetailes && !isMakePayment && (
        <div className="absolute top-0 left-0 container mx-auto w-full h-full box-border bg-white">
          <div className="flex justify-start items-center bg-black mb-5 p-4">
            <button onClick={() => setAddPickupPoint(false)}>
              <img src={iconLeft} alt="icon back" className="w-4" />
            </button>
            <span className="text-lg font-sans text-white font-medium ml-4">Ride Available</span>
          </div>
          <div className=" px-4 pb-3 border-b">
            <div className="">
              <h2 className="text-sm font-sans font-bold mb-6">Results for :</h2>
              <div className="w-full flex items-center relative">
                <picture className="w-8">
                  <img className="w-6" src={pointFrom_x3} alt="Rider icon" />
                </picture>
                <div className="w-full flex justify-between items-center">
                  <div className="relative">
                    <span className="absolute -top-4 text-xs">Pick up</span>
                    <span className="text-base font-medium">{pickUpPoint && pickUpPoint.name}</span>
                  </div>
                  <div className="relative mr-4">
                    <span className="absolute -top-4 left-0 text-xs">Time</span>
                    <span className="text-base font-medium">
                      {formatAMPM(new Date(startPointDate))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" pt-4 mb-5">
            <h2 className="flex justify-between items-center pl-4 pr-8 mb-6 text-sm font-sans font-bold">
              <span>Ladies Driver only</span>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={() => {
                    filterExactRidesByLadyDrivers(!isLadyDrivers);
                  }}
                  checked={isLadyDrivers}
                />
                <span className="slider round"></span>
              </label>
            </h2>
            <div className="pl-4 overflow-scroll">
              {!queryFindRidesLoading ? (
                <>
                  {filterExactRides && filterExactRides.length ? (
                    filterExactRides.map((el, index) => (
                      <ExactDriverItem
                        key={`driverItemExact${index}`}
                        rideInfo={el}
                        setRideDetailes={setRideDetailes}
                        formatAMPM={formatAMPM}
                        setSelectedRide={setSelectedRide}
                        setStartRequests={setStartRequests}
                      />
                    ))
                  ) : (
                    <span className="text-sm font-sans text-nuale_neon_blue">
                      No exact prefered rides
                    </span>
                  )}
                </>
              ) : (
                <span>loading ...</span>
              )}
            </div>
          </div>
          <div className="pb-5">
            <h2 className="px-4 mb-3 text-sm font-sans font-bold text-nuale_turquoise">
              Other available rides
            </h2>
            <div className="px-4 mb-5 text-sm font-bold flex justify-between items-center">
              <span className="text-gray-400">
                Filtered time :
                <span className="text-gray-600"> {formatAMPM(new Date(filteredTime))}</span>
              </span>
              <div className="h-6 border-r-2 border-gray-500"></div>
              <form className="flex items-center w-1/2 px-0.5">
                <span>-1h</span>
                <input
                  className="inputRange mx-1"
                  onInput={changeRange}
                  type="range"
                  min={+startPointDate - 3600000}
                  max={+startPointDate + 3600000}
                  step={900000}
                  defaultValue={+filteredTime}
                  id="size"
                />
                <span>+1h</span>
              </form>
            </div>
            <div className="p-4 overflow-scroll">
              {!queryFindRidesLoading ? (
                <>
                  {filterFlexiRides && filterFlexiRides.length ? (
                    filterFlexiRides.map((el, index) => (
                      <FlexiDriverItem
                        key={`driverItemFlexi${index}`}
                        rideInfo={el}
                        setRideDetailes={setRideDetailes}
                        formatAMPM={formatAMPM}
                        setSelectedRide={setSelectedRide}
                        setStartRequests={setStartRequests}
                      />
                    ))
                  ) : (
                    <span className="text-sm font-sans text-nuale_neon_blue">
                      No flexi prefered rides
                    </span>
                  )}
                </>
              ) : (
                <span>loading ...</span>
              )}
            </div>
          </div>
        </div>
      )}
      {isRideDetailes && (
        <RideDetailes
          setRideDetailes={setRideDetailes}
          formatAMPM={formatAMPM}
          setMakePayment={setMakePayment}
          selectedRide={selectedRide}
          co2Marker={co2Marker}
          seatsNumber={seatsNumber}
          setRideRequestId={setRideRequestId}
          setMapHeight={setMapHeight}
        />
      )}
      {isMakePayment && (
        <MakePayment
          // setRideDetailes={setRideDetailes}
          // // selectedRide={selectedRide}
          // setMakePayment={setMakePayment}
          stepFromMakePaymentToRideDetails={stepFromMakePaymentToRideDetails}
          setPaidStatus={setPaidStatus}
          setPaidStatusStatus={setPaidStatusStatus}
          rideRequestId={rideRequestId}
          totalPrice={
            selectedRide && seatsNumber && (selectedRide.pricePerSeat * seatsNumber).toFixed(2)
          }
        />
      )}
    </>
  );
}
