import React from 'react';

import profile_x3 from '../../../assets/images/menu/profile@3x.png';

export function FlexiDriverItem({
  setRideDetailes,
  rideInfo,
  formatAMPM,
  setSelectedRide,
  setStartRequests,
}) {
  return (
    <div
      className="p-4 mb-3 w-full flex items-center rounded-lg cursor-pointer hover:bg-gray-100"
      style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
      onClick={() => {
        setSelectedRide(rideInfo);
        setRideDetailes(true);
        setStartRequests(true);
      }}
    >
      <div
        className="bg-white min-w-12 w-12 h-12 rounded-full border mr-3"
        style={{
          backgroundImage: `url(${rideInfo.driver.userImg || profile_x3})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {' '}
      </div>
      <div className="w-4/5 flex flex-col justify-between">
        <div className="relative">
          <span className="text-sm font-bold">{`${rideInfo.driver.firstName} ${rideInfo.driver.lastName}`}</span>
          <span className="absolute -top-3 left-0 text-xs text-purple-400">Eco</span>
        </div>
        <div className="w-full flex justify-between text-xs text-gray-600">
          <div className="mr-4 flex flex-col">
            <span className="">Pickup : </span>
            <span className="font-bold">{formatAMPM(new Date(+rideInfo.waypointsEta[0].eta))}</span>
          </div>
          <div className="mr-4 flex flex-col">
            <span>ETA : </span>
            <span className="font-bold">{formatAMPM(new Date(+rideInfo.waypointsEta[1].eta))}</span>
          </div>
          <div className="mr-4 flex flex-col">
            <span>Contribution : </span>
            <span className="font-bold">
              {rideInfo && `MUR ${rideInfo.pricePerSeat.toFixed(2)}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
