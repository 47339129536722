import React, {
  useRef,
  useEffect, // useState
} from 'react';
import { useHistory } from 'react-router-dom';

import { Search, TouchActions, BecomeDriver, Button, MinSpinner } from '../';
import DatePickerComponentWithStyles from '../DatePicker';
import TimePickerComponentWithStyles from '../TimePicker';
import { ROLES, ROUTES } from '../../shared/constants';

import rider_x3 from '../../assets/images/mapFindForm/rider-one-colour.svg';
import driver_x3 from '../../assets/images/mapFindForm/driver.svg';
import driverWhite_x3 from '../../assets/images/mapFindForm/driver-white.svg';
import riderBlack from '../../assets/images/mapFindForm/rider.svg';
import pointFrom_x3 from '../../assets/images/mapFindForm/edit-location-black-18-dp.svg';
import pointTo_x3 from '../../assets/images/mapFindForm/location-on-black-18-dp.svg';
import calender_x3 from '../../assets/images/mapFindForm/calender.svg';
import group_x3 from '../../assets/images/mapFindForm/group-16.svg';
import time_x3 from '../../assets/images/mapFindForm/timw.svg';
import LocationImg from '../../assets/images/mapFindForm/my-location-24-px-1.png';
import delText from '../../assets/images/icon-close-filled-black.svg';

export const MapFindForm = ({
  setCenterCoords,
  // setTargetCoords,
  setResponse,
  setOrigin,
  setDestination,
  setMarkers,
  setMarkerStart,
  setMarkerEnd,
  setDurationMarker,
  setDistanceMarker,
  setFuelConsumptionMarker,
  setCo2Marker,
  setWayPoints3,
  setAddPickupPoint,
  destination,
  origin,
  role,
  addRide,
  setStartRequests,
  setSeatsNumber,
  seatsNumber,
  startPointDate,
  setStartPointDate,
  setIsGetPickUpPoint,
  setIsGetDropOffPoint,
  setIsFlexiTime,
  isFlexiTime,
  setPickUpPointTime,
  getYourLocation,
  setShowScheduledRideDetails,
  setChooseScheduleRide,
  chooseScheduleRide,
  setShowForm,
  showForm,
  setMapHeight,
  geoLocationMarkerLoading,
  setGridAllRidesShow,
  // FindRide
}) => {
  // const [showForm, setShowForm] = useState(true);
  let history = useHistory();
  const originInputRef = useRef(null);
  const destInputRef = useRef(null);

  useEffect(() => {
    return () => setShowForm(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = () => {
    if (origin && destination) return true;
  };

  return (
    <TouchActions
      setShowForm={setShowForm}
      setMapHeight={setMapHeight}
      setShowScheduledRideDetails={setShowScheduledRideDetails}
    >
      <div
        id="findFormID"
        className={
          showForm
            ? 'absolute w-full h-104 right-0 bottom-0 rounded-tl-3xl rounded-tr-3xl bg-white pt-1 px-3.5 pb-6 font-sans text-base font-bold'
            : 'absolute w-full h-104 right-0 -bottom-80 rounded-tl-3xl rounded-tr-3xl bg-white pt-1 px-3.5 pb-6 font-sans text-base font-bold'
        }
        onClick={() => {
          chooseScheduleRide && setChooseScheduleRide(false);
          chooseScheduleRide && setShowForm(true);
          chooseScheduleRide && setShowScheduledRideDetails(true);
        }}
        style={{ boxShadow: '0 -10px 20px rgba(0, 0, 0, 0.2)', transition: '0.5s' }}
      >
        <div
          className="w-20 h-1 bg-gray-200 m-auto rounded-full"
          onClick={() => {
            // setShowForm((current) => !current);
            // setMapHeight((current) => (current === '100%' ? '70%' : '100%'));
          }}
        >
          {' '}
        </div>
        <ul className="flex justify-between mb-6 pt-1.5">
          <li
            onClick={() => history.push(ROUTES.FIND_RIDE)}
            className={
              !addRide
                ? 'w-36 flex justify-between py-2 px-4 bg-nuale_turquoise text-white rounded-3xl cursor-pointer'
                : 'w-36 flex justify-between py-2 px-4 cursor-pointer'
            }
          >
            <picture>
              <img className="w-5" src={!addRide ? rider_x3 : riderBlack} alt="Rider icon" />
            </picture>
            <span>Find a ride</span>
          </li>
          <li
            onClick={() => history.push(ROUTES.ADD_RIDE)}
            className={
              addRide
                ? 'w-36 flex justify-between py-2 px-4 rounded-3xl bg-nuale_turquoise text-white cursor-pointer'
                : 'w-36 flex justify-between py-2 px-4 rounded-3xl cursor-pointer'
            }
          >
            <picture>
              <img className="w-5" src={addRide ? driverWhite_x3 : driver_x3} alt="Driver icon" />
            </picture>
            <span>Add a ride</span>
          </li>
        </ul>
        {(addRide && role === ROLES.driver) ||
        (!addRide && role === ROLES.rider) ||
        (!addRide && !role) ? (
          <form className="flex justify-between flex-wrap">
            <div className="w-full h-12 flex items-center mb-4 relative">
              <picture className="w-8">
                <img className="w-6" src={pointFrom_x3} alt="Rider icon" />
              </picture>
              <Search
                setCenterCoords={setCenterCoords}
                // setMarkers={setMarkers}
                setMarkerStart={setMarkerStart}
                setMarkerEnd={setMarkerEnd}
                setOrigin={setOrigin}
                setStartRequests={setStartRequests}
              >
                <div
                  className="w-full"
                  onClick={() => {
                    setIsGetPickUpPoint && setIsGetPickUpPoint(true);
                  }}
                >
                  <input
                    className="w-full h-12 bg-gray-100 py-3 px-4 text-base font-medium font-sans"
                    placeholder="Your location"
                    defaultValue={origin.name || ''}
                    disabled={!!setIsGetPickUpPoint}
                    type="text"
                    ref={originInputRef}
                  />
                  <div className="absolute top-0 right-0 h-full flex">
                    <div
                      className=" w-10 cursor-pointer flex items-center justify-center z-20 bg-gray-100"
                      onClick={(event) => {
                        // event.stopPropagation();
                        getYourLocation();
                        // getYourLocation(-0.15);
                      }}
                    >
                      {!geoLocationMarkerLoading ? (
                        <img className="w-4 cursor-pointer" src={LocationImg} alt="Location img" />
                      ) : (
                        <MinSpinner color={'gray'} />
                      )}
                    </div>
                    <div
                      className={
                        origin
                          ? 'h-full w-6 cursor-pointer flex items-center justify-center z-20'
                          : 'hidden'
                      }
                      onClick={() => {
                        setOrigin('');
                        setMarkerStart();
                        setWayPoints3 && setWayPoints3([]);
                        setResponse(null);
                        setDistanceMarker();
                        setDurationMarker();
                        originInputRef.current.value = '';
                        setFuelConsumptionMarker && setFuelConsumptionMarker();
                        setCo2Marker && setCo2Marker();
                        // setMarkers((current) => {
                        //   if (current.length > 1) {
                        //     return [current[1]];
                        //   }
                        //   return [];
                        // });
                      }}
                    >
                      <img src={delText} alt="delete img" />
                    </div>
                  </div>
                </div>
              </Search>
              <div className="absolute top-11 left-2.5 h-6 flex flex-col justify-between">
                <span className="w-0.5 h-0.5 bg-black rounded-full"></span>
                <span className="w-0.5 h-0.5 bg-black rounded-full"></span>
                <span className="w-0.5 h-0.5 bg-black rounded-full"></span>
              </div>
            </div>
            <div className="w-full h-12 flex items-center mb-4">
              <picture className="w-8">
                <img className="w-6" src={pointTo_x3} alt="Rider icon" />
              </picture>
              <Search
                setCenterCoords={setCenterCoords}
                // setMarkers={setMarkers}
                setMarkerStart={setMarkerStart}
                setMarkerEnd={setMarkerEnd}
                setDestination={setDestination}
                setStartRequests={setStartRequests}
              >
                <div
                  className="w-full"
                  onClick={() => {
                    setIsGetDropOffPoint && setIsGetDropOffPoint(true);
                  }}
                >
                  <input
                    className="w-full bg-gray-100 py-3 px-4 text-base font-medium font-sans"
                    placeholder="Destination (Drop off point)"
                    defaultValue={destination.name || ''}
                    disabled={!!setIsGetDropOffPoint}
                    type="text"
                    ref={destInputRef}
                  />
                  <div
                    className={
                      destination
                        ? 'absolute top-0 right-0 h-full w-6 cursor-pointer flex items-center justify-center z-20'
                        : 'hidden'
                    }
                    onClick={() => {
                      setDestination('');
                      setMarkerEnd();
                      setWayPoints3 && setWayPoints3([]);
                      destInputRef.current.value = '';
                      setResponse(null);
                      setDistanceMarker();
                      setDurationMarker();
                      setFuelConsumptionMarker && setFuelConsumptionMarker();
                      setCo2Marker && setCo2Marker();
                      // setMarkers((current) => {
                      //   if (current.length > 1) {
                      //     return [current[0]];
                      //   }
                      //   return [];
                      // });
                    }}
                  >
                    <img src={delText} alt="delete img" />
                  </div>
                </div>
              </Search>
            </div>
            <label className="w-40 h-12 flex items-center mb-4">
              <picture className="w-10">
                <img className="w-5" src={calender_x3} alt="Rider icon" />
              </picture>
              <DatePickerComponentWithStyles
                startPointDate={startPointDate}
                setStartPointDate={setStartPointDate}
                setPickUpPointTime={setPickUpPointTime}
              />
            </label>
            <label className="w-40 h-12 flex items-center mb-4">
              <picture className="w-10">
                <img className="w-5" src={time_x3} alt="Rider icon" />
              </picture>
              <TimePickerComponentWithStyles
                styles={
                  'w-full h-12 flex items-center justify-center bg-gray-100 py-3 px-4 text-base font-bold font-sans'
                }
                setPointTime={setStartPointDate}
                pointTime={startPointDate}
                setPickUpPointTime={setPickUpPointTime}
              />
            </label>
            <label className="w-40 flex items-center mb-4">
              <picture className="w-10">
                <img className="w-5" src={group_x3} alt="Rider icon" />
              </picture>
              <select
                className="w-full h-12 bg-gray-100 py-3 px-4 text-base font-medium font-sans"
                name="group"
                id=""
                onChange={(event) => setSeatsNumber(event.target.value)}
                value={seatsNumber}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </label>
            {/*{!addRide && (*/}
            {/*<label className="relative w-40 h-12 flex items-center pl-1 checkbox-container">*/}
            {/*<span className="mr-4">Flexi time</span>*/}
            {/*<input*/}
            {/*type="checkbox"*/}
            {/*checked={isFlexiTime}*/}
            {/*onChange={() => setIsFlexiTime((current) => !current)}*/}
            {/*/>*/}
            {/*<span className="checkmark"></span>*/}
            {/*</label>*/}
            {/*)}*/}
            {!addRide && (
              <div
                className={
                  !(origin || destination)
                    ? 'w-40 h-12 flex items-center justify-center bg-nuale_turquoise text-white'
                    : 'hidden'
                }
                onClick={() => history.push(ROUTES.ALL_RIDES_SCHEDULES)}
                // onClick={()=> setGridAllRidesShow(true)}
              >
                List all rides
              </div>
            )}
            <Button
              classProp={
                !isDisabled()
                  ? 'text-center mt-6 px-4 text-white rounded-3xl bg-gray-400 text-base font-bold font-sans'
                  : 'text-center mt-6 px-4 text-white rounded-3xl bg-black text-base font-bold font-sans'
              }
              clickFuncProp={() => {
                setAddPickupPoint(true);
                // setMapHeight("100%")
              }}
              isDisableProp={!isDisabled() ? 'disabled' : ''}
              contentProp={!addRide ? 'Find available rides' : 'Add new ride'}
            />
          </form>
        ) : (
          <BecomeDriver />
        )}
      </div>
    </TouchActions>
  );
};
