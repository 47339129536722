import gql from 'graphql-tag';

export const RIDE_STATUS_CHANGED_SUBSCRIPTION = gql`
  subscription rideStatusChanged($ridesList: [ID!]!) {
    rideStatusChanged(ridesList: $ridesList) {
      id
      status
    }
  }
`;
