import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { useWebSocket } from '../../components/contexts/WebSocketContext';
import { ROUTES } from '../../shared/constants';

import { REMOVE_NOTIFICATION } from '../../shared/gqlQueries/removeNotificationById';
import { UPDATE_NOTIFICATION } from '../../shared/gqlQueries/updateNotification';
import { NotificationItem } from '../../components';

import iconLeft from '../../assets/images/arrow-left-solid.svg';

export function Notifications() {
  const history = useHistory();
  const {
    setNotificationsNotReadedGlobal,
    // notificationsNotReadedGlobal,
    setNotificationsArr,
    notificationsArr,
    notificationsData,
    getMyNotificationsFunc,
  } = useWebSocket();

  const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
    errorPolicy: 'all',
    onCompleted: (req) => {
      console.log('[updateNotification]', req);
      setNotificationsNotReadedGlobal((current) =>
        current.filter((el) => el.id !== req.updateNotification.id),
      );
      // getMyNotificationsFunc();
    },
    onError: (error) => {
      console.log('[updateNotification] error updateNotification', error);
    },
  });

  const [removeNotification] = useMutation(REMOVE_NOTIFICATION, {
    errorPolicy: 'all',
    onCompleted: (req) => {
      console.log('[removeNotification]', req);
      setNotificationsArr((current) => current.filter((el) => el.id !== req.removeNotification.id));
      // getMyNotificationsFunc();
    },
    onError: (error) => {
      console.log('[removeNotification] error removeNotification', error);
    },
  });

  function showNotificationItem(noteItem) {
    // console.log('noteItem', noteItem);
    let updatedNotificationsArr = notificationsArr.map((el) => {
      if (el.id === noteItem.id) {
        if (!el.read) {
          updateNotification({
            variables: {
              id: noteItem.id,
              updateNotificationInput: {
                read: true,
              },
            },
          });
        }
        return { ...el, read: true, statusShow: !el.statusShow };
      } else {
        return el;
      }
    });
    setNotificationsArr([...updatedNotificationsArr]);
  }

  function removeNotificationFunc(id) {
    removeNotification({
      variables: {
        id,
      },
    });
  }

  useEffect(() => {
    getMyNotificationsFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (loading) return null;
  // if (error) return `Error! ${error}`;

  return (
    <div className="container mx-auto h-full box-border relative overflow-x-hidden">
      {/*<Header setShowMen={setShowMenu} />*/}
      <div className="fixed top-0 left-0 w-full flex justify-start items-center bg-black p-4">
        <button onClick={() => history.push(ROUTES.FIND_RIDE)}>
          <img src={iconLeft} alt="icon back" className="w-4" />
        </button>
        <span className="text-lg font-sans font-medium ml-4 text-white">Notifications</span>
      </div>
      <ul className="pt-14 mt-1">
        {notificationsArr.length ? (
          notificationsArr.map((el, index) => (
            <NotificationItem
              key={'notificationItem' + index}
              notificationInfo={el}
              removeNotificationFunc={removeNotificationFunc}
              showNotificationItem={showNotificationItem}
            />
          ))
        ) : (
          <li className="p-4 text-nuale_neon_blue">
            {/*Currently, no notifications*/}
            {notificationsData.loading ? 'Loading...' : 'Currently, no notifications'}
          </li>
        )}
      </ul>
    </div>
  );
}
