import React, { useState, useEffect } from 'react';
import {
  // useMutation,
  useLazyQuery,
} from '@apollo/client';

import { FAQ_QUERY } from '../../shared/gqlQueries/faq';
import arrowDown from '../../assets/images/chevron-down-solid.svg';

export const FaqComponent = () => {
  const [faqArr, setFaqArr] = useState([]);

  useEffect(() => {
    getFaq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFaqArrforShow = (arr) => {
    let sortArrByCategory = [...arr].sort((a, b) => a.category.name - b.category.name);

    let driverFaqArr = [];
    let riderFaqArr = [];
    let categoryObj = {};
    let category = sortArrByCategory[0].category.name;
    let groupArrByCategory = [];
    sortArrByCategory.forEach((el) => {
      if (el.category.name === category) {
        if (el.faqTarget === 'DRIVER') {
          driverFaqArr = [
            ...driverFaqArr,
            { status: false, id: el.id, question: el.question, answer: el.answer },
          ];
        }
        if (el.faqTarget === 'RIDER') {
          riderFaqArr = [
            ...riderFaqArr,
            { status: false, id: el.id, question: el.question, answer: el.answer },
          ];
        }
      }
      if (
        el.category.name !== category ||
        sortArrByCategory.length - 1 === sortArrByCategory.indexOf(el)
      ) {
        categoryObj = {
          category: {
            name: category,
          },
          faqs: {
            driver: driverFaqArr,
            rider: riderFaqArr,
          },
        };
        groupArrByCategory = [...groupArrByCategory, categoryObj];
        driverFaqArr = [];
        riderFaqArr = [];
        categoryObj = {};
        category = el.category.name;
      }
    });
    return groupArrByCategory;
  };

  const [getFaq, { data, loading }] = useLazyQuery(FAQ_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[Faq] getFaq', data.faqs);
      // console.log('[Faq] update getFaq', updateFaqArrforShow(data.faqs));
      setFaqArr(updateFaqArrforShow(data.faqs));
    },
    onError: (error) => {
      console.log('[Faq] error getFaq', error);
    },
  });

  const showAnswer = (event) => {
    let targetQuestion = event.target;
    let someFaqArrName = targetQuestion.dataset.name;
    let updateItem;
    let updateFaqArr = faqArr.map((el) => {
      if (el.category.name === targetQuestion.dataset.category) {
        updateItem = el.faqs[someFaqArrName].map((el2) => {
          if (el2.id === targetQuestion.id) {
            let newObj = { ...el2, status: !el2.status };
            return newObj;
          }
          return el2;
        });
      }
      return { ...el, faqs: { ...el.faqs, [someFaqArrName]: updateItem } };
    });
    setFaqArr([...updateFaqArr]);
  };

  return (
    <div className="bg-gray-100 mt-16">
      <div className="px-2 bg-white">
        <h1 className="px-3 text-3xl font-bold  mb-9">
          <span>Frequently</span> <br />
          <span>Asked Questions</span>
        </h1>
        {faqArr.length ? (
          faqArr.map((el, index) => (
            <div className="text-base" key={'category' + index}>
              <h2 className="mx-3 pb-2 mb-7 font-bold border-b-2 border-gray-400">
                {el.category.name}
              </h2>
              <h3 className="px-3 font-bold text-nuale_neon_blue">I'm a Driver</h3>
              <ul className="mb-4 font-medium">
                {el.faqs.driver.length &&
                  el.faqs.driver.map((driverFaqItem, indexDriver) => (
                    <li className="text-sm" key={'driverFaqItem' + indexDriver}>
                      <p className="px-3 border-b-2 border-gray-200 py-4 flex items-center justify-between">
                        <span>{driverFaqItem.question}</span>
                        <img
                          className={
                            driverFaqItem.status
                              ? 'w-3 transform rotate-180 duration-75'
                              : 'w-3 duration-75'
                          }
                          src={arrowDown}
                          alt="chevron img"
                          id={driverFaqItem.id}
                          data-name={'driver'}
                          data-category={el.category.name}
                          onClick={(event) => showAnswer(event)}
                        />
                      </p>
                      <p
                        className={
                          driverFaqItem.status
                            ? 'px-3 py-4 faqItem h-full duration-75'
                            : 'px-3 overflow-hidden h-0 duration-75'
                        }
                        dangerouslySetInnerHTML={{ __html: driverFaqItem.answer }}
                      />
                    </li>
                  ))}
              </ul>
              <h3 className="px-3 text-nuale_turquoise font-bold">I'm a Rider</h3>
              <ul className="font-medium">
                {el.faqs.driver.length &&
                  el.faqs.rider.map((riderFaqItem, indexRider) => (
                    <li className="text-sm" key={'riderFaqItem' + indexRider}>
                      <p
                        className="px-3 border-b-2 border-gray-200 py-4 flex items-center justify-between"
                        id={riderFaqItem.id}
                        data-name={'rider'}
                        data-category={el.category.name}
                        onClick={(event) => showAnswer(event)}
                      >
                        <span>{riderFaqItem.question}</span>
                        <img
                          className={
                            riderFaqItem.status
                              ? 'w-3 transform rotate-180 duration-75'
                              : 'w-3 duration-75'
                          }
                          src={arrowDown}
                          alt="chevron img"
                          id={riderFaqItem.id}
                          data-name={'rider'}
                          data-category={el.category.name}
                          onClick={(event) => showAnswer(event)}
                        />
                      </p>
                      <p
                        className={
                          riderFaqItem.status
                            ? 'px-3 py-4 faqItem h-full duration-75'
                            : 'px-3 overflow-hidden h-0 duration-75'
                        }
                        dangerouslySetInnerHTML={{ __html: riderFaqItem.answer }}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          ))
        ) : (
          <div>{loading ? 'Loading...' : ''}</div>
        )}
      </div>
    </div>
  );
};
