import React from 'react';

import profile_x3 from '../../assets/images/menu/profile@3x.png';

export const PassengersGrid = ({ passengersArr }) => {
  // console.log('passengersArr', passengersArr);
  // console.log('passengersArr.slice(1)', passengersArr.slice(1));

  return (
    <div>
      <h2 className="text-xs text-nuale_turquoise mb-1">Riders</h2>
      <ul className="mb-1">
        <li>
          <div className="flex items-center ">
            <div
              className="bg-white min-w-9 w-9 h-9 rounded-full border mr-3 mb-1"
              style={{
                backgroundImage: `url(${passengersArr[0].passenger.userImg || profile_x3})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            >
              {' '}
            </div>
            {passengersArr.slice(1).length ? (
              passengersArr.slice(1).map((pas, index) => (
                <li key={'passengerKey' + index}>
                  <div className="bg-nuale_neon_blue min-w-5 w-5 h-5 rounded-full mr-3 text-white text-center text-sm">
                    {index + 1}
                  </div>
                </li>
              ))
            ) : (
              <span></span>
            )}
          </div>
          <span className="font-bold text-sm">{`${passengersArr[0].passenger.firstName} ${passengersArr[0].passenger.lastName}`}</span>
        </li>
      </ul>
    </div>
  );
};
