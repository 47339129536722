import React, { useState, useRef } from 'react';

import Slider from 'infinite-react-carousel';

import arrowLeft from '../../assets/images/arrow-left-black.svg';
import arrowRight from '../../assets/images/arrow-right-black.svg';
import slider5img from '../../assets/images/slider/rectangle5@3x.png';
import slider4img from '../../assets/images/slider/rectangle4@3x.png';
import slider3img from '../../assets/images/slider/rectangle3@3x.png';
import slider2img from '../../assets/images/slider/rectangle2@3x.png';
import slider1img from '../../assets/images/slider/rectangle@3x.png';

export const SimpleSlider = ({ nextStep }) => {
  const [index, setIndex] = useState(0);
  const prevArrowBtn = useRef(null);
  const nextArrowBtn = useRef(null);

  return (
    <div>
      <Slider
        dots
        prevArrow={<button className="prevArrow" ref={prevArrowBtn}></button>}
        nextArrow={<button className="nextArrow" ref={nextArrowBtn}></button>}
        beforeChange={(oldIndex, newIndex) => {
          if (newIndex !== undefined) {
            setIndex(newIndex);
          }
        }}
        afterChange={(newIndex) => {
          if (index !== newIndex) {
            setIndex(newIndex);
          }
        }}
      >
        <div className="pt-6 px-6">
          {/*<div className="max-w-xs w-full h-80 bg-gray-100 m-auto mb-8 bg-slider1Image bg-no-repeat bg-cover bg-center"></div>*/}
          <div className="max-w-16rem w-full bg-gray-100 m-auto mb-8"
               style={{
                 backgroundImage: `url(${slider1img})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 width: 235,
                 height: 235
               }}
          >
            {/*<img src={slider1img} alt="slider1 img" />*/}
          </div>
          <h1 className="text-center font-bold text-base mb-4">DOWNLOAD APP </h1>
          <p className="font-medium text-base mb-32">
            Easy to use and reliable, the app was built for drivers & riders, with them in mind.
          </p>
        </div>
        <div className="pt-6 px-6">
          {/*<div className="max-w-xs w-full h-80 bg-gray-100 m-auto mb-8 bg-slider2Image bg-no-repeat bg-cover"></div>*/}
          <div className="max-w-16rem w-full bg-gray-100 m-auto mb-8"
               style={{
                 backgroundImage: `url(${slider2img})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 width: 235,
                 height: 235
               }}
          >
            {/*<img src={slider2img} alt="slider2 img" />*/}
          </div>
          <h1 className="text-center font-bold text-base mb-4">SET JOURNEY </h1>
          <p className="font-medium text-base mb-32">
            Opportunity is everywhere. Fit driving around what matters most.Drive & Ride at any time
            and on any day of the week.
          </p>
        </div>
        <div className="pt-6 px-6">
          {/*<div className="max-w-xs w-full h-80 bg-gray-100 m-auto mb-8 bg-slider3Image bg-no-repeat bg-cover"></div>*/}
          <div className="max-w-16rem w-full bg-gray-100 m-auto mb-8"
               style={{
                 backgroundImage: `url(${slider3img})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 width: 235,
                 height: 235
               }}
          >
            {/*<img src={slider3img} alt="slider3 img" />*/}
          </div>
          <h1 className="text-center font-bold text-base mb-4">CHOOSE PICKUP LOCATION </h1>
          <p className="font-medium text-base mb-32">
            Just say where you’re going, where you’d like to pick up and drop off passengers
            andwhen. Our suggestions for popular places help make things easier for everyone!
          </p>
        </div>
        <div className="pt-6 px-6">
          {/*<div className="max-w-xs w-full h-80 bg-gray-100 m-auto mb-8 bg-slider4Image bg-no-repeat bg-cover"></div>*/}
          <div className="max-w-16rem w-full bg-gray-100 m-auto mb-8"
               style={{
                 backgroundImage: `url(${slider4img})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 width: 235,
                 height: 235
               }}
          >
            {/*<img src={slider4img} alt="slider4 img" />*/}
          </div>
          <h1 className="text-center font-bold text-base mb-4">SELECT DRIVER </h1>
          <p className="font-medium text-base mb-32">
            Profiles are moderated. All profiles, photos, ratings, rides and ride comments
            aremoderated to maintain trust and respect in the community. Together, stronger!
          </p>
        </div>
        <div className="pt-6 px-6">
          {/*<div className="max-w-xs w-full h-80 bg-gray-100 m-auto mb-8 bg-slider5Image bg-no-repeat bg-cover"></div>*/}
          <div className="max-w-16rem w-full bg-gray-100 m-auto mb-8"
               style={{
                 backgroundImage: `url(${slider5img})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 width: 235,
                 height: 235
               }}
          >
            {/*<img src={slider5img} alt="slider5 img" />*/}
          </div>
          <h1 className="text-center font-bold text-base mb-4">ENJOY YOUR RIDE </h1>
          <p className="font-medium text-base mb-32">
            You’re making a bit step towards making transportation greener.
          </p>
        </div>
        <div className="pt-6 px-6">
          {/*<div className="max-w-xs w-full h-80 bg-gray-100 m-auto mb-8 bg-slider5Image bg-no-repeat bg-contain bg-center"></div>*/}
          <div className="max-w-16rem w-full bg-gray-100 m-auto mb-8"
               style={{
                 backgroundImage: `url(${slider5img})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 width: 235,
                 height: 235
               }}
          >
            {/*<img src={slider5img} alt="slider6 img" />*/}
          </div>
          <h1 className="text-center font-bold text-base mb-4">
            HEALTH & SAFETY REQUIREMENTS FOR RIDESHARE{' '}
          </h1>
          <p className="font-medium text-base mb-32">
            Extra health safety precautions are necessary to help protect each other from
            COVID-19.We are advising all riders and drivers to follow our health safety
            recommendations. Youcan find the most current information in our Newsroom.
          </p>
        </div>
      </Slider>
      <div className="mt-8 flex justify-beetwen items-center px-4 pb-4">
        {index !== 0 ? (
          <img
            className="w-4 h-4"
            src={arrowLeft}
            onClick={() => prevArrowBtn.current.click()}
            alt="arrow left icon"
          />
        ) : (
          <span className="w-4"></span>
        )}
        {index !== 5 && (
          <div
            className="w-24 mx-auto text-base font-sans font-bold text-black bg-white rounded-3xl text-center py-1.5 border-2 border-solid border-black cursor-pointer"
            onClick={() => nextStep()}
          >
            Skip
          </div>
        )}
        {index !== 5 ? (
          <img
            className="w-4 h-4"
            src={arrowRight}
            onClick={() => nextArrowBtn.current.click()}
            alt="arrow right icon"
          />
        ) : (
          <div
            className="w-24 ml-auto text-base font-sans font-bold text-white bg-black rounded-3xl text-center py-1.5 border-2 border-solid border-black cursor-pointer"
            onClick={() => nextStep()}
          >
            Start now
          </div>
        )}
      </div>
    </div>
  );
};
