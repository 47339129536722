import { gql } from '@apollo/client';

export const CREATE_RIDE_REQUEST = gql`
  mutation createRideRequest($input: CreateRideRequestInput!) {
    createRideRequest(createRideRequestInput: $input) {
      id
      passenger {
        id
        firstName
      }
      ride {
        id
      }
      requestedSeatsCount
      status
      message
    }
  }
`;
