import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import {
  withStyles as YearPickerComponentWithStyles,
  createMuiTheme,
} from '@material-ui/core/styles';

const classes = (theme) => ({
  input: {
    'font-weight': '500',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00CCBF',
      padding: 0,
    },
  },
});

function YearPickerComponent({ name, id, placeholder, className, value, onChange }) {
  // const [selectedDate, setSelectedDate] = React.useState(new Date());

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <DatePicker
          className={className || 'year-picker-edit'}
          name={name}
          id={id}
          views={['year']}
          value={value}
          minDate={new Date(2014, 0, 1, 0, 0, 0)}
          maxDate={new Date()}
          onChange={onChange}
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          placeholder={placeholder}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default YearPickerComponentWithStyles(classes)(YearPickerComponent);
