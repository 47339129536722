import gql from 'graphql-tag';

export const WAYPOINTS_QUERY = gql`
  query wayPoints {
    waypoints {
      id
      location {
        lat
        lng
      }
      name
      placeId
    }
  }
`;
