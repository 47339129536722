import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  Polyline,
  DistanceMatrixService,
} from '@react-google-maps/api';

import { Spinner } from '../../components';
import { WAYPOINTS_QUERY } from '../../shared/gqlQueries/wayPoints';

import '@reach/combobox/styles.css';
import mapStyles from './mapStyles';

import circleBlue from '../../assets/images/map/circle-blue.svg';
import circleFiolet from '../../assets/images/map/circle-fiolet.svg';
import circleSolid from '../../assets/images/map/markerPolylineGreen.svg';
import circleSolidBlue from '../../assets/images/map/markerPolylineBlue.svg';
import circleEndBlue from '../../assets/images/map/sircleBlueWithWhiteRing.svg';
import circleStartBlack from '../../assets/images/map/sircleBlackWithWhiteRing.svg';
import circleMiddlePoint from '../../assets/images/map/sircleBlueWithWhiteRingAndGreenBorder.svg';
import locationImg from '../../assets/images/mapFindForm/my-location-24-px-1.png';

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: false,
};

export function Map({
  centerCoords,
  markers,
  markerStart,
  markerEnd,
  origin,
  destination,
  setDestination,
  setOrigin,
  setMarkers,
  setMarkerStart,
  setMarkerEnd,
  isLoaded,
  loadError,
  fuelConsumption,
  setWayPoints3,
  wayPoints3,
  setWayPoints4,
  wayPoints4,
  setPickUpPoint,
  setDropOffPoint,
  startRequests,
  setStartRequests,
  setTotalDuration,
  isGetPickUpPoint,
  isGetDropOffPoint,
  findRide,
  isMarkersClickable,
  setDistanceMarker,
  distanceMarker,
  setPickUpPointTime,
  setIsGetPickUpPoint,
  setIsGetDropOffPoint,
  selectRouteIndex,
  setSelectRouteIndex,
  selectedRide,
  fuelConsumptionMarker,
  setFuelConsumptionMarker,
  co2Marker,
  setCo2Marker,
  geoLocationMarker,
  mapHeight,
  // isScheduleRide,
  setTestRideRouteGlobal,
  response,
  setResponse,
  durationMarker,
  setDurationMarker,
}) {
  // const [response, setResponse] = useState(null);
  const [wayPoints, setWayPoints] = useState([]);
  // const [durationMarker, setDurationMarker] = useState([]);
  const [map, setMap] = useState(null);
  const [startGetDistance, setStartGetDistance] = useState(false);
  const mapContainerStyle = {
    height: `${mapHeight}`,
    width: '100%',
    transition: '0.1s',
  };

  const [getWayPointsFunc, wayPointsData] = useLazyQuery(WAYPOINTS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      if (wayPointsData.data.waypoints && wayPointsData.data.waypoints.length) {
        setWayPoints(wayPointsData.data.waypoints);
      }
    },
    onError: (error) => {
      console.log('[DriverInfo] error getCarsInfo', error);
    },
  });

  useEffect(() => {
    getWayPointsFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isGetPickUpPoint && origin && getNearestPointsForGetPickUpPoint(origin);
    isGetDropOffPoint && destination && getNearestPointsForGetPickUpPoint(destination);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin, destination, geoLocationMarker]);

  function createMarkerOptions(response, type, key, position, fuelConsumption = 1) {
    return response.routes.map((route) => ({
      lat: route.overview_path[Math.floor(route.overview_path.length * position)].lat(),
      lng: route.overview_path[Math.floor(route.overview_path.length * position)].lng(),
      [key]:
        route.legs.length > 1
          ? route.legs.reduce((ac, el) => {
              return el[type].value + ac;
            }, 0) * fuelConsumption
          : route.legs[0][type].value * fuelConsumption,
    }));
  }

  function getNearestPoints(response, indexText = 0) {
    let coof = 0.025;
    let arr = [];
    let lastArr = response.routes[indexText].overview_path;
    let wayPointIndex = 1;

    lastArr.forEach((el) => {
      wayPoints.forEach((el2, index) => {
        if (
          Math.abs(el.lat() - el2.location.lat) < coof &&
          Math.abs(el.lng() - el2.location.lng) < coof
        ) {
          if (!arr.find((marker) => marker.index === index)) {
            arr = [...arr, { ...el2, index, wayPointIndex: wayPointIndex++ }];
          }
        }
      });
    });

    if (arr.length > 25) {
      arr = [...arr.slice(0, 13), ...arr.slice(-12)];
    }
    setWayPoints3([]);
    setStartGetDistance(true);
    setWayPoints3(arr);
  }

  function getNearestPointsForGetPickUpPoint(targetStartPoint) {
    let coof = 0.03;
    let arr = [];
    let lat, lng;

    // for add-ride
    if (targetStartPoint.location) {
      lat = targetStartPoint.location.lat;
      lng = targetStartPoint.location.lng;
    }

    // for find-ride
    if (targetStartPoint.latLng) {
      lat = targetStartPoint.latLng.lat();
      lng = targetStartPoint.latLng.lng();
    }

    // for get location
    if (targetStartPoint.lat && targetStartPoint.lng) {
      lat = targetStartPoint.lat;
      lng = targetStartPoint.lng;
    }

    let wayPointIndex = 1;

    wayPoints.forEach((el2, index) => {
      if (Math.abs(lat - el2.location.lat) < coof && Math.abs(lng - el2.location.lng) < coof) {
        if (!arr.find((marker) => marker.index === index)) {
          arr = [...arr, { ...el2, index, wayPointIndex: wayPointIndex++ }];
        }
      }
    });

    if (arr.length > 25) {
      arr = [...arr.slice(0, 13), ...arr.slice(-12)];
    }
    setWayPoints3([]);
    (targetStartPoint.location || (targetStartPoint.lat && targetStartPoint.lng)) &&
      setStartGetDistance(true);
    setWayPoints3(arr);
  }

  function getDistanceToWayPoints(response, status) {
    if (response) {
      if (status === 'OK') {
        setStartGetDistance(false);
        let distanceArr = response.rows[0].elements;
        let newArr = wayPoints3.map((el, index) => {
          let newObj = { ...el };
          newObj.distance = distanceArr[index].distance.value;
          newObj.duration = distanceArr[index].duration.value;
          return newObj;
        });

        setWayPoints3([...newArr]);
      }
    }
  }

  function directionsCallback(response, status) {
    if (response !== null) {
      if (status === 'OK') {
        setStartRequests(false);
        wayPoints3.length && setWayPoints3([]);
        setResponse(response);
        setTestRideRouteGlobal && setTestRideRouteGlobal(response);
        setTotalDuration &&
          setTotalDuration(
            createMarkerOptions(response, 'duration', 'time', findRide ? 2 / 3 : 4 / 5)[0].time,
          );
        setDurationMarker(
          createMarkerOptions(response, 'duration', 'time', findRide ? 2 / 3 : 4 / 5),
        );
        setDistanceMarker(
          createMarkerOptions(response, 'distance', 'dis', findRide ? 1 / 3 : 3 / 5),
        );
        !findRide &&
          setFuelConsumptionMarker(
            createMarkerOptions(response, 'distance', 'fuel', 2 / 5, fuelConsumption),
          );
        selectedRide &&
          setCo2Marker(
            createMarkerOptions(
              response,
              'distance',
              'co2',
              findRide ? 1 / 2 : 1 / 5,
              (fuelConsumption * 2500) / 100,
            ),
          );
        getNearestPoints(response);
      }
    }
    // setDelayRequests(true);
    // setTimeout(() => setDelayRequests(false), 500)
  }

  const mapRef = useRef();

  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map;
      setMap(map);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map],
  );

  if (loadError) return 'Error';
  if (!isLoaded) return <Spinner />;

  return (
    <div className="h-screen w-full ">
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={centerCoords}
        gestureHandling="greedy"
        options={options}
        onClick={(event) => {
          // console.log(event);
          isMarkersClickable && setStartGetDistance(true);
          isMarkersClickable && getNearestPointsForGetPickUpPoint(event);
        }}
        onLoad={onMapLoad}
        // clickableIcons={isMarkersClickable}
        // onZoomChanged={handleZoomChange}
      >
        {/*{markers.map((marker, index) => (*/}
        {/*<Marker*/}
        {/*key={`marker${index + 1}_${marker.location.lat}-${marker.location.lng}`}*/}
        {/*position={{ lat: marker.location.lat, lng: marker.location.lng }}*/}
        {/*icon={{*/}
        {/*url: `${index === 0 ? circleStartBlack : circleEndBlue}`,*/}
        {/*anchor: new window.google.maps.Point(10, 10),*/}
        {/*scaledSize: new window.google.maps.Size(20, 20),*/}
        {/*}}*/}
        {/*/>*/}
        {/*))}*/}
        {markerStart && (
          <Marker
            position={{ lat: markerStart.location.lat, lng: markerStart.location.lng }}
            icon={{
              url: `${circleStartBlack}`,
              anchor: new window.google.maps.Point(10, 10),
              scaledSize: new window.google.maps.Size(20, 20),
            }}
          />
        )}
        {markerEnd && (
          <Marker
            position={{ lat: markerEnd.location.lat, lng: markerEnd.location.lng }}
            icon={{
              url: `${circleEndBlue}`,
              anchor: new window.google.maps.Point(10, 10),
              scaledSize: new window.google.maps.Size(20, 20),
            }}
          />
        )}
        {wayPoints.length &&
          wayPoints.map((marker, index) => (
            <Marker
              key={`marker${index + 1}_${marker.location.lat}-${marker.location.lng}`}
              position={{ lat: marker.location.lat, lng: marker.location.lng }}
              onClick={(event) => {
                // console.log(event);
                isMarkersClickable && getNearestPointsForGetPickUpPoint(event);
                isMarkersClickable && setStartGetDistance(true);
              }}
              icon={{
                url: `${circleFiolet}`,
                // url: `${LocationImg}`,
                // origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(4, 4),
                scaledSize: new window.google.maps.Size(8, 8),
              }}
              // label={{
              //   text: `${index.js}`,
              //   color: '#fff',
              // }}
              zIndex={1}
            />
          ))}
        {
          // !isScheduleRide &&
          wayPoints3.length &&
            wayPoints3.map((marker, index) => (
              <Marker
                key={`marker${index + 1}_${marker.location.lat}-${marker.location.lng}`}
                position={{ lat: marker.location.lat, lng: marker.location.lng }}
                clickable={isMarkersClickable}
                onClick={() => {
                  // setPosition({
                  //   lat: marker.location.lat,
                  //   lng: marker.location.lng,
                  // })
                  if (wayPoints4.length < 2) {
                    setStartRequests(true);
                    wayPoints4.length === 0 && setPickUpPoint(marker);
                    wayPoints4.length === 0 &&
                      setPickUpPointTime &&
                      setPickUpPointTime((current) => +current + +marker.duration * 1000);
                    wayPoints4.length === 1 && setDropOffPoint(marker);
                    findRide && wayPoints4.length === 0 && setOrigin(marker);
                    findRide && wayPoints4.length === 1 && setDestination(marker);
                    findRide && setWayPoints3([]);
                    findRide &&
                      wayPoints4.length === 0 &&
                      setIsGetPickUpPoint &&
                      setIsGetPickUpPoint(false);
                    findRide &&
                      wayPoints4.length === 1 &&
                      setIsGetDropOffPoint &&
                      setIsGetDropOffPoint(false);
                    findRide &&
                      wayPoints4.length === 0 &&
                      setMarkerStart({ location: marker.location });
                    // setMarkers((current) => {
                    //   if (current.length) {
                    //     return current[1]
                    //       ? [{ location: marker.location }, current[1]]
                    //       : [{ location: marker.location }];
                    //   }
                    //   return [{ location: marker.location }];
                    // });
                    findRide &&
                      wayPoints4.length === 1 &&
                      setMarkerEnd({ location: marker.location });
                    // setMarkers((current) => {
                    //   if (current.length) {
                    //     return current[0]
                    //       ? [current[0], { location: marker.location }]
                    //       : [{ location: marker.location }];
                    //   }
                    //   return [{ location: marker.location }];
                    // });
                    !findRide && setSelectRouteIndex(0);
                    setWayPoints4((result) => [...result, marker]);
                  }
                  // console.log(marker)
                }}
                icon={{
                  url: `${circleBlue}`,
                  // url: `${LocationImg}`,
                  // origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(8, 8),
                  scaledSize: new window.google.maps.Size(16, 16),
                }}
                label={{
                  text: `${index + 1}`,
                  fontSize: '10px',
                  color: '#fff',
                }}
                zIndex={2}
              />
            ))
        }
        {wayPoints4.length &&
          wayPoints4.map((marker, index) => (
            <Marker
              key={`marker${index + 1}_${marker.location.lat}-${marker.location.lng}`}
              position={{ lat: marker.location.lat, lng: marker.location.lng }}
              onClick={() => {
                if (wayPoints4.length < 2) {
                  setStartRequests(true);
                  wayPoints4.length === 0 && setPickUpPoint(marker);
                  wayPoints4.length === 1 && setDropOffPoint(marker);
                  setWayPoints4((result) => [...result, marker]);
                }
                // console.log(marker)
              }}
              icon={{
                url: `${circleMiddlePoint}`,
                // url: `${LocationImg}`,
                // origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(10, 10),
                scaledSize: new window.google.maps.Size(20, 20),
              }}
              zIndex={10}
            />
          ))}
        {
          // !isScheduleRide &&
          origin &&
            destination &&
            durationMarker &&
            durationMarker.length &&
            durationMarker.map((el, index) => (
              <Marker
                key={'durationMarker' + index}
                position={{
                  lat: el.lat,
                  lng: el.lng,
                }}
                opacity={selectRouteIndex === index ? 1 : 0.6}
                // onClick={() => {
                //   setSelected(marker);
                // }}
                icon={{
                  url: `${selectRouteIndex === index ? circleSolid : circleSolidBlue}`,
                  // url: `${LocationImg}`,
                  // origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(30, 10),
                  scaledSize: new window.google.maps.Size(60, 20),
                }}
                label={{
                  text: `${Math.floor(el.time / 60)} min`,
                  color: '#fff',
                  fontSize: '10px',
                }}
                zIndex={selectRouteIndex === index ? 10 : 0}
              />
            ))
        }
        {
          // !isScheduleRide &&
          origin &&
            destination &&
            distanceMarker &&
            distanceMarker.length &&
            distanceMarker.map((el, index) => (
              <Marker
                key={'distanceMarker' + index}
                position={{
                  lat: el.lat,
                  lng: el.lng,
                }}
                opacity={selectRouteIndex === index ? 1 : 0.6}
                // onClick={() => {
                //   setSelected(marker);
                // }}
                icon={{
                  url: `${selectRouteIndex === index ? circleSolid : circleSolidBlue}`,
                  // url: `${LocationImg}`,
                  // origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(30, 10),
                  scaledSize: new window.google.maps.Size(60, 20),
                }}
                label={{
                  text: `${(el.dis / 1000).toFixed(1)} km`,
                  color: '#fff',
                  fontSize: '10px',
                }}
                zIndex={selectRouteIndex === index ? 10 : 0}
              />
            ))
        }
        {
          // !isScheduleRide &&
          origin &&
            destination &&
            fuelConsumptionMarker &&
            fuelConsumptionMarker.length &&
            fuelConsumptionMarker.map((el, index) => (
              <Marker
                key={'fuelConsumptionMarker' + index}
                position={{
                  lat: el.lat,
                  lng: el.lng,
                }}
                opacity={selectRouteIndex === index ? 1 : 0.6}
                // onClick={() => {
                //   setSelected(marker);
                // }}
                icon={{
                  url: `${selectRouteIndex === index ? circleSolid : circleSolidBlue}`,
                  // url: `${LocationImg}`,
                  // origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(30, 10),
                  scaledSize: new window.google.maps.Size(60, 20),
                }}
                label={{
                  text: `${(el.fuel / 1000).toFixed(1)} L`,
                  color: '#fff',
                  fontSize: '10px',
                }}
                zIndex={selectRouteIndex === index ? 10 : 0}
              />
            ))
        }
        {
          // !isScheduleRide &&
          origin &&
            destination &&
            co2Marker &&
            co2Marker.length &&
            co2Marker.map((el, index) => (
              <Marker
                key={'co2Marker' + index}
                position={{
                  lat: el.lat,
                  lng: el.lng,
                }}
                opacity={selectRouteIndex === index ? 1 : 0.6}
                // onClick={() => {
                //   setSelected(marker);
                // }}
                icon={{
                  url: `${selectRouteIndex === index ? circleSolid : circleSolidBlue}`,
                  // url: `${LocationImg}`,
                  // origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(30, 10),
                  scaledSize: new window.google.maps.Size(60, 20),
                }}
                label={{
                  text: `${(el.co2 / 1000).toFixed(0)} g/km`,
                  color: '#fff',
                  fontSize: '10px',
                }}
                zIndex={selectRouteIndex === index ? 10 : 0}
              />
            ))
        }
        {geoLocationMarker && (
          <Marker
            position={{ lat: geoLocationMarker.lat, lng: geoLocationMarker.lng }}
            icon={{
              url: `${locationImg}`,
              anchor: new window.google.maps.Point(10, 10),
              scaledSize: new window.google.maps.Size(20, 20),
            }}
            zIndex={100}
          />
        )}
        {wayPoints3.length && startGetDistance && (
          // wayPoints3.forEach((el, index.js) =>
          <DistanceMatrixService
            options={{
              // destinations: [],
              destinations: wayPoints3.map((el) => el.location),
              origins: [origin.location],
              travelMode: 'DRIVING',
            }}
            onLoad={(DistanceMatrixService) => {
              setStartGetDistance(false);
              // console.log(
              //   'DistanceMatrixService onLoad DistanceMatrixService: ',
              //   DistanceMatrixService,
              // );
            }}
            // optional
            onUnmount={(DistanceMatrixService) => {
              // console.log(
              //   'DistanceMatrixService onUnmount DistanceMatrixService: ',
              //   DistanceMatrixService,
              // );
            }}
            callback={getDistanceToWayPoints}
            // callback={(response, status) => console.log('[DistanceMatrixService] response', response.rows[0].elements[0].distance, response.rows[0].elements[0].duration)}
          />
          // )
        )}
        {destination !== '' && origin !== '' && startRequests && (
          <DirectionsService
            options={{
              destination: destination.location,
              origin: origin.location,
              travelMode: 'DRIVING',
              waypoints: wayPoints4.map((el) => ({ location: el.location })),
              // drivingOptions: {departureTime: Date.now()},
              avoidFerries: false, // If true, instructs the Directions service to avoid ferries where possible. Optional.
              avoidHighways: false, // If true, instructs the Directions service to avoid highways where possible. Optional.
              avoidTolls: false, // If true, instructs the Directions service to avoid toll roads where possible. Optional.
              optimizeWaypoints: false, // If set to true, the DirectionsService will attempt to re-order the supplied intermediate waypoints to minimize overall cost of the route. If waypoints are optimized, inspect DirectionsRoute.waypoint_order in the response to determine the new ordering.
              provideRouteAlternatives: !findRide, // Whether or not route alternatives should be provided. Optional.
              // travelMode: travelMode,
            }}
            onLoad={(directionsService) => {
              // console.log('DirectionsService onLoad directionsService: ', directionsService);
            }}
            // optional
            onUnmount={(directionsService) => {
              // console.log('DirectionsService onUnmount directionsService: ', directionsService);
            }}
            callback={directionsCallback}
          />
        )}
        {origin && destination && response !== null && (
          <DirectionsRenderer
            options={{
              directions: response,
              map: map,
              polylineOptions: {
                strokeColor: '#00CCBF',
                strokeWeight: 3,
                strokeOpacity: 0,
                // suppressPolylines: true,
                // clickable: true
                // editable: true
              },
              // draggable: true,
              markerOptions: {
                // icon: LocationImg,
                opacity: 0,
              },
              icon: { scale: 3 },
            }}
            onLoad={(directionsRenderer) => {
              // console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer);
            }}
            onUnmount={(directionsRenderer) => {
              // console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer);
            }}
            zIndex={0}
          />
        )}

        {origin &&
          destination &&
          response !== null &&
          response.routes.map((route, index) => (
            <Polyline
              key={'Polyline' + index}
              options={{
                strokeColor: selectRouteIndex === index ? '#00CCBF' : '#5750FF',
                strokeOpacity: selectRouteIndex === index ? '1' : '0.4',
                strokeWeight: 3,
                map: map,
                geodesic: true,
                // suppressPolylines: true,
                clickable: !findRide,
                // editable: true
                // draggable: true,
              }}
              onLoad={(event) => {
                // console.log('Polyline onLoad Polyline: ', event);
              }}
              path={route.overview_path}
              onClick={() => {
                // changeResponseRoutesStatus(response, index.js);
                setSelectRouteIndex(index);
                getNearestPoints(response, index);
              }}
              zIndex={route.selectStatus ? 10 : 0}
            />
          ))}
      </GoogleMap>
    </div>
  );
}
