import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { useAuth } from '../../components/contexts/AuthContext';
import { ROUTES } from '../../shared/constants';
import { ROLES } from '../../shared/constants';
import { RIDE_REQUEST_STATUS } from '../../shared/constants';
import { RIDE_REQUESTS_BY_PASSENGER_ID_QUERY } from '../../shared/gqlQueries/rideRequestsByPassengerId';
import { RIDES_BY_DRIVER_ID_QUERY } from '../../shared/gqlQueries/findRidesByDriverId';
import { RideHistoryItem } from '../../components';

import iconLeft from '../../assets/images/arrow-left-solid.svg';

export function RideHistory() {
  const history = useHistory();
  const [rideRequestsArr, setRideRequestsArr] = useState([]);
  const [ridesArr, setRidesArr] = useState([]);
  const [activeList, setActiveList] = useState('riderList');
  const { currentUser } = useAuth();

  const [getRideRequestsFunc, rideRequestsData] = useLazyQuery(
    RIDE_REQUESTS_BY_PASSENGER_ID_QUERY,
    {
      fetchPolicy: 'network-only', // always will fetch data, no cache
      onCompleted: () => {
        if (rideRequestsData.data && rideRequestsData.data.rideRequestsByPassengerId) {
          // console.log('[rideRequestsData]', ridesFilterByRiderAndPaidStatus(rideRequestsData.data.rideRequestsByPassengerId));
          setRideRequestsArr(
            ridesFilterByRiderAndPaidStatus(rideRequestsData.data.rideRequestsByPassengerId),
          );
        }
      },
      onError: (error) => {
        console.log('[GetRidesHistory] error getRideRequestsFunc', error);
      },
    },
  );

  const [getRidesFunc, ridesData] = useLazyQuery(RIDES_BY_DRIVER_ID_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      if (ridesData.data && ridesData.data.findRidesByDriverId) {
        // console.log('[ridesData]', ridesFilterByDriver(ridesData.data.findRidesByDriverId));
        setRidesArr(ridesFilterByDriver(ridesData.data.findRidesByDriverId));
      }
    },
    onError: (error) => {
      console.log('[GetRidesHistory] error getRideRequestsFunc', error);
    },
  });

  function ridesFilterByRiderAndPaidStatus(arr) {
    return arr.filter(
      (el) =>
        el.passenger.id === currentUser.id &&
        +el.ride.waypointsEta[0].eta < +Date.now() &&
        (el.status.toLowerCase() === RIDE_REQUEST_STATUS.PAID.toLowerCase() ||
          el.status.toLowerCase() === RIDE_REQUEST_STATUS.CANCELED.toLowerCase()),
    );
  }

  function ridesFilterByDriver(arr) {
    return arr.filter(
      (el) => el.driver.id === currentUser.id && +el.waypointsEta[0].eta < +Date.now(),
    );
  }

  useEffect(() => {
    getRideRequestsFunc({ variables: { id: currentUser.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mx-auto box-border relative">
      {/*<Header setShowMen={setShowMenu} />*/}
      <div className="w-full flex justify-start items-center bg-black p-4">
        <button onClick={() => history.push(ROUTES.FIND_RIDE)}>
          <img src={iconLeft} alt="icon back" className="w-4" />
        </button>
        <span className="text-lg font-sans font-medium ml-4 text-white">Ride History</span>
      </div>
      <ul
        className={currentUser.role === ROLES.driver ? 'w-full flex justify-between' : 'hidden'}
        onClick={(event) => setActiveList(event.target.dataset.id)}
      >
        <li
          className={
            activeList === 'riderList'
              ? 'w-1/2 bg-white border-t-full p-4 font-semibold text-nuale_turquoise text-center'
              : 'w-1/2 bg-nuale_turquoise p-4 font-semibold text-center text-white'
          }
          data-id="riderList"
          onClick={() => getRideRequestsFunc({ variables: { id: currentUser.id } })}
        >
          RIDER
        </li>
        <li
          className={
            activeList === 'driverList'
              ? 'w-1/2 bg-white border-t-full p-4 font-semibold text-nuale_turquoise text-center'
              : 'w-1/2 bg-nuale_turquoise p-4 font-semibold text-center text-white'
          }
          data-id="driverList"
          onClick={() => getRidesFunc({ variables: { id: currentUser.id } })}
        >
          DRIVER
        </li>
      </ul>
      <div
        className={
          currentUser.role === ROLES.driver
            ? 'w-full rideListForDriverCalcHeight overflow-y-scroll'
            : 'w-full rideListForRiderCalcHeight overflow-y-scroll'
        }
      >
        <ul className={activeList === 'riderList' ? 'p-4 z-10 bg-white w-full' : 'hidden'}>
          {rideRequestsArr.length ? (
            rideRequestsArr.map((el, index) => (
              <RideHistoryItem key={'rideRequestItem' + index} rideInfo={el.ride} rideId={el.id} />
            ))
          ) : (
            <li className="text-nuale_neon_blue min-w-full">
              {rideRequestsData.loading ? 'Loading...' : 'Currently, no rides'}
            </li>
          )}
        </ul>
        <ul
          className={
            activeList === 'driverList' && currentUser.role === ROLES.driver
              ? 'p-4 bg-white w-full '
              : 'hidden'
          }
        >
          {ridesArr.length ? (
            ridesArr.map((el, index) => (
              <RideHistoryItem key={'rideItem' + index} rideInfo={el} rideId={el.id} />
            ))
          ) : (
            <li className="text-nuale_neon_blue w-full bg-white">
              {ridesData.loading ? 'Loading...' : 'Currently, no rides'}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
