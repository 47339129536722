import gql from 'graphql-tag';

export const FIND_RIDE_QUERY = gql`
  query findRide($input: ListRidesInput!) {
    findRide(filters: $input) {
      flexi {
        id
        seats
        pricePerSeat
        driver {
          id
          firstName
          lastName
          gender
          userImg
          userVehicles {
            make {
              name
            }
            model
            spec {
              engineSize
              fuelConsumption
            }
          }
        }
        startDate
        startPoint {
          name
          eta
        }
        endPoint {
          name
          eta
        }
        waypoints {
          id
          name
        }
        waypointsEta {
          eta
          waypointId
        }
      }
      exact {
        id
        seats
        pricePerSeat
        driver {
          id
          firstName
          lastName
          userImg
          gender
          userVehicles {
            make {
              name
            }
            model
            spec {
              engineSize
              fuelConsumption
            }
          }
        }
        startDate
        startPoint {
          name
          eta
        }
        endPoint {
          name
          eta
        }
        waypoints {
          id
          name
        }
        waypointsEta {
          eta
          waypointId
        }
      }
    }
  }
`;
