import React from 'react';

import { AntiDiscriminationPolicy } from './AntiDiscriminationPolicy';
import { PrivacyPolicy } from './PrivacyPolicy';
import { Terms } from './Terms';
import { WheelChairPolicy } from './WheelChairPolicy';

export const TermComponent = () => {
  return (
    <div className="bg-gray-100">
      <div className="px-7 bg-white">
        <h1 className="text-3xl font-bold  mb-9">Terms &amp; Privacy Policy</h1>
        <Terms />
        <PrivacyPolicy />
        <AntiDiscriminationPolicy />
        <WheelChairPolicy />
      </div>
    </div>
  );
};
