import React from 'react';

export const MinSpinner = ({ color, bg_color }) => {
  return (
    <div className={bg_color ? `${bg_color} sk-fading-circle` : `sk-fading-circle`}>
      <div className={color ? `sk-circle1 sk-circle-${color}` : 'sk-circle1 sk-circle'}></div>
      <div className={color ? `sk-circle2 sk-circle-${color}` : 'sk-circle2 sk-circle'}></div>
      <div className={color ? `sk-circle3 sk-circle-${color}` : 'sk-circle3 sk-circle'}></div>
      <div className={color ? `sk-circle4 sk-circle-${color}` : 'sk-circle4 sk-circle'}></div>
      <div className={color ? `sk-circle5 sk-circle-${color}` : 'sk-circle5 sk-circle'}></div>
      <div className={color ? `sk-circle6 sk-circle-${color}` : 'sk-circle6 sk-circle'}></div>
      <div className={color ? `sk-circle7 sk-circle-${color}` : 'sk-circle7 sk-circle'}></div>
      <div className={color ? `sk-circle8 sk-circle-${color}` : 'sk-circle8 sk-circle'}></div>
      <div className={color ? `sk-circle9 sk-circle-${color}` : 'sk-circle9 sk-circle'}></div>
      <div className={color ? `sk-circle10 sk-circle-${color}` : 'sk-circle10 sk-circle'}></div>
      <div className={color ? `sk-circle11 sk-circle-${color}` : 'sk-circle11 sk-circle'}></div>
      <div className={color ? `sk-circle12 sk-circle-${color}` : 'sk-circle12 sk-circle'}></div>
    </div>
  );
};
