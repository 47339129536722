export const ROUTES = {
  ADD_RIDE: '/add-ride',
  FIND_RIDE: '/find-ride',
  PROFILE: '/profile',
  MESSAGE: '/message',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  BECOME_DRIVER: '/become-driver',
  FAQ: '/faq',
  TERM: '/term',
  RIDE_HISTORY: '/ride-history',
  RIDE_SCHEDULED: '/ride-scheduled',
  NOTIFICATIONS: '/notifications',
  TEST_DRIVE: '/test-drive',
  ABOUT_US: '/about-us',
  SAFETY: '/safety',
  ALL_RIDES_SCHEDULES: '/all-rides-schedules',
};

export const ROLES = {
  rider: 'RIDER',
  driver: 'DRIVER',
};

export const FUEL_TYPES = {
  DIESEL: 'DIESEL',
  PETROL: 'PETROL',
  HYBRID: 'HYBRID',
  ELECTRIC: 'ELECTRIC',
};

export const RIDE_STATUS = {
  SCHEDULED: 'SCHEDULED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const SIGN_UP_METHODS = {
  PHONE: 'PHONE',
  GOOGLE: 'GOOGLE',
};

export const RIDE_REQUEST_STATUS = {
  PAID: 'PAID',
  REQUESTED: 'REQUESTED',
  CANCELED: 'CANCELED',
};

export const NUALE_NOTIFICATION_TYPE = {
  DRIVER_RIDE_ADDED: 'DRIVER_RIDE_ADDED',
  DRIVER_RIDE_BOOKED: 'DRIVER_RIDE_BOOKED',
  RIDER_RIDE_BOOKED: 'RIDER_RIDE_BOOKED',
  RIDER_RIDE_CANCELED_BY_DRIVER: 'RIDER_RIDE_CANCELED_BY_DRIVER',
  DRIVER_RIDE_CANCELED_BY_RIDER: 'DRIVER_RIDE_CANCELED_BY_RIDER',
  RIDER_RIDE_CANCELED_BY_RIDER: 'RIDER_RIDE_CANCELED_BY_RIDER',
  RIDER_RIDE_CANCELED_REFUND: 'RIDER_RIDE_CANCELED_REFUND',
};

export const LOCAL_STORAGE_TOKEN_KEY = 'fb-auth-token';
export const LINK_SET_GEOLOCATION =
  'https://support.google.com/maps/answer/2839911?hl=en&authuser=0&p=browser_lp&visit_id=637634795171107157-3335722826&co=GENIE.Platform%3DDesktop&oco=&rd=1#permission';
