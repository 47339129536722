import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DriverInfo } from '../../components/SignUp';

import { ROUTES } from '../../shared/constants';

export function BecomeDriverPage() {
  const history = useHistory();

  const [driverLicenceNumber, setDriverLicenceNumber] = useState('');
  const [vehicleCategory, setVehicleCategory] = useState('');
  const [vehicleBrand, setVehicleBrand] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [carImages, setCarImages] = useState([]);
  const [driverLicenceImages, setDriverLicenceImages] = useState([]);
  const [userAvatarImage, setUserAvatarImage] = useState([]);
  const [fuelType, setFuelType] = useState('');
  const [fuelConsumption, setFuelConsumption] = useState('');
  const [engineSize, setEngineSize] = useState('');
  const [gender, setGender] = useState('');
  const [idCard, setIdCard] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [yearOfRegistration, setYearOfRegistration] = useState(new Date());

  const values = {
    driverLicenceNumber,
    vehicleCategory,
    vehicleBrand,
    vehicleModel,
    carImages,
    driverLicenceImages,
    fuelType,
    fuelConsumption,
    engineSize,
    gender,
    userAvatarImage,
    idCard,
    bankName,
    bankAccountNumber,
    beneficiaryName,
    yearOfRegistration,
  };

  const setters = {
    setDriverLicenceNumber,
    setVehicleCategory,
    setVehicleBrand,
    setVehicleModel,
    setCarImages,
    setDriverLicenceImages,
    setUserAvatarImage,
    setFuelType,
    setFuelConsumption,
    setEngineSize,
    setGender,
    setIdCard,
    setBankName,
    setBankAccountNumber,
    setBeneficiaryName,
    setYearOfRegistration,
  };

  return (
    <div className="p-6">
      <DriverInfo
        prevStep={() => history.push(ROUTES.FIND_RIDE)}
        nextStep={() => history.push(ROUTES.ADD_RIDE)}
        values={values}
        setters={setters}
      />
    </div>
  );
}
