import { LOCAL_STORAGE_TOKEN_KEY } from '../constants';

export function setAuthToken(token) {
  if (!token) {
    throw new Error('Token not provided');
  }

  if (typeof token !== 'string') {
    throw new Error('Token should be a string');
  }

  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
}

export function getAuthToken() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
}

export function clearAuthToken() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
}
