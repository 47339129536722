import React from 'react';

import aboutFirst from '../../assets/images/about/image-1@3x.png';
import aboutSecond from '../../assets/images/about/Driver_screen_cut.jpg';
import aboutThird from '../../assets/images/about/Emergency_cut.jpg';
import aboutFourth from '../../assets/images/about/Sharing_Location_cut.jpg';
import aboutFifth from '../../assets/images/about/24_cut.jpg';
import aboutSixth from '../../assets/images/about/25_cut.jpg';

export const AboutUsComponent = () => {
  return (
    <div className="bg-gray-100 mt-16">
      <div className=" bg-white">
        <h1 className="px-5 text-3xl font-bold  mb-12">ABOUT US</h1>
        <div className="aboutUsContent">
          <div>
            <h2 className="font-bold mb-5 px-5">
              We ignite opportunity through fairness, freedom and fun by connecting people together
              on their everyday journey.
            </h2>
            <p className="text-sm">
              Good things happen when people can move, whether across town or toward their dreams.
              Opportunities appear, open up, and become reality.
            </p>
            <p className="text-sm">
              What started as a way to tap a button to get a ride will lead to billions of moments
              of human connection as people around Mauritius. You can go all kinds of places with a
              peace of mind thanks to the help of our technology.
            </p>
            <div className="w-full mt-8">
              <img className="w-full" src={aboutFirst} alt="first img" />
            </div>
          </div>
          <div>
            <h2 className="font-bold mb-5 px-5">SAFETY</h2>
            <p className="text-sm">All drivers are background checked before their first trip</p>
            <p className="text-sm">
              Before anyone can drive with Nuale, they must undergo a multi-step safety screen,
              which means all drivers have to be authorized by us before offering rides. In
              addition, Nuale rescreens drivers regularly and uses technology to look for issues in
              between.
            </p>
            <p className="font-medium text-nuale_turquoise mt-5">How driver screening works</p>
            <div
              className="w-full my-8 px-10 w-full"
              // style={{
              //   backgroundImage: `url(${aboutSecond})`,
              //   backgroundRepeat: 'no-repeat',
              //   backgroundPosition: 'center',
              //   backgroundSize: 'cover',
              // }}
            >
              <img className="w-full" src={aboutSecond} alt="second img" />
            </div>
            <p className="text-sm">There’s emergency help if you need it</p>
            <p className="text-sm">
              In an emergency, speed and accuracy are critical. This is why calling the emergencies
              services or others are now a click away.
            </p>
            <p className="font-bold text-nuale_turquoise mt-5">See how it works</p>
            <div className="w-full mt-8 px-10 w-full">
              <img className="w-full" src={aboutThird} alt="third img" />
            </div>
          </div>
          <div>
            <h2 className="font-bold mb-5 px-5">Share your location</h2>
            <p className="text-sm">
              Pick from your contact list to share your real time localisation and driver’s info
              with.
            </p>
            <div className="w-full mt-8 px-10 w-full">
              <img className="w-full" src={aboutFourth} alt="fourth img" />
            </div>
          </div>
          <div>
            <h2 className="font-bold mb-5 px-5">You have our support, whenever you need it</h2>
            <p className="text-sm">
              Nuale support is available 24 hours a day, 7 days a week, and is handled by our team.
              If you’re in immediate danger, you should always contact authorities first.
            </p>
            <p className="font-medium text-nuale_turquoise mt-5">How to reach us</p>
            <div className="w-full mt-8 px-10 w-full">
              <img className="w-full" src={aboutFifth} alt="fourth img" />
            </div>
          </div>
          <div>
            <h2 className="font-bold mb-5 px-5">Your personal details stay private</h2>
            <p className="text-sm">
              We use technology to help keep your phone number private, so neither drivers nor
              riders will see each other’s numbers when communicating through the Nuale app. Once a
              trip is completed, the app also protects rider information by concealing specific
              pickup and drop-off addresses in a driver’s trip history.
            </p>
            <div className="w-full mе-8 px-10 w-full">
              <img className="w-full" src={aboutSixth} alt="fourth img" />
            </div>
          </div>
          {/*<p>A Letter from Derek Ducasse, founder.</p>*/}
          {/*<p>*/}
          {/*Nuale Ltd was born during a critical time where our motherland’s environment and economy*/}
          {/*was dramatically affected due to Covid 19.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*2020, has been hard on everyone and it was even harder to remain inactive at this time,*/}
          {/*which led us to think and rethink of ways to bring some stability and opportunity to the*/}
          {/*year 2021.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*This is where Nuale Ltd has risen. We now give the opportunity to the people of*/}
          {/*Mauritius to contribute to a greener way of traveling across the island and saving on*/}
          {/*their day-to-day journey.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*Building this platform has required lots of willingness to challenge the status quo in a*/}
          {/*bid to improve our ways of travelling. We are not Uber, nor Blablacar or Lyft. We are as*/}
          {/*unique as the soul inside each and every single one of us. Nuale is a community built on*/}
          {/*trust. A place where our differences and ethics are valued. A place where helping others*/}
          {/*is more important than making profits. And finally, a place where sustainability is seen*/}
          {/*as the way for the future.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*Through our platform you will feel secured thanks to our evolving technology. And*/}
          {/*because we are only started in our journey, we will operate with an eye for the future*/}
          {/*to fulfil your needs. It is just the beginning, there is so much more in the pipeline. I*/}
          {/*want to close with my commitment to you: I am not perfect and will not pretend to be,*/}
          {/*but I will listen to you; I will ensure that the NUALE Community treats riders, drivers,*/}
          {/*and our cities with respect, humility, and integrity.*/}
          {/*</p>*/}
          {/*<p>Derek Ducasse</p>*/}
          {/*<h2>SUSTAINABILITY</h2>*/}
          {/*<p>*/}
          {/*We are committed to sustainability. While most people primarily associate sustainability*/}
          {/*with environmental conservation, it is also about people and the health of our*/}
          {/*communities! Through carpooling or ride sharing, you will positively impact our Co2*/}
          {/*consumption, thus making huge step in greenifying the Mauritian way of travelling.*/}
          {/*</p>*/}
          {/*<h2>YOUR SAFETY DRIVES US</h2>*/}
          {/*<p>*/}
          {/*Whether you are in the back seat or behind the wheel, your safety is crucial to us. Our*/}
          {/*commitment to your safety is a priority, and technology is at the heart of this*/}
          {/*approach. We develop new technologies and systems to help improve safety and help make*/}
          {/*it easier for everyone to get around. There’s so much more to come.*/}
          {/*</p>*/}
          {/*<h2>COMMUNITY</h2>*/}
          {/*<p>*/}
          {/*Building a trusted, eco responsible and educated community is also a priority. We take*/}
          {/*the time to get to know our members. All profiles and ratings are checked. IDs are*/}
          {/*properly verified. So, you know who you are travelling with.*/}
          {/*</p>*/}
        </div>
      </div>
    </div>
  );
};
