import React from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../../shared/constants';
import {
  // Menu,
  SafetyComponent,
} from '../../components';

import iconLeft from '../../assets/images/arrow-left-black.svg';

export function Safety() {
  // const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  return (
    <div className="container mx-auto h-full box-border relative overflow-x-hidden">
      {/*<Header setShowMen={setShowMenu} />*/}
      <div className="flex fixed top-o left-0 w-full justify-start items-center bg-white p-4">
        <button onClick={() => history.push(ROUTES.FIND_RIDE)}>
          <img src={iconLeft} alt="icon back" className="w-4" />
        </button>
        <span className="text-lg font-sans font-medium ml-4">Back</span>
      </div>
      <SafetyComponent />
      {/*<Menu showMenu={showMenu} setShowMenu={setShowMenu} />*/}
    </div>
  );
}
