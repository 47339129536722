import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import {
  withStyles as TimePickerComponentWithStyles,
  createMuiTheme,
} from '@material-ui/core/styles';

const classes = (theme) => ({
  input: {
    'font-weight': '500',
    padding: '0 1rem',
  },
  input2: {
    'font-weight': '500',
    padding: '0 1rem',
    fontFamily:
      'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: '1rem',
    color: '#000',
  },
  input3: {
    'font-weight': '500',
    padding: '0 1rem',
    color: 'rgba(000, 000, 000, 0)',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00CCBF',
    },
  },
});

function TimePickerComponent({
  classes,
  styles,
  addDropOffPointTime,
  setAddDropOffPointTime,
  setPickUpPointTime,
  pointTime,
  setPointTime,
}) {
  // const [selectedTime, setSelectedTime] = React.useState(new Date());

  const handleTimeChange = (time) => {
    setPointTime(time);
    setPickUpPointTime && setPickUpPointTime(time);
    setAddDropOffPointTime && setAddDropOffPointTime(false);
  };

  // console.log('pointTime', new Date(pointTime));
  // console.log('addDropOffPointTime ? null : pointTime', addDropOffPointTime ? null : pointTime);
  // console.log('addDropOffPointTime', addDropOffPointTime);

  if (addDropOffPointTime) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <TimePicker
            className={styles}
            id="time-picker"
            value={pointTime}
            onChange={handleTimeChange}
            InputProps={{
              disableUnderline: true,
              className: addDropOffPointTime ? classes.input3 : classes.input,
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <TimePicker
          className={styles}
          id="time-picker"
          value={pointTime}
          onChange={handleTimeChange}
          InputProps={{
            disableUnderline: true,
            className: classes.input2,
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

// export default TimePickerComponent;
export default TimePickerComponentWithStyles(classes)(TimePickerComponent);
