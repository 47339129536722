import React, { useState } from 'react';
import { PhoneLogin, OTPLogin } from '../../components/Login';

export function Login() {
  const [activeStep, setActiveStep] = useState(0);
  const [phone, setPhone] = useState('');

  function prevStep() {
    setActiveStep(activeStep - 1);
  }
  function nextStep() {
    setActiveStep(activeStep + 1);
  }

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <PhoneLogin nextStep={nextStep} phone={phone} setPhone={setPhone} />;
      case 1:
        return <OTPLogin prevStep={prevStep} phone={phone} />;
      default:
        return <div>Not Found</div>;
    }
  }
  return <div className="p-6">{_renderStepContent(activeStep)}</div>;
}
