import React from 'react';
import { NavLink } from 'react-router-dom';
import iconLeft from '../../assets/images/sign-up/icons_left.png';
// import riderImg from '../../assets/images/verifyID.svg';
// import { ReactComponent as RiderImg } from '../../assets/images/sign-up/ic_rider.svg';
import riderImg from '../../assets/images/sign-up/ic_rider.svg';
import driverImg from '../../assets/images/sign-up/ic_driver.svg';
import { ROLES } from '../../shared/constants';

export function RoleSelect({ setRole }) {
  // function setUserRole(roleValue) {
  //   switch (roleValue) {
  //     case ROLES.rider: {
  //       setRole(roleValue);
  //       nextStep();
  //       break;
  //     }
  //     case ROLES.driver: {
  //       setRole(roleValue);
  //       nextStep();
  //       break;
  //     }
  //     default: {
  //       setRole(ROLES.rider);
  //       nextStep();
  //       break;
  //     }
  //   }
  // }
  return (
    <div className="role-wrapper">
      <div className="py-2 flex flex-row items-center">
        <NavLink to="/">
          <button className="focus:outline-none p-1.5">
            <img src={iconLeft} alt="Back" className="w-4" />
          </button>
        </NavLink>
        <div className="font-bold ml-3">Back</div>
      </div>

      <div className="my-2">
        <h1 className="font-bold" style={{ fontSize: 32 }}>
          Sign Up
        </h1>
      </div>

      <div className="my-2">How would you like to Sign up?</div>

      <div className="flex flex-col">
        <div className="flex flex-col items-center relative m-auto"
          style={{
            backgroundImage: `url(${riderImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: 235,
            height: 235
          }}
        >
          {/*<img src={riderImg} alt="Rider" style={{ width: 235, height: 235 }} />*/}
          <button
            className="absolute bottom-0 px-4 py-2 font-bold text-base text-nuale_turquoise rounded-full border-nuale_turquoise bg-white border-2 focus:outline-none transform -translate-y-6"
            onClick={() => setRole(ROLES.rider)}
          >
            As a rider
          </button>
        </div>
        <div className="flex flex-col items-center relative m-auto"
             style={{
               backgroundImage: `url(${driverImg})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
               width: 235,
               height: 235
             }}
        >
          {/*<img src={driverImg} alt="Rider" style={{ width: 235, height: 235 }} />*/}
          <button
            className="absolute bottom-0 px-4 py-2 font-bold text-base text-nuale_neon_blue rounded-full border-nuale_neon_blue bg-white border-2 focus:outline-none transform -translate-y-6"
            onClick={() => setRole(ROLES.driver)}
          >
            As a driver
          </button>
        </div>
      </div>
    </div>
  );
}
