import React from 'react';

export const SafetyComponent = () => {
  return (
    <div className="bg-gray-100 mt-16">
      <div className="px-7 bg-white">
        <h1 className="text-3xl font-bold  mb-9">Safety</h1>
        <div className="safetyContent">
          <div>
            <h2>BECOME A DRIVER</h2>
            <div>
              <h3>Driver</h3>
              <p>Have an empty seat? Offer a ride to passengers.</p>
              <p>
                Share your car journeys with trusted passengers and contribute to a greener
                environment. We fix the costs per Kms and you make the savings!
              </p>
            </div>
            <u>1. Offer a ride</u>
            <p>
              Just say where you are going and where you would like to pick up and drop off
              passengers and at what time. You can also choose from our suggested locations for pick
              up and drop offs. It is our way of making things easier for both drivers and
              passengers. Plus you can set up your parameters to have only ladies riders and
              multiple or only one stop.
            </p>
            <div>
              <u>2. Passengers book and pay online on our secured platform</u>
              <p>
                When a passenger books a seat with you, we will hide your contact info for your
                privacy. Whenever you call and text a driver through the app, they won’t see your
                phone number, and you won’t see theirs.
              </p>
              <p>You can check out the details of your ride and passenger’s profile at any time.</p>
            </div>
            <div>
              <u>3. Travel together</u>
              <p>
                Share stories, tunes or just a quiet ride with other travellers. You are taking a
                big step towards making Mauritius better.
              </p>
            </div>
            <div>
              <u>4. Earn a contribution</u>
              <p>
                You will cover totality of your fuel expenses and earn a slight margin for helping
                the community making Mauritius a greener place to live in.
              </p>
              <p>
                We will transfer your payment at the completion of the ride on your designated bank
                account. You will be paid instantly upon confirmation of safe arrival and good ride
                condition.
              </p>
            </div>
          </div>
          <div>
            <h2>BECOME A RIDER</h2>
            <div>
              <h3>Passenger</h3>
              <p>Need a ride? Find one with a trusted driver.</p>
              <p>
                Save money on your daily journey, even at the very last minute, and contribute to a
                greener environment by reducing fuel consumption.
              </p>
            </div>
            <div>
              <u>1. Find a ride</u>
              <p>
                Tell us where you are heading, your pickup location and your preferred time. Then
                select a ride that works for you! If you need more info, you can message drivers
                before making a booking. Check out drivers' profiles! You will see what others have
                to say about travelling with them. Plus, we have also selected our popular locations
                for pick up and drop off: getting there will make things much easier for everybody.
                Read more about Trust & Safety and Community guidelines.
              </p>
            </div>
            <div>
              <u>2. Book and contribute online</u>
              <p>
                Tap “book and contribute” to pay for your seat. Once completed you and the driver
                will get linked on the app. You’ll be able to either chat or call to get in touch.
              </p>
              <p>
                With our live tracking you’ll be able to see and follow your driver’s progress to
                the pick up point.
              </p>
              <p>
                If a driver cancels, we will refund you according to our cancellation & refund
                policy.
              </p>
            </div>
            <div>
              <u>3. Travel together</u>
              <p>
                Enjoy the ride and please do not forget to leave a rating! Ratings are important to
                build a trusted community. Plus leaving a rating means you are more likely to
                receive one too.
              </p>
            </div>
          </div>
          <div>
            <h2>Trust & Safety</h2>
            <div>
              <h3>A community built on trust and respect.</h3>
              <p>All profiles are thoroughly verified and approved by our expert team.</p>
            </div>
            <div>
              <h3>Choose who you travel with</h3>
              <p>
                {' '}
                When you check out other members' profiles you can see how many Facebook friends and
                Instagram connections they have. You can also see who the community’s leading
                ride-sharers are, thanks to their experience level.
              </p>
            </div>
            <div>
              <h3>Check out their ratings</h3>
              <p>
                See what others say about them, and benefit from the feedback of other members when
                choosing who to travel with.
              </p>
            </div>
            <div>
              <h3>Find out more about them</h3>
              <p>
                Check out their preferences and mini bio so you learn more about who you will be
                travelling with.
              </p>
            </div>
            <div>
              <h3>Profiles are moderated</h3>
              <p>
                All profiles, photos, ratings, rides and ride comments are moderated to maintain
                trust and respect in the community. Together, stronger!
              </p>
            </div>
            <div>
              <h3>Your journey is insured</h3>
              <p>
                As a driver, your car should have the required insurance to cover all passengers.
              </p>
              <p>
                As passengers, you only contribute to the cost of fuel; drivers make no profits.
              </p>
              <p>
                As agreed by the Insurers Association of Mauritius this means that their insurance
                is not affected.
              </p>
            </div>
            <div>
              <h3>Get in touch before you travel</h3>
              <p>
                Use our secured messaging system. Get to know each other before the ride and easily
                arrange where to meet. Contact info are hide hidden for your privacy. Whenever you
                call and text a driver through the app, they won’t see your phone number, and you
                won’t see theirs.
              </p>
            </div>
            <div>
              <h3>You can rely on us</h3>
              <p>
                Get help and support as required from our Community Relations team, seven days a
                week within a window.
              </p>
            </div>
          </div>
          <div>
            <h2>LADIES ONLY</h2>
            <div>
              <p>
                For many women, carpooling can raise concerns about safety, and although Nuale has
                developed a large number of safety measures that create a secure and trusted
                community, it may happen sometimes that some women still do not feel comfortable
                sharing a ride with people they don't know and from a different sex men they don’t
                know.
              </p>
              <p>
                That's why at Nuale we have our Ladies Only option; to allow members to plan book a
                ride where the driver and passengers can be both women. Full profiles, with a photo,
                mini-bio, preferences, verified contact details and ratings left by other members,
                are much more powerful in creating trust between members, regardless of gender.
              </p>
            </div>
            <div>
              <h3>Finding a ride</h3>
              <p>
                If you are a female passenger looking for a ride and you would prefer not to travel
                with a male driver, just log in and look for rides on your preferred route. You can
                then select the “Ladies Only” filter (only visible for members who are logged in).
                You will then see all the Ladies Only rides offered by female drivers.
              </p>
            </div>
            <div>
              <h3>Offering a ride</h3>
              <p>
                If you are a female driver with empty seats, you can choose to offer a ride to
                female members only - your ride will then only be available to women. You can see on
                the ride if it is Ladies Only.
              </p>
            </div>
          </div>
          <div>
            <h2>RATINGS</h2>
            <h3>Why do we have ratings?</h3>
            <p>
              Ratings help us into making make decisions. They are recommendations that we rely on
              every day. Why? Because we trust other people thought.
            </p>
            <p>
              When it comes to carpooling, they help us decide if we want to travel with someone.
              So, the next time you travel, leave a rating to share your experience with the rest of
              the community. If it happens a driver/passenger gets a bad review, we’ll get in touch
              with them. If it happens more than once, they can be banned from the platform.
            </p>
            <p>
              Ratings are also about cleanliness of the car. We’ll be We are very tough on that. No
              one wants to ride in a dirty car, and no one should. At Nuale, we pride ourselves on
              cleanliness and comfort
            </p>
            <p>Together, we build a trusted and respectful community for a better Mauritius.</p>
          </div>
          <div>
            <h2>TOP 10 TIPS FOR A SAFE RIDE</h2>
            <div>
              <p>We’re looking out for our passengers and drivers.</p>
              <p>
                Stay safe by controlling what you can. Like seatbelts! Make sure everyone has one
                and most importantly uses it. Here are 10 simple ways to stay safe.
              </p>
            </div>
            <div>
              <h3>I’m a passenger</h3>
              <p>
                Luggage: Put your luggage in the boot, not on the rear window shelf. The person
                driving will need to see out the back window!
              </p>
              <p>
                Baby on board: It’s your responsibility; don’t assume the driver will have one a car
                seat.
              </p>
              <p>
                Help the driver stay alert: There is nothing wrong with talking with the person
                driving; it’s actually a proven way to prevent drowsiness. Just do not distract
                them! If they seem tired, suggest a coffee break.
              </p>
            </div>
            <div>
              <h3>I’m a driver</h3>
              <p>
                Phones: Do not use your phone! Your passengers can set up the navigation system, you
                can take calls using your hands-free option.
              </p>
              <p>Travelling at night: Rest during the day and take a nap before travelling.</p>
              <p>
                Check your vehicle: Check your tyre pressure, oil, water, brakes, headlights and
                window wipers before hitting the road. Also, make sure you have valid insurance,
                driving license and MOT and keep copies in your vehicle.
              </p>
              <p>
                Alcohol and drugs: DO NOT DO IT! You will put everyone in the car at risk and you
                will lose your license. The Nuale community completely prohibits using its services
                while intoxicated. Legal repercussions can be processed.
              </p>
              <p>
                Weather: Take special care in the rain. Bad weather does not cause bad driving. Just
                remember, braking distances need to be greater, visibility will be worse.
              </p>
            </div>
          </div>
          <div>
            <h2>FAQS</h2>
            <h3>Cancellations & No-shows</h3>
            <h4>I’m a Driver</h4>
            <div>
              <u>What if I cannot contact a passenger?</u>
              <p>Having issues getting in touch with your passenger?</p>
              <p>
                Try using our messaging system to get in touch with or simply shoot them a call by
                tap on the call icon through the app. They won’t see your phone number and you won’t
                see theirs.
              </p>
              <p>
                If you still have not been able to get in touch with the passenger and there is
                still some time before your ride leaves, then it might be worth waiting a bit to see
                if they get back to you. With different work/life schedules, it takes some people
                longer than others to respond back.
              </p>
              <p>
                If you are worried that your ride is approaching, you can cancel their booking and
                report that they were inaccessible. Their seat will be available for another
                passenger to book.
              </p>
            </div>
            <div>
              <u>What if a passenger cancels before departure or does not show up?</u>
              <p>
                It happens, but we know how disappointing it can be. That is why we compensate you
                for last minute cancellations. It is even worse when someone does not show up. You
                can start a claim by heading to your ride, selecting the passenger and reporting
                that you did not travel.
              </p>
              <p>In either case, we will compensate you accordingly:</p>
              <i>
                <b>- My passenger has cancelled 2 hours before my ride leaves</b>
              </i>
              <p>
                We will make the seat available for new passengers, but you will not be compensated.
                There is still plenty of time for you to find another passenger.
              </p>
              <i>
                <b>- My passenger has cancelled less than 2 hours before my ride leaves</b>
              </i>
              <p>
                We will make the seat available for new passengers; you will be compensated 50% of
                the fare.
              </p>
              <p>
                Make sure you are available and reachable by phone before your ride, so the
                passenger can contact you. In case they can’t reach you, they might get worried
                about their ride and cancel. In the case of cancellations due to drivers being
                unreachable, the passengers are refunded in full.
              </p>
              <i>
                <b>- My passenger does not show up</b>
              </i>
              <p>
                We will work on the claim as soon as you let us know. We give the passenger 7 days
                to agree or disagree with you, to be fair. If it turns out the passenger was at
                fault, you will get 100% of the money back, just as though they travelled with you
              </p>
            </div>
            <div>
              <u>How do I cancel a ride?</u>
              <p>
                Only a minute is needed to delete or cancel your ride. Obviously, it is much easier
                if you do not already have passengers. But either way, you can head to your Rides
                and find the one you want to delete.
              </p>
              <p>
                Pick a reason for your cancellation and take a quick moment to share any relevant
                details (only our Community Relations team will see this info). It is totally fine
                if you cannot travel anymore, but to keep the awesome trust and love we have in our
                community, cancel online and let your passengers know ASAP. They will be grateful
                for the extra time to find another ride.
              </p>
              <p>We will also let them know about the cancellation by SMS and email.</p>
              <p>
                You will not be penalised for cancelling, but when drivers cancel regularly and/or
                at the last minute, we can suspend them from offering rides to keep Nuale reliable.
              </p>
            </div>
            <h4>I’m a passenger</h4>
            <div>
              <u>What if I cannot contact my driver?</u>
              <p>
                Try using our messaging system to get in touch with or simply shoot them a call by
                tapping on the call icon through the app. They won’t see your phone number and you
                won’t see theirs.
              </p>
              <p>Still no reply?</p>
              <p>
                If there is still some time before the ride leaves, then it might be worth waiting a
                bit longer to see if they get back to you. With different work/life schedules, it
                takes some people longer than others to get in touch.
              </p>
              <p>
                If you are worried that your ride is approaching, you can cancel your booking and
                report that they were unreachable. Do not forget to include as many relevant details
                as possible so we can process your refund faster (we will not share them with the
                driver).
              </p>
            </div>
            <div>
              <u>How do I cancel my booking?</u>
              <p>You can cancel any booking from Bookings.</p>
              <p>
                We know how quickly plans can change, just cancel your booking as soon as you can.
                It is easier for everyone. That is also why your refund depends on when you make the
                cancellation.
              </p>
              <p>Here’s the breakdown:</p>
              <i>
                <b>- I have cancelled more than 2 hours before departure</b>
              </i>
              <p>We will give you a full refund, excluding service fees.</p>
              <i>
                <b>- I have cancelled in the last 2 hours before departure</b>
              </i>
              <p>We will refund you 50% of your fare, excluding service fees.</p>
              <p>
                If you cancel because the driver is unreachable, you will be refunded in full, after
                our teams verifies that.
              </p>
              <p>
                However, if you book in the last 24 hours before the ride, but cancel within 30
                minutes of booking, we will refund your booking, excluding service fees.
              </p>
              <i>
                <b>- I did not show up</b>
              </i>
              <p>
                We will compensate the driver your full contribution. We have worked out this
                Cancellation policy to be as fair as possible to drivers and passengers. If you
                cancel the day before or the same day, it is harder for drivers to find new
                passengers to share their costs. That is why we will not refund the full amount at
                this point.
              </p>
              <p>
                Regardless, the most important thing to do when you have to cancel is let drivers
                know. We are all part of keeping carpooling a reliable way to travel.
              </p>
            </div>
            <div>
              <u>What if the driver cancels the ride or does not show up?</u>
              <p>You will get your money back, including service fees.</p>
              <u>What if I do not travel as planned?</u>
              <p>
                If for whatever reason you did not travel as planned, just let us know online as
                soon as possible from the Bookings page.
              </p>
              <p>
                Find the ride in question and select that you did not travel. Pick a reason and
                share any relevant details (do not worry, we will not share them).
              </p>
              <p>We give the driver the chance to agree or disagree to be fair.</p>
              <p>
                You have 24 hours from the time the ride was planned to leave, otherwise we assume
                everything went okay and will pay the driver. That is why it is best to report that
                the ride did not happen as soon as possible.
              </p>
              <p>If it turns out the driver was at fault, you will get a full refund.</p>
            </div>
          </div>
          <div>
            <h2>Insurance</h2>
            <div>
              <u>Am I covered by my own insurance when I carpool with Nuale?</u>
              <p>
                Yes, no specific car insurance is required beyond the mandatory insurance (Third
                Party Liability). In case of any accident, all passengers will be covered by the
                driver’s mandatory TPL. 
              </p>
              <p>
                Nuale provides a service connecting drivers with passengers – where motoring costs
                are shared and no profit is made by the driver – as such, you are covered by your
                own insurance already when you carpool with Nuale.
              </p>
            </div>
            <div>
              <u>Do I need to inform my insurer that I carpool?</u>
              <p>
                Drivers with any concern are encouraged to check directly with their car insurer, as
                terms and conditions of individual car insurance providers may vary over time.
              </p>
            </div>
            <div>
              <u>Will carpooling affect my insurance or taxes?</u>
              <p>
                All motor insurers have agreed that if your passengers contribute towards your
                running costs, your insurance cover will not be affected, as long as rides are given
                in a vehicle seating eight passengers or less. Nuale limits number of seats and
                prices, scrupulously fulfilling the above requirement regarding profit: drivers’
                costs are only offset, and no profit* can be made. Drivers with any concern are also
                encouraged to check directly with their car insurer, as terms and conditions of
                individual car insurance providers may vary over time.
              </p>
              <p>
                To offset their running costs, Nuale drivers do not receive payment in excess of HM
                Revenue & Customs Approved Mileage Payment Allowance, so no profit is made. When
                drivers do no more than covering running costs, and do not drive over 10,000 miles
                per annum, they make no taxable profits.
              </p>
              <p>
                *Drivers only earned enough to cover their running cost such as fuel expenses for
                their trip. The extra earned will help them reducing all the other expenses they
                have. Example: Insurance, Cleaning, Declaration etc.
              </p>
            </div>
            <div>
              <u>Are passengers covered when I carpool?</u>
              <p>
                Yes, injuries caused to passengers are covered by your mandatory car insurance, as
                part of Third Party Liability.
              </p>
            </div>
          </div>
          <div>
            <h2>COMMUNITY GUIDELINES</h2>
            <div>
              <h3>Treat everyone with respect</h3>
              <p>
                Everyone should feel supported and welcomed, in any case whatsoever. Which is why we
                have built standards on physical contact, sexual assault and misconduct, threatening
                and rude behaviour, unwanted contact, discrimination and property damage.
              </p>
            </div>
            <div>
              <h3>Avoiding Physical contact</h3>
              <p>
                Do not touch strangers or anyone you have just met while using any of Nuale’s
                platforms. Hurting or intending to hurt anyone is strictly prohibited.
              </p>
            </div>
            <div>
              <h3>Avoiding Sexual assault and misconduct</h3>
              <p>
                Sexual assault and sexual misconduct of any kind is not allowed. Sexual assault and
                misconduct refer to sexual contact or behaviour without explicit consent of the
                other person.
              </p>
              <p>
                Personal space and privacy should always be respected. The following list provides
                examples of inappropriate conduct but is surely not exhaustive.
              </p>
              <i>
                <b>
                  - Behaviours and comments that could result in making someone feel uncomfortable
                  are not acceptable. Examples include nudges, whistles, and winks. You should not
                  touch or flirt with people you do not know.
                </b>
              </i>
              <i>
                {' '}
                <b>
                  - Do not comment on appearance, perceived gender identity, or sexual orientation.
                  Certain conversations that could be perceived as harmless can be offensive. Hold
                  back from asking unrelated personal questions, such as, “Are you in a
                  relationship?” Avoid discussing your own or someone else’s sex life, using
                  explicit language, or making jokes about sex.
                </b>
              </i>
              <i>
                <b>
                  - Nuale has a no-sex rule. Sexual contact is not accepted while using the Nuale’s
                  platform.
                </b>
              </i>
            </div>
            <div>
              <h3>Rude behaviour</h3>
              <p>
                Aggressive, confrontational, or harassing behaviour is prohibited. Avoid using
                language or make gestures that could be perceived as disrespectful, threatening, or
                inappropriate. It is always a good idea to stay away from personal topics that can
                potentially be divisive, like religion and political beliefs.
              </p>
            </div>
            <div>
              <h3>Unwanted contact</h3>
              <p>
                Contact should end as soon as a trip is completed, unless it is to return a lost
                item or with mutual consent. For example, texting, calling, social media contact,
                visiting, or trying to visit someone in person after the trip has been completed is
                prohibited.
              </p>
            </div>
            <div>
              <h3>Discrimination</h3>
              <p>
                Feeling safe and welcomed should always be the case to everyone. That is why we
                condone any conducts we have determined to be discriminatory. Discrimination against
                someone based on traits such as age, colour, disability, gender identity, marital or
                civil partnership status, national origin, race, religion, sex, sexual orientation,
                or any other characteristic protected under relevant law are strictly prohibited. It
                is also unacceptable to rate another user—whether they are a Nuale user,
                driver—based on these traits.
              </p>
            </div>
            <div>
              <h3>Property damage and locking</h3>
              <p>
                Damage to property is strictly prohibited. Some examples include damaging or
                tampering with the car requested through the Nuale’s platforms; breaking or
                vandalising a phone or tablet; intentionally spilling food or drink; smoking in a
                car; vomiting due to excessive alcohol consumption or otherwise. If you damage
                property, you are responsible for the cost of cleaning and repair fees, outside of
                normal wear and tear.
              </p>
            </div>
            <div>
              <h3>Help keep each other safe</h3>
              <p>
                Everyone has a role to play in helping to make a safe and trusted environment. That
                is why we have standards on account sharing, account holder age and more.
              </p>
            </div>
            <div>
              <h3>Account sharing</h3>
              <p>
                Account sharing is not accepted, unless expressly permitted in our guidelines,
                terms, or other policies. To use Nuale’s platform, you need to register and maintain
                your own active account. Allowing another person to use your account is not
                accepted, and you should never share your login information with anyone else.
              </p>
            </div>
            <div>
              <h3>Vehicle information</h3>
              <p>
                To make pickup easy, the Nuale platform gives riders and users identifying
                information about drivers and their vehicles, including their license plate number,
                vehicle, make and model, profile picture, and name.
              </p>
            </div>
            <div>
              <h3>Public emergencies</h3>
              <p>
                Nuale may have to take additional measures to try to preserve the safety of our
                platform during public emergencies, including but not limited to natural disasters,
                public health emergencies and public crisis situations.
              </p>
              <p>
                For example, if Nuale receives notice from a public health authority that someone
                using the Nuale platform may present a potential for public harm, we may suspend the
                individual’s account until it is reasonably safe to allow the individual to resume
                using the Nuale platform. Similarly, we may prevent individuals in an entire city or
                region from using part or all of the Nuale platform in order to comply with guidance
                from authorities during a time of public health emergency, natural disaster or other
                public crisis situation, or when the continued availability of the Nuale platform
                might present a clear and present danger.
              </p>
            </div>
            <div>
              <h3>Strengthening our community</h3>
              <p>
                Our strict Community Guidelines helps to keep Nuale enjoyable and inclusive for
                everyone. Any person who doesn’t follow the guidelines can be removed from the
                platform.
              </p>
            </div>
          </div>
          <div>
            <h2>A GUIDE TO NUALE</h2>
            <div>
              <h3>Rating a driver</h3>
              <p>
                Ratings at Nuale are taken very seriously to ensure a positive experience for both
                riders and drivers. Drivers with low ratings may lose access to the Nuale app
                forever.
              </p>
              <p>
                To rate a driver, follow the prompts in the app at the end of the trip. You will
                also be requested to rate your driver from the bottom of your receipt that you will
                have received by email or In-app.
              </p>
              <p>You can also rate a driver during a trip:</p>
              <ul>
                <li>1. Open the Nuale app or log in on the web.</li>
                <li>2. On the main screen, tap the white screen to expand trip details.</li>
                <li>3. Tap "Rate or tip" next to "How's your ride going?"</li>
                <li>4. Select the number of stars (1-5) you want to rate your driver.</li>
                <li>5. Add a tip if you want to and select "Save."</li>
              </ul>
              <p>If you rate the driver:</p>
              <i>
                <b>
                  - 5 stars, you will see the option to give them a compliment. Most riders provide
                  a 5-star rating unless there was a specific issue with the trip.
                </b>
              </i>
              <i>
                <b>
                  - Less than 5 stars, you will be encouraged to provide specific feedback about the
                  trip or driver from a list of common issues. A 1-star rating typically means there
                  was a serious problem with a driver.
                </b>
              </i>
              <i>
                <b>
                  Please note that Issues issues like trip price, app issues, or inconvenient pool
                  matches are not the driver's fault so will not affect their overall rating. We
                  encourage you to submit this type of feedback through the contact support
                  form/button.
                </b>
              </i>
              <p>Rating a driver can be completed within 30 days of a trip.</p>
            </div>
            <div>
              <h3>How to tip a driver</h3>
              <p>
                Tipping is a great way to thank a driver for a good experience. Tips are voluntary
                and are not included in the fare. The entire amount you tip goes directly to the
                driver. If a driver opts for a door-to-door service, we encourage you to tip the
                driver.
              </p>
              <p>
                At the end of each trip, you will be prompted to rate and tip the driver in the app.
              </p>
              <p>You can also tip a driver during a trip:</p>
              <ul>
                <li>1. Open the Nuale app.</li>
                <li>2. Tap the white screen to expand the trip details.</li>
                <li>3. Tap "Rate or tip" next to "How's your ride going?"</li>
                <li>4. Select the number of stars you want to rate the driver.</li>
                <li>
                  5. If you selected 5 stars, you will be prompted to tip the driver. If you select
                  less than 5 stars, you will see the option to add a tip.
                </li>
                <li>6. Tap "Save."</li>
              </ul>
              <p>You may also use cash to tip the driver.</p>
            </div>
            <div>
              <h3>Getting a trip receipt</h3>
              <p>
                At the end of your trip, we automatically send a receipt to the email address on
                your Nuale account. If you have not received your receipts, check the spam or junk
                folder for your designated email address. You can review your email address by
                selecting "Settings" from your app menu. Tap your email address to update it.
              </p>
              <p>
                Your Nuale account also includes a trip history of every ride you have taken. To
                review:
              </p>
              <ul>
                <li>1. Select "Your Trips" from the app menu/User dashboard.</li>
                <li>2. Select a past trip.</li>
                <li>3. You can review the trip's route, pickup location and final destination.</li>
                <li>4 For a full breakdown of your fare, tap Receipt.</li>
              </ul>
              <p>
                To resend a receipt email for trips with an economy vehicle option, sign in to your
                account/trips and select "My Trips" from the menu. Select the trip and click the
                Resend button.
              </p>
              <h3>Does Nuale have recurring charges?</h3>
              <p>
                Wouhouu! There are no recurring charges or membership fees for using Nuale. You are
                only charged for the rides you take.
              </p>
            </div>
            <div>
              <h3>Account and Payment options</h3>
            </div>
            <div>
              <h3>Why was my payment declined?</h3>
              <p>
                If you see any of the error messages below in your app, you will not be able to
                request a ride until you update your payment method:
              </p>
              <ul>
                <li>
                  <b>- "Request Failed: Card Declined"</b>
                </li>
                <li>
                  <b>- "Your payment method is invalid. Please update your billing settings"</b>
                </li>
                <li>
                  <b>- "There was an error processing your request"</b>
                </li>
              </ul>
              <p>
                Update or add a new payment method in the "Payment" section of the app menu if your
                payment method was declined for one of the reasons below:
              </p>
              <ul>
                <li>
                  <b>- Card number entered is incorrect</b>
                </li>
                <li>
                  <b>- Credit or debit card has expired</b>
                </li>
                <li>
                  <b>- Insufficient funds in the account</b>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2>ABOUT US</h2>
            <div>
              <h3>
                We create opportunity through fairness, freedom and fun by connecting people
                together on their everyday journey.
              </h3>
              <p>
                Good things happen when people move, whether across town or toward their dreams.
                Opportunities come up and become reality.
              </p>
              <p>
                What started as a tap of a button to get a ride will lead to billions of moments of
                human connection between people around Mauritius. You can go to all kinds of places
                with peace of mind thanks to the help of our technology.
              </p>
              <h4>A Letter from Derek Ducasse, founder.</h4>
              <p>
                Nuale Ltd was born during a critical time where our motherland’s environment and
                economy was dramatically affected due to Covid 19.
              </p>
              <p>
                2020, has been hard on everyone and it was even harder to remain inactive at this
                time, which led us to think and rethink of ways to bring some stability and
                opportunity to the year 2021.
              </p>
              <p>
                This is where Nuale Ltd has risen. We now give the opportunity to the people of
                Mauritius to contribute to a greener way of traveling across the island and saving
                on their day-to-day journey.
              </p>
              <p>
                Building this platform has required lots of willingness to challenge the status quo
                in a bid to improve our ways of travelling. We are not Uber, nor Blablacar or Lyft.
                We are as unique as the soul inside each and every single one of us. Nuale is a
                community built on trust. A place where our differences and ethics are valued. A
                place where helping others is more important than making profits. And finally, a
                place where sustainability is seen as the way for the future.
              </p>
              <p>
                Through our platform you will feel secured thanks to our evolving technology. And
                because we are only started in our journey, we will operate with an eye for the
                future to fulfil your needs. It is just the beginning, there is so much more in the
                pipeline.
              </p>
              <p>
                I want to close with my commitment to you: I am not perfect and will not pretend to
                be, but I will listen to you; I will ensure that the NUALE Community treats riders,
                drivers, and our cities with respect, humility, and integrity.
              </p>
            </div>
            <div>
              <h3>Derek Ducasse</h3>
            </div>
          </div>
          <div>
            <h2>SUSTAINABILITY</h2>
            <p>
              We are committed to sustainability. While most people primarily associate
              sustainability with environmental conservation, it is also about people and the health
              of our communities! Through carpooling or ride sharing, you will positively impact our
              Co2 consumption, thus making huge step in greenifying the Mauritian way of travelling.
            </p>
          </div>
          <div>
            <h2>YOUR SAFETY DRIVES US</h2>
            <p>
              Whether you are in the back seat or behind the wheel, your safety is crucial to us.
              Our commitment to your safety is a priority, and technology is at the heart of this
              approach. We develop new technologies and systems to help improve safety and help make
              it easier for everyone to get around. There’s so much more to come.
            </p>
          </div>
          <div>
            <h2>COMMUNITY</h2>
            <p>
              Building a trusted, eco responsible and educated community is also a priority. We take
              the time to get to know our members. All profiles and ratings are checked. IDs are
              properly verified. So, you know who you are travelling with.
            </p>
          </div>
          <div>
            <h2>NEWSROOM</h2>
            <p>Nuale newsroom.</p>
            <p>Learn more about the latest updates arriving now.</p>
          </div>
          <div>
            <h2>DASHBOARD MENU</h2>
            <p>Your rides</p>
            <p>Inbox</p>
            <p>Alerts</p>
            <p>Transfers</p>
            <p>Payments & Refunds</p>
            <p>Log out</p>
          </div>
          <div>
            <h2>SAFETY</h2>
            <div>
              <h3>All drivers are background checked before their first trip</h3>
              <p>
                Before anyone can drive with Nuale, they must undergo a multi-step safety
                assessment, including being checked for driving violations, impaired driving,
                violent crime, and other checks. In addition, Nuale rescreens drivers regularly and
                uses technology to look for issues in between.
              </p>
              <h4>How driver screening works (Create a landing page)</h4>
            </div>
            <div>
              <h3>There is emergency help if you need it</h3>
              <p>
                In an emergency, speed and accuracy are critical. If you call 911 from the Nuale
                app, the app displays your live location and trip details, so you can share them
                with the emergency dispatcher.
              </p>
              <h4>See how it works (Create a landing page)</h4>
            </div>
            <div>
              <h3>We have built technology to look out for you</h3>
              <p>
                We hope every trip is a smooth one, but things do not always go as planned—which is
                why we built RideCheck. By using sensors and GPS data, RideCheck can help detect if
                a trip goes unusually off-course or if a possible crash has occurred. If the app
                alerts us to anything out of the ordinary, we will reach out to provide you with the
                resources you need to get help.
              </p>
              <h4>Learn more about RideCheck (Create a landing page)</h4>
            </div>
            <div>
              <h3>Share your status</h3>
              <p>
                Pick from your list 5 persons to share your real time localisation and driver’s info
                with.
              </p>
            </div>
            <div>
              <h3>You have our support, whenever you need it</h3>
              <p>
                Nuale support is available 24 hours a day, 7 days a week, and is handled by our
                team. If you are in immediate danger, you should always contact authorities first.
              </p>
              <h4>How to reach us (Bring to Nuale Help)</h4>
            </div>
            <div>
              <h3>Your personal details stay private</h3>
              <p>
                We use technology to help keep your phone number private, so neither drivers nor
                riders will see each other’s numbers when communicating through the Nuale app. Once
                a trip is completed, the app also protects rider information by concealing specific
                pickup and drop-off addresses in a driver’s trip history.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
