import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../components/contexts/AuthContext';
import { useWebSocket } from '../../components/contexts/WebSocketContext';
import { TouchActions } from '../../components';
import { firebaseSignOut } from '../../shared/firebase';
import { ROLES, ROUTES } from '../../shared/constants';

import profile_x3 from '../../assets/images/menu/profile.svg';
import history_x3 from '../../assets/images/menu/ride-history.svg';
import chat_x3 from '../../assets/images/menu/chat@3x.png';
import schedule_x3 from '../../assets/images/menu/schedule-a-ride.svg';
import iconClose from '../../assets/images/icon-close-filled.svg';

export function Menu({ showMenu, setShowMenu }) {
  let history = useHistory();

  const { currentUser, isUserAuthenticated } = useAuth();
  const { setNotificationsNotReadedGlobal } = useWebSocket();

  const chooseMenuItemPage = (path) => {
    setShowMenu(false);
    history.push(path);
  };

  const logout = async (path) => {
    await firebaseSignOut()
      .then((_) => {
        setNotificationsNotReadedGlobal([]);
      })
      .catch((e) => {
        console.log('[Menu] Logout error', e.message);
      })
      .finally((_) => {
        setShowMenu(false);
        history.push(path);
      });
  };

  if (isUserAuthenticated()) {
    const { role, userImg } = currentUser;

    const avatarStyle = {
      backgroundImage: `url(${userImg ? userImg : profile_x3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    };

    return (
      <TouchActions setShowMenu={setShowMenu}>
        <div
          className={
            showMenu
              ? 'absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-70 visible'
              : 'absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-0 delay-200 invisible'
          }
          onClick={() => {
            setShowMenu(false);
          }}
          style={{ transition: '0.5s' }}
        >
          <div
            className={
              showMenu
                ? 'absolute top-0 left-0 z-10 w-full h-full'
                : 'absolute top-0 -left-full z-10 w-full h-full'
            }
            style={{ transition: '0.4s' }}
          >
            <div
              className="w-80  h-full bg-white"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <div className="w-full px-4 py-5 bg-black flex justify-between items-center">
                <div className="bg-white w-12 h-12 rounded-full border" style={avatarStyle}>
                  {' '}
                </div>
                {/*<button*/}
                {/*className="px-4 py-3 text-nuale_turquoise border-2 rounded-full border-nuale_turquoise text-base font-medium"*/}
                {/*onClick={() => logout(ROUTES.FIND_RIDE)}*/}
                {/*>*/}
                {/*Logout*/}
                {/*</button>*/}
                <img src={iconClose} alt="close button" onClick={() => setShowMenu(false)} />
              </div>
              <div className="w-full bg-white menuCalcHeight overflow-scroll pb-8">
                <ul className="w-full text-sm font-medium">
                  <li
                    className="w-full border-b p-4 flex items-center cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.RIDE_SCHEDULED)}
                  >
                    <picture className="mr-3">
                      <img className="w-5" src={schedule_x3} alt="Schedule icon" />
                    </picture>
                    <span>Ride schedules</span>
                  </li>
                  <li
                    className="w-full border-b p-4 flex items-center cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.RIDE_HISTORY)}
                  >
                    <picture className="mr-3">
                      <img className="w-5" src={history_x3} alt="History icon" />
                    </picture>
                    <span>Ride history</span>
                  </li>
                  <li
                    className="w-full border-b p-4 flex items-center cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.MESSAGE)}
                  >
                    <picture className="mr-3">
                      <img className="w-5" src={chat_x3} alt="Chat icon" />
                    </picture>
                    <span>Talk to us</span>
                  </li>
                  <li
                    className="w-full border-b p-4 flex items-center cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.PROFILE)}
                  >
                    <picture className="mr-3">
                      <img className="w-5" src={profile_x3} alt="Profile icon" />
                    </picture>
                    <span>Profile</span>
                  </li>
                </ul>
                {/*<h1 className="font-bold pl-5 mb-6">On the web</h1>*/}
                <ul className="w-full text-sm font-medium mb-9">
                  <li
                    className="w-full border-b p-4"
                    onClick={() => chooseMenuItemPage(ROUTES.ABOUT_US)}
                  >
                    <span className="pl-1">The company</span>
                  </li>
                  <li
                    className="w-full border-b p-4 cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.SAFETY)}
                  >
                    <span className="pl-1">Safety</span>
                  </li>
                  {/*<li className="w-full border-b p-4">*/}
                  {/*<select*/}
                  {/*className="w-full text-sm font-medium font-sans cursor-pointer bg-white"*/}
                  {/*name="group"*/}
                  {/*id=""*/}
                  {/*>*/}
                  {/*<option value="">Help</option>*/}
                  {/*</select>*/}
                  {/*</li>*/}
                  {role === ROLES.rider && (
                    <li
                      className="w-full border-b p-4 cursor-pointer"
                      onClick={() => chooseMenuItemPage(ROUTES.BECOME_DRIVER)}
                    >
                      <span className="pl-1">Become a Driver</span>
                    </li>
                  )}
                  <li
                    className="w-full border-b p-4 cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.FIND_RIDE)}
                  >
                    <span className="pl-1">Find ride</span>
                  </li>
                  <li
                    className="w-full border-b p-4 cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.ADD_RIDE)}
                  >
                    <span className="pl-1">Add ride</span>
                  </li>
                  <li
                    className="w-full border-b p-4 cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.FAQ)}
                  >
                    <span className="pl-1">FAQ</span>
                  </li>
                  <li
                    className="w-full border-b p-4 cursor-pointer"
                    onClick={() => chooseMenuItemPage(ROUTES.TERM)}
                  >
                    <span className="pl-1">Terms & Privacy Policy</span>
                  </li>
                </ul>
                <div
                  className="m-auto w-36 text-center py-3 text-nuale_turquoise border-2 rounded-full border-nuale_turquoise text-base font-medium"
                  onClick={() => logout(ROUTES.FIND_RIDE)}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
      </TouchActions>
    );
  }

  return (
    <TouchActions setShowMenu={setShowMenu}>
      <div
        className={
          showMenu
            ? 'absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-70 visible'
            : 'absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-0 delay-200 invisible'
        }
        onClick={() => {
          setShowMenu(false);
        }}
        style={{ transition: '0.5s' }}
      >
        <div
          className={
            showMenu
              ? 'absolute top-0 left-0 z-10 w-full h-full '
              : 'absolute top-0 -left-full z-10 w-full h-full'
          }
          style={{ transition: '0.4s' }}
        >
          <div
            className="w-80  h-full bg-white"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="w-full px-4 py-5 bg-black flex justify-between items-center">
              <picture className="p-2 bg-white rounded-full">
                <img className="w-6" src={profile_x3} alt="Profile icon" />
              </picture>
              {/*<button*/}
              {/*className="px-4 py-3 text-nuale_turquoise border-2 rounded-full border-nuale_turquoise text-base font-medium"*/}
              {/*onClick={() =>*/}
              {/*chooseMenuItemPage({*/}
              {/*pathname: '/',*/}
              {/*propsStep: 1,*/}
              {/*})*/}
              {/*}*/}
              {/*>*/}
              {/*Login / Sign up*/}
              {/*</button>*/}
              <img src={iconClose} alt="close button" onClick={() => setShowMenu(false)} />
            </div>
            <div className="w-full bg-white pt-6 menuCalcHeight overflow-scroll pb-8">
              {/*<h1 className="font-bold pl-5 mb-6">On the web</h1>*/}
              <ul className="w-full text-sm font-medium mb-9">
                <li className="w-full border-b p-4">
                  <span className="pl-1">The company</span>
                </li>
                <li
                  className="w-full border-b p-4"
                  onClick={() => chooseMenuItemPage(ROUTES.SAFETY)}
                >
                  <span className="pl-1">Safety</span>
                </li>
                {/*<li className="w-full border-b p-4">*/}
                {/*<select*/}
                {/*className="w-full text-sm font-medium font-sans bg-white"*/}
                {/*name="group"*/}
                {/*id=""*/}
                {/*>*/}
                {/*<option value="">Help</option>*/}
                {/*</select>*/}
                {/*</li>*/}
                <li
                  className="w-full border-b p-4 cursor-pointer"
                  onClick={() =>
                    chooseMenuItemPage({
                      pathname: '/',
                      propsStep: 1,
                    })
                  }
                >
                  <span className="pl-1">Become a Driver</span>
                </li>
                <li
                  className="w-full border-b p-4 cursor-pointer"
                  onClick={() => chooseMenuItemPage(ROUTES.FIND_RIDE)}
                >
                  <span className="pl-1">Find ride</span>
                </li>
                <li
                  className="w-full border-b p-4 cursor-pointer"
                  onClick={() => chooseMenuItemPage(ROUTES.FAQ)}
                >
                  <span className="pl-1">FAQ</span>
                </li>
                <li
                  className="w-full border-b p-4 cursor-pointer"
                  onClick={() => chooseMenuItemPage(ROUTES.TERM)}
                >
                  <span className="pl-1">Terms & Privacy Policy</span>
                </li>
              </ul>
              <div
                className="m-auto w-40 text-center py-3 text-nuale_turquoise border-2 rounded-full border-nuale_turquoise text-base font-medium"
                onClick={() =>
                  chooseMenuItemPage({
                    pathname: '/',
                    propsStep: 1,
                  })
                }
              >
                Login / Sign up
              </div>
            </div>
          </div>
        </div>
      </div>
    </TouchActions>
  );
}
