import firebase from 'firebase/app';

export const setUpRecaptcha = async () => {
  window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    size: 'invisible',
    callback: (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      // console.log('response', response);
    },
  });
};
