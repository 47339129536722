import React, { useState } from 'react';
import { SimpleSlider, Welcome } from '../../components';
// import {useAuth} from '../../components/contexts/AuthContext';

export function StartPage(props) {
  const someStep = props.location.propsStep || 0;
  const [activeStep, setActiveStep] = useState(someStep);

  function prevStep() {
    setActiveStep(activeStep - 1);
  }
  function nextStep() {
    setActiveStep(activeStep + 1);
  }

  // const {isUserAuthenticated} = useAuth();

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <SimpleSlider nextStep={nextStep} />;
      case 1:
        return <Welcome prevStep={prevStep} />;
      default:
        return <div>Not Found</div>;
    }
  }

  return <div className={'h-screen'}>{_renderStepContent(activeStep)}</div>;
}
