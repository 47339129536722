import React from 'react';

export const WheelChairPolicy = () => {
  return (
    <div className="wheelchair-policy-block mt-12">
      <h2 className="pb-2 mb-7 font-bold border-b-2 border-gray-400">Wheelchair Policy</h2>
      <p className="text-sm my-4">
        Drivers are required by law and Nuale’s policy to transport riders who use foldable mobility
        devices. Drivers are required to assist riders in the storage of their mobility devices,
        unless physically unable to do so.
      </p>
      <ul className="list-disc text-sm">
        <li>Mobility devices include the following:</li>
        <li>Foldable wheelchair</li>
        <li>Foldable walker</li>
        <li>Foldable scooter</li>
        <li>Canes, crutches and other smaller assistive devices</li>
      </ul>
      <p className="text-sm my-4">
        Wheelchairs and scooters that can safely and securely fit in the vehicle’s trunk or
        backseat, without obstructing the driver’s view, must be accommodated by drivers on the
        Nuale platform.
      </p>
      <p className="text-sm my-4">
        Drivers may not deny service to or otherwise discriminate against individuals with
        disabilities. Drivers who are found to have refused service due to a mobility device are at
        risk for removal from the platform for violating Nuale’s{' '}
        <span className="underline">Terms of Service</span>.
      </p>
    </div>
  );
};
