import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  // from,
  split,
  ApolloClient,
  ApolloProvider,
  // ApolloLink,
  // createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import OneSignal from 'react-onesignal';

import { AuthProvider } from './components/contexts/AuthContext';
import { GlobalVariablesProvider } from './components/contexts/GlobalVariablesContext';
import { WebSocketProvider } from './components/contexts/WebSocketContext';
import { UseRoutes } from './components';
import { getAuthToken } from './shared/helpers/localStorageHelper';

// window.OneSignal = window.OneSignal || [];
// const OneSignal = window.OneSignal;

function App() {
  const routes = UseRoutes();
  // const httpLink = createHttpLink({
  //   uri: process.env.REACT_APP_API_ENDPOINT,
  // });
  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
  });

  // const authLink = new ApolloLink((operation, forward) => {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    const token = getAuthToken();
    // console.log('headers', headers);
    // console.log('_', _);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? token : '',
      },
    };
  });

  const wsLink = new WebSocketLink({
    uri: 'wss://nuale-api.idin.tech/graphql',
    options: {
      reconnect: true,
      // connectionParams: {
      //   authToken: getAuthToken() ? getAuthToken() : '',
      // },
    },
  });

  const additiveLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    authLink.concat(uploadLink),
    // uploadLink,
  );

  const client = new ApolloClient({
    link: additiveLink,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_ID,
      // allowLocalhostAsSecureOrigin: true,
    });
    // OneSignal.getTags((tags) => {
    //   console.log(tags);
    //   Object.keys(tags).forEach((tag) => OneSignal.deleteTag(tag));
    // });
  }, []);

  // TODO: if server is down(or no internet connection) need render some base component
  // with message 'Server is unavailable' or similar

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <WebSocketProvider>
          <GlobalVariablesProvider>
            <BrowserRouter>{routes}</BrowserRouter>
          </GlobalVariablesProvider>
        </WebSocketProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;

// import React, { useEffect } from 'react';
// import { BrowserRouter } from 'react-router-dom';
// import {
//   // from,
//   split,
//   ApolloClient,
//   ApolloProvider,
//   // ApolloLink,
//   // createHttpLink,
//   InMemoryCache,
// } from '@apollo/client';
// import { createUploadLink } from 'apollo-upload-client';
// import { setContext } from '@apollo/client/link/context';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { getMainDefinition } from '@apollo/client/utilities';
//
// import OneSignal from 'react-onesignal';
//
//
// import { AuthProvider } from './components/contexts/AuthContext';
// import { GlobalVariablesProvider } from './components/contexts/GlobalVariablesContext';
// import { WebSocketProvider } from './components/contexts/WebSocketContext';
// import { UseRoutes } from './components';
// import { getAuthToken } from './shared/helpers/localStorageHelper';
//
// // window.OneSignal = window.OneSignal || [];
// // const OneSignal = window.OneSignal;
//
// function App() {
//   const routes = UseRoutes();
//   // const httpLink = createHttpLink({
//   //   uri: process.env.REACT_APP_API_ENDPOINT,
//   // });
//   const uploadLink = createUploadLink({
//     uri: process.env.REACT_APP_API_ENDPOINT,
//   });
//
//
//   // const authLink = new ApolloLink((operation, forward) => {
//   const authLink = setContext((_, { headers }) => {
//     // get the authentication token from local storage if it exists
//
//     const token = getAuthToken();
//     // console.log('headers', headers);
//     // console.log('_', _);
//     // return the headers to the context so httpLink can read them
//     return {
//       headers: {
//         ...headers,
//         authorization: token ? token : '',
//       },
//     };
//   });
//
//   const wsLink = new WebSocketLink({
//     uri: 'wss://nuale-api.idin.tech/graphql',
//     options: {
//       reconnect: true,
//       // connectionParams: {
//       //   authToken: getAuthToken() ? getAuthToken() : '',
//       // },
//     },
//   });
//
//   const additiveLink = split(
//     ({ query }) => {
//       const { kind, operation } = getMainDefinition(query);
//       return kind === 'OperationDefinition' && operation === 'subscription';
//     },
//     wsLink,
//     authLink.concat(uploadLink),
//     // uploadLink,
//   );
//
//
//   // const additiveLink = from([
//   //   // ({ query }) => {
//   //   //       const { kind, operation } = getMainDefinition(query);
//   //   //       return (
//   //   //         kind === 'OperationDefinition' &&
//   //   //         operation === 'subscription'
//   //   //       );
//   //   //     },
//   //   authLink,
//   //   // httpLink,
//   //   uploadLink,
//   //   // wsLink,
//   // ]);
//
//
//   const client = new ApolloClient({
//     link: additiveLink,
//     cache: new InMemoryCache(),
//   });
//
//
//   // useEffect(() => {
//   //   OneSignal.push(() => {
//   //     OneSignal.init({
//   //       appId: process.env.REACT_APP_ONE_SIGNAL_ID_LOCAL,
//   //       allowLocalhostAsSecureOrigin: true,
//   //     })
//   //   });
//   // },[]);
//
//   // useEffect(() => {
//   //   OneSignal.push(()=> {
//   //     OneSignal.init(
//   //       {
//   //         appId: process.env.REACT_APP_ONE_SIGNAL_ID_LOCAL,
//   //         allowLocalhostAsSecureOrigin: true,
//   //         promptOptions: {
//   //           slidedown: {
//   //             enabled: true,
//   //             actionMessage: "We'd like to show you notifications for the latest news and updates about the following categories.",
//   //             acceptButtonText: "OMG YEEEEESS!",
//   //             cancelButtonText: "NAHHH",
//   //             categories: {
//   //               tags: [
//   //                 {
//   //                   tag: "react",
//   //                   label: "ReactJS",
//   //                 },
//   //                 {
//   //                   tag: "angular",
//   //                   label: "Angular",
//   //                 },
//   //                 {
//   //                   tag: "vue",
//   //                   label: "VueJS",
//   //                 },
//   //                 {
//   //                   tag: "js",
//   //                   label: "JavaScript",
//   //                 }
//   //               ]
//   //             }
//   //           }
//   //         },
//   //         welcomeNotification: {
//   //           "title": "One Signal",
//   //           "message": "Thanks for subscribing!",
//   //         }
//   //       },
//   //       OneSignal.getUserId(function(userId) {
//   //         console.log("OneSignal User ID:", userId);
//   //         // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
//   //       }),
//   //       OneSignal.showNativePrompt()
//   //     );
//   //   });
//   // }, []);
//
//   useEffect(() => {
//
//     OneSignal.init(
//       {
//         appId: process.env.REACT_APP_ONE_SIGNAL_ID_LOCAL,
//         allowLocalhostAsSecureOrigin: true,
//         // webhooks: {
//         //   cors: false, // Defaults to false if omitted
//         //   'notification.displayed': 'http://localhost:3000', // e.g. https://site.com/hook
//         //   'notification.clicked': 'http://localhost:3000',
//         //   // ... follow the same format for any event in the list above
//         // }
//       }
//     ).then(() => {
//       // console.log('OneSignal', OneSignal);
//       OneSignal.getUserId(function (userId) {
//         console.log("OneSignal User ID:", userId);
//         // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
//       });
//       // OneSignal.showNativePrompt(),
//       OneSignal.on('subscriptionChange', function (isSubscribed) {
//         console.log("The user's subscription state is now:", isSubscribed);
//         OneSignal.getNotificationPermission(function (permission) {
//           console.log("Site Notification Permission:", permission);
//           // (Output) Site Notification Permission: default
//         });
//       });
//       OneSignal.on('notificationDisplay', function (event) {
//         console.log('OneSignal notification displayed:', event);
//       });
//       OneSignal.on('message', function (event) {
//         console.log('message:', event);
//       });
//
//     })
//     // return () => OneSignal.setSubscription(false);
//   }, []);
//
//
//
//   // TODO: if server is down(or no internet connection) need render some base component
//   // with message 'Server is unavailable' or similar
//
//   return (
//     <ApolloProvider client={client}>
//       <AuthProvider>
//         <WebSocketProvider>
//           <GlobalVariablesProvider>
//             <BrowserRouter>{routes}</BrowserRouter>
//           </GlobalVariablesProvider>
//         </WebSocketProvider>
//       </AuthProvider>
//     </ApolloProvider>
//   );
// }
//
// export default App;
