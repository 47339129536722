import React, {
  useState
} from 'react';

import starPainted from '../../assets/images/star-solid.svg';
import star from '../../assets/images/star-regular.svg';


export const DriverRating = () => {

  const [number, setNumber] = useState(0);
  const [text, setText] = useState("");

  return (
    <>
      <div className="px-16 mb-4">
          <h2 className="mb-2 text-sm">Please rate your driver</h2>
          <div className="flex justify-between w-full">
            <img onClick={() => setNumber(1)} className="w-7" src={number > 0 ? starPainted : star} alt="star1"/>
            <img onClick={() => setNumber(2)} className="w-7" src={number > 1 ? starPainted : star} alt="star2"/>
            <img onClick={() => setNumber(3)} className="w-7" src={number > 2 ? starPainted : star} alt="star3"/>
            <img onClick={() => setNumber(4)} className="w-7" src={number > 3 ? starPainted : star} alt="star4"/>
            <img onClick={() => setNumber(5)} className="w-7" src={number > 4 ? starPainted : star} alt="star5"/>
          </div>
      </div>
      <div className="pb-16">
        <h2 className="font-bold mb-2">Reason for rate</h2>
        <textarea className="border w-full h-32" value={text} onChange={(event)=> setText(event.target.value)}> </textarea>
      </div>
    </>
  );
};
