import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import { UPDATE_USER } from '../../shared/gqlQueries/updateUser';
import { ME_QUERY } from '../../shared/gqlQueries/me';
import { CARMAKERS_QUERY } from '../../shared/gqlQueries/carmakers';
import { VEHICLES_QUERY } from '../../shared/gqlQueries/vehicles';
import {
  UPLOAD_CAR_FILES,
  UPLOAD_LICENSE_FILES,
  UPLOAD_PROFILE_FILE,
} from '../../shared/gqlQueries/uploadFiles';
import { useAuth } from '../contexts/AuthContext';
import NualeSelect from '../inputs/NualeSelect';
import { UploadFiles } from '../UploadFiles/index';

import { ROLES } from '../../shared/constants';

import iconLeft from '../../assets/images/sign-up/icons_left.png';
import deleteItem from '../../assets/images/sign-up/delete.png';
import { MinSpinner } from '../MinSpinner/index';
// import { YearPickerComponent } from '../YearPicker';
import YearPickerComponentWithStyles from '../YearDatePicker';
// import { Button } from "../Button/index.js";

export function DriverInfo({ values, setters, nextStep, prevStep }) {
  const {
    setDriverLicenceNumber,
    setVehicleCategory,
    setVehicleBrand,
    setVehicleModel,
    setFuelType,
    // setFuelConsumption,
    setEngineSize,
    setGender,
    setCarImages,
    setDriverLicenceImages,
    setUserAvatarImage,
    setIdCard,
    setBankName,
    setBankAccountNumber,
    setBeneficiaryName,
    setYearOfRegistration,
  } = setters;

  const {
    driverLicenceNumber,
    vehicleCategory,
    vehicleBrand,
    vehicleModel,
    fuelType,
    // fuelConsumption,
    engineSize,
    gender,
    carImages,
    driverLicenceImages,
    userAvatarImage,
    idCard,
    bankName,
    bankAccountNumber,
    beneficiaryName,
    yearOfRegistration,
  } = values;

  const [showUploadFilesWindow, setShowUploadFilesWindow] = useState(false);
  const [imagesState, setImagesState] = useState('');
  const [carMakers, setCarMakers] = useState([]);
  const [carMakers2, setCarMakers2] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vehicles2, setVehicles2] = useState([]);

  useEffect(() => {
    getCarsInfo();
    getVehicles();
    // document.querySelector('.year-input').setAttribute('placeholder', 'Year of registration');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // carMakers and vehicles arrays preparation function for NualeSelect component
  function addlabelAndValueInPerElementOfArrayForSelectComponent(arr, typeName, vehicles) {
    let newArr;
    let newObj = {};
    newArr = arr.map((el) => {
      let subNewObj = {};
      newObj = { ...el };
      newObj.label = el[typeName];
      newObj.value = el[typeName].toUpperCase();
      if (vehicles) {
        newObj.fuelTypes = newObj.fuelTypes.map((el2) => {
          subNewObj = { ...el2 };
          subNewObj.label = el2.type;
          subNewObj.value = el2.type.toUpperCase();
          return subNewObj;
        });
        newObj.spec = newObj.spec.map((el2) => {
          subNewObj = { ...el2 };
          subNewObj.label = el2.engineSize;
          subNewObj.value = el2.engineSize;
          return subNewObj;
        });
      }
      return newObj;
    });
    return newArr;
  }

  const [getCarsInfo, carsData] = useLazyQuery(CARMAKERS_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[DriverInfo] getCarsInfo', carsData.data.carMakers);
      if (carsData.data && carsData.data.carMakers && carsData.data.carMakers.length) {
        let newArr = addlabelAndValueInPerElementOfArrayForSelectComponent(
          carsData.data.carMakers,
          'name',
        );
        setCarMakers(newArr);
        setCarMakers2(newArr);
      }
    },
    onError: (error) => {
      console.log('[DriverInfo] error getCarsInfo', error);
    },
  });

  const [getVehicles, vehiclesData] = useLazyQuery(VEHICLES_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[DriverInfo] getVehiclesInfo', vehiclesData.data);
      if (vehiclesData.data && vehiclesData.data.vehicles && vehiclesData.data.vehicles.length) {
        let newArr = addlabelAndValueInPerElementOfArrayForSelectComponent(
          vehiclesData.data.vehicles,
          'model',
          true,
        );
        setVehicles(newArr);
        setVehicles2(newArr);
      }
    },
    onError: (error) => {
      console.log('[DriverInfo] error getVehiclesInfo', error);
    },
  });

  const vehicleCategories = [
    { value: 'STANDARD', label: 'Standard' },
    { value: 'ECOFRIENDLY', label: 'Ecofriendly' },
    { value: 'LUXURY', label: 'Luxury' },
  ];

  const genders = [
    { value: 'MALE', label: 'Male' },
    { value: 'FEMALE', label: 'Female' },
  ];

  const { setCurrentUser } = useAuth();

  const [getMyInfo, { data }] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only', // always will fetch data, no cache
    onCompleted: () => {
      // console.log('[DriverInfo] getMyInfo', data);
      setCurrentUser(data.me);
      nextStep();
    },
    onError: (error) => {
      console.log('[DriverInfo] error getMyInfo', error);
    },
  });

  const [updateUser, updateUserStatus] = useMutation(UPDATE_USER, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      setCurrentUser(res.updateUser);
      console.log('update user successfully');
      if (carImages.length || driverLicenceImages.length || userAvatarImage.length) {
        try {
          uploadAllFiles().then((result) => {
            console.log('All files uploaded');
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        nextStep();
      }
    },

    onError: (error) => {
      console.log('[DriverInfo] error updateUser', error);
    },
  });

  async function uploadAllFiles() {
    try {
      carImages.length && (await uploadImagesFunc(uploadCarFiles, carImages));
      driverLicenceImages.length &&
        (await uploadImagesFunc(uploadLicenseFiles, driverLicenceImages));
      userAvatarImage.length && (await uploadUserAvatarFunc());
      if (carImages.length || driverLicenceImages.length || userAvatarImage.length) {
        await getMyInfo();
      }
      return true;
    } catch (error) {
      console.error(error);
    }
  }

  const [uploadUserAvatar, uploadAvatarStatus] = useMutation(UPLOAD_PROFILE_FILE, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('res.uploadUserAvatar', res);
    },
    onError: (error) => {
      console.log('[DriverInfo] error uploadUserAvatar', error);
    },
  });

  const [uploadCarFiles, uploadCarStatus] = useMutation(UPLOAD_CAR_FILES, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('res.uploadCarFiles', res);
    },
    onError: (error) => {
      console.log('[DriverInfo] error uploadCarFiles', error);
    },
  });

  const [uploadLicenseFiles, uploadLicenseStatus] = useMutation(UPLOAD_LICENSE_FILES, {
    errorPolicy: 'all',
    onCompleted: (res) => {
      console.log('res.uploadLicenseFiles', res);
    },
    onError: (error) => {
      console.log('[DriverInfo] error uploadLicenseFiles', error);
    },
  });

  const delItemImage = (event) => {
    let id = event.target.dataset.id;
    let imageName = event.target.dataset.name;

    switch (imageName) {
      case 'car':
        return setCarImages((result) => [...result.filter((el) => el.path !== id)]);
      case 'driver license':
        return setDriverLicenceImages((result) => [...result.filter((el) => el.path !== id)]);
      case 'profile':
        return setUserAvatarImage([]);
      default:
        return null;
    }
  };

  async function uploadImagesFunc(uploadFunc, imagesArray) {
    await uploadFunc({
      variables: {
        files: imagesArray,
      },
    });
  }

  async function uploadUserAvatarFunc() {
    await uploadUserAvatar({
      variables: {
        file: userAvatarImage[0],
      },
    });
  }

  function updateDriverInfo() {
    let driverInfo = {
      driverLicenceNumber,
      idCard,
      vehicleCategory,
      fuelType,
      fuelConsumption: vehicles[0].spec.find((el) => +el.engineSize === +engineSize)
        .fuelConsumption,
      engineSize: engineSize,
      carImages: [],
      driverLicenceImages: [],
      bankName,
      bankAccountNumber,
      beneficiaryName,
      yearOfRegistration: new Date(yearOfRegistration).getFullYear(),
    };

    updateUser({
      variables: {
        input: {
          driverInfo,
          role: ROLES.driver,
          gender,
          userVehicles: [vehicles[0].id],
        },
      },
    });
  }

  function isDisabled() {
    if (
      bankName &&
      bankAccountNumber &&
      beneficiaryName &&
      yearOfRegistration &&
      driverLicenceNumber &&
      idCard &&
      vehicleCategory &&
      vehicleBrand &&
      vehicleModel &&
      fuelType &&
      gender &&
      carImages.length &&
      driverLicenceImages.length
    )
      return false;
    return true;
  }

  function isLoading() {
    if (
      updateUserStatus.loading ||
      uploadCarStatus.loading ||
      uploadLicenseStatus.loading ||
      uploadAvatarStatus.loading
    )
      return true;
    return false;
  }

  return (
    <div className="relative">
      <div className="py-2 flex flex-row items-center">
        <button className="focus:outline-none p-1.5" onClick={() => prevStep()}>
          <img src={iconLeft} alt="Back" className="w-4" />
        </button>
        <div className="font-bold ml-3">Back</div>
      </div>

      <div className="px-3">
        <h1 className="font-bold leading-8" style={{ fontSize: 32 }}>
          Provide us with some additional information
        </h1>
        <div className="mt-6">Help us to ensure the best service</div>
      </div>

      <div className="mt-3">
        <input
          type="text"
          name="driverLicenceNumber"
          id="driverLicenceNumber"
          required
          placeholder="Driver Licence number"
          className="bg-gray-100 text-black text-base rounded-sm px-4 py-2 w-full"
          value={driverLicenceNumber}
          onChange={(e) => setDriverLicenceNumber(e.target.value)}
        />
        <input
          type="text"
          name="idCardNumber"
          id="idCardNumber"
          required
          placeholder="ID card number"
          className="bg-gray-100 text-black text-base rounded-sm px-4 py-2 w-full mt-4"
          value={idCard}
          onChange={(e) => setIdCard(e.target.value)}
        />
        <NualeSelect
          className="mt-4"
          options={vehicleCategories}
          onChange={(event) => setVehicleCategory(event.value)}
          placeholder="Vehicle Category"
          someType={'Category'}
        />
        <NualeSelect
          className="mt-4"
          options={carMakers2}
          onChange={(event) => {
            setVehicleBrand(event.value);
            setCarMakers((current) => carMakers2.filter((el) => el.value === event.value));
            vehicleModel && setVehicleModel('');
            engineSize && setEngineSize('');
            fuelType && setFuelType('');
          }}
          placeholder="Vehicle Brand"
        />
        <NualeSelect
          className="mt-4"
          options={
            vehicles2.length &&
            carMakers.length &&
            vehicles2.filter((b) => b.make.id === carMakers[0].id)
          }
          value={
            vehicles2.length &&
            carMakers.length &&
            vehicles2.filter((el) => el.value === vehicleModel)
          }
          onChange={(event) => {
            setVehicleModel(event.value);
            engineSize && setEngineSize('');
            fuelType && setFuelType('');
          }}
          placeholder="Vehicle Model"
        />
        <NualeSelect
          className="mt-4"
          options={
            vehicles2.length &&
            carMakers.length &&
            vehicles2.filter(
              (b) =>
                b.make.id === carMakers[0].id &&
                b.model.toLowerCase() === vehicleModel.toLowerCase(),
            ).length &&
            vehicles2.filter(
              (b) =>
                b.make.id === carMakers[0].id &&
                b.model.toLowerCase() === vehicleModel.toLowerCase(),
            )[0].spec
          }
          value={
            vehicles2.length &&
            carMakers.length &&
            vehicles2.filter(
              (b) =>
                b.make.id === carMakers[0].id &&
                b.model.toLowerCase() === vehicleModel.toLowerCase(),
            ).length &&
            vehicles2
              .filter(
                (b) =>
                  b.make.id === carMakers[0].id &&
                  b.model.toLowerCase() === vehicleModel.toLowerCase(),
              )[0]
              .spec.filter((el) => el.value === engineSize)
          }
          onChange={(event) => {
            setEngineSize(event.value);
            fuelType && setFuelType('');
          }}
          placeholder="Engine size"
        />
        <NualeSelect
          className="mt-4"
          options={
            vehicles2.length &&
            carMakers.length &&
            vehicles2.filter(
              (b) =>
                b.make.id === carMakers[0].id &&
                b.model.toLowerCase() === vehicleModel.toLowerCase(),
            ).length &&
            vehicles2.filter(
              (b) =>
                b.make.id === carMakers[0].id &&
                b.model.toLowerCase() === vehicleModel.toLowerCase(),
            )[0].fuelTypes
          }
          value={
            vehicles2.length &&
            carMakers.length &&
            vehicles2.filter(
              (b) =>
                b.make.id === carMakers[0].id &&
                b.model.toLowerCase() === vehicleModel.toLowerCase(),
            ).length &&
            vehicles2
              .filter(
                (b) =>
                  b.make.id === carMakers[0].id &&
                  b.model.toLowerCase() === vehicleModel.toLowerCase(),
              )[0]
              .fuelTypes.filter((el) => el.value === fuelType)
          }
          onChange={(event) => {
            setFuelType(event.value);
            setVehicles(() =>
              vehicles2.filter(
                (b) =>
                  b.make.id === carMakers[0].id &&
                  b.model.toLowerCase() === vehicleModel.toLowerCase(),
              ),
            );
          }}
          placeholder="Powered by"
        />
        <NualeSelect
          className="mt-4"
          options={genders}
          onChange={(event) => {
            setGender(event.value);
          }}
          placeholder="Gender"
        />
        <input
          type="text"
          name="bankName"
          id="bankName"
          required
          placeholder="Card bank name"
          className="bg-gray-100 text-base text-black rounded-sm px-4 py-2 w-full mt-4"
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
        />
        <input
          type="text"
          name="bankAccountNumber"
          id="bankAccountNumber"
          required
          placeholder="Bank account number"
          className="bg-gray-100 text-base text-black rounded-sm px-4 py-2 w-full mt-4"
          value={bankAccountNumber}
          onChange={(e) => setBankAccountNumber(e.target.value)}
        />
        <input
          type="text"
          name="beneficiaryName"
          id="beneficiaryName"
          required
          placeholder="Beneficiary name"
          className="bg-gray-100 text-base text-black rounded-sm px-4 py-2 w-full mt-4"
          value={beneficiaryName}
          onChange={(e) => setBeneficiaryName(e.target.value)}
        />
        {/*<input*/}
        {/*type="text"*/}
        {/*name="yearOfRegistration"*/}
        {/*id="yearOfRegistration"*/}
        {/*required*/}
        {/*placeholder="Year of registration"*/}
        {/*className="bg-gray-100 text-black rounded-sm px-4 py-2 w-full mt-4"*/}
        {/*value={yearOfRegistration}*/}
        {/*onChange={(e) => setYearOfRegistration(e.target.value)}*/}
        {/*/>*/}
        <YearPickerComponentWithStyles
          className={'year-picker-signUp'}
          name="yearOfRegistration"
          id="yearOfRegistration"
          placeholder="Year of registration" // placeholder not work here, look at useEffect
          value={yearOfRegistration}
          onChange={(date) => setYearOfRegistration(date)}
        />
        {/*<YearPickerComponent*/}
        {/*name="yearOfRegistration"*/}
        {/*id="yearOfRegistration"*/}
        {/*placeholder="Year of registration" // placeholder not work here, look at useEffect*/}
        {/*value={yearOfRegistration}*/}
        {/*onChange={(date) => setYearOfRegistration(date)}*/}
        {/*/>*/}
        <div className="relative font-bold text-base text-nuale_neon_blue mt-4 mb-9 cursor-pointer">
          <div className="flex flex-col justify-center">
            {!carImages.length && (
              <span
                className="cursor-pointer flex items-center"
                data-name="carImages"
                data-value="car"
                onClick={(event) => {
                  setImagesState(event.target.dataset.value);
                  setShowUploadFilesWindow(true);
                }}
              >
                <span className="text-3xl font-medium">+</span>
                Add picture of your car
              </span>
            )}
            {!driverLicenceImages.length && (
              <span
                className="cursor-pointer flex items-center"
                data-name="driverLicenceImages"
                data-value="driver license"
                onClick={(event) => {
                  setImagesState(event.target.dataset.value);
                  setShowUploadFilesWindow(true);
                }}
              >
                <span className="text-3xl font-medium">+</span>
                Add driving license
              </span>
            )}
            {!userAvatarImage.length && (
              <span
                className="cursor-pointer flex items-center"
                data-name="userAvatarImage"
                data-value="profile"
                onClick={(event) => {
                  setImagesState(event.target.dataset.value);
                  setShowUploadFilesWindow(true);
                }}
              >
                <span className="text-3xl font-medium">+</span>
                Add profile picture
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap">
          {carImages &&
            carImages.map((el, index) => (
              <div
                className="relative w-20 h-20 mr-4 mb-4 border rounded"
                key={`${imagesState}${Date.now()}${index}`}
                style={{
                  background: `url(${el.path}) no-repeat center`,
                  backgroundSize: '100%',
                }}
              >
                <img
                  data-id={el.path}
                  data-name="car"
                  // data-id={`${imagesState}${imagePlace}${index.js}`}
                  className="absolute -top-3 -right-3 w-5 opacity-70 cursor-pointer"
                  onClick={(event) => {
                    delItemImage(event);
                  }}
                  src={deleteItem}
                  alt="delete icon"
                />
                <span className="absolute -bottom-1.5 -left-1.5 bg-white rounded-full px-1 text-min border bg-nuale_neon_blue text-white">
                  CAR
                </span>
              </div>
            ))}
          {driverLicenceImages &&
            driverLicenceImages.map((el, index) => (
              <div
                className="relative w-20 h-20 mr-4 mb-4 border rounded"
                key={`${imagesState}${Date.now()}${index}`}
                style={{
                  background: `url(${el.path}) no-repeat center`,
                  backgroundSize: '100%',
                }}
              >
                <img
                  data-id={el.path}
                  data-name="driver license"
                  // data-id={`${imagesState}${imagePlace}${index.js}`}
                  className="absolute -top-3 -right-3 w-5 opacity-70 cursor-pointer"
                  onClick={(event) => {
                    delItemImage(event);
                  }}
                  src={deleteItem}
                  alt="delete icon"
                />
                <span className="absolute -bottom-1.5 -left-1.5 bg-white rounded-full px-1 text-min border bg-nuale_neon_blue text-white">
                  LICENSE
                </span>
              </div>
            ))}
          {userAvatarImage &&
            userAvatarImage.map((el, index) => (
              <div
                className="relative w-20 h-20 rounded border"
                key={`${imagesState}${Date.now()}${index}`}
                style={{
                  background: `url(${el.path}) no-repeat center`,
                  backgroundSize: '100%',
                }}
              >
                <img
                  data-id={el.path}
                  data-name="profile"
                  // data-id={`${imagesState}${imagePlace}${index.js}`}
                  className="absolute -top-3 -right-3 w-5 opacity-70 cursor-pointer"
                  onClick={(event) => {
                    delItemImage(event);
                  }}
                  src={deleteItem}
                  alt="delete icon"
                />
                <span className="absolute -bottom-1.5 -left-1.5 bg-white rounded-full px-1 text-min border bg-nuale_neon_blue text-white">
                  PROFILE
                </span>
              </div>
            ))}
        </div>

        <button
          disabled={isLoading() || isDisabled()}
          onClick={updateDriverInfo}
          className="relative block mt-12 w-full disabled:opacity-50 bg-blue-700 text-white text-base font-bold px-4 py-2 rounded-full focus:outline-none"
        >
          {!isLoading() ? 'Register as driver' : <MinSpinner />}
        </button>
        {/*<Button*/}
        {/*classProp={"relative block mt-12 disabled:opacity-50 bg-blue-700 text-white text-base font-bold px-4 rounded-full focus:outline-none"}*/}
        {/*clickFuncProp={updateDriverInfo}*/}
        {/*isDisableProp={isLoading() || isDisabled()}*/}
        {/*contentProp={!isLoading() ? 'Register as driver' : <MinSpinner />}*/}
        {/*/>*/}
      </div>

      <UploadFiles
        values={values}
        showUploadFilesWindow={showUploadFilesWindow}
        setShowUploadFilesWindow={setShowUploadFilesWindow}
        setCarImages={setCarImages}
        setDriverLicenceImages={setDriverLicenceImages}
        setUserAvatarImage={setUserAvatarImage}
        imagesState={imagesState}
        delItemImage={delItemImage}
        uploadAvatarStatus={uploadAvatarStatus.loading}
      />
    </div>
  );
}
